import React from "react";
import {
  HGInputProps,
  HGLabel,
  HGLabelProps,
  useHGFormError,
} from "./utils/HGInput.utils";
import { useEditContext } from "../HGFormContext";
import { Form } from "react-bootstrap";
import { HGFormComponent } from "./utils/HGFormComponent";

export const HGNumber: React.FC<
  HGLabelProps &
    HGInputProps & {
      inputGroup?: any;
    }
> = ({ name, defaultValue, inputGroup, required, ...props }) => {
  const { editing } = useEditContext();
  const { ErrorDisplay, isInvalid } = useHGFormError({
    name,
    overrideError: props.error,
    editing,
  });
  return (
    <Form.Group>
      <HGLabel {...props} />
      {(!editing && <p>{defaultValue}</p>) || (
        <>
          <HGFormComponent
            type="number"
            defaultValue={defaultValue}
            name={name}
            isInvalid={isInvalid}
            onChange={props?.onChange}
            defaultName={props?.defaultName}
            inputGroup={inputGroup}
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-") {
                e.preventDefault();
              }
            }}
            min={props?.min}
            placeholder={props?.placeholder}
          />
          <ErrorDisplay />
        </>
      )}
    </Form.Group>
  );
};
