import {
  PriorityBadge,
  SeverityBadge,
  StatusBadge,
  AssessmentResponseStatusBadge,
} from "common/Badges";
import { InfoBubble } from "common/InfoBubble/InfoBubble";
import React, { useEffect, useState } from "react";
import { formatStatus } from "utils/formatStatus";
import useFileManager from "utils/useFileManager";
import { useGetScenario } from "utils/connectors/scenarioConnectors";
import { useThresholds } from "../../Modules/Customers/Risk/riskSchema";
import { rgba } from "polished";
import { getStatusProps } from "Modules/Customers/Assessment/StatusDisplay/StatusDisplay";
import { useGetControl } from "utils/connectors/controlsConnectors";
import { Modal } from "react-bootstrap";
import { sleep } from "utils/useSleep";

const dataFields = ({
  status,
  priority,
  severity,
  responseStatus,
  dueDate,
  displayID,
  ...remainder
}: any) => {
  const entries = Object.entries({
    status,
    priority,
    severity,
    responseStatus,
    dueDate,
    displayID,
  });

  return entries
    .filter(([key, val]) => !!key && !!val)
    .map(([key, val]) => {
      switch (key) {
        case "status":
          return [key, <StatusBadge status={val} />];
        case "priority":
          return [key, <PriorityBadge priority={val} />];
        case "severity":
          return [key, <SeverityBadge severity={val} />];
        case "responseStatus":
          return [key, <AssessmentResponseStatusBadge status={val} />];
        case "dueDate":
        case "displayID":
          return [
            <b>
              {(key.charAt(0).toUpperCase() + key.slice(1))
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .trim()}
            </b>,
            val,
          ];
        default:
          return [key, val];
      }
    });
};

export const AttachedItem = ({
  type,
  item,
  detach,
  refetch,
  loading,
  setLoading,
  currentId,
  setCurrentID,
  userRole,
  groups,
}) => {
  const _item = item.item;
  const itemData = dataFields(_item || {});
  const fullData = type.name === "Controls" && useGetControl(item.id);
  const [isOpen, setIsOpend] = useState(false);
  return (
    <>
      <Modal show={isOpen} onHide={() => setIsOpend(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <table className="table  table-striped table-bordered">
            <tr>
              <td>Display ID</td>
              <td>{fullData?.displayID}</td>
            </tr>
            <tr>
              <td>Title</td>
              <td>{fullData?.title}</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{fullData?.description}</td>
            </tr>
            <tr>
              <td>Discussion</td>
              <td>{fullData?.discussion}</td>
            </tr>
          </table>
        </Modal.Body>
      </Modal>
      <p
        className="attachedItem"
        style={{
          width: "100%",
          padding: "5px",
          paddingLeft: "15px",
        }}
      >
        <div>
          <InfoBubble
            title={type.format(item)}
            archived={item?.item?.archived}
            itemId={_item.id}
            loading={loading}
            currentId={currentId}
            modalFn={type.name === "Controls" ? setIsOpend : ""}
            userRole={userRole}
            groupTitle={_item.relatedItemsGroup?.title ?? ""}
            date={
              <span style={{ textTransform: "capitalize" }}>
                {itemData?.map(([key, val]) => (
                  <span style={{ display: "inline-block" }}>
                    {key}:{val}{" "}
                  </span>
                ))}
              </span>
            }
            onCancel={async () => {
              if (_item.id) {
                setLoading(true);
                setCurrentID((currentId) => [...currentId, _item.id]);
                await detach(_item.id);
                if (refetch) {
                  await refetch();
                }
                setCurrentID((currentId) => {
                  return currentId?.filter((a) => a !== _item.id);
                });
                setLoading(false);
              }
            }}
          />
        </div>
      </p>
    </>
  );
};
export const AttachedScenario = ({
  type,
  item,
  detach,
  refetch,
  loading,
  setLoading,
  currentId,
  setCurrentID,
  userRole,
}) => {
  const { scenario } = useGetScenario(item.id, false);
  const { getThresholdStatus, getThresholdColor } = useThresholds();
  const thresholdColor = getThresholdColor(scenario);
  const threshold = getThresholdStatus(scenario);
  console.log("GROUP CHECK TEST", scenario);
  const backgrroundColor = (() => {
    switch (threshold) {
      case "Exceeds Capacity":
        return { background: rgba(216, 54, 72, 0.2) };
      case "Exceeds Appetite":
        return { background: rgba(238, 130, 25, 0.2) };
      case "Acceptable":
        return { background: rgba(12, 138, 74, 0.2) };
      default:
        return {};
    }
  })();
  const _item = item.item;
  const itemData = dataFields(_item || {});
  return (
    <p
      className="attachedItem"
      style={{
        width: "100%",
        padding: "5px",
        paddingLeft: "15px",
      }}
    >
      <div>
        <InfoBubble
          title={type.format(item)}
          archived={item?.item?.archived}
          itemId={_item.id}
          loading={loading}
          currentId={currentId}
          color={backgrroundColor}
          thresholdColor={thresholdColor}
          userRole={userRole}
          groupTitle={_item.relatedItemsGroup.title}
          date={
            <span style={{ textTransform: "capitalize" }}>
              {itemData?.map(([key, val]) => (
                <span>
                  {key}:{val}{" "}
                </span>
              ))}
            </span>
          }
          onCancel={async () => {
            if (_item.id) {
              setLoading(true);
              setCurrentID((currentId) => [...currentId, _item.id]);
              await detach(_item.id);
              if (refetch) {
                await refetch();
              }
              setCurrentID((currentId) => {
                return currentId?.filter((a) => a !== _item.id);
              });
              setLoading(false);
            }
          }}
        />
      </div>
    </p>
  );
};
export const AttachedArtifact = ({
  item,
  detach,
  refetch,
  loading,
  setLoading,
  currentId,
  setCurrentID,
  userRole,
}) => {
  const fileManager = useFileManager();
  console.log("DIAGNOSE ITEM", item);
  return (
    <p
      className="attachedItem"
      style={{
        width: "100%",
      }}
    >
      <InfoBubble
        title={item?.item?.fileName}
        archived={item?.item?.archived}
        itemId={item.id}
        loading={loading}
        currentId={currentId}
        downloadButton
        extension={item.extension}
        userRole={userRole}
        groupTitle={item?.item?.relatedItemsGroup.title}
        onCancel={async () => {
          if (item.id) {
            setLoading(true);
            setCurrentID((currentId) => [...currentId, item.id]);
            await detach(item.id);
            if (refetch) {
              await refetch();
            }
            setCurrentID((currentId) => {
              return currentId?.filter((a) => a !== item.id);
            });
            setLoading(false);
          }
        }}
        onClick={() => {
          fileManager.download(item.id as string, item.title);
        }}
      />
    </p>
  );
};

export const AttachedURL = ({
  item,
  detach,
  refetch,
  loading,
  setLoading,
  currentId,
  setCurrentID,
  userRole,
}) => {
  return (
    <p
      className="attachedItem"
      style={{
        width: "100%",
        padding: "5px",
        paddingLeft: "15px",
      }}
    >
      <InfoBubble
        title={
          <a
            href={item.address}
            target="_blank"
            className="p0"
            rel="noreferrer"
          >
            {item.title ?? "unset"}
          </a>
        }
        url={item.address}
        userRole={userRole}
        groupTitle={item?.item?.relatedItemsGroup.title}
        onCancel={async () => {
          if (item.id) {
            setLoading(true);
            setCurrentID((currentId) => [...currentId, item.id]);
            await detach(item.id);
            if (refetch) {
              await refetch();
            }
            setCurrentID((currentId) => {
              return currentId?.filter((a) => a !== item.id);
            });
            setLoading(false);
          }
        }}
        itemId={item.id}
        loading={loading}
        currentId={currentId}
      />
    </p>
  );
};
