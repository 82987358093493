import { DateTime } from "luxon";
import { usePermissions } from "common/Permissions";
import { assertNever } from "utils/typeUtils";

export type GenerateFileReportParams =
  | {
      type: "filename";
      fileName: string;
      timestamp: DateTime;
      extension?: string;
    }
  | {
      type: "itemTitle";
      itemType: string;
      item: { title: string };
      timestamp: DateTime;
      extension?: string;
    }
  | {
      type: "moduleBulk";
      itemType: string;
      timestamp: DateTime;
      extension?: string;
    };

export const filenameTimestamp = (timestamp: DateTime) =>
  timestamp.toLocal().toFormat("MMM-dd-yyyy_hh-mm_a");

export const useGenerateReportFilename = () => {
  const permissions = usePermissions();
  return (props: GenerateFileReportParams) => {
    const extension = props.extension ?? "docx";
    switch (props.type) {
      case "filename":
        return `${props.fileName}-${filenameTimestamp(
          props.timestamp
        )}.${extension}`;
      case "itemTitle":
        return `DecipherRisk-${props.item.title}-${
          props.itemType
        }-${filenameTimestamp(props.timestamp)}.${extension}`;
      case "moduleBulk":
        return `DecipherRisk-${permissions.organization.title}-${
          permissions.group.title
        }-${props.itemType}-${filenameTimestamp(props.timestamp)}.${extension}`;
      default:
        return assertNever(props);
    }
  };
};
