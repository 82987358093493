import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AssessmentRoutes from "Modules/Customers/Assessment/AssessmentRoutes";
import HIPAARiskAssessmentRoutes from "Modules/Customers/HIPAARiskAssessment/HIPAARiskAssessmentRoutes";
import { AllNotifications } from "Modules/Customers/AllNotifications";
import { AuthUI } from "common/AuthUI";
import { RiskDomainCalendar } from "Modules/Customers/Audit/RiskDomain/RiskDomainCalendar";
import { AuditPlanningList as AuditPlanning } from "Modules/Customers/Audit/AuditPlanning";
import { CreateAuditPlanning } from "Modules/Customers/Audit/AuditPlanning/CreateAuditPlanning";
import { AuditPlanningPage } from "Modules/Customers/Audit/AuditPlanning/AuditPlanningPage/AuditPlanningPage";
import Loader from "common/Loader";
import SystemManagementRoutes from "Modules/Customers/SystemManagement/SystemManagementRoutes";
import AdministrationRoutes from "Modules/Customers/Administration/AdministrationRoutes";

const RiskRoutes = React.lazy(
  () => import("Modules/Customers/Risk/RiskRoutes")
);
const UserSettings = React.lazy(() => import("Modules/Customers/UserSettings"));
const Dashboard = React.lazy(() => import("Modules/Customers/Dashboard"));
const DashboardGroup = React.lazy(
  () => import("Modules/Customers/Dashboard/DashboardGroup")
);
const PerformanceMatrix = React.lazy(
  () =>
    import("Modules/Customers/Dashboard/PerformanceMatrix/PerformanceMatrix")
);
const Analysis = React.lazy(() => import("Modules/Customers/Risk/Analysis"));
const ActionPlanRoutes = React.lazy(
  () => import("Modules/Customers/ActionPlans/ActionPlanRoutes")
);

const IssueRoutes = React.lazy(
  () => import("Modules/Customers/Issues/IssueRoutes")
);
const AppRoutes: React.FC = ({ children }) => {
  return (
    <AuthUI>
      {children}
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/dashboard/department">
            <DashboardGroup />
          </Route>
          <Route exact path="/dashboard/performanceMatrix">
            <PerformanceMatrix />
          </Route>
          <Route path="/assessments">
            <AssessmentRoutes />
          </Route>
          <Route path="/HIPAARiskAssessment">
            <HIPAARiskAssessmentRoutes />
          </Route>

          <Route exact path="/audits">
            <AuditPlanning />
          </Route>
          <Route exact path="/audits/create">
            <CreateAuditPlanning />
          </Route>
          <Route exact path="/audits/calendar">
            <RiskDomainCalendar />
          </Route>
          <Route path="/audits/:id/:tab/:relatedItems?">
            <AuditPlanningPage />
          </Route>
          <Redirect from="/audits/:id" to="/audits/:id/details" />

          <Route path="/issues">
            <IssueRoutes />
          </Route>
          <Route path="/risk/dev">
            <Analysis />
          </Route>
          <Route path="/risk">
            <RiskRoutes />
          </Route>
          {/* <Route path="/solutions">
          <SolutionsRoutes />
        </Route> */}
          <Route path="/actionplans">
            <ActionPlanRoutes />
          </Route>

          <Route path="/systemManagement">
            <SystemManagementRoutes />
          </Route>

          <Route path="/administration">
            <AdministrationRoutes />
          </Route>

          <Route exact path="/settings">
            <UserSettings />
          </Route>

          <Route exact path="/AllNotifications">
            <AllNotifications />
          </Route>

          {/* Old Routes Start */}
          <Route exact path="/audits/riskdomain">
            <Redirect to="/systemManagement/systemLists/riskDomain" />
          </Route>
          <Route
            exact
            path="/audits/riskdomain/:id"
            render={(props) => (
              <Redirect
                to={`/systemManagement/systemLists/riskDomain/${props.match.params.id}`}
              />
            )}
          />
          <Route exact path="/reporting/summary">
            <Redirect to="/systemManagement/performanceMetrics" />
          </Route>
          <Route exact path="/reporting/changelog">
            <Redirect to="/administration/changeLog" />
          </Route>

          <Route path="/permissions">
            <Redirect to="/administration/permissions" />
          </Route>

          <Route path="/artifacts">
            <Redirect to="/systemManagement/systemLists/artifacts" />
          </Route>

          <Route exact path="/issues/sources">
            <Redirect to="/systemManagement/systemLists/sources" />
          </Route>

          <Route path="/settings/organization">
            <Redirect to="/administration/organizationSetting" />
          </Route>

          {/* <Route exact path="/settings/system">
            <Redirect to="/administration/systemSetting" />
          </Route> */}
          {/* Old Routes End */}
          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    </AuthUI>
  );
};

export default AppRoutes;
