import React, { useState, useMemo, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  FormLabel,
} from "react-bootstrap";
import { useMutation, ApolloError } from "@apollo/client";
import { createActionPlan } from "graphql/mutations";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import * as yup from "yup";
import { CreateActionPlanInput, Priority } from "API";
import { HGForm, HGFormSubmit } from "common/HGForm";
import { usePermissions } from "common/Permissions";
import { ActionPlanTypeEnum, useUserEnum } from "utils/dataFormatters";
import { ActionPlanStatus } from "utils/formatStatus";
import { cancelPopupMessage } from "utils/globalVariables";
import { HGRichText } from "common/HGForm/HGRichText";
import { HGDatePicker } from "common/HGForm/HGDatePicker";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { useGetActionPlansInSelectedGroup } from "utils/connectors/actionPlanConnectors";
import { GroupSelector } from "common/layout/Header/GroupSelector";
import { HGInputBox, HGCheckBox, HGSelect } from "common/HGForm/Inputs";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";

export const CreateActionPlanButton: React.FC<{
  onComplete?: Function;
  onOpen?: Function;
  onClose?: Function;
}> = ({ onComplete, onOpen, onClose, ...props }) => {
  const { group, userId } = usePermissions();
  const [showCreateActionPlan, setShowCreateActionPlan] =
    useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [actionPlans, setActionPlan] = useState([]);
  const [groupID, setGroupID] = useState(group.id);
  const users = useUserEnum(groupID);
  const getItemInSelectedGroup = useGetActionPlansInSelectedGroup();

  const [makeActionPlan] = useMutation(gql(createActionPlan), {
    onCompleted: (response) => {
      setShowCreateActionPlan(false);
      onComplete?.(response?.CreateActionPlan);
      setGroupID(group.id);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
    // errorPolicy: "all",
  });

  const createActionPlanSchema = useMemo(
    () =>
      yup.object<Partial<CreateActionPlanInput>>({
        title: yup
          .string()
          .default("")
          .required()
          .label("Title")
          .test(
            "check-duplication",
            "Action Plan title already exists!",
            (value) => {
              if (!value) return true;
              return !isUniqueTitle(actionPlans, value);
            }
          )
          .matches(
            /^(?!\s+$).*/,
            "This field cannot contain only blank spaces"
          ),
        description: yup.string().label("Description"),
        resources: yup.string().label("Resources"),
        notes: yup.string().label("Notes"),
        groupID: yup.string().default(groupID).required().label("GroupID"),
        owner: yup.string().label("Owner").default(userId).required(),
        approver: yup.string().label("Approver").default(userId).required(),
        priority: yup
          .mixed()
          .oneOf(Object.values(Priority))
          .required()
          .default(Priority.Null)
          .label("Priority"),
        status: yup
          .mixed()
          .oneOf(Object.values(ActionPlanStatus))
          .required()
          .default(ActionPlanStatus.Created)
          .label("Status"),
        type: yup.string().default("").label("Type"),
        startDate: yup
          .string()
          .default("")
          .label("Start Date")
          .test(
            "is-date",
            // eslint-disable-next-line
            "${path} should be in MM/DD/YYYY format",
            (value) => {
              if (value) {
                const date = DateTime.fromFormat(value, "MM/dd/yyyy");
                return date?.isValid;
              }
              return true;
            }
          ),
        dueDate: yup
          .string()
          .default("")
          .label("Due Date")
          .test(
            "is-date",
            // eslint-disable-next-line
            "${path} should be in MM/DD/YYYY format",
            (value) => {
              if (value) {
                const date = DateTime.fromFormat(value, "MM/dd/yyyy");
                return date?.isValid;
              }
              return true;
            }
          ),
        reviewDate: yup
          .string()
          .default("")
          .label("Review Date")
          .test(
            "is-date",
            // eslint-disable-next-line
            "${path} should be in MM/DD/YYYY format",
            (value) => {
              if (value) {
                const date = DateTime.fromFormat(value, "MM/dd/yyyy");
                return date?.isValid;
              }
              return true;
            }
          ),
        archived: yup.boolean().required().default(false),
        effortAmount: yup.number().default(-1),
        isTemplate: yup.boolean(),
      }),
    [group, actionPlans]
  );
  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowCreateActionPlan(false);
    }
  }

  useEffect(() => {
    if (showCreateActionPlan) {
      return onOpen?.();
    }
    return onClose?.();
  }, [showCreateActionPlan]);

  useEffect(() => {
    getItemInSelectedGroup({
      variables: { id: groupID },
    }).then((actionPlans) => {
      setActionPlan(actionPlans);
    });
  }, [groupID]);

  return (
    <>
      <Button
        {...props}
        onClick={() => {
          setShowCreateActionPlan(true);
        }}
      >
        Create Action Plan
      </Button>

      <Modal
        show={!!showCreateActionPlan}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          onSubmit={async (input) => {
            return makeActionPlan({
              variables: { input: { ...input, isTemplate } },
            });
          }}
          resolver={createActionPlanSchema}
        >
          <Modal.Header>
            Create Action Plan
            <div className="float-right">
              <GroupSelector
                groupID={groupID}
                onChange={(id) => {
                  setGroupID(id);
                }}
                isAttachment
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <HGInputBox name="title" label="Title" />
              </Col>
              <Col sm={12}>
                <HGCheckBox
                  id="isTemplate"
                  label="Create as Template"
                  checkGroup="isTemplate"
                  checked={isTemplate}
                  onChange={() => setIsTemplate((prevState) => !prevState)}
                />
              </Col>
              <Col sm={12}>
                <HGRichText label="Description" name="description" />
              </Col>
              <Col sm={12}>
                <HGRichText label="Resources" name="resources" />
              </Col>

              <Col sm={12}>
                <HGRichText label="Notes" name="notes" />
              </Col>

              <Col sm={6}>
                <HGSelect label="Owner" options={users} name="owner" />
              </Col>
              <Col sm={6}>
                <HGSelect label="Approver" name="approver" options={users} />
              </Col>
              <Col sm={6}>
                <HGDatePicker name="startDate" label="Start Date" />
              </Col>
              <Col sm={6}>
                <HGDatePicker name="dueDate" label="Due Date" />
              </Col>
              <Col sm={6}>
                <HGDatePicker name="reviewDate" label="Review Date" />
              </Col>
              <Col sm={6}>
                <HGSelect label="Priority" name="priority" enumObj={Priority} />
              </Col>
              <Col sm={6}>
                <HGSelect
                  label="Status"
                  name="status"
                  enumObj={ActionPlanStatus}
                />
              </Col>
              <Col sm={6}>
                <HGSelect
                  label="Type"
                  name="type"
                  options={ActionPlanTypeEnum}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel-btn"
              onClick={() => {
                Confirmation();
                setGroupID(group.id);
              }}
              variant="light"
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
