import React from "react";
import Form from "react-bootstrap/Form";
import { useFormContext } from "react-hook-form";

export type HGInputProps = {
  onChange?: (e: any) => void;
  error?: boolean | string;
  defaultValue?: string | number | null;
  defaultName?: string;
  name: string;
  value?: string | number;
  placeholder?: string;
  inputGroup?: any;
  required?: boolean;
  min?: number;
  onKeyDown?: (e: any) => void;
};

export type HGLabelProps = {
  label?: string;
  labelClass?: string;
};

export const HGLabel: React.FC<HGLabelProps> = ({ label, labelClass }) => {
  return label ? (
    <Form.Label className={`font-weight-bold ${labelClass || undefined}`}>
      {label}
    </Form.Label>
  ) : (
    <></>
  );
};

export type HGFormErrorProps = HGInputProps & {
  editing?: boolean;
  overrideError?: boolean | string | { message: string };
};
export const useHGFormError = (props: HGFormErrorProps) => {
  const { errors } = useFormContext();
  const error =
    errors[props?.name] ||
    props?.name.split(".").reduce((acc, key) => acc && acc[key], errors);
  const ErrorDisplay = () => (
    <>
      {props.editing && (props.overrideError || error) && (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {props.overrideError || error.message}
        </Form.Control.Feedback>
      )}
    </>
  );
  const isInvalid = !!error || !!props.overrideError;

  return { isInvalid, ErrorDisplay };
};
