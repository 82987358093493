import React from "react";

import { Link } from "react-router-dom";

export const CreateRiskButton: React.FC = () => {
  return (
    <Link className="btn btn-primary" role="button" to="/risk/create">
      Create Risk Scenario
    </Link>
  );
};
