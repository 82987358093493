import { GetRiskDomainQuery } from "API";
import { HGRichTextRead } from "common/HGForm/HGRichText";
import { ITableRecordFormatting } from "common/TabbedTable";
import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getUserFormat,
  getDateTimeFormat,
  getDateTimeDisplayFormat,
} from "utils/dataFormatters";
import { useGetRiskDomainInGroup } from "utils/connectors/riskDomainConnectors";

type schemaParams = {
  archiveRiskDomain?: Function;
  showGroupPrefix?: boolean;
  userRole?: string;
};

export const useriskDomainSchema = (params?: schemaParams) => {
  const _riskDomains = useGetRiskDomainInGroup();
  const formatters: Array<
  ITableRecordFormatting<NonNullable<GetRiskDomainQuery["getRiskDomain"]>>
  > = [
    {
      name: "ID",
      format: (riskDomain) => riskDomain.displayID,
      tableDisplayFormat: (riskDomain) => {
        return (
          (riskDomain.id && riskDomain.sort && (
            <Link to={`/systemManagement/systemLists/riskDomain/${riskDomain?.id}`}>
              {riskDomain?.displayID}
            </Link>
          ))
          || ""
        );
      },
      required: true,
      first: true,
    },
    {
      name: "Title",
      format: (riskDomain) => riskDomain.title,
      tableDisplayFormat: (riskDomain) => {
        return (
          (riskDomain.id && riskDomain.sort && (
            <Link to={`/systemManagement/systemLists/riskDomain/${riskDomain?.id}`}>
              {riskDomain.title}
            </Link>
          ))
          || ""
        );
      },
      required: true,
      first: true,
    },
    {
      name: "Description",
      format: (riskDomain) => riskDomain?.description,
      tableDisplayFormat: (riskDomain) => (
        <HGRichTextRead content={riskDomain?.description} />
      ),
      defaultVisible: false,
    },
    {
      id: "parent",
      name: "Parent",
      format: (domain) => {
        return _riskDomains?.riskDomains?.find((s) => domain.parentID === s.id)
          ?.archived
          ? `${
            _riskDomains?.riskDomains?.find((s) => domain.parentID === s.id)
              ?.title
          }  (Archived)`
          : _riskDomains?.riskDomains?.find((s) => domain.parentID === s.id)
            ?.title;
      },
    },
    {
      id: "createdBy",
      name: "Created By",
      format: getUserFormat("riskDomainCreatedBy"),
      defaultVisible: false,
    },
    {
      id: "modifiedBy",
      name: "Modified By",
      format: getUserFormat("riskDomainModifiedBy"),
      defaultVisible: false,
    },
    {
      id: "createdAt",
      name: "Created At",
      type: "Date",
      format: getDateTimeFormat("createdAt"),
      displayFormat: getDateTimeDisplayFormat("createdAt"),
      defaultVisible: false,
    },
    {
      id: "lastModified",
      name: "Last Modified",
      type: "Date",
      format: getDateTimeFormat("lastModified"),
      displayFormat: getDateTimeDisplayFormat("lastModified"),
      defaultVisible: false,
    },
    {
      id: "",
      name: "",
      format: () => "",
      tableDisplayFormat: (riskDomain) => (
        params?.userRole !== 'read'
          ? <Button
          variant="outline-primary"
          onClick={() =>
            params?.archiveRiskDomain?.(riskDomain.id, !riskDomain.archived)
          }
          >
          {(riskDomain.archived && "Unarchive") || "Archive"}
            </Button> : <></>
      ),
      required: true,
      last: true,
    },
  ];
  return formatters;
};
