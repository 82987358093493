// @ts-nocheck
import React, { createContext, useState, useContext } from "react";
import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useAsync } from "react-use";
import { MfaModal } from "Modules/Customers/UserSettings/MfaModal";

type UserAttributes = {
  sub?: string;
  email?: string;
  given_name?: string;
  family_name?: string;
  username?: string;
};

interface AuthContext {
  isAuthenticated?: boolean;
  userAttributes?: UserAttributes;
  jwtToken?: string;
  changePassword?: (lastPass, newPass) => Promise<any>;
  mfaState?: string | null;
  setupMfa?: () => void;
}

const AuthStateContext = createContext<AuthContext>({
  isAuthenticated: undefined,
  userAttributes: undefined,
  jwtToken: undefined,
  changePassword: undefined,
  mfaState: undefined,
  setupMfa: () => {},
});

const AuthState = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [jwtToken, setJwtToken] = useState<string | undefined>(undefined);
  const [userAttributes, setUserAttributes] = useState<
    UserAttributes | undefined
  >(undefined);
  const [user, setUser] = React.useState<CognitoUser | null | undefined>(
    undefined
  );

  const [mfaState, setMfaState] = useState<string | null>();
  const [mfaCode, setMfaCode] = useState<string | null>(null);

  Hub.listen("auth", ({ payload }) => {
    if (payload?.event === "signIn") {
      Auth.currentAuthenticatedUser()
        .then((u) => {
          setUser(u);
        })
        .catch(() => {
          setUser(null);
        });
      setUserAttributes({
        ...(payload?.data?.attributes ?? {}),
        username: payload.data?.username,
      });
      setIsAuthenticated(true);
    } else if (payload?.event === "signOut") {
      setUser(null);
      setIsAuthenticated(false);
      setJwtToken(undefined);
      setUserAttributes(undefined);
    }
  });
  useAsync(async () => {
    Auth.currentAuthenticatedUser()
      .then((u) => {
        setUser(u);
      })
      .catch(() => {
        setUser(null);
      });
    Auth.currentSession()
      .then((data) => {
        setIsAuthenticated(true);
        setJwtToken(data.getAccessToken().getJwtToken());
        setUserAttributes({
          sub: data?.getIdToken()?.payload?.sub ?? undefined,
          username:
            data?.getIdToken()?.payload?.["cognito:username"] ?? undefined,
        });
        if (user) {
          Auth.getPreferredMFA(user).then((response) => {
            setMfaState(response);
          });
        }
        return data;
      })
      .catch((error) => {
        setIsAuthenticated(false);
        setUser(undefined);
        setJwtToken(undefined);
        setUserAttributes(undefined);
        return null;
      });
  }, [Auth]);

  const setupMfa = () => {
    Auth.setupTOTP(user).then((code) => {
      setMfaCode(code);
    });
  };

  const verifyMfa = (answer) => {
    Auth.verifyTotpToken(user, answer)
      .then(() => {
        Auth.setPreferredMFA(user, "TOTP");
        setMfaCode(null);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const changePassword = (oldPass, newPass) => {
    return Auth.changePassword(user, oldPass, newPass);
  };

  const verificationString = `otpauth://totp/Decipher Risk:${user?.attributes?.email}?secret=${mfaCode}&issuer=Decipher Risk`;

  return (
    <AuthStateContext.Provider
      value={{
        jwtToken,
        isAuthenticated,
        userAttributes,
        changePassword,
        mfaState,
        setupMfa,
      }}
    >
      {mfaCode && (
        <MfaModal
          code={verificationString}
          onHide={() => {
            setMfaCode(null);
          }}
          verify={verifyMfa}
        />
      )}
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStateContext);

export default AuthState;
