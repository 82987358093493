import { HGFormControl } from "common/HGForm";
import React from "react";
import { Col } from "react-bootstrap";
import { HGRichText } from "common/HGForm/HGRichText";
import { useGetLocationsInGroup } from "utils/connectors/locationConnectors";
import { useGetSupportsInGroup } from "utils/connectors/supportConnectors";
import { useGetCategoriesInGroup } from "utils/connectors/categoryConnectors";
import { useUserEnum } from "utils/dataFormatters";
import { usePermissions } from "common/Permissions";
import { AssetsGroups } from "./AssetsGroups";
import { HGNumber, HGSelect } from "common/HGForm/Inputs";

const AssetsInputs: React.FC<{ assets?: any }> = ({ assets }) => {
  const { _supportsData } = useGetSupportsInGroup();
  const { _locationsData } = useGetLocationsInGroup();
  const { _categoriesData } = useGetCategoriesInGroup();
  const { userId, group, getOrganizationUser } = usePermissions();
  const users = useUserEnum(group.id);
  return (
    <Col sm={12}>
      <HGFormControl
        name="displayID"
        label="ID"
        defaultValue={assets?.displayID}
      />
      <HGFormControl label="Name" name="title" defaultValue={assets?.title} />
      <HGNumber label="Qty" name="qty" defaultValue={assets?.qty || 0} />
      <AssetsGroups
        name="assetsGroupID"
        selectedId={assets?.assetsGroupID}
        assets={assets}
      />
      <HGRichText label="Notes" name="notes" defaultValue={assets?.notes} />
      <HGRichText
        label="Notes About Controls"
        name="notesAboutControls"
        defaultValue={assets?.notesAboutControls}
      />
      <HGRichText
        label="Notes About Threats"
        name="notesAboutThreats"
        defaultValue={assets?.notesAboutThreats}
      />
      <HGSelect
        name="support"
        label="Support"
        defaultValue={assets?.support}
        options={_supportsData || []}
        overrideFormat={assets?.assetSupport?.title || ""}
      />
      <HGSelect
        name="location"
        label="Location"
        defaultValue={assets?.location}
        options={_locationsData || []}
        overrideFormat={assets?.assetLocation?.title || ""}
      />
      <HGSelect
        name="category"
        label="Category"
        defaultValue={assets?.category}
        options={_categoriesData || []}
        overrideFormat={assets?.assetCategory?.title || ""}
      />
      <HGSelect
        name="owner"
        label="Owner"
        defaultValue={assets?.owner || userId}
        options={users}
        overrideFormat={getOrganizationUser(assets?.owner)?.displayName}
      />
    </Col>
  );
};
export default AssetsInputs;
