import { HGForm, HGFormSubmit } from "common/HGForm";
import { HGDatePicker } from "common/HGForm/HGDatePicker";
import { HGRichText } from "common/HGForm/HGRichText";
import React, { useMemo } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import Sources from "./Sources";
import { usePermissions } from "common/Permissions";
import { useUserEnum } from "utils/dataFormatters";
import { Priority, UpdateIssueInput, Severity } from "API";
import { DateTime } from "luxon";
import { useParams } from "react-router";
import { useGetIssuesInGroup } from "utils/connectors/issueConnectors";
import { HGSelect } from "common/HGForm/Inputs";
import { formatPriority, formatSeverity } from "utils/formatStatus";
import { HGRadioButton, HGInputBox } from "common/HGForm/Inputs";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";

export const checkWorkflowStatus = (issue) => {
  if (
    issue?.description === "<p></p>" ||
    issue?.reportedBy === "" ||
    issue?.reportedOn === "" ||
    issue?.issueSourceId === ""
  ) {
    return "Intake Incomplete";
  } else if (issue?.status === "Created") {
    return "Intake Complete";
  } else if (
    (issue?.severity === "Null" ||
      issue?.priority === "Null" ||
      issue?.requiresActionplan === null ||
      issue?.requiresScenario === null ||
      issue?.assumptions === "<p></p>") &&
    issue?.status !== "Created"
  ) {
    return "Triage Prep Incomplete";
  } else if (issue?.status === "In_Triage") {
    return "Triage Prep Complete";
  } else if (issue?.status === "Deferred") {
    return "Deferred";
  } else if (
    issue?.status === "In_Progress" ||
    issue?.status === "Closed_Pending_Approval"
  ) {
    return "Act";
  } else if (issue?.status === "Closed") {
    return "Monitor";
  }
};
export const IssuePageModal = (props) => {
  const { issue, isOpen, setIsOpen } = props;
  const { id = issue?.id } = useParams<{ id: string }>();
  const { issues } = useGetIssuesInGroup();
  const { getOrganizationUser, group } = usePermissions();
  const users = useUserEnum(group.id).map((item) => {
    if (item.id === null) {
      return {
        id: "Null",
        title: "Not Set",
      };
    }
    return item;
  });
  const workflowStatus = checkWorkflowStatus(issue);

  const issueAttributesSchema = useMemo(
    () =>
      yup.object<Partial<UpdateIssueInput>>({
        ...(workflowStatus === "Intake Complete" ||
        workflowStatus === "Intake Incomplete"
          ? {
              title: yup
                .string()
                .default(issue?.title)
                .required()
                .label("Title")
                .matches(
                  /^(?!\s+$).*/,
                  "This field cannot contain only blank spaces"
                )
                .test(
                  "check-duplication",
                  "Issue title already exists!",
                  (value) => {
                    return !isUniqueTitle(
                      issues?.filter((i) => i?.id !== id),
                      value
                    );
                  }
                ),
              reportedBy: yup.string().label("Reported By"),
              reportedOn: yup
                .string()
                .test(
                  "is-date",
                  // eslint-disable-next-line
                  "${path} should be in MM/DD/YYYY format",
                  (value) => {
                    if (value) {
                      const date = DateTime.fromFormat(value, "MM/dd/yyyy");
                      return date?.isValid;
                    }
                    return true;
                  }
                )
                .label("Reported On"),
              owner: yup
                .string()
                .label("Owner")
                .default(issue?.owner)
                .required(),
              description: yup
                .string()
                .label("Description")
                .default(issue?.description),
              issueSourceId: yup
                .string()
                .label("Source")
                .default(issue?.issueSourceId ?? undefined),
            }
          : {
              priority: yup
                .mixed()
                .oneOf(Object.values(Priority))
                .label("Priority"),
              severity: yup
                .mixed()
                .oneOf(Object.values(Severity))
                .label("Severity"),
              assumptions: yup.string().label("Assumptions").default(""),
              requiresScenario: yup
                .boolean()
                .nullable()
                .default(issue?.requiresScenario)
                .label("Requires Scenario"),
              requiresActionplan: yup
                .boolean()
                .nullable()
                .default(issue?.requiresActionplan)
                .label("Requires Action Plan"),
            }),
      }),
    [issue, issues, isOpen]
  );
  return (
    <Modal
      show={isOpen}
      onHide={() => {
        setIsOpen(!isOpen);
      }}
    >
      <HGForm
        key="form_editIssueIntakeDetails"
        onSubmit={async (input) => {
          const _issue = await props.updateIssue({
            variables: {
              input: { id: id || issue.id, groupID: group.id, ...input },
            },
          });
          setIsOpen(!isOpen);
          return _issue;
        }}
        resolver={issueAttributesSchema}
      >
        <Modal.Header>
          Workflow Status
          {workflowStatus !== "Intake Complete" &&
          workflowStatus !== "Intake Incomplete"
            ? ` - ${issue?.title}`
            : ""}
        </Modal.Header>
        <Modal.Body>
          {workflowStatus === "Intake Complete" ||
          workflowStatus === "Intake Incomplete" ? (
            <Row>
              <Col sm={12}>
                <HGInputBox
                  name="title"
                  label="Title"
                  defaultValue={issue?.title}
                />
              </Col>
              <Col sm={12}>
                <HGRichText
                  label="Description"
                  name="description"
                  defaultValue={issue?.description}
                />
              </Col>
              <Col sm={6}>
                <HGInputBox
                  label="Reported By"
                  name="reportedBy"
                  defaultValue={issue?.reportedBy}
                />
              </Col>
              <Col sm={6}>
                <HGDatePicker
                  name="reportedOn"
                  label="Reported On"
                  defaultValue={issue?.reportedOn}
                />
              </Col>
              <Col sm={6}>
                <Sources
                  name="issueSourceId"
                  defaultValue={issue?.issueSourceId}
                  groupID={group.id}
                />
              </Col>
              <Col sm={6}>
                <HGSelect
                  label="Owner"
                  defaultValue={issue?.owner}
                  name="owner"
                  options={users}
                  overrideFormat={
                    getOrganizationUser(issue?.owner)?.displayName || "Not Set"
                  }
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={12}>
                <HGRichText
                  label="Assumptions"
                  name="assumptions"
                  defaultValue={issue?.assumptions}
                />
              </Col>
              <Col sm={6}>
                <HGSelect
                  label="Priority"
                  defaultValue={issue?.priority}
                  overrideFormat={formatPriority(issue?.priority)}
                  enumObj={Priority}
                  labelClass="issue-priority"
                  name="priority"
                />
              </Col>
              <Col sm={6}>
                <HGSelect
                  defaultValue={issue?.severity}
                  overrideFormat={formatSeverity(issue?.severity)}
                  label="Severity"
                  name="severity"
                  enumObj={Severity}
                />
              </Col>
              <Col sm={12} xl={6}>
                <HGRadioButton
                  key={issue}
                  name="requiresScenario"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  label="Requires Risk Scenario"
                  defaultValue={issue?.requiresScenario}
                  defaultLabel={
                    issue.requiresScenario !== null
                      ? issue.requiresScenario
                        ? "Yes"
                        : "No"
                      : "Not Set"
                  }
                />
              </Col>
              <Col sm={12} xl={6}>
                <HGRadioButton
                  name="requiresActionplan"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  label="Requires Action Plan"
                  defaultValue={issue?.requiresActionplan}
                  defaultLabel={
                    issue.requiresActionplan !== null
                      ? issue.requiresActionplan
                        ? "Yes"
                        : "No"
                      : "Not Set"
                  }
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="cancel-btn"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Cancel
          </Button>
          <HGFormSubmit />
        </Modal.Footer>
      </HGForm>
    </Modal>
  );
};
