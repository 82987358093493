/* eslint-disable no-fallthrough */
import {
  AlignmentType,
  CheckBox,
  HeadingLevel,
  Paragraph,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  WidthType,
} from "docx";
import { olReference, indentLevels } from "./docConfig";
import { DateTime } from "luxon";
type TraverseOptions = {
  level?: number;
  type?: string;
  parentType?: string;
  olReference?: string;
  bold?: boolean;
  italics?: boolean;
  debug?: boolean;
  paragraphFormatting?: any;
  textFormatting?: any;
  listStructure?: "ol" | "ul";
  checkBox?: boolean;
  checked?: boolean;
};

const getElement = (node: ChildNode) => node.firstChild?.parentElement;

const addTextFormatting = (options, formatting) => {
  return {
    ...options,
    textFormatting: { ...(options?.textFormatting ?? {}), ...formatting },
  };
};

export const resolveListItem = (node: ChildNode, options?: TraverseOptions) => {
  if (!options?.parentType) throw new Error("No parent list type");
  const checkedVal = getElement(node)?.getAttribute("data-checked");
  if (checkedVal !== null) {
    return traverseDOM(node.childNodes[1], {
      ...options,
      checkBox: true,
      checked: checkedVal === "true",
      paragraphFormatting: {
        indent: indentLevels[options?.level ?? 0],
      },
    });
  }
  if (options?.parentType === "ol") {
    return traverseDOM(node, {
      ...options,
      paragraphFormatting: {
        ...options?.paragraphFormatting,
        numbering: {
          level: options?.level ?? 0,
          reference: olReference(options?.olReference ?? ""),
        },
      },
    });
  }
  if (options?.parentType === "ul") {
    if (node.childNodes) {
      return traverseDOM(node, {
        ...options,
        paragraphFormatting: {
          ...options?.paragraphFormatting,
          bullet: {
            level: options?.level ?? 0,
          },
        },
      });
    }
  }
};

let ols = 0;

export function getOlCount() {
  return ols;
}

export function resetOlCount() {
  ols = 0;
}

export const checkboxSettings = {
  checkedState: { value: "2611" },
  uncheckedState: { value: "2610" },
};

export const getTitlePage = (item: any, department?: string) =>{
  const currentDate = DateTime.now();
  const formattedDate = currentDate.toFormat('MM/dd/yy'); 
  console.log('item.__typename', item)
  return [
      new Paragraph({
      alignment: AlignmentType.CENTER,
      heading: HeadingLevel.HEADING_1,
      children: [
        new TextRun({
          text: item.__typename !== undefined ? item.__typename.replace(/([a-z])([A-Z])/g, '$1 $2') : "My Items Needing Approval",
          break:2
        })
      ]
      
    }),
    new Paragraph({
      border: {
        bottom: {
          style: 'dashed',
          color: 'auto',
          size: 3,
        },
      },
    }),
    new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [
        new TextRun({
          text: department === undefined ? item.title : `${department} Register Export`,
          break:1
        })
      ]
    }),
    new Paragraph({
      alignment: AlignmentType.CENTER,
      children:[
        new TextRun({
          text: '',
          break: 1
        }),
        new TextRun({
          text: 'Date: ',
          bold: true
        }),
        new TextRun({
          text: formattedDate
        })
      ]
    }),
  ]
}

/**
 * Handling dom traversal of html strings
 * @param parentNode {HTMLNode} In initial calls, will be the `body` of the DOMParser results
 * @param options
 * @param initial {boolean} Required when calling as a root export option
 */
export function traverseDOM(
  parentNode: Document | ChildNode,
  options?: TraverseOptions,
  initial = false
) {
  const structure = Array.from(parentNode.childNodes)
    .map((node) => {
      // test comment
      // console.log(node.nodeName, "AS", node.textContent, options?.parentType);

      switch (node.nodeName.toLowerCase()) {
        case "p":
          if (options?.checkBox) {
            return new Paragraph({
              children: [
                options.checked === true
                  ? new CheckBox({
                      ...checkboxSettings,
                      checked: true,
                    })
                  : new CheckBox({
                      ...checkboxSettings,
                      checked: false,
                    }),
                new TextRun(`${node.textContent}`),
              ],
              ...options?.paragraphFormatting,
            });
          }
          if (options?.type === "li") {
            return new Paragraph({
              children: traverseDOM(node, {
                ...options,
                parentType: "li",
                type: "p",
              }),
              ...options?.paragraphFormatting,
            });
          }
          return new Paragraph({
            children: traverseDOM(node, {
              ...options,
              parentType: options?.type,
              type: "p",
            }),
            ...options?.paragraphFormatting,
          });
        case "#text":
          if (options?.type === "li") {
            return new Paragraph({
              children: [
                new TextRun({
                  text: node.textContent,
                  ...options?.textFormatting,
                }),
              ],
              ...options?.paragraphFormatting,
            });
          }
          return new TextRun({
            text: node.textContent,
            ...options?.textFormatting,
          });
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
          const headerLevel = node.nodeName.toLowerCase();
          if (node.childNodes.length > 0) {
            return new Paragraph({
              children: traverseDOM(node, { ...options, type: headerLevel }),
              style: `richText-${headerLevel}`,
            });
          }
          return new Paragraph({
            text: node.textContent ?? "",
            style: `richText-${headerLevel}`,
          });
        case "strong":
          return traverseDOM(node, addTextFormatting(options, { bold: true }));
        case "s":
          return traverseDOM(
            node,
            addTextFormatting(options, { strike: true })
          );
        case "em":
          return traverseDOM(
            node,
            addTextFormatting(options, { italics: true })
          );
        case "ol":
          var _olRef = options?.olReference;
          if (!_olRef || options?.listStructure !== "ol") {
            _olRef = (ols++).toString();
          }
          return traverseDOM(node, {
            ...options,
            level: options?.level !== undefined ? options?.level + 1 : 0,
            type: "ol",
            listStructure: "ol",
            olReference: _olRef ?? undefined,
          });
        case "ul":
          return traverseDOM(node, {
            ...options,
            level: options?.level !== undefined ? options?.level + 1 : 0,
            listStructure: "ul",
            type: "ul",
          });
        case "li":
          return resolveListItem(node, {
            ...options,
            parentType: options?.type,
            type: "li",
          });
        case "table":
          if (node.childNodes.length > 0) {
            return traverseDOM(node, { ...options, type: "table" });
          }
          return;
        case "tbody":
          return new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            layout: TableLayoutType.FIXED,
            rows: traverseDOM(node, { ...options, type: "table" }),
          });
        case "tr":
          return new TableRow({
            children: traverseDOM(node, {
              ...options,
              type: "tr",
              parentType: "table",
            }),
          });
        case "td":
          return new TableCell({
            children: traverseDOM(node, {
              ...options,
              type: "td",
              parentType: "tr",
            }),
          });
        case "th":
          return new TableCell({
            children: traverseDOM(
              node,
              addTextFormatting(
                {
                  ...options,
                  type: "th",
                  parentType: "tr",
                },
                { bold: true }
              )
            ),
          });
        case "label":
          return new Paragraph({
            children: [
              getElement(node.childNodes[0])?.getAttribute("checked")
                ? new CheckBox({
                    checked: true,
                    checkedState: { value: "2611", font: "MS Gothic" },
                  })
                : new CheckBox(),
              new TextRun(`${node.nextSibling?.childNodes[0].textContent}`),
            ],
          });
        case "div":
          if (node?.childNodes.length > 1) {
            return traverseDOM(node?.childNodes[0], {
              ...options,
              type: "ul",
              level: options?.level !== undefined ? options?.level + 1 : 0,
            });
          }
          return;
        case "pre":
          return new Paragraph({
            children: [
              new TextRun({
                text: node.textContent ?? "",
                style: "codeBlock",
              }),
            ],
          });
        case "code":
          return new TextRun({
            text: node.textContent ?? "",
            style: "codeBlock",
          });
        case "br":
          return [
            new TextRun({ text: "", break: 1 }),
            ...traverseDOM(node, options),
          ];
        case "hr":
          return new Paragraph({
            thematicBreak: true,
          });
        default:
          if (options?.type === "li") {
            console.log(node, options);
            throw new Error("LI SHOULD NOT REACH DEFAULT");
            return resolveListItem(node, options);
          }
          if (!options?.type) {
            return new TextRun({
              text: node.textContent ?? "",
            });
          }
          return new Paragraph({
            children: [new TextRun({ text: node.textContent ?? "" })],
          });
      }
    })
    .reduce((a, b) => (Array.isArray(b) ? [...a, ...b] : [...a, b]), [])
    .filter((item) => !!item);
  if (initial) {
    return [structure];
  }
  return structure;
}
