import {
  Footer,
  Paragraph,
  AlignmentType,
  TextRun,
  PageNumber,
  Table,
  TableRow,
  TableCell,
  WidthType,
  TableBorders,
  TableLayoutType,
  ImageRun,
} from "docx";
import { DateTime } from "luxon";
import Axios from "axios";
import decipherRiskLogo from "common/layout/Header/DecipherRisk-dark.png";

export const docxFooter = async () => {
  const targetImageHeight = 40;
  const logoImage = new Image();
  logoImage.src = decipherRiskLogo;
  await logoImage.decode();
  const { height, width } = logoImage;

  const scaledWidth = (width / height) * targetImageHeight;

  const logo = await Axios(decipherRiskLogo, {
    responseType: "blob",
  }).then((response) => response.data);
  return new Footer({
    children: [
      new Table({
        borders: TableBorders.NONE,
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        layout: TableLayoutType.FIXED,
        rows: [
          new TableRow({
            children: [
              new TableCell({
                verticalAlign: "center",
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        children: [DateTime.now().toFormat("MMM dd, yyyy")],
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                verticalAlign: "center",
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        children: [
                          PageNumber.CURRENT,
                          "/",
                          PageNumber.TOTAL_PAGES,
                        ],
                      }),
                    ],
                  }),
                ],
              }),

              new TableCell({
                verticalAlign: "center",
                children: [
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [
                      new ImageRun({
                        data: logo,
                        transformation: {
                          height: targetImageHeight,
                          width: scaledWidth,
                        },
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
};
