import React, { useEffect, useMemo, useState } from "react";
import { usePermissions } from "common/Permissions";
import { useHistory } from "react-router";
import { Modal, Button, Row, Col } from "react-bootstrap";
import * as yup from "yup";
import { useMutationWithManualUpdate } from "utils/useMutationWithManualUpdate";
import { ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { cancelPopupMessage } from "utils/globalVariables";
import { HGForm, HGFormControl, HGFormSubmit } from "common/HGForm";
import {
  CloneAuditPlanInput,
  CloneAuditPlanMutation,
  CloneAuditPlanMutationVariables,
} from "API";
import { cloneAuditPlan } from "graphql/mutations";
import { useGetAuditPlansInGroup } from "utils/connectors/auditPlanningConnectors";
import { HGSelect } from "common/HGForm/Inputs";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";
import { HGInputBox } from "common/HGForm/Inputs";

export const CreateAuditPlanningTemplateButton: React.FC<{
  onComplete?: Function;
  onOpen?: Function;
  onClose?: Function;
  defaultValues?: any;
  auditPlanTemplates?: any;
}> = ({
  onComplete,
  onOpen,
  onClose,
  defaultValues,
  auditPlanTemplates,
  ...props
}) => {
  const [showCreateTemplate, setShowCreateTemplate] = useState<boolean>(false);
  const history = useHistory();
  const { userId } = usePermissions();
  const { auditPlans } = useGetAuditPlansInGroup();
  const auditPlanTemplatesArr = [{ id: "", title: "" }];

  const [CloneAuditPlan] = useMutationWithManualUpdate<
    CloneAuditPlanMutation,
    CloneAuditPlanMutationVariables
  >(
    gql(cloneAuditPlan),
    {
      mutationKey: "CloneAuditPlan",
      currentData: (response) => response?.CloneAuditPlan,
      onCompleted: (response) => {
        const auditPlan = response?.CloneAuditPlan;
        const id = (auditPlan?.[0] && JSON.parse(auditPlan?.[0])?.id) ?? null;
        if (id) {
          setTimeout(() => {
            history.push(`/audits/${id}`);
          }, 500);
        }
        setShowCreateTemplate(false);
      },
      onError: ({ message }: ApolloError) => {
        ToastifyQueue(message, "danger");
      },
    },
    { message: "Audit Plan Created Successfully" }
  );

  auditPlanTemplates &&
    auditPlanTemplates.forEach(function (element) {
      if (element?.archived === true) {
        auditPlanTemplatesArr.push({
          id: element?.id || "",
          title: `${element?.title} (Archived)` || "",
        });
      }
      auditPlanTemplatesArr.push({
        id: element?.id || "",
        title: `${element?.title}` || "",
      });
    });

  const createAuditPlanTemplateSchema = useMemo(() => {
    return yup.object<Partial<CloneAuditPlanInput>>({
      title: yup
        .string()
        .default("")
        .required()
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test(
          "check-duplication",
          "Audit Plan title already exists!",
          (value) => {
            if (!value) return true;
            return !isUniqueTitle(auditPlans, value);
          }
        ),
      auditPlanID: yup.string().required().label("Template"),
    });
  }, [userId, auditPlans]);

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowCreateTemplate(false);
    }
  }

  useEffect(() => {
    if (showCreateTemplate) {
      return onOpen?.();
    }
    return onClose?.();
  }, [showCreateTemplate]);

  return (
    <>
      <Button
        {...props}
        className="ml-2"
        onClick={() => {
          setShowCreateTemplate(true);
        }}
      >
        Create From Template
      </Button>
      <Modal
        show={!!showCreateTemplate}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          onSubmit={async (input) => {
            return CloneAuditPlan({
              variables: { input: { ...input } },
            });
          }}
          resolver={createAuditPlanTemplateSchema}
        >
          <Modal.Header>Create Audit Plan Form Template</Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <HGInputBox name="title" label="Title" />
              </Col>
              <Col sm={12}>
                <HGSelect
                  label="Template"
                  name="auditPlanID"
                  options={auditPlanTemplatesArr || []}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                Confirmation();
              }}
              variant="light"
              className="cancel-btn"
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
