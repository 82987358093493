import { gql } from "@apollo/client";
import {
  GetCategoryQuery,
  GetCategoryQueryVariables,
  GetCategorysInGroupQueryVariables,
  GetCategorysInGroupQuery,
} from "API";
import { usePermissions } from "common/Permissions";
import { getCategory, getCategorysInGroup } from "graphql/queries";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";

export const useGetCategoriesInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetCategorysInGroupQuery,
    GetCategorysInGroupQueryVariables
  >(
    gql(getCategorysInGroup),
    {
      variables: {
        groupID: group.id,
      },
    },
    hasGroup
  );

  const categories = (response.data?.getCategorysInGroup.items || []).map(
    sortToID("CATEGORY#")
  );

  const _categoriesData = [
    { id: null, title: "" },
    ...categories.map((item) => {
      if (item.archived) {
        return {
          id: item.id,
          title: `${item?.title} (Archived)`,
        };
      }

      return {
        id: item.id,
        title: item?.title,
      };
    }),
  ].sort((a, b): any =>
    a.title.toLowerCase() > b.title.toLowerCase()
      ? 1
      : b.title.toLowerCase() > a.title.toLowerCase()
      ? -1
      : 0
  );

  return { ...response, categories, _categoriesData };
};

export const useGetCategory = (id) => {
  const { hasGroup } = usePermissions();
  const response = useProtectedQuery<
    GetCategoryQuery,
    GetCategoryQueryVariables
  >(
    gql(getCategory),
    {
      variables: {
        id,
      },
    },
    hasGroup
  );
  return {
    ...response?.data?.getCategory,
    categoryTitle: response?.data?.getCategory?.title,
  };
};
