import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createContext, useCallback, useContext, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { CreateBulkEdit } from "common/createBulkEdit";
import { usePermissions } from "./Permissions";
import { CreateBulkArchive } from "./createBulkArchive";

const ItemEditContext = createContext<{
  checked: Array<string>;
  checkedData: Array<any>;
  total: number;
  setCheckedStatus: (item: any) => void;
  getIsChecked: (id: string) => boolean;
  clear: Function;
}>({
  checked: [],
  checkedData: [],
  total: 0,
  setCheckedStatus: () => {},
  getIsChecked: () => false,
  clear: () => {},
});

export const useItemEditContext = () => useContext(ItemEditContext);

const useItemEdit = () => {
  const [checked, setChecked] = useState<Array<string>>([]);
  const [checkedData, setCheckedData] = useState<Array<any>>([]);
  const getIsChecked = useCallback(
    (id: string) => {
      return checked.includes(id);
    },
    [checked]
  );

  const setCheckedStatus = (item) => {
    if (getIsChecked(item?.id)) {
      setChecked((checked) => checked.filter((c) => c !== item?.id));
      setCheckedData((checkedData) =>
        checkedData.filter((c) => c?.id !== item?.id)
      );
    } else {
      setChecked((checked) => [...checked, item?.id]);
      setCheckedData((checkedData) => [...checkedData, item]);
    }
  };

  const clear = () => {
    setChecked([]);
  };

  return {
    checked,
    checkedData,
    total: checked.length,
    setCheckedStatus,
    getIsChecked,
    clear,
  };
};
let allData;
export const ItemEdit = ({ children, items, modifyItem }) => {
  const itemEdit = useItemEdit();
  allData = items;
  return (
    <ItemEditContext.Provider value={itemEdit}>
      {children}
      {itemEdit.total > 0 && (
        <div className="tableComparison">
          <div className="buffer" />
          <div style={{ position: "relative" }}>
            <div className="clearfix dock">
              <div className="float-right">
                <CreateBulkArchive
                  items={items}
                  type={items?.[0]?.__typename}
                  modifyItem={modifyItem}
                />
                <CreateBulkEdit items={items} type={items?.[0]?.__typename} />
              </div>

              <h5 className="mt-2">
                <Button
                  variant="link"
                  onClick={() => {
                    itemEdit.clear();
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={faTimes} />
                </Button>{" "}
                <Badge pill style={{ marginLeft: "15px" }}>
                  {itemEdit.total}
                </Badge>{" "}
                Items Selected
              </h5>
            </div>
          </div>
        </div>
      )}
    </ItemEditContext.Provider>
  );
};

export const BulkEdit: React.FC<{ item }> = ({ item }) => {
  const { setCheckedStatus, getIsChecked } = useItemEditContext();
  const isChecked = getIsChecked(item?.id);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const { groupRole } = usePermissions();
  return groupRole !== "read" ? (
    <div className="custom-control custom-checkbox">
      <input
        className="custom-control-input"
        type="checkbox"
        aria-label="checkbox"
        id={item?.id || "selectAll"}
        checked={item === "selectAll" ? isCheckedAll : isChecked}
        onChange={() => {
          if (item === "selectAll") {
            allData?.map((x) => setCheckedStatus(x));
            setIsCheckedAll(!isCheckedAll);
          } else {
            setCheckedStatus(item);
          }
        }}
      />
      <label
        className="custom-control-label"
        htmlFor={item?.id || "selectAll"}
      />
    </div>
  ) : (
    <></>
  );
};
