import React, { useState } from "react";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HGForm, HGFormControl, HGFormSubmit } from "common/HGForm";
import { SearchBar } from "../SearchBar";

import * as yup from "yup";

import searchDictionary from "./searchDictionary";

import { HGMultiSelect } from "common/HGForm/HGMultiSelect";
import { formatSeverity, formatObj, formatPriority } from "utils/formatStatus";
import { Priority, Severity } from "API";
import { HGDateRangePicker } from "common/HGForm/HGDatePicker";
import { HGInputBox } from "common/HGForm/Inputs";

const SearchUI = ({
  searchableItems,
  setSearchEntries,
  setSearchBarValue,
  searchBarValue,
  searchEntries,
  module,
}) => {
  const [open, setOpen] = useState(false);
  const resolver = yup.object(
    Object.assign(
      {},
      ...Object.values(searchableItems).map((item: any) => ({
        [item.name]: searchDictionary[item.type].validator,
      }))
    )
  );

  const severity = formatObj(Severity, formatSeverity);
  const priority = formatObj(Priority, formatPriority);
  const hasEntries =
    Object.values(searchEntries).filter((e) => {
      if (Array.isArray(e)) {
        return e.filter((e2) => e2 !== "").length > 0;
      }
      return e !== "";
    }).length > 0;
  return (
    <>
      <ButtonGroup style={{ zIndex: 2, height: "100%" }}>
        {hasEntries && (
          <Button
            variant="outline-primary"
            aria-label="Clear"
            onClick={() => {
              setSearchEntries({});
            }}
          >
            Clear
          </Button>
        )}
        <Button
          variant="outline-primary"
          className="dropdown-toggle"
          aria-label="dropdown"
          onClick={() => setOpen(!open)}
        >
          <FontAwesomeIcon aria-hidden="true" icon={faFilter} />
        </Button>
      </ButtonGroup>
      <Modal show={open}>
        <HGForm
          resolver={resolver}
          onSubmit={async (form, ...other) => {
            const entries = Object.entries(form)
              .map(([key, val]) => ({ [key]: val }))
              .reduce((a, b) => Object.assign(a, b), {});
            setSearchEntries(entries);
            setOpen(false);
          }}
        >
          <Modal.Header>
            {module === "AuditPlanning"
              ? "Audit Plan"
              : module === "RelatableItem"
              ? "Related Items"
              : module?.replace(/([a-z])(?=[A-Z])/g, "$1 ")}{" "}
            Register Filter
          </Modal.Header>
          <Modal.Body className="modal-body-scroll">
            <div>
              {open && <div onClick={() => setOpen(!open)} />}
              <div style={{ height: "100%" }}>
                {open && (
                  <div>
                    {searchableItems.map((item) => {
                      const formatter = searchDictionary[item.type];
                      return (
                        (formatter.as === "date" && (
                          <HGDateRangePicker
                            name={item.name}
                            label={item.name}
                            range={searchEntries?.[item.name]}
                          />
                        )) ||
                        (formatter.as === "options" &&
                          (((item.name === "Status" ||
                            item.name === "Workflow Status") && (
                            <HGMultiSelect
                              name={item.name}
                              label={item.name}
                              enumObj={item.optionValues}
                              defaultValue={searchEntries?.[item.name] ?? ""}
                            />
                          )) ||
                            (item.name === "Severity" && (
                              <HGMultiSelect
                                name={item.name}
                                label={item.name}
                                enumObj={severity}
                                defaultValue={searchEntries?.[item.name] ?? ""}
                              />
                            )) ||
                            (item.name === "Priority" && (
                              <HGMultiSelect
                                name={item.name}
                                label={item.name}
                                enumObj={priority}
                                defaultValue={searchEntries?.[item.name] ?? ""}
                              />
                            )))) || (
                          <HGInputBox
                            name={item.name}
                            label={item.name}
                            defaultValue={searchEntries?.[item.name] ?? ""}
                          />
                        )
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setOpen(!open)}
              variant="light"
              className="cancel-btn"
            >
              Cancel
            </Button>
            <HGFormSubmit title="Apply" />
          </Modal.Footer>
        </HGForm>
      </Modal>
      <SearchBar
        key="searchBar"
        componentAppend=""
        setSearchBarValue={setSearchBarValue}
        searchBarValue={searchBarValue}
      />
    </>
  );
};

export default SearchUI;
