import React from "react";
import { Button } from "react-bootstrap";
import { useMutation, gql } from "@apollo/client";
import {
  AddUserToGroupMutation,
  AddUserToGroupMutationVariables,
  RemoveUserFromGroupMutation,
  RemoveUserFromGroupMutationVariables,
  SetUserRoleInGroupMutation,
  SetUserRoleInGroupMutationVariables,
  UserRoles,
} from "API";
import {
  addUserToGroup,
  removeUserFromGroup,
  setUserRoleInGroup,
} from "graphql/mutations";
import { usePermissions } from "common/Permissions";
import { sleep } from "utils/useSleep";

export const groupMemberRender = (props) => {
  const { group, refetchGroupUsers } = usePermissions();
  const [runRemoveUserFromGroup] = useMutation<
  RemoveUserFromGroupMutation,
  RemoveUserFromGroupMutationVariables
  >(gql(removeUserFromGroup), {
    onCompleted: () => {
      sleep(500).then(refetchGroupUsers);
    },
  });
  const [runSetUserRoleInGroup] = useMutation<
  SetUserRoleInGroupMutation,
  SetUserRoleInGroupMutationVariables
  >(gql(setUserRoleInGroup), {
    onCompleted: () => {
      sleep(500).then(refetchGroupUsers);
    },
  });
  return (
    <span>
      {props?.value === 'admin'
        ? (
        <>
          <Button
            variant="link"
            onClick={() => {
              runSetUserRoleInGroup({
                variables: {
                  input: [
                    {
                      groupID: group.id,
                      userID: props?.data?.ID || "",
                      userRole: UserRoles.write,
                    },
                  ],
                },
              });
            }}
          >
            Make Member
          </Button>
{" "}
          ,
{" "}
          <Button
            variant="link"
            onClick={() => {
              runRemoveUserFromGroup({
                variables: {
                  input: [
                    {
                      groupID: group.id,
                      userID: props?.data?.ID || "",
                    },
                  ],
                },
              });
            }}
          >
            Remove
          </Button>
        </>
        ) 
        : props.value === 'read' || props.value === 'write'
          ? (
        <>
          <Button
        variant="link"
        onClick={() => {
          runSetUserRoleInGroup({
            variables: {
              input: [
                {
                  groupID: group.id,
                  userID: props?.data?.ID ?? "",
                  userRole: UserRoles.admin,
                },
              ],
            },
          });
        }}
          >
        Make Admin ,
{" "}
          </Button>

      <Button
        variant="link"
        onClick={() => {
          runRemoveUserFromGroup({
            variables: {
              input: [
                {
                  groupID: group.id,
                  userID: props?.data?.ID ?? "",
                },
              ],
            },
          });
        }}
      >
        Remove
      </Button>
        </>
          )
          : (
            ""
          )}
    </span>
  );
};
export const groupNonMemberRender = (props) => {
  const { group, refetchGroupUsers } = usePermissions();
  const [runAddUserToGroup] = useMutation<
  AddUserToGroupMutation,
  AddUserToGroupMutationVariables
  >(gql(addUserToGroup), {
    onCompleted: () => {
      sleep(500).then(refetchGroupUsers);
    },
  });
  return (
    <span>
      <Button
        variant="link"
        onClick={() => {
          runAddUserToGroup({
            variables: {
              input: [
                {
                  groupID: group.id,
                  userID: props?.data?.ID ?? "",
                  userRole: UserRoles.write,
                },
              ],
            },
          });
        }}
      >
        Add
      </Button>
      {" "}
          ,
{" "}
      <Button
        variant="link"
        onClick={() => {
          runAddUserToGroup({
            variables: {
              input: [
                {
                  groupID: group.id,
                  userID: props?.data?.ID ?? "",
                  userRole: UserRoles.read,
                },
              ],
            },
          });
        }}
      >
        Add (Read-only)
      </Button>
    </span>
  );
};
