import { usePermissions } from "common/Permissions";
import React, { useCallback, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import useFileManager from "utils/useFileManager";
import "./RelatedArtifacts.scss";
import {
  AttachedArtifact,
  AttachedURL,
  AttachedItem,
  AttachedScenario,
} from "./AttachedItem";
import { RelatedItemSearchButton } from "./RelatedItemSearch";

const alphabeticalSort = (a = { title: "" }, b = { title: "" }) =>
  a.title?.toLowerCase().localeCompare(b.title?.toLowerCase());

interface IRelatedItemType {
  id: string;
  attachments: Array<any>;
  type: any;
  attach: Function;
  detach: Function;
  props: any;
  refetch: Function;
}

export const RelatedItemType: React.FC<IRelatedItemType> = ({
  id,
  attachments,
  type,
  attach,
  detach,
  props,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentID] = useState([]);
  const { groupRole, groups } = usePermissions();
  return (
    <Card className="rightCard1">
      <Card.Header>
        {groupRole !== "read" && (
          <RelatedItemSearchButton
            id={id}
            attachedData={attachments}
            {...{ attach, detach }}
            className="float-right"
            types={[type]}
            assementData={props}
            refetch={refetch}
          />
        )}
        {type.name}
      </Card.Header>
      <Card.Body className="right-height">
        {attachments?.length > 0
          && attachments
            .filter(type.attachedFilter)
            .map((d) => ({
              id: d.target,
              entity: d[type.value],
              title:
                // d?.title ??
                d?.item?.title ?? d?.fileName ?? "unknown",
              type: type.value,
              address: d?.address ?? undefined,
              CreateItemButton: type.CreateItemButton,
              item: d.item,
            }))
            .sort(alphabeticalSort)
            .map((d) => {
              switch (type.name) {
                case "URLs":
                  return (
                    <AttachedURL
                      item={d}
                      detach={detach}
                      refetch={refetch}
                      loading={loading}
                      setLoading={setLoading}
                      currentId={currentId}
                      setCurrentID={setCurrentID}
                      userRole={groupRole}
                    />
                  );
                case "Risk Scenarios":
                  return (
                    <AttachedScenario
                      type={type}
                      item={d}
                      detach={detach}
                      refetch={refetch}
                      loading={loading}
                      setLoading={setLoading}
                      currentId={currentId}
                      setCurrentID={setCurrentID}
                      userRole={groupRole}
                    />
                  );
                default:
                  return (
                    <AttachedItem
                      type={type}
                      item={d}
                      detach={detach}
                      refetch={refetch}
                      loading={loading}
                      setLoading={setLoading}
                      currentId={currentId}
                      setCurrentID={setCurrentID}
                      userRole={groupRole}
                      groups={groups}
                    />
                  );
              }
            })}
      </Card.Body>
    </Card>
  );
};

export const RelatedItemArtifactType: React.FC<IRelatedItemType> = ({
  attachments,
  type,
  attach,
  detach,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentID] = useState([]);
  const { group, groupRole } = usePermissions();
  const fileManager = useFileManager();

  const onDrop = useCallback(
    async (acceptedFiles: Array<File>) => {
      const uploadedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          await fileManager
            .upload({
              fileName: file.name,
              groupID: group.id,
              file,
            })
            .then(async (fileID) => {
              await attach(fileID);
            });
        }),
      );
    },
    [group.id],
  );

  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Card className="rightCard1 filecard">
      <Card.Header>
        {groupRole !== "read" && (
          <RelatedItemSearchButton
            attachedData={attachments}
            {...{ attach, detach }}
            className="float-right"
            types={[type]}
            refetch={refetch}
          />
        )}

        {type.name}
      </Card.Header>
      <Card.Body
        className="mb-3 right-height"
        {...(groupRole !== "read" && { ...getRootProps() })}
        style={{
          background: (isDragActive && "blue") || "inherit",
        }}
      >
        {attachments?.length > 0
          && attachments
            .filter(type.attachedFilter)
            .map((d) => ({
              id: d.target,
              entity: d[type.value],
              title:
                // d?.title ??
                d?.item?.title ?? d?.item?.fileName ?? "unknown",
              type: type.value,
              extension: d?.item?.fileName?.split(".").pop().toLowerCase(),
              CreateItemButton: type.CreateItemButton,
              item: d.item,
            }))
            .sort(alphabeticalSort)
            .map((d) => {
              return (
                <AttachedArtifact
                  item={d}
                  detach={detach}
                  refetch={refetch}
                  loading={loading}
                  setLoading={setLoading}
                  currentId={currentId}
                  setCurrentID={setCurrentID}
                  userRole={groupRole}
                />
              );
            })}
        {fileManager?.isUploading === true ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          ""
        )}
        {groupRole !== "read"
          && (isDragActive ? (
            <p className="mb-0">Drop the files here ...</p>
          ) : (
            <p
              style={{ fontStyle: "italics", color: "gray", marginBottom: "0" }}
            >
              Drag and drop files to upload.
            </p>
          ))}
      </Card.Body>
    </Card>
  );
};
