import { ReportConfig } from "utils/docx-export/reportSchemaParse.types";
import { AudditPlanningType } from "./auditPlanningTypes";
import { useGenerateReportFilename } from "utils/docx-export/useCreateFilename";
import { usePermissions } from "common/Permissions";
import { DateTime } from "luxon";
import { formatStatus } from "utils/formatStatus";
import { AlignmentType, Paragraph, TextRun } from "docx";
import {
  relatedItemsParse,
  useGetGroupByRelatedItem,
} from "utils/useGetGroupByRelatedItems";

export const auditPlanningReportSchema =
  (): ReportConfig<AudditPlanningType> => {
    const getFilename = useGenerateReportFilename();
    const { groups, getOrganizationUser } = usePermissions();
    return {
      getFilename: (item) =>
        getFilename({
          type: "filename",
          fileName: `Decipher Risk - Audit ${item.title ?? "title"}`,
          timestamp: DateTime.now(),
        }),
      fields: [
        { type: "Header", content: (item) => item.title ?? "" },
        {
          type: "InlineText",
          label: "Status",
          content: (item) => formatStatus(item?.status),
        },
        {
          type: "InlineText",
          label: "Owner",
          content: (item) =>
            getOrganizationUser(item?.owner)?.displayName || "Not Set",
        },
        {
          type: "InlineText",
          label: "Risk Domain",
          content: (item) => item?.riskDomainDetails?.title || "",
        },
        {
          type: "InlineText",
          label: "Plan Year",
          content: (item) => item.year || "",
        },
        {
          type: "InlineText",
          label: "Conducted By",
          content: (item) => item?.auditor,
        },
        {
          type: "InlineText",
          label: "Estimated Duration",
          content: (item) => (item.days ? `${item.days} (days)` : ""),
        },
        {
          type: "InlineText",
          label: "Start Date",
          content: (item) => item?.auditStartDate,
        },
        {
          type: "InlineText",
          label: "End Date",
          content: (item) => item?.auditEndDate,
        },
        {
          type: "InlineText",
          label: "Estimated Hours",
          content: (item) => item?.hours,
        },
        {
          type: "InlineText",
          label: "Actual Hours",
          content: (item) => item?.totalHours,
        },
        {
          type: "RichText",
          label: "Background",
          content: (item) => item.background ?? "",
        },
        {
          type: "RichText",
          label: "Scope",
          content: (item) => item.scope ?? "",
        },
        {
          type: "RichText",
          label: "Objective",
          content: (item) => item.objective ?? "",
        },
        {
          type: "RichText",
          label: "Methodology",
          content: (item) => item.methodology ?? "",
        },
        {
          type: "RichText",
          label: "Examination Details",
          content: (item) => item.points ?? "",
        },
        {
          type: "RichText",
          label: "Findings (Itemized)",
          content: (item) => item.auditFindings ?? "",
        },
        {
          type: "RichText",
          label: "Recommendations",
          content: (item) => item.recommendations ?? "",
        },
        {
          type: "RichText",
          label: "Management Response",
          content: (item) => item.managementResponse ?? "",
        },
        {
          type: "Custom",
          label: "Related Items",
          formatting: (item) => [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              heading: "Heading2",
              children: [
                new TextRun({ text: "Related Items", bold: true, break: 1 }),
              ],
            }),
            ...relatedItemsParse(
              useGetGroupByRelatedItem(
                item?.auditPlanningRelatedItems?.items,
                groups
              )
            ),
          ],
        },
      ],
    };
  };
