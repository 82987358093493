import React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";

import OrganizationPermissionsView from "./OrganizationPermissionsView";
import GroupPermissionsView from "./GroupPermissionsView";
import useSetTitle from "utils/useSetTitle";

export const PermissionsView = () => {
  useSetTitle("Permissions");
  return (
    <>
      <Row className="dashboard-header">
        <Col xs="12" className="px-8">
          <Breadcrumb>
            <Breadcrumb.Item>Permissions Manager</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs="12" className="px-8">
          <h3>Permissions</h3>
        </Col>
      </Row>
      <Row>
        <OrganizationPermissionsView />
        <GroupPermissionsView />
      </Row>
    </>
  );
};
