import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

const HGAccordion = ({ module = "", children }) => {
  const accordionState = localStorage.getItem(`${module}-accordion-state`);
  const [isExpanded, setIsExpanded] = useState(accordionState === "true");
  const toggleAccordion = () => {
    localStorage.setItem(`${module}-accordion-state`, (!isExpanded).toString());
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <Accordion defaultActiveKey={isExpanded === true ? "0" : "1"}>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            onClick={toggleAccordion}
            style={{ cursor: "pointer" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <span>KPI cards</span>

              {!isExpanded ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{children}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default HGAccordion;
