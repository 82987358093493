import { CreateActionPlanButton } from "Modules/Customers/ActionPlans/CreateActionPlan";
import { CreateIssueButton } from "Modules/Customers/Issues/CreateIssue";
import { CreateRiskButton } from "Modules/Customers/Risk/CreateRisk";
import { getEntityType } from ".";
import { RelatedItemArtifactType, RelatedItemType } from "./RelatedItem.Type";
import { useGetIssuesInSelectedGroup } from "utils/connectors/issueConnectors";
import { useGetActionPlansInSelectedGroup } from "utils/connectors/actionPlanConnectors";
import { useGetScenariosInSelectedGroup } from "utils/connectors/scenarioConnectors";
import { useGetAuditPlansInSelectedGroup } from "utils/connectors/auditPlanningConnectors";
import { useGetUrlsInSelectedGroup } from "utils/connectors/urlConnectors";
import { useGetArtifactsInSelectedGroup } from "utils/connectors/artifactsConnectors";

export const relatedItemTypes = (attach) => {
  const getIssuesInSelectedGroup = useGetIssuesInSelectedGroup();
  const getActionPlansInSelectedGroup = useGetActionPlansInSelectedGroup();
  const getScenariosInSelectedGroup = useGetScenariosInSelectedGroup();
  const getAuditPlansInSelectedGroup = useGetAuditPlansInSelectedGroup();
  const geturlsInSelectedGroup = useGetUrlsInSelectedGroup();
  const getArtifactsInSelectedGroup = useGetArtifactsInSelectedGroup();
  return [
    getEntityType({
      name: "Files",
      value: "File",
      baseUrl: "artifacts",
      attachMethod: attach,
      targetType: "Artifact",
      RelatedItemType: RelatedItemArtifactType,
      getItemsInSelectedGroup: getArtifactsInSelectedGroup,
    }),
    getEntityType({
      name: "URLs",
      value: "Url",
      baseUrl: "urls",
      attachMethod: attach,
      targetType: "Url",
      RelatedItemType,
      getItemsInSelectedGroup: geturlsInSelectedGroup,
    }),
    getEntityType({
      name: "Issues",
      value: "Issue",
      baseUrl: "issues",
      attachMethod: attach,
      targetType: "Issue",
      fallback: undefined,
      CreateItemButton: CreateIssueButton,
      RelatedItemType,
      getItemsInSelectedGroup: getIssuesInSelectedGroup,
    }),
    getEntityType({
      name: "Risk Scenarios",
      value: "Scenario",
      baseUrl: "risk",
      attachMethod: attach,
      targetType: "Scenario",
      fallback: "scenarios",
      CreateItemButton: CreateRiskButton,
      RelatedItemType,
      getItemsInSelectedGroup: getScenariosInSelectedGroup,
    }),
    getEntityType({
      name: "Action Plans",
      value: "ActionPlan",
      baseUrl: "actionplans",
      attachMethod: attach,
      targetType: "ActionPlan",
      fallback: undefined,
      CreateItemButton: CreateActionPlanButton,
      RelatedItemType,
      getItemsInSelectedGroup: getActionPlansInSelectedGroup,
    }),
    getEntityType({
      name: "Assessements",
      value: "Assessements",
      baseUrl: "assessments/HIPAAGapAssessment/response",
      targetType: "assessmentresponse",
      fallback: "assessmentresponse",
      RelatedItemType,
      getItemsInSelectedGroup: getIssuesInSelectedGroup,
    }),
    getEntityType({
      name: "Audit Plans",
      value: "AuditPlan",
      baseUrl: "audits",
      attachMethod: attach,
      targetType: "AuditPlanning",
      fallback: "auditplanning",
      RelatedItemType,
      getItemsInSelectedGroup: getAuditPlansInSelectedGroup,
    }),
    getEntityType({
      name: "Assets",
      value: "Assets",
      baseUrl: "assessments/assetManagement/asset",
      attachMethod: attach,
      targetType: "Assets",
      fallback: "assets",
      RelatedItemType,
      getItemsInSelectedGroup: getIssuesInSelectedGroup,
    }),
    getEntityType({
      name: "Controls",
      value: "Controls",
      baseUrl: "controls",
      attachMethod: attach,
      targetType: "Controls",
      fallback: "controls",
      RelatedItemType,
      getItemsInSelectedGroup: getIssuesInSelectedGroup,
    }),
  ];
};
