import { HGTooltip } from "common/HGTooltip";
import React from "react";
import "./BarGraph.view.scss";

const getMax = (vals: any[], combineLast = false) => {
  if (vals[0].value) {
    if (!combineLast) {
      return Math.max(...vals.map((d) => d.value || 0));
    }
    return vals.map((d) => d.value || 0).reduce((a, b) => a + b, 0);
  }
  if (vals[0].objects[0].value) {
    if (!combineLast) {
      return Math.max(
        ...vals.map((d) => Math.max(...d.objects.map((x) => x.value || 0)))
      );
    }
    return Math.max(
      ...vals.map((d) =>
        d.objects.map((x) => x.value || 0).reduce((a, b) => a + b, 0)
      )
    );
  }
  if (vals[0].objects[0].objects[0] !== undefined) {
    return (
      Math.max(
        ...vals.map((d) =>
          Math.max(
            ...d.objects.map((x) =>
              x.objects.length > 0
                ? Math.max(...x.objects.map((y) => y.value || 1))
                : 1
            )
          )
        )
      ) || 1
    );
  }
};

export const Stack = ({ wVal, i, data, max }) => {
  let heightIndex = 0;
  return (
    <svg
      className={`${data.name} stack`}
      width={`${wVal}%`}
      height="100%"
      x={`${i * wVal}%`}
    >
      {data.objects ? (
        <svg height="100%" className="bar">
          <svg height="95%">
            {data.objects.map((bar, index) => {
              let height = (100 * bar.value) / max;
              heightIndex += height;
              return (
                <HGTooltip tooltip={`${bar.name} (${bar.value})`}>
                  <rect
                    className={bar.name}
                    width="95%"
                    y={`${100 - heightIndex}%`}
                    height={`${height}%`}
                  />
                </HGTooltip>
              );
            })}
          </svg>
          <svg height="5%" y="95%">
            <text
              fontSize="8px"
              width="100%"
              x="50%"
              textAnchor="middle"
              alignmentBaseline="hanging"
            >
              {data.name}
            </text>
          </svg>
        </svg>
      ) : (
        <rect
          className={data.name}
          width="95%"
          y={`${100 - (100 * data.value) / max}%`}
          height={`${(100 * data.value) / max}%`}
        />
      )}
    </svg>
  );
};

export const Group = ({ wVal, i, children, name }) => (
  <svg
    width={`${wVal}%`}
    height="100%"
    x={`${i * wVal}%`}
    className={`${name} group`}
  >
    <svg width="90%" height="90%" x="5%" className="stackWrapper">
      {children}
    </svg>
    <svg width="100%" height="10%" y="90%" className="groupLabel">
      <text
        fontWeight="bold"
        width="100%"
        x="50%"
        textAnchor="middle"
        alignmentBaseline="hanging"
      >
        {name}
      </text>
    </svg>
  </svg>
);

export default (props) => {
  let max = getMax(props.data);

  return (
    <div>
      <svg
        {...(props.width && { width: props.width })}
        {...(props.height && { height: props.height })}
        //viewBox="0 0 300 200"
        preserveAspectRatio="xMidYMid"
        className={`${props.className} groupedBarGraph`}
      >
        {props.data.map((group, i) => {
          let wVal = 100 / props.data.length;
          return (
            <Group wVal={wVal} i={i} name={group.name}>
              {group.objects.map((stack, stackIndex) => {
                let stackVal = 100 / group.objects.length;
                return (
                  <Stack
                    max={max}
                    wVal={stackVal}
                    i={stackIndex}
                    data={stack}
                  />
                );
              })}
            </Group>
          );
        })}
      </svg>
    </div>
  );
};
