import { gql } from "@apollo/client";
import {
  GetSupportQuery,
  GetSupportQueryVariables,
  GetSupportsInGroupQueryVariables,
  GetSupportsInGroupQuery,
} from "API";
import { usePermissions } from "common/Permissions";
import { getSupport, getSupportsInGroup } from "graphql/queries";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";

export const useGetSupportsInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetSupportsInGroupQuery,
    GetSupportsInGroupQueryVariables
  >(
    gql(getSupportsInGroup),
    {
      variables: {
        groupID: group.id,
      },
    },
    hasGroup
  );

  const supports = (response.data?.getSupportsInGroup.items || []).map(
    sortToID("SUPPORT#")
  );

  const _supportsData = [
    { id: null, title: "" },
    ...supports.map((item) => {
      if (item.archived) {
        return {
          id: item.id,
          title: `${item?.title} (Archived)`,
        };
      }

      return {
        id: item.id,
        title: item?.title,
      };
    }),
  ].sort((a, b): any =>
    a.title.toLowerCase() > b.title.toLowerCase()
      ? 1
      : b.title.toLowerCase() > a.title.toLowerCase()
      ? -1
      : 0
  );

  return { ...response, supports, _supportsData };
};

export const useGetSupport = (id) => {
  const { hasGroup } = usePermissions();
  const response = useProtectedQuery<GetSupportQuery, GetSupportQueryVariables>(
    gql(getSupport),
    {
      variables: {
        id,
      },
    },
    hasGroup
  );

  return {
    ...response?.data?.getSupport,
    supportTitle: response?.data?.getSupport?.title,
  };
};
