import * as R from "ramda";
export const getRelatedItemsSearchString = (relatedItems, getRelatedItemsSummaryFn) =>
    relatedItems
    && Object.keys(getRelatedItemsSummaryFn(relatedItems))
      .filter((a) => a !== "")
      .map((key) => `${key}: ${relatedItems[key]}`)
      .reduce((acc, next) => `${acc}\n${next}`, "");

export const getRelatedIssueSummary = (relatedItems) =>
    R.reduce(
      R.mergeWith(R.add),
      {},
      relatedItems
        .filter((a) => a.targetType === "issues")
        .map((a) => ({ [a.item?.title || ""]: 1 })),
    ); 

export const getRelatedTaskSummary = (relatedItems) =>
    R.reduce(
        R.mergeWith(R.add),
        {},
        relatedItems.map((a) => ({ [a.task?.title || ""]: 1 })),
    );

export const getRelatedActionPlansSummary = (relatedItems) =>
    relatedItems
    && R.reduce(
        R.mergeWith(R.add),
        {},
        relatedItems
        .filter((a) => a.targetType === "actionplans")
        .map((a) => ({ [a.item?.title || ""]: 1 })),
    );