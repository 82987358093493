import { gql, useMutation, useQuery } from "@apollo/client";
import { faChartBar , faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LinkItemsInput,
  UnlinkItemsInput,
  GetLinksByItemQuery,
  GetLinksByItemQueryVariables,
} from "API";
import { HGForm } from "common/HGForm";
import { linkItems, unlinkItems } from "graphql/mutations";
import React, { useState, useRef, useEffect } from "react";
import {
  Button, Modal, InputGroup, FormControl, Dropdown, Spinner, 
} from "react-bootstrap";
import { useAssetComparisonContext } from "./BulkEditAssets";
import { cancelPopupMessage } from "utils/globalVariables";
import { useGetControlsSurvey } from "utils/connectors/controlsConnectors";

import useOnLoseTreeFocus from "utils/useOnLoseTreeFocus";
import "../../../../common/RelatedItems/SearchBar/SearchBar.scss";
import { sleep } from "utils/useSleep";
import ToastifyQueue from 'common/Overlays/ToastifyQueue';
import { useGetQuery } from "./QueryBuilder";

export const CreateBulkEditControls = ({ assets }) => {
  const assetComparison = useAssetComparisonContext();
  const [showCreate, setShowCreate] = useState(false);
  const [isLoading, setIsLoading] = useState([]) as any;
  const [treeActive, setTreeActive] = useState(false);
  const [filter, setFilter] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [attachedControlsResponse, setAttachedControlsResponse] = useState() as any;

  const wrapperRef = useRef(null);
  useOnLoseTreeFocus(wrapperRef, () => {
    setTreeActive(false);
  })
  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowCreate(false);
    }
  }
  const [fileType, setFileType] = useState<any>('NIST-CSF');

  let currentEntities;
  const controlsList: any[] = [];
  function getControlsChildren(obj) {
    obj?.forEach((subObj: any) => {
      if (subObj?.controlsList?.items?.length > 0) {
        getControlsChildren(subObj.controlsList?.items);
      }
      else {
        controlsList.push(subObj)
      }
    })
    return currentEntities = controlsList;
  }
  const { controls } = useGetControlsSurvey();

  if (fileType === "NIST-800-53") {
    controls && controls.map((item) => {
      if (item?.uuid === "control-base-NIST-800-53") {
        return currentEntities = getControlsChildren(item?.controlsList?.items)
      }
    })
  }
  else if (fileType === "NIST-CSF") {
    controls && controls?.map((item) => {
      if (item?.uuid === "control-base-NIST-CSF") {
        return currentEntities = getControlsChildren(item?.controlsList?.items)
      }
    })
  }

  const [_attach] = useMutation<LinkItemsInput>(gql(linkItems));
  const attach = (id2: string) => {
    setIsLoading(currentEntities => [...currentEntities,id2])
    const arr = assetComparison && assetComparison.checked.map((obj) => ({
      id: obj,
      id2,
    }));

    _attach({
      variables: {
        input: arr,
      },
    }).then(() => {
      sleep(500).then(attachedControlsResponse?.refetch);
      ToastifyQueue(`Controls Attach Successfully`, 'success')
      setIsLoading(currentEntities => currentEntities?.filter(id => id !== id2))
    });
  };
  const [_detach, { loading: detachLoading, called: detachCalled }] = useMutation<UnlinkItemsInput>(gql(unlinkItems));
  const detach = (id2: string) => {
    setIsLoading(currentEntities => [...currentEntities,id2])
    const arr = assetComparison && assetComparison.checked.map((obj) => ({
      id: obj,
      id2,
    }));
    _detach({
      variables: {
        input: arr,
      },
    }).then(() => {
      sleep(500).then(attachedControlsResponse?.refetch);
      ToastifyQueue(`Controls Detach Successfully`, 'success')
      setIsLoading(currentEntities => currentEntities?.filter(id => id !== id2))
    });
  };
  const datas = useGetQuery(assetComparison?.checked)

  const response: any = useQuery<
  GetLinksByItemQuery,
  GetLinksByItemQueryVariables
  >(gql(datas), {
    onCompleted: () => {
      setAttachedControlsResponse(response)
      sleep(500).then(response?.refetch);
    },
  });
  const attchedId = [] as any
  let intersection = [] as any
 
  if (response?.data) {
    Object.entries(response?.data).forEach((entry, index) => {
      const [key, value] = entry as any;
      value?.items && value?.items.forEach((val) => {
        attchedId.push({ [key]: val?.item?.uuid })
      })
    })
  }

  if (attchedId?.length > 0) {
    const ansObj = attchedId.reduce(
      (prv, cur) => {
        Object.entries(cur).forEach(([key, v]) => key in prv ? prv[key].push(v) : (prv[key] = [v]));
        return prv;
      }, {},
    )
    const ansArray: any = Object.entries(ansObj).map(([key, value]) => (value));
    if (ansArray?.length === 1) {
      intersection = ansArray[0].filter(element => ansArray[0].includes(element));
    }
    for (let j = 0; j < ansArray?.length; j++) {
      if (ansArray[j + 1]) {
        intersection = ansArray[j].filter(element => ansArray[j + 1].includes(element));
      }
    }
  }

  return (
    <>
      <Button
        className="mr-2"
        variant="light"
        onClick={() => {
          setShowCreate(true);
        }}
      >
        <FontAwesomeIcon className="mr-1" icon={faChartBar} />
        Controls
      </Button>
      <Modal
        show={showCreate}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm>
          <Modal.Header>Attach Controls</Modal.Header>
          <Modal.Body>
            <div
              style={{
                height: "auto",
                padding: "0",
                maxHeight: "300px",
                overflowY: "scroll",
              }}
            >
              <div style={{ display: "relative" }} ref={wrapperRef}>
                <InputGroup>
                  <FormControl
                    className="searchinput"
                    type="text"
                    placeholder="Search for controls..."
                    value={filter}
                    onFocus={(e) => {
                      setTreeActive(true);
                      setAddNew(false);
                    }}
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                  />

                  <InputGroup.Append>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <>
                        <Dropdown
                          onSelect={(e) => setFileType(e)}
                        >
                          <Dropdown.Toggle>
                            <div style={{ float: "right" }}>
                              <b className="caret" />
                            </div>
                            {fileType}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="NIST-CSF">NIST-CSF</Dropdown.Item>
                            <Dropdown.Item eventKey="NIST-800-53">NIST-800-53</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </>
                  </InputGroup.Append>
                </InputGroup>

              </div>
             {currentEntities
               ?.filter(
                 (entity) =>
                   !filter
                      || entity?.__typename === "Controls" ? (entity?.displayID?.toLowerCase().indexOf(filter?.toLowerCase()) > -1 || entity?.title?.toLowerCase().indexOf(filter?.toLowerCase()) > -1) : entity.title?.toLowerCase().indexOf(filter?.toLowerCase()) > -1,
               ).map((d) => {
                 return (<>
                  <div className="form-check" key="resultMap">
                    <div className="col-md-10 pl-0 listresult">
                      <span className="font-weight-bold">
{d?.displayID}
{' '}
                      </span>
{' '}
:
{d?.title}
                      <Button
                        variant="link"
                        onClick={() => {
                          intersection.includes(d?.uuid) ? detach(d?.uuid) : attach(d?.uuid);

                        }}
                        className="p0 float-right"
                      >
                        {isLoading?.includes(d?.uuid) ? <Spinner size="sm" animation="border" variant="primary" /> :  intersection.includes(d?.uuid)  ? "Remove" : "Add"}
                      </Button>
                    </div>
                  </div>
{' '}
 
                         </>)
               })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn cancel-btn"
              variant="light"
              onClick={() => {
                Confirmation();
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
