import { DateTime } from "luxon";
import * as yup from "yup";

const searchDictionary: Object = {
  Date: {
    as: "date",
    defaultValue: (item) => ({
      [`${item.name}[0]`]: "",
      [`${item.name}[1]`]: "",
    }),
    filterFn: (testItem, searchItem, searchEntries, formatting) => {
      const entry = searchEntries[`${searchItem.name}`];
      const startVal = entry?.[0] ?? "";
      const endVal = entry?.[1] ?? "";
      const format = formatting.find((f) => f.name === searchItem.name)?.format;
      const dateOfItem = DateTime.fromISO(format?.(testItem));
      const dateOfStart = DateTime.fromFormat(startVal, "MM/dd/yyyy");
      const dateOfEnd = DateTime.fromFormat(endVal, "MM/dd/yyyy");

      if (!startVal && !endVal) {
        return true;
      }
      if (!dateOfItem.isValid) {
        return false;
      }

      const isOnOrAfter =
        !dateOfStart.isValid || dateOfItem >= dateOfStart.startOf("day");
      const isOnOrBefore =
        !dateOfEnd.isValid || dateOfItem <= dateOfEnd.endOf("day");

      return isOnOrBefore && isOnOrAfter;
    },
    validator: yup
      .array()
      .of(yup.string())
      .test(
        "valid-start",
        "${path} - After should be in MM/DD/YYYY format",
        (value) => {
          if (value?.[0]) {
            return DateTime.fromFormat(value?.[0], "MM/dd/yyyy").isValid;
          }
          return true;
        }
      )
      .test(
        "valid-end",
        "${path} - Before should be in MM/DD/YYYY format",
        (value) => {
          if (value?.[1]) {
            return DateTime.fromFormat(value?.[1], "MM/dd/yyyy").isValid;
          }
          return true;
        }
      ),
  },
  Text: {
    as: "input",
    defaultValue: (item) => ({ [`${item.name}`]: "" }),
    filterFn: (testItem, searchItem, searchEntries, formatting) => {
      const format = formatting.find((f) => f.name === searchItem.name)?.format;
      const con1 = !searchEntries[searchItem.name];
      const formattedItem = format?.(testItem, "debug")?.toString();

      const con3 = formattedItem
        ?.toLowerCase()
        .includes(searchEntries[searchItem.name]?.toLowerCase());
      return con1 || con3;
    },
    validator: yup.string(),
  },
  Options: {
    as: "options",
    defaultValue: (item) => ({ [`${item.name}`]: "" }),
    filterFn: (testItem, searchItem, searchEntries, formatting) => {
      const format = formatting.find((f) => f.name === searchItem.name)?.format;
      const newSearchArray = searchEntries?.[searchItem.name]?.split(",");

      if (
        searchEntries?.[searchItem.name] === "" ||
        searchEntries?.[searchItem.name] === undefined
      ) {
        return true;
      }

      return newSearchArray?.includes(format?.(testItem));
    },
    validator: yup.string(),
  },
};

export default searchDictionary;
