import { getStatusProps } from "Modules/Customers/Assessment/StatusDisplay/StatusDisplay";
import React from "react";
import { Badge } from "react-bootstrap";

export const AssessmentResponseStatusBadge: React.FC<{
  status?: string | null;
}> = ({ status }) => {
  const { text } = getStatusProps(status);
  return (
    <Badge pill title={text}>
      {text}
    </Badge>
  );
};
