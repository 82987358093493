import { GetScenariov3Query, RiskThresholdInput } from "API";
import { ITableRecordFormatting } from "common/TabbedTable";

import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getUserFormat,
  getDateTimeFormat,
  getDateTimeDisplayFormat,
  getCompositeId,
  getActivityFeedUserFormat,
} from "utils/dataFormatters";
import { formatStatus, IssueStatus, formatObj } from "utils/formatStatus";

import * as d3 from "d3";
import { useGetGroupRiskThreshold } from "utils/connectors/scenarioConnectors";
import { HGRichTextRead } from "common/HGForm/HGRichText";
import { usePermissions } from "common/Permissions";
import { StatusBadge } from "common/Badges";
import { ScenarioSelectorCheckbox } from "./Comparison/TableComparison";

type schemaParams = {
  archiveScenario?: Function;
  riskThreshold?: RiskThresholdInput;
  showGroupPrefix?: boolean;
  userRole?: string;
};

type ScenarioType = NonNullable<GetScenariov3Query["getScenariov3"]>;

export const useThresholds = () => {
  const { riskThreshold } = useGetGroupRiskThreshold();
  const getThresholdStatus = (scenario: ScenarioType) => {
    const summary =
      (scenario?.FAIRSummary && JSON.parse(scenario?.FAIRSummary)?.summary) ||
      {};
    if (
      // @ts-ignore
      summary?.ALEs?.[riskThreshold?.financial?.capacityPercent ?? 99] <=
        // @ts-ignore
        riskThreshold?.financial?.capacityAbsolute &&
      // @ts-ignore
      summary?.ALEs?.[riskThreshold?.financial?.appetitePercent ?? 99] <=
        // @ts-ignore
        riskThreshold?.financial?.appetiteAbsolute
    ) {
      return "Acceptable";
    }
    if (
      // @ts-ignore
      summary?.ALEs?.[riskThreshold?.financial?.capacityPercent ?? 99] >
      // @ts-ignore
      riskThreshold?.financial?.capacityAbsolute
    ) {
      return "Exceeds Capacity";
    }
    if (
      // @ts-ignore
      summary?.ALEs?.[riskThreshold?.financial?.appetitePercent ?? 99] >
      // @ts-ignore
      riskThreshold?.financial?.appetiteAbsolute
    ) {
      return "Exceeds Appetite";
    }
    return "Not Run";
  };

  const mapThresholdColor = (status) => {
    switch (status) {
      case "Acceptable":
        return "rgb(54, 180, 72)";
      case "Exceeds Appetite":
        return "rgb(238, 130, 25)";
      case "Exceeds Capacity":
        return "rgb(216, 54, 72)";
      default:
        return "rgb(180,180,180)";
    }
  };

  const getThresholdColor = (scenario: ScenarioType) => {
    const status = getThresholdStatus(scenario);
    return mapThresholdColor(status);
  };

  return {
    getThresholdStatus,
    getThresholdColor,
    mapThresholdColor,
    riskThreshold,
  };
};

export const useRiskSchema = (
  params?: schemaParams
): Array<ITableRecordFormatting<any>> => {
  const { getThresholdStatus, getThresholdColor } = useThresholds();
  const { getOrganizationGroup } = usePermissions();
  const issueStatus = formatObj(IssueStatus, formatStatus);
  const moneyFormat = (runner) => (scenario) => {
    const retVal = runner(scenario);
    if (retVal) {
      return d3.format("$.3s")(retVal);
    }
    return null;
  };

  const getALE = (percentile: number) => (scenario) => {
    const val = JSON.parse(scenario?.FAIRSummary ?? "{}").summary?.ALEs?.[
      (percentile > 0 && percentile - 1) || 0
    ]?.toString();
    return val || "";
  };

  const getMax = (scenario) => {
    const val = JSON.parse(
      scenario?.FAIRSummary ?? "{}"
    ).summary?.max.toString();
    return val || "";
  };

  const riskSchema = [
    {
      id: "checkbox",
      name: "",
      format: () => "",
      tableDisplayFormat: (scenario) => (
        <ScenarioSelectorCheckbox
          scenario={scenario}
          userRole={params?.userRole}
        />
      ),
      required: true,
      first: true,
    },
    {
      id: "threshold",
      name: "Threshold",
      format: getThresholdStatus,
      tableDisplayFormat: (scenario) => {
        const color = getThresholdColor(scenario);

        return (
          <div
            style={{
              display: "block",
              height: "20px",
              width: "20px",
              lineHeight: "20px",
              borderRadius: "10px",
              border: `2px solid ${color}`,
              textAlign: "center",
              verticalAlign: "middle",
              fontWeight: "bold",
            }}
          />
        );
      },
      required: true,
      first: true,
    },
    {
      id: "compositeID",
      name: "ID",
      format: (scenario) => `${getCompositeId(scenario)}`,
      required: true,
      first: true,
    },
    {
      id: "title",
      name: "Title",
      format: (scenario) => scenario.title,
      tableDisplayFormat: (scenario) => {
        return <Link to={`/risk/${scenario.id}`}>{scenario.title}</Link>;
      },
      required: true,
      first: true,
    },
    {
      id: "description",
      name: "Description",
      format: (scenario) => scenario?.description,
      tableDisplayFormat: (scenario) => (
        <HGRichTextRead content={scenario?.description} />
      ),
      defaultVisible: false,
    },
    {
      id: "assumptions",
      name: "Assumptions",
      format: (scenario) => scenario?.assumptions,
      tableDisplayFormat: (scenario) => (
        <HGRichTextRead content={scenario?.assumptions} />
      ),
      defaultVisible: false,
    },
    {
      id: "notes",
      name: "Notes",
      format: (scenario) => scenario?.notes,
      tableDisplayFormat: (scenario) => (
        <HGRichTextRead content={scenario?.notes} />
      ),
      defaultVisible: false,
    },
    {
      id: "status",
      name: "Status",
      format: ({ status }) => formatStatus(status),
      tableDisplayFormat: ({ status }) => <StatusBadge status={status} />,
      defaultVisible: true,
      type: "Options",
      optionValues: issueStatus,
    },
    {
      id: "owner",
      name: "Owner",
      format: getUserFormat("scenarioOwner"),
      activityFeed: getActivityFeedUserFormat("owner"),
      defaultVisible: false,
    },
    {
      id: "createdBy",
      name: "Created By",
      format: getUserFormat("scenarioCreatedBy"),
      defaultVisible: false,
    },
    {
      id: "modifiedBy",
      name: "Modified By",
      format: getUserFormat("scenarioModifiedBy"),
      defaultVisible: false,
    },
    {
      id: "createdAt",
      name: "Created At",
      type: "Date",
      format: getDateTimeFormat("createdAt"),
      displayFormat: getDateTimeDisplayFormat("createdAt"),
      defaultVisible: false,
    },
    {
      id: "lastModified",
      name: "Last Modified",
      type: "Date",
      format: getDateTimeFormat("lastModified"),
      displayFormat: getDateTimeDisplayFormat("lastModified"),
      defaultVisible: false,
    },
    {
      id: "dateClosed",
      name: "Closed Date",
      type: "Date",
      format: getDateTimeFormat("dateClosed"),
      displayFormat: getDateTimeDisplayFormat("dateClosed"),
      defaultVisible: false,
    },
    {
      id: "min_ale",
      name: "Min ALE",
      format: getALE(0),
      tableDisplayFormat: moneyFormat(getALE(0)),
      defaultVisible: true,
    },
    {
      id: "25_ale",
      name: "25th % ALE",
      format: getALE(25),
      tableDisplayFormat: moneyFormat(getALE(25)),
    },
    {
      id: "50_ale",
      name: "50th % ALE",
      format: getALE(50),
      tableDisplayFormat: moneyFormat(getALE(50)),
      defaultVisible: true,
    },
    {
      id: "75_ale",
      name: "75th % ALE",
      format: getALE(75),
      tableDisplayFormat: moneyFormat(getALE(75)),
    },
    {
      id: "90_ale",
      name: "90th % ALE",
      format: getALE(90),
      tableDisplayFormat: moneyFormat(getALE(90)),
      defaultVisible: true,
    },
    {
      id: "max_ale",
      name: "Max ALE",
      format: getMax,
      tableDisplayFormat: moneyFormat(getMax),
      defaultVisible: true,
    },
    {
      id: "",
      name: "",
      format: () => "",
      tableDisplayFormat: (scenario) =>
        params?.userRole !== "read" ? (
          <Button
            variant="outline-primary"
            onClick={() =>
              params?.archiveScenario?.(scenario.id, !scenario.archived)
            }
          >
            {(scenario.archived && "Unarchive") || "Archive"}
          </Button>
        ) : (
          <></>
        ),
      required: true,
      last: true,
    },
  ];

  params?.showGroupPrefix &&
    riskSchema.splice(2, 0, {
      id: "department",
      name: "Department",
      format: (actionPlan) =>
        `${
          params?.showGroupPrefix
            ? `${getOrganizationGroup(actionPlan.groupID)?.title} `
            : ""
        }`,
      required: true,
      first: true,
    });
  return riskSchema;
};
