import {
  TextRun,
  Paragraph,
  WidthType,
  TableLayoutType,
  Table,
  TableCell,
  TableRow,
} from "docx";

export const createTableHeader = (headerItems): Array<TableCell> => {
  return headerItems.map((item) => {
    return new TableCell({
      children: [
        new Paragraph({
          children: [new TextRun({ text: item, bold: true })],
        }),
      ],
    });
  });
};
export const createTableCells = (data) => {
  return new TableCell({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: data?.toString() || "",
          }),
        ],
      }),
    ],
  });
};
export const createPHIRecordsTableRows = (data) => {
  return new TableRow({
    children: [
      createTableCells(data.min),
      createTableCells(data.mode),
      createTableCells(data.max),
      createTableCells(data.confidence),
    ],
  });
};

export const createPHIRecordTable = (item): any => {
  if (item === null) return new Paragraph("");
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
    rows: [
      new TableRow({
        children: createTableHeader([
          "Min",
          "Most Likely",
          "Max",
          "Confidence",
        ]),
      }),
      createPHIRecordsTableRows(JSON.parse(item)),
    ],
  });
};

export const createThreatsCells = (cellVals) => {
  return Object.entries(cellVals).map(([key, value]) => {
    return createTableCells(value);
  });
};

export const createThreatsTableRows = (items) => {
  return items.map((item) => {
    const { title, min, mostLikely, max, vulnerability } = item;
    const cellValue = {
      title,
      min: min.toString(),
      mostLikely: mostLikely.toString(),
      max: max.toString(),
      vulnerability: `${vulnerability.toString()}%`,
    };
    return new TableRow({
      children: createThreatsCells(cellValue),
    });
  });
};

export const createThreatsTable = (threats): any => {
  if (threats.length === 0) return new Paragraph("");

  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
    rows: [
      new TableRow({
        children: createTableHeader([
          "Threat",
          "Min",
          "Most Likely",
          "Max",
          "Vulnerability",
        ]),
      }),
      ...createThreatsTableRows(threats),
    ],
  });
};
