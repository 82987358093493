import React, { useState, useEffect, useCallback } from "react";
import { Col } from "react-bootstrap";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useAsync } from "react-use";
import { yupResolver } from "@hookform/resolvers";

import { EditContext, useEditContext } from "./HGFormContext";

export const HGSubForm: React.FC<{ defaultOn?: boolean }> = ({
  children,
  defaultOn,
}) => {
  const { editing, toggleEditing, ...editContext } = useEditContext();
  const [_editing, _setEditing] = useState(defaultOn ?? false);

  const _toggleEditing = () => {
    if (!editing) {
      toggleEditing();
    }
    _setEditing(!_editing);
  };

  return (
    <EditContext.Provider
      value={{
        editing: _editing && editing,
        toggleEditing: _toggleEditing,
        ...editContext,
      }}
    >
      {children}
    </EditContext.Provider>
  );
};

export const HGForm: React.FC<{
  onSubmit?: (...any) => Promise<any> | null;
  resolver?: any;
  editToggle?: boolean;
  resetOnSave?: boolean;
}> = ({
  children, onSubmit, resolver, editToggle, resetOnSave, 
}: any) => {
  const _resolver = (resolver && yupResolver(resolver)) || undefined;

  const methods = useForm({
    mode: "all",
    resolver: _resolver,
  });

  const values = methods.watch();

  const resolverFields = resolver?.fields;

  const validatorErrors = useAsync(async () => {
    return (_resolver(values) as Promise<any>).then(({ errors }) => errors);
  }, [JSON.stringify(values)]);

  const errorEntries = Object.entries(validatorErrors?.value || []);
  const valueEntries = Object.entries(values);

  const unhandledErrors = errorEntries
    .filter((error) => !valueEntries.some((value) => value[0] === error[0]))
    .map(([key, val]) => val);

  useWatch({ control: methods.control });

  const [editing, setEditing] = useState(!editToggle);
  const toggleEditing = () => {
    setEditing((value) => !value);
    methods.reset();
  };

  const submit =    onSubmit
    && ((...props) =>
      onSubmit(...props).then((response) => {
        if (response !== false) {
          setEditing(false);
          if (resetOnSave) {
            methods.reset();
          }
        }
      }));

  // setDefaultValuesToFields
  const updateDefaults = useCallback(() => {
    if (!resolver?.fields) return;
    const fields = Object.entries(resolver?.fields);
    fields.forEach(([key, value]: [string, any]) => {
      if (value?._default) {
        const _val = value?._default ;
        const val = Array.isArray(_val) ? _val.map((v) => v.toString()) : _val;
        const oldVal = methods.getValues(key);
        if (oldVal !== val) {
          methods.setValue(key, val);
        }
      }
    });
    // eslint-disable-next-line
  }, [resolver, methods, values]);

  useEffect(() => {
    updateDefaults();
    // eslint-disable-next-line
  }, [resolver]);

  const _submit = submit && methods.handleSubmit(submit);

  return (
    <FormProvider
      {...{
        ...methods,
        errors: (_resolver && validatorErrors?.value) || methods.errors,
        updateDefaults,
      }}
    >
      <form onSubmit={_submit}>
        <EditContext.Provider
          value={{
            editing: editing || !editToggle,
            toggleEditing,
            resolverFields,
            submit:_submit,
          }}
        >
          {children}
        </EditContext.Provider>
      </form>
      {editing && unhandledErrors.length > 0 && (
        <Col sm={12}>
          {JSON.stringify({ unhandledErrors })}
          Unhandled Validation Error:
          <ul>
            {unhandledErrors.map((e: any) => {
              return <li key={e?.message}>{e?.message}</li>;
            })}
          </ul>
        </Col>
      )}
    </FormProvider>
  );
};
