import { ReportConfig } from "utils/docx-export/reportSchemaParse.types";
import { useGenerateReportFilename } from "utils/docx-export/useCreateFilename";
import { RiskDomainType } from "./riskDomainTypes";
import { DateTime } from "luxon";


export const riskDomainReportSchema = (): ReportConfig<RiskDomainType> => {
  const getFilename = useGenerateReportFilename();
  return{
    getFilename: (item) =>
      getFilename({
        type: "filename",
        fileName: `Decipher Risk - Risk Domain ${item.title ?? "title"}`,
        timestamp: DateTime.now(),
      }),
    fields: [
      { type: "Header", content: (item) => item.title ?? "" },
      {
        type: "InlineText",
        label: "ID",
        content: (item) => item?.displayID ?? '',
      },
      {
        type: "InlineText",
        label: "Parent",
        content: (item) => item?.parent?.title ?? '',
      },
      {
        type: "RichText",
        label: "Description",
        content: (item) => item.description ?? "",
      },
    ],
  }
}