import React, { createContext, useContext } from "react";

export interface EditContextProps {
  editing: boolean;
  toggleEditing: () => void;
  resolverFields: Object;
  submit: () => any;
}

export const EditContext = createContext<EditContextProps>({
  editing: false,
  toggleEditing: () => {},
  resolverFields: {},
  submit: () => {},
});

export const useEditContext = () => useContext(EditContext);
