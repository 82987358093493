import React, { useMemo } from "react";

import {
  ApolloClient,
  DefaultOptions,
  createHttpLink,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  TypePolicy,
} from "@apollo/client";
import { Auth } from "@aws-amplify/auth";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { useAlertQueue } from "common/Overlays/AlertQueue";
import { getMainDefinition } from "@apollo/client/utilities";
import omitDeep from "omit-deep-lodash";

import * as Sentry from "@sentry/browser";

import { setContext } from "@apollo/client/link/context";
import { OperationDefinitionNode } from "graphql";
import ToastifyQueue from "common/Overlays/ToastifyQueue";

const dataErrorLink = new ApolloLink((operation, forward) => {
  console.log(`starting request for ${operation.operationName}`, operation);
  return forward(operation).map((data) => {
    const retObj = Object.values(data.data ?? {})[0]?.[0];
    console.log("DATA RETURN", Object.values(data.data ?? {}), retObj);
    if (retObj?.res == "ERROR") {
      throw new Error(JSON.parse(retObj?.error ?? "").msg ?? "Error");
    }
    console.log(`ending request for ${operation.operationName}`);
    return data;
  });
});

const errorLink = () =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        if (!error.message.includes("Non-Error exception captured")) {
          Sentry.captureException(error);
        }
      });
    } else if (networkError) {
      const contents = JSON.stringify(networkError);
      Sentry.captureException(networkError);
      console.log(`[Network error]: ${networkError}`);
    } else {
      console.log(`[Unknown error]:`, { graphQLErrors, networkError });
    }
  });

const retryLink = () =>
  new RetryLink({
    attempts: (count, operation, error) => {
      if (error && count === 1) {
        Sentry.captureException(new Error(error));
        ToastifyQueue(`Refreshing user token.`, "warning");
      }
      return !!error;
    },
    delay: (count, operation, error) => {
      return count * 1000;
    },
  });
Auth.configure({
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_USER_POOL_ID
    ? process.env.REACT_APP_USER_POOL_ID
    : "us-west-2_bi9k82eM6",

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    ? process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    : "hc5uibinkjljk9l1rii9fth1",
  endpoint: "https://auth.decipherrisk.com/",
});
// Amplify.configure({
//   Auth: {
//     // OPTIONAL - Amazon Cognito User Pool ID
//     userPoolId: process.env.REACT_APP_USER_POOL_ID
//       ? process.env.REACT_APP_USER_POOL_ID
//       : "us-west-2_bi9k82eM6",

//     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//     userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
//       ? process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
//       : "hc5uibinkjljk9l1rii9fth1",
//     endpoint: "https://auth.decipherrisk.com/",
//   },
// });

const graphqlURL = process.env.REACT_APP_GRAPHQL_URL
  ? process.env.REACT_APP_GRAPHQL_URL
  : "";

const auth: any = {
  type: "AMAZON_COGNITO_USER_POOLS",
  jwtToken: async () =>
    (await Auth.currentSession()).getAccessToken().getJwtToken(),
};

const authLink = setContext(async (_, { headers }) => {
  const token = await auth.jwtToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
  query: {
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

const httpLink: ApolloLink = createHttpLink({
  uri: graphqlURL,
  headers: {
    "Content-Encoding": "gzip",
    "Accept-Encoding": "gzip",
  },
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ["__typename"]; // more keys like timestamps could be included here

  const def = getMainDefinition(operation.query) as OperationDefinitionNode;
  if (def && def.operation === "mutation") {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

const creatableItemArray = [
  "Task",
  "Threats",
  "ActionPlan",
  "Issue",
  "Scenario",
  "AuditPlanning",
  "Assets",
  "AssessmentAssets",
];
const DataProvider: React.FC = ({ children }) => {
  const alertQueue = useAlertQueue();

  const client = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          cleanTypenameLink,
          authLink,
          dataErrorLink,
          retryLink(),
          errorLink(),
          httpLink,
        ]),
        cache: new InMemoryCache({
          dataIdFromObject: (...baseO: any) => {
            const o = baseO[0];
            return o.id
              ? (o.sort && `${o.__typename}-${o.id}${o.sort}`) ||
                  `${o.__typename}-${o.id}`
              : `${o.__typename}-${o.cursor}`;
          },
          typePolicies: {
            ...creatableItemArray
              .map((item) => ({
                [`Paginated${item}References`]: { keyFields: false },
              }))
              .reduce((a, b) => ({ ...a, ...b })),
            PaginatedIssueReferences: {
              keyFields: false,
            },
            RiskThreshold: {
              keyFields: false,
            },
            Threshold: {
              keyFields: false,
            },
            SurveyTotals: {
              keyFields: false,
            },
            PaginatedLinks: {
              keyFields: false,
            },
            FrequencyData: {
              keyFields: false,
            },
            AnalysisRun: {
              keyFields: false,
            },
            PaginatedMagnitudeData: {
              keyFields: false,
            },
            PaginatedAssessment: {
              keyFields: false,
            },
            PaginatedAssessmentResponse: {
              keyFields: false,
            },
            MagnitudeData: {
              keyFields: false,
            },
            PertInfo: {
              keyFields: false,
            },
            PaginatedTaskReferences: {
              keyFields: false,
            },
            PaginatedThreatsReferences: {
              keyFields: false,
            },
            Task: {
              keyFields: false,
            },
            PaginatedControls: {
              keyFields: false,
            },
            Link: {
              keyFields: false,
            },
            RelatableItem: {
              keyFields: false,
            },
            ItemApprover: {
              keyFields: false,
            },
            PaginatedItemApprover: {
              keyFields: false,
            },
          },
        }) as any,
        defaultOptions,
        connectToDevTools: true,
      }),
    // eslint-disable-next-line
    [authLink, httpLink]
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default DataProvider;
