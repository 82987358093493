import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { useAssetComparisonContext } from "./BulkEditAssets";
import { cancelPopupMessage } from "utils/globalVariables";
import AssetsThreatsInputs from "./AssetsThreatsInputs";

export const CreateBulkEditThreats = ({ assets }) => {
  const history = useHistory();
  const assetComparison = useAssetComparisonContext();
  const [showCreate, setShowCreate] = useState(false);

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowCreate(false);
      history.push("/HIPAARiskAssessment/assets");
    }
  }
  const updateModal = ():void => {
    setShowCreate(!showCreate)
  }
  return (
    <>
      <Button
        className="mr-2"
        variant="light"
        onClick={() => {
          setShowCreate(true);
        }}
      >
        <FontAwesomeIcon className="mr-1" icon={faChartBar} />
        Threats
      </Button>
      <Modal className="attachThreatsModal"
        show={showCreate}
        onHide={() => {
          Confirmation();
        }}
      >
        <Modal.Header>Attach Threats</Modal.Header>
        <Modal.Body>
          <AssetsThreatsInputs
            assetID={assetComparison.checked}
            assetsList={assets}
            mode="Bulk"
            name={showCreate} 
            updateModal={updateModal}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn cancel-btn"
            style={{ marginRight: "5px" }}
            data-dismiss="modal"
            onClick={() => {
              Confirmation();
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
