import { useMutation, gql } from "@apollo/client";
import {
  ModifyGroupInput,
  ModifyGroupMutation,
  ModifyGroupMutationVariables,
} from "API";
import { HGForm, HGFormControl, HGFormSubmit } from "common/HGForm";
import { usePermissions } from "common/Permissions";
import { modifyGroup } from "graphql/mutations";
import React, { useMemo, useState } from "react";
import { Col, Card, Button, Modal } from "react-bootstrap";
import GroupPermissionsViewUsers from "./GroupPermissionsViewUsers";
import { cancelPopupMessage } from "utils/globalVariables";
import { HGRichText } from "common/HGForm/HGRichText";
import * as yup from "yup";
import { HGInputBox } from "common/HGForm/Inputs";

const ModifyGroup: React.FC<{ refetch; group; groups; style }> = ({
  refetch,
  group,
  groups,
  style,
}) => {
  const [show, setShow] = useState(false);
  const [showGroupFooter, setShowGroupFooter] = useState(false);

  const modifyGroupSchema = useMemo(() => {
    return yup.object<Partial<ModifyGroupInput>>({
      title: yup
        .string()
        .required()
        .default(group.title)
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test(
          "check-duplication",
          "Department title already exists!",
          (value) => {
            const index = groups
              .filter((e) => e.title !== group.title)
              .findIndex((element) => {
                return (
                  element.title.toLowerCase() === value?.toLowerCase().trim()
                );
              });
            return index === -1;
          }
        ),
      shortTitle: yup
        .string()
        .default(group.shortTitle)
        .max(3, "Department Name Abbreviation can be at most 3 characters")
        .label("Department Name Abbreviation"),
    });
  }, [group]);

  const [runModifyGroup] = useMutation<
    ModifyGroupMutation,
    ModifyGroupMutationVariables
  >(gql(modifyGroup));
  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShow(false);
    }
  }
  return (
    <div style={style}>
      <Button
        variant="outline-primary"
        onClick={() => {
          setShow(true);
        }}
      >
        Modify Department
      </Button>
      <Modal
        show={show}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          onSubmit={async ({ title, shortTitle }) => {
            return runModifyGroup({
              variables: {
                input: [
                  {
                    id: group.id,
                    title,
                    shortTitle,
                  },
                ],
              },
            }).then(async (response) => {
              await refetch();
              return setShow(false);
            });
          }}
          resolver={modifyGroupSchema}
        >
          <Modal.Header>Modify Department</Modal.Header>
          <Modal.Body>
            <HGInputBox
              name="title"
              defaultValue={group?.title}
              label="Department Name"
            />
            <HGInputBox
              name="shortTitle"
              defaultValue={group.shortTitle ?? ""}
              label="Department Name Abbreviation"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel-btn"
              variant="light"
              onClick={() => {
                Confirmation();
              }}
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
      &nbsp;
      <Button
        variant="outline-primary"
        onClick={() => {
          setShowGroupFooter(true);
        }}
      >
        Department Footer
      </Button>
      <Modal
        show={showGroupFooter}
        onHide={() => {
          setShowGroupFooter(false);
        }}
      >
        <HGForm
          onSubmit={async ({ groupEmailFooter }) => {
            return runModifyGroup({
              variables: {
                input: [
                  {
                    id: group.id,
                    groupEmailFooter,
                  },
                ],
              },
            }).then(async (response) => {
              await refetch();
              return setShowGroupFooter(false);
            });
          }}
        >
          <Modal.Header>Department email footer</Modal.Header>
          <Modal.Body>
            <HGRichText
              name="groupEmailFooter"
              label="Department email footer"
              defaultValue={group?.groupEmailFooter || ""}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel-btn"
              variant="light"
              onClick={() => {
                setShowGroupFooter(false);
              }}
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </div>
  );
};

const GroupPermissionsView = () => {
  const { group, organization, groups, refetchGroups } = usePermissions();
  return (
    ((group?.userRole === "admin" || organization?.userRole === "admin") && (
      <Col sm={12}>
        <Card>
          <Card.Header>
            Department:&nbsp;
            {group?.title}
            <ModifyGroup
              group={group}
              groups={groups}
              refetch={refetchGroups}
              style={{ float: "right" }}
            />
          </Card.Header>
          <Card.Body>
            <GroupPermissionsViewUsers />
          </Card.Body>
        </Card>
      </Col>
    )) ||
    null
  );
};

export default GroupPermissionsView;
