import React from "react";
import { FormControl } from "react-bootstrap";

export const SearchBar = ({
  searchBarValue,
  setSearchBarValue,
  componentAppend,
}) => {
  return (
    <FormControl
      placeholder="Search All Columns..."
      value={searchBarValue}
      className="placeholder-right formInput"
      onChange={(e) => setSearchBarValue(e.target.value)}
    />
  );
};
