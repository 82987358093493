import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, InputGroup, Modal } from "react-bootstrap";
import { faArrowsAlt, faColumns } from "@fortawesome/free-solid-svg-icons";
import "./FilterSortPanel.scss";
import { useLocalStorage } from "react-use";
import { ITableRecordFormatting } from "common/TabbedTable";
import * as R from "ramda";
import { HGCheckBox } from "common/HGForm/Inputs";

export const shapeResolver = (shape, defaultShape) => {
  const _shape = shape
    .filter((d) => defaultShape.find((s) => s.name === d.name))
    .map((d) => ({ ...defaultShape.find((s) => s.name === d.name), ...d }));
  const _defaultShape = defaultShape.filter(
    (d) => !shape.find((s) => s.name === d.name)
  );
  return [..._shape, ..._defaultShape].map((d) => ({
    visible: d.visible || d.defaultVisible,
    ...d,
  }));
};

export const SortPanel: React.FC<{ save; order; module }> = ({
  save,
  order,
  module,
}) => {
  const [open, setOpen] = useState(false);
  const [tempShape, setTempShape] = useState(order);
  useEffect(() => {
    setTempShape(order);
  }, [order]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      setTempShape(R.move(source.index, destination.index, tempShape));
    }
  };

  const cancel = () => {
    setOpen(false);
    setTempShape(order);
  };

  const saveOrder = () => {
    save(tempShape);
    setOpen(false);
  };
  const sortableUI = () => (
    <div
      style={{ position: "relative" }}
      aria-label="sortableUI"
      className="sortableUI"
    >
      {open && <div className="screenFilter" onClick={cancel} />}
      <div className="float-right">
        <ButtonGroup style={{ zIndex: 2 }}>
          <Button
            aria-label="Columns"
            className="dropdown-toggle"
            onClick={() => setOpen(!open)}
          >
            <FontAwesomeIcon icon={faColumns} />
          </Button>
        </ButtonGroup>
        {open && (
          <Modal show={open}>
            <Modal.Header>
              {module === "AuditPlanning"
                ? "Audit Plan"
                : module === "RelatableItem"
                ? "Related Items"
                : module?.replace(/([a-z])(?=[A-Z])/g, "$1 ")}{" "}
              Column Settings
            </Modal.Header>
            <Modal.Body className="modal-body-scroll">
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="sortable" ignoreContainerClipping>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {tempShape.map((item, index) => {
                          return (
                            <Draggable
                              key={item.name}
                              draggableId={item.name}
                              index={index}
                            >
                              {(draggableProps, snapshot) => (
                                <div
                                  ref={draggableProps.innerRef}
                                  {...draggableProps.draggableProps}
                                  style={draggableProps.draggableProps.style}
                                >
                                  <HGCheckBox
                                    inputGroup={() => (
                                      <div
                                        {...draggableProps.dragHandleProps}
                                        className="draggable"
                                        style={{ margin: "5px" }}
                                      >
                                        <FontAwesomeIcon
                                          className="nav-icon"
                                          icon={faArrowsAlt}
                                        />
                                      </div>
                                    )}
                                    id={item.name}
                                    label={item.name}
                                    name={item.name}
                                    checked={item.visible}
                                    onChange={(e) => {
                                      e.persist();
                                      setTempShape(
                                        tempShape.map((i) => {
                                          if (i.name === item.name) {
                                            return {
                                              name: i.name,
                                              visible: e.target.checked,
                                            };
                                          }
                                          return i;
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="cancel-btn mr-1"
                onClick={cancel}
                variant="light"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  saveOrder();
                }}
              >
                Apply
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );

  return <>{sortableUI()}</>;
};

interface SortItem {
  name: string;
  visible?: boolean;
}

export const useFilterSortPanel = <T extends {}>({
  order,
  defaultOrder,
  saveKey,
  module,
}: {
  order?: SortItem[];
  defaultOrder: ITableRecordFormatting<T>[];
  saveKey?: string;
  module?: string;
}): {
  sortOrder: SortItem[];
  setOrdering: any;
  filteredOrder: ITableRecordFormatting<T>[];
  SortUI: React.ReactElement;
  module?: string;
} => {
  const defaultSortableColumns = defaultOrder.filter(
    (d) => !d.first && !d.last
  );

  const [sortOrder, setOrdering] = useLocalStorage<SortItem[]>(
    saveKey ??
      (
        window.crypto.getRandomValues(new Uint32Array(1))[0] /
        2 ** 32
      ).toString(),
    defaultSortableColumns
  );

  const resolvedShape = shapeResolver(sortOrder, defaultSortableColumns);

  const filteredOrder: any[] = [
    ...defaultOrder.filter((d) => d.first),
    ...resolvedShape,
    ...defaultOrder.filter((d) => d.last),
  ].map((d) => ({ visible: d.required || d.defaultVisible, ...d }));
  return {
    sortOrder: sortOrder || [],
    setOrdering,
    filteredOrder,
    SortUI: (
      <SortPanel
        save={(data) => {
          setOrdering(shapeResolver(data, resolvedShape));
        }}
        order={resolvedShape}
        module={module}
      />
    ),
  };
};

export default SortPanel;
