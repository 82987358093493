import {
  TextRun,
  Paragraph,
  WidthType,
  TableLayoutType,
  Table,
  TableCell,
  TableRow,
} from "docx";
import { getRiskCategories } from "./RiskPage/RiskTabs/RiskCategories/categories";
import * as d3 from "d3";
import { getFairData } from "../FAIR/Summary/FairSummary";
import { sumMagnitudes } from "../FAIR/Summary/ScenarioFairSummaryTable";

export type FAIRScopeEntry = {
  [key: string]: string;
};

export type FAIRScopeCategory = {
  [key: string]: Array<string | number>;
};
export type FAIRScopeConfig = {
  categories?: string;
};
export type FormattedCategory = {};

export const getAnalysisTableCells = (items) => {
  return items.map((item)=>{
    return new TableCell({
      children:[
        new Paragraph({text: item.trim()})
      ]
    })
  })
}

export const getAnalysisScopeText = (
  items: Array<FAIRScopeEntry>
): Array<any> => {
  return items
    .map((item): Array<any> => {
      const key = Object.keys(item)[0];
      const value = item[key].split(',');
      if (!item[key]) {
        return [
          new Paragraph({
            children:[
              new TextRun({
                text: `${key.replace(/([a-z])([A-Z])/g, "$1 $2")}: `,
                bold: true,
              }),
            ]
          }),
        ];
      }
      const tableRows: TableRow[] = [];
      let currentRowCells: any[] = [];
      
       // Fill the remaining empty cells if the length of value is not a multiple of 3
       const remainingEmptyCells = (3 - (value.length % 3)) % 3;
       const valueWithEmptyCells = [...value, ...Array(remainingEmptyCells).fill("")];
 
       valueWithEmptyCells.forEach((cell, index) => {
         currentRowCells.push(cell);
         if (currentRowCells.length === 3) {
           tableRows.push(
             new TableRow({
               children: getAnalysisTableCells(currentRowCells),
             })
           );
           currentRowCells = [];
         }
       });
 
       // If there's still content in the last row, add it with empty cells to make it a complete row
       if (currentRowCells.length > 0) {
         // Fill the remaining empty cells to complete the last row
         const remainingEmptyCellsLastRow = 3 - currentRowCells.length;
         currentRowCells = [...currentRowCells, ...Array(remainingEmptyCellsLastRow).fill("")];
 
         tableRows.push(
           new TableRow({
             children: getAnalysisTableCells(currentRowCells),
           })
         );
       }
      return [
        new Paragraph({
          children:[
            new TextRun({
              text: `${key.replace(/([a-z])([A-Z])/g, "$1 $2")}: `,
              bold: true,
              break: 1,
            }),
          ]
        }),
        value.length>0 &&
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.FIXED,
          rows: tableRows,
        }),
      ];
    })
    .flat();
};

export const getFAIRScopeObject = (
  data: string | undefined
): FAIRScopeCategory => {
  if (!data || data === "I'M HERE?") return {};
  return JSON.parse(data) as FAIRScopeCategory;
};

export const createFormattedCategoriesArray = (
  categories: FAIRScopeCategory
): Array<FormattedCategory> => {
  const { riskCategories } = getRiskCategories();

  const formattedCategoriesArray = riskCategories.map(
    (category): FAIRScopeEntry => {
      const selectedCategories: FAIRScopeEntry = {
        [category.title]: category.options
          .filter(({ disabled }) => !disabled)
          .filter(({ value }) =>
            categories?.[category.title]
              ?.map((i) => i.toString())
              ?.includes(value)
          )
          .map(({ label }): string => label)
          .reduce((a, b): string => (!a ? b : `${a}, ${b}`), ""),
      };
      return selectedCategories;
    }
  );
  formattedCategoriesArray.forEach((item, index) => {
    const keys = Object.keys(item);
    if (keys.includes("Impact Categories")) {
      formattedCategoriesArray.push({
        ImpactCategories: item["Impact Categories"],
      });
      formattedCategoriesArray.splice(index, 1);
    }
  });
  formattedCategoriesArray.forEach((item, index) => {
    const keys = Object.keys(item);
    if (keys.includes("Financial Loss")) {
      formattedCategoriesArray.push({
        FinancialLoss: item["Financial Loss"],
      });
      formattedCategoriesArray.splice(index, 1);
    }
  });

  return formattedCategoriesArray;
};

export const getAnalysisScope = (data): Array<Paragraph | Table> => {
  const categories = getFAIRScopeObject(data?.categories);
  return getAnalysisScopeText(createFormattedCategoriesArray(categories));
};

const getFairSummaryRowData = (
  data: number[],
  financial: boolean,
  label?: string | null
) => {
  const format = (financial && d3.format("$.3~s")) || d3.format(".3~f");

  const minimum = format(d3.min(data ?? 0) ?? 0);
  const mean = format(d3.mean(data ?? 0) ?? 0);
  const median = format(d3.median(data ?? 0) ?? 0);
  const maximum = format(d3.max(data ?? 0) ?? 0);
  return {
    minimum,
    mean,
    median,
    maximum,
  };
};

export type FAIRSummaryEntry = {
  minimum: string;
  mean: string;
  median: string;
  maximum: string;
};

export type FAIRSummaryMagnitudeEntry = { [key: string]: FAIRSummaryEntry };

export type FAIRSummaryData = {
  frequency: FAIRSummaryEntry;
  magnitudes?: Array<FAIRSummaryMagnitudeEntry>;
  aggregateMagnitude: FAIRSummaryEntry;
};

export const createFAIRSummaryData = (item): FAIRSummaryData => {
  const fairData = getFairData(item);
  const frequency = getFairSummaryRowData(
    fairData?.frequency?.[0]?.data,
    false
  );
  const magnitudes = fairData?.magnitudes.map((magnitude) => {
    return {
      [magnitude.title]: getFairSummaryRowData(magnitude?.data, true),
    };
  });
  const aggregateMagnitude = getFairSummaryRowData(
    sumMagnitudes(fairData?.magnitudes.map(({ data }) => data)),
    true
  );
  return {
    frequency,
    magnitudes,
    aggregateMagnitude,
  };
};

export const FAIRSummaryTableHeader = (): Array<TableCell> => {
  const headerItems = ["", "Minimum", "Mean", "Median", "Maximum"];
  return headerItems.map((item) => {
    return new TableCell({
      children: [
        new Paragraph({
          children: [new TextRun({ text: item, bold: true })],
        }),
      ],
    });
  });
};

export const createValueCell = (data: string, label?: string, prefix?: string): TableCell => {
  return new TableCell({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: data?.replace(/([A-Z])/g, " $1")?.trim(),
          }),
        ],
      }),
    ],
  });
};

export const createFAIRSummaryCells = (
  label: string,
  data: FAIRSummaryEntry
): Array<TableCell> => {
  return [
    new TableCell({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: (label.charAt(0).toUpperCase() + label.slice(1))
                .replace(/([A-Z])/g, " $1")
                .trim(),
            }),
          ],
        }),
      ],
    }),
    createValueCell(data.minimum, label),
    createValueCell(data.mean, label),
    createValueCell(data.median, label),
    createValueCell(data.maximum, label),
  ];
};

export const getMagnitudeRows = (
  magnitudes?: Array<FAIRSummaryMagnitudeEntry> | undefined
): Array<TableRow> => {
  if (!magnitudes) return [];
  return magnitudes.map((item: FAIRSummaryMagnitudeEntry): TableRow => {
    const key = Object.keys(item)[0];
    return new TableRow({
      children: createFAIRSummaryCells(key, item[key]),
    });
  });
};

export const createFAIRSummaryTableRows = (
  data: FAIRSummaryData
): Array<TableRow> => {
  return [
    new TableRow({
      children: createFAIRSummaryCells("frequency", data.frequency),
    }),
    ...getMagnitudeRows(data.magnitudes),
    new TableRow({
      children: createFAIRSummaryCells(
        "aggregateMagnitude",
        data.aggregateMagnitude
      ),
    }),
  ].filter((item) => !!item);
};

export const createFAIRSummaryTable = (
  fairSummaryData: FAIRSummaryData
): Table => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
    rows: [
      new TableRow({
        children: FAIRSummaryTableHeader(),
      }),
      ...createFAIRSummaryTableRows(fairSummaryData),
    ],
  });
};
