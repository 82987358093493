import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
} from "victory";
import { DateTime } from "luxon";
import useDimensions from "react-use-dimensions";

interface LineChartInput {
  title: string;
  data?: any;
}

export const LineChart: React.FC<LineChartInput> = ({ data, title }) => {
  const [measureRef, { width }] = useDimensions();
  return (
    <div className="ibox">
      <div className="ibox-title">
      <Row className="dashboard-header">
        <h3>{title}</h3>
      </Row>
      </div>
      <div className="ibox-content">
        <Row>
          <Col xs="12" ref={measureRef}>
            <VictoryChart
              width={width}
              height={300}
              containerComponent={
                <VictoryContainer
                  style={{
                    margin: "0px",
                    left: "0px",
                  }}
                />
              }
              theme={VictoryTheme.material}
              domain={{
                x: data.xAxis,
                y: data.yAxis,
              }}
            >
              <VictoryAxis
                tickValues={Array.from({ length: data.yAxis[1] + 1 }, (_, index) => index)}
                dependentAxis /* To target the y-axis */
                style={{
                  axisLabel: { padding: 30, fontWeight: 'bold' },
                  tickLabels: { angle: 0 }, 
                }}
                fixLabelOverlap
                label={data?.yAxisLabel}
              />
              <VictoryAxis
                style={{
                  axisLabel: { padding: 30, fontWeight: 'bold' },
                  grid: { stroke: "grey" },
                }}
                tickValues={data.xTicks ?? []}
                tickFormat={data.xFormat}
                label={data?.xAxisLabel}
              />
              <VictoryLine
                style={{
                  data: { stroke: "#c43a31" },
                  parent: { border: "1px solid #ccc" },
                }}
                data={data.graphData}
              />
              <VictoryScatter
                data={data.graphData}
                style={{ data: { fill: "#c43a31" } }}
              />
            </VictoryChart>
          </Col>
        </Row>
      </div>
    </div>
  );
};
