/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { usePermissions } from "common/Permissions";
import { exportDocX } from "./docx-export";
import {
  reportSchemaParse,
  reportGetFilename,
  ReportSchemaParseReturn,
} from "./reportSchemaParse";
import { saveAs } from "file-saver";
import { resetOlCount, getOlCount, getTitlePage } from "./docUtils";
import { ReportConfig } from "./reportSchemaParse.types";
import { Paragraph } from "docx";
import { faSpinner, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export type DownloadItemProps<T> =
  | {
      type: "Single";
      item: T;
      schema: ReportConfig<T>;
      tableOfContents?: boolean;
      fileName?: string;
    }
  | {
      type: "Multi";
      items: Array<T>;
      schema: ReportConfig<T>;
      fileName?: string;
      tableOfContents?: boolean;
    };

const getContents = async <T extends {}>(
  props: DownloadItemProps<T>,
  permissions?: any
): Promise<{
  fileName: string;
  contents: ReportSchemaParseReturn;
  titlePage?: any;
}> => {
  switch (props.type) {
    case "Single":
      var singleReturn = await reportSchemaParse(props.schema, props.item);
      var titleReturn = getTitlePage(props.item);

      return {
        fileName: reportGetFilename<T>(props.schema, props.item),
        contents: singleReturn,
        titlePage: titleReturn,
      };
    case "Multi":
      var multiReturn = await Promise.all(
        props.items.map((item) => reportSchemaParse(props.schema, item))
      ).then((items) =>
        items
          .reduce(
            (a, b): ReportSchemaParseReturn => [
              ...a,
              new Paragraph({ pageBreakBefore: true }),
              ...b,
            ],
            []
          )
          .flat()
      );
      var titleReturn = getTitlePage(props.items[0], permissions.group.title);
      return {
        fileName: props?.fileName ?? "",
        contents: multiReturn,
        titlePage: titleReturn,
      };
    default:
      throw new Error();
  }
};

export const generateReportData = async (permissions, props) => {
  const { fileName, contents, titlePage } = await getContents(
    props,
    permissions
  );
  const ols = getOlCount();
  return exportDocX({
    contents,
    titlePage,
    ols,
    organization: permissions.organization.title,
    group: permissions.group.title,
    tableOfContents: props?.tableOfContents,
  });
};

const GenerateReport = <T extends {}>(props: DownloadItemProps<T>): any => {
  const permissions = usePermissions();
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(() => true);
    resetOlCount();
    const { fileName, contents } = await getContents(props, permissions);
    const ols = getOlCount();

    const blobData = await generateReportData(permissions, props);
    saveAs(blobData, fileName);
    setLoading(false);
  };
  return (
    <>
      <Button
        className={`mb-2 ml-2 ${props?.fileName === "" ? "disabled" : ""}`}
        onClick={handleClick}
        disabled={props?.fileName === ""}
      >
        {loading === true ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : props.type === "Multi" ? (
          "Download Report"
        ) : (
          <>
            <FontAwesomeIcon icon={faFileWord} />
          </>
        )}
      </Button>
    </>
  );
};

export default GenerateReport;
