import { gql,useLazyQuery } from "@apollo/client";
import {
  GetUrlsInGroupQuery,
  GetUrlsInGroupQueryVariables,
  GetUrlQuery,
  GetUrlQueryVariables,
} from "API";
import { usePermissions } from "common/Permissions";
import { getUrl, getUrlsInGroup } from "graphql/queries";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";

const urlsInGroupTransform = (response) => {
  return (response.data?.getUrlsInGroup.items || []).map(
    sortToID("URL#"))
};

export const useGetUrlsInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
  GetUrlsInGroupQuery,
  GetUrlsInGroupQueryVariables
  >(
    gql(getUrlsInGroup),
    {
      variables: {
        groupID: group.id,
      },
    },
    hasGroup,
  );

  const urls = (response.data?.getUrlsInGroup.items || []).map(
    sortToID("URL#"),
  );

  return { ...response, urls };
};

export const useGetUrlsInSelectedGroup = () => {
  const [_getUrlsInSelectedGroup, response] = useLazyQuery<
  GetUrlsInGroupQuery,
  GetUrlsInGroupQueryVariables
  >(gql(getUrlsInGroup));
  
  return (groupID) => {
    return _getUrlsInSelectedGroup(groupID).then(urlsInGroupTransform);
  };
};

export const useGetUrl = (id) => {
  const { group, hasGroup } = usePermissions();
  const response = useProtectedQuery<GetUrlQuery, GetUrlQueryVariables>(
    gql(getUrl),
    {
      variables: {
        groupID: group.id,
        id,
      },
    },
    hasGroup,
  );

  const url = sortToID("URL#")(response.data?.getUrl);

  return { ...response, url };
};
