import { usePermissions } from "common/Permissions";
import { useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { useHistory } from "react-router";
import ToastifyQueue from "common/Overlays/ToastifyQueue";

const random = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
const alertID = random.toString();
export const useGroupCheck = (
  itemGroupID,
  redirectUrl,
  validateGroup = true
) => {
  const [canAccess, setCanAccess] = useState(true);
  const { group, getOrganizationGroup, setGroupId } = usePermissions();
  const groupID = group.id;
  const history = useHistory();

  const prevGroupID = usePrevious(groupID);

  useEffect(() => {
    if (!itemGroupID || !groupID || !validateGroup) return;
    if (itemGroupID !== groupID) {
      const groupMatches = groupID === prevGroupID;

      if (!groupMatches) {
        history.push(redirectUrl);
        return;
      }

      if (groupMatches) {
        setGroupId(itemGroupID)
          .then(() => {
            ToastifyQueue(
              `This item belongs to the ${
                getOrganizationGroup(itemGroupID)?.title || "unknown"
              } group. Your current group has been changed to that context. If you are trying to switch groups, please go to the module's table view before switching.`,
              "primary"
            );
            setCanAccess(true);
          })
          .catch(() => {
            ToastifyQueue(
              "You do not belong to the group that owns this item.",
              "danger"
            );
            setCanAccess(false);
          });
      }
    }
  }, [groupID, itemGroupID]);

  return canAccess;
};
