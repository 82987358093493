import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button, Row, Col, InputGroup } from "react-bootstrap";
import { useMutation, ApolloError } from "@apollo/client";
import { createIssue } from "graphql/mutations";
import gql from "graphql-tag";
import * as yup from "yup";
import {
  CreateIssueInput,
  Priority,
  Status,
  Severity,
  CreateIssueMutation,
  CreateIssueMutationVariables,
} from "API";
import { HGForm, HGFormSubmit } from "common/HGForm";
import { usePermissions } from "common/Permissions";
import { DateTime } from "luxon";
import Sources from "./IssuePage/Sources";
import { useUserEnum } from "utils/dataFormatters";
import { IssueStatus } from "utils/formatStatus";
import { HGRichText } from "common/HGForm/HGRichText";
import { cancelPopupMessage } from "utils/globalVariables";
import { HGDatePicker } from "common/HGForm/HGDatePicker";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { useGetIssuesInSelectedGroup } from "utils/connectors/issueConnectors";
import { GroupSelector } from "common/layout/Header/GroupSelector";
import SeverityWizard from "./SeverityWizard";
import { HGSelect, HGInputBox, HGCheckBox } from "common/HGForm/Inputs";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";
export const CreateIssueButton: React.FC<{
  onComplete?: Function;
  onOpen?: Function;
  onClose?: Function;
  defaultValues?: any;
}> = ({ onComplete, onOpen, onClose, defaultValues, ...props }) => {
  const { user, userId, group } = usePermissions();
  const useGetItemInSelectedGroup = useGetIssuesInSelectedGroup();
  const [showCreateIssue, setShowCreateIssue] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [severity, setSeverity] = useState<any>("Null");
  const [serverityResponse, setServerityResponse] = useState<any>([]);
  const [groupID, setGroupID] = useState(group.id);
  const [issues, setIssues] = useState([]);
  const users = useUserEnum(groupID).map((item) => {
    if (item.id === null) {
      return {
        id: "Null",
        title: "Not Set",
      };
    }
    return item;
  });

  const serverityData = [
    {
      QNo: "Impact",
      Question:
        "Is there reason to believe this issue could lead to significant human harm or financial loss",
    },
    {
      QNo: "Impact",
      Question:
        "Is there reason to believe this issue could significantly impact operations or affect our ability to execute our mission or achieve business objectives",
    },
    {
      QNo: "Impact",
      Question:
        "Is there reason to believe this issue can impact our accreditation or compliance with federal/state laws",
    },
    {
      QNo: "Urgency",
      Question:
        "Is there reason to believe this issue could result in significant loss/harm in the next 12 - 24 months",
    },
  ];

  const [makeIssue] = useMutation<
    CreateIssueMutation,
    CreateIssueMutationVariables
  >(gql(createIssue), {
    onCompleted: (response) => {
      setShowCreateIssue(false);
      setGroupID(group.id);
      onComplete?.(response?.CreateIssue);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
    // errorPolicy: "all",
  });

  const createIssueSchema = useMemo(() => {
    return yup.object<Partial<CreateIssueInput>>({
      title: yup
        .string()
        .default("")
        .required()
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test("check-duplication", "Issue title already exists!", (value) => {
          if (!value) return true;
          return !isUniqueTitle(issues, value);
        }),
      reportedBy: yup.string().label("Reported By"),
      groupID: yup.string().default(groupID).required().label("GroupID"),
      reportedOn: yup
        .string()
        .test(
          "is-date",
          // eslint-disable-next-line
          "${path} should be in MM/DD/YYYY format",
          (value) => {
            if (value) {
              const date = DateTime.fromFormat(value, "MM/dd/yyyy");
              return date?.isValid;
            }
            return true;
          }
        )
        .label("Reported On"),
      issueSourceId: yup.string().default("Null").label("Source"),
      description: yup.string().label("Description"),
      acceptanceCriteria: yup.string().label("Resolution Criteria"),
      assumptions: yup.string().label("Assumptions"),
      notes: yup.string().label("Notes"),
      nextReview: yup
        .string()
        .test(
          "is-date",
          // eslint-disable-next-line
          "${path} should be in MM/DD/YYYY format",
          (value) => {
            if (value) {
              const date = DateTime.fromFormat(value, "MM/dd/yyyy");
              return date?.isValid;
            }
            return true;
          }
        )
        .label("Review Date"),
      owner: yup.string().label("Owner").default("Null").required(),
      approver: yup.string().label("Approver").default(userId).required(),
      priority: yup
        .mixed()
        .oneOf(Object.values(Priority))
        .required()
        .default(Priority.Null)
        .label("Priority"),
      status: yup
        .mixed()
        .oneOf(Object.values(IssueStatus))
        .required()
        .default(IssueStatus.Created)
        .label("Status"),
      severity: yup
        .mixed()
        .oneOf(Object.values(Severity))
        .required()
        .default(severity)
        .label("Severity"),
      archived: yup.boolean().required().default(false),
      isTemplate: yup.boolean(),
    });
  }, [userId, issues, severity]);

  const Confirmation = () => {
    if (window.confirm(cancelPopupMessage)) {
      setShowCreateIssue(false);
    }
  };

  const getAppendedHGRichTextHtml = (serveritydata, severityresponse) => {
    const temp = `<ul id='severityHTML'>${serveritydata?.map((item, index) => {
      return `<li><p>${item.QNo}: ${item.Question}<strong>: ${severityresponse[index]}</strong></p></li>`;
    })}</ul>`;
    return temp.replace(/,/g, "");
  };

  useEffect(() => {
    if (showCreateIssue) {
      return onOpen?.();
    }
    return onClose?.();
  }, [showCreateIssue]);

  useEffect(() => {
    useGetItemInSelectedGroup({
      variables: { id: groupID },
    }).then((issues) => {
      setIssues(issues);
    });
  }, [groupID]);
  return (
    <>
      <Button
        {...props}
        onClick={() => {
          setShowCreateIssue(true);
        }}
      >
        Create Issue
      </Button>
      <Modal
        show={!!showCreateIssue}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          onSubmit={async (input) => {
            return makeIssue({
              variables: {
                input: {
                  ...input,
                  isTemplate,
                  verifiedBy:
                    input.status === Status.Closed ||
                    input.status === Status.Closed_Pending_Approval
                      ? userId
                      : "",
                },
              },
            });
          }}
          resolver={createIssueSchema}
        >
          <Modal.Header>
            Create Issue
            <div className="float-right">
              <GroupSelector
                groupID={groupID}
                onChange={(id) => {
                  setGroupID(id);
                }}
                isAttachment
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <HGInputBox
                  name="title"
                  label="Title"
                  defaultValue={defaultValues?.data?.title ?? ""}
                />
              </Col>
              <Col sm={12}>
                <HGCheckBox
                  id="isTemplate"
                  label="Create as Template"
                  checkGroup="isTemplate"
                  checked={isTemplate}
                  onChange={() => setIsTemplate((prevState) => !prevState)}
                />
              </Col>
              <Col sm={12}>
                <HGRichText
                  label="Description"
                  name="description"
                  defaultValue={defaultValues?.data?.responseComments ?? ""}
                />
              </Col>
              <Col sm={12}>
                <HGRichText
                  label="Resolution Criteria"
                  name="acceptanceCriteria"
                  defaultValue=""
                />
              </Col>
              <Col sm={12}>
                <HGRichText
                  label="Assumptions"
                  name="assumptions"
                  defaultValue=""
                  appendValue={
                    serverityResponse.length > 0 &&
                    !serverityResponse?.includes(null) &&
                    `${getAppendedHGRichTextHtml(
                      serverityData,
                      serverityResponse
                    )}`
                  }
                />
              </Col>
              <Col sm={12}>
                <HGRichText label="Notes" name="notes" defaultValue="" />
              </Col>
              <Col sm={6}>
                <HGInputBox
                  label="Reported By"
                  name="reportedBy"
                  defaultValue={
                    defaultValues === undefined ? "" : user?.displayName
                  }
                />
              </Col>
              <Col sm={6}>
                <HGDatePicker name="reportedOn" label="Reported On" />
              </Col>
              <Col sm={6}>
                <Sources name="issueSourceId" groupID={groupID} />
              </Col>
              <Col sm={6}>
                <HGSelect
                  label="Owner"
                  name="owner"
                  options={users}
                  defaultValue={"Null"}
                />
              </Col>

              <Col sm={6}>
                <HGSelect
                  label="Priority"
                  enumObj={Priority}
                  labelClass="issue-priority"
                  name="priority"
                />
              </Col>
              <Col sm={6}>
                <HGSelect
                  label="Approver"
                  name="approver"
                  options={users}
                  defaultValue={userId}
                />
              </Col>
              <Col sm={6}>
                <HGSelect
                  label="Status"
                  labelClass="issue-status"
                  name="status"
                  enumObj={IssueStatus}
                />
              </Col>

              <Col sm={6}>
                <HGSelect
                  label="Severity"
                  name="severity"
                  enumObj={Severity}
                  defaultValue={severity}
                  inputGroup={(element) => (
                    <InputGroup key="sourceGroup">
                      {element}
                      <InputGroup.Append>
                        <SeverityWizard
                          setSeverity={setSeverity}
                          serverityData={serverityData}
                          setServerityResponse={setServerityResponse}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  )}
                />
              </Col>
              <Col sm={12}>
                <HGDatePicker name="nextReview" label="Review Date" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                Confirmation();
                setGroupID(group.id);
              }}
              variant="light"
              className="cancel-btn"
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
