import React from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { HGInputProps } from "./HGInput.utils";
//This component is used to create Dropdown and input fields
export const HGFormComponent: React.FC<HGInputProps & FormControlProps> = (
  props
) => {
  const { register, setValue } = useFormContext();

  const handleChange =
    props.onChange ||
    ((event) => {
      setValue(props.name, event.target.value);
    });
  return (
    <InputGroup>
    <Form.Control onChange={handleChange} ref={register} {...props}>
      {props.children}
    </Form.Control>
    {props?.inputGroup && <InputGroup.Append>{props?.inputGroup()}</InputGroup.Append>}
    </InputGroup>
  );
};
