import _ from "lodash";

interface dataFormat {
  nameField?: Function;
  valueField?: Function;
}
(Array.prototype as any).formatData = function (
  dataFormat: dataFormat = {}
): Object[] {
  const nameField: Function = dataFormat.nameField
    ? dataFormat.nameField
    : (d) => d.name;
  const valueField: Function = dataFormat.valueField
    ? dataFormat.valueField
    : (d) => d.value;

  return this.map((d) => ({ name: nameField(d), value: valueField(d) }));
};

(Array.prototype as any).groupData = function (
  groupBy: Function,
  childProcess?: Function
): Object[] {
  let group = _.uniq(this.map((d) => groupBy(d)));
  return group.map((g) => {
    let objects = this.filter((d) => groupBy(d) === g);
    return {
      name: g,
      objects: childProcess ? childProcess(objects) : objects,
    };
  });
};

interface GroupOptions {
  clearEmpty?: boolean;
  childProcess?: Function;
}
(Array.prototype as any).groupDataByArray = function (
  groupBy: Function,
  groupArray: string[],
  groupOptions: GroupOptions = {}
): Object[] {
  return groupArray
    .map((g) => {
      let objects = this.filter((d) => groupBy(d) === g);
      return {
        name: g,
        objects: groupOptions.childProcess
          ? groupOptions.childProcess(objects)
          : objects,
      };
    })
    .filter((d: any) => {
      if (groupOptions.clearEmpty) {
      }
      return (
        !groupOptions.clearEmpty ||
        d.value === 0 ||
        d.objects.filter((x) => x.value > 0).length > 0
      );
    });
};
