import { gql, useMutation } from "@apollo/client";
import {
  CreateUrlMutation,
  CreateUrlMutationVariables,
  LinkItemsMutation,
  LinkItemsMutationVariables,
} from "API";
import { HGForm, HGFormControl, HGFormSubmit } from "common/HGForm";
import { usePermissions } from "common/Permissions/Permissions";
import { createUrl, linkItems } from "graphql/mutations";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { sleep } from "utils/useSleep";

import * as yup from "yup";
import { cancelPopupMessage } from "utils/globalVariables";
import { GroupSelector } from "common/layout/Header/GroupSelector";

const CreateUrlButton: React.FC<{ id?: string; refetch?: Function; refetchGroupData?: Function } | any> = ({
  id,
  refetch,
  refetchGroupData,
  ...props
}) => {
  const { group } = usePermissions();
  const [groupID, setGroupID] = useState(group.id);
  const [showModal, setShowModal] = useState(false);
  const [triggerAddURLToItem] = useMutation<
    LinkItemsMutation,
    LinkItemsMutationVariables
  >(gql(linkItems));

  const [triggerCreateURL] = useMutation<
    CreateUrlMutation,
    CreateUrlMutationVariables
  >(gql(createUrl), {
    onCompleted: (_response) => {
      const response = _response.CreateUrl;
      if (id && response?.[0]?.id) {
        triggerAddURLToItem({
          variables: {
            input: [{
              id,
              id2: response?.[0]?.id,
            }],
          },
        }).then(() => {
          sleep(500).then(refetch);
          sleep(500).then(refetchGroupData);
          setShowModal(false);
        });
      } else {
        setShowModal(false);
      }
      setGroupID(group.id)
    },
  });

  const schema = yup.object({
    address: yup
      .string()
      .matches(
        /^.*:\/\//,
        "Must include protocol prefix (http://, https://, etc...)"
      )
      .url()
      .required()
      .label("URL"),
    title: yup
      .string()
      .required()
      .label("Title")
      .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces"),
  });

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowModal(false);
    }
  }
  return (
    <>
      <Button
        {...props}
        onClick={() => {
          setShowModal(true);
        }}
      >
        Create URL
      </Button>
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => {
            Confirmation();
          }}
        >
          <HGForm
            resolver={schema}
            onSubmit={({ address, title }) => {
              return triggerCreateURL({
                variables: {
                  input: [
                    {
                      groupID,
                      address,
                      title,
                    },
                  ],
                },
              });
            }}
          >
            <Modal.Header>
              Create URL
              <div className="float-right">
              <GroupSelector
                groupID={groupID}
                onChange={(id) => {
                  setGroupID(id);
                }}
                isAttachment
              />
              </div>
            </Modal.Header>
            <Modal.Body>
              <HGFormControl name="address" />
              <HGFormControl name="title" />
            </Modal.Body>
            <Modal.Footer>
              <HGFormSubmit />
              <Button
                className="cancel-btn"
                variant="light"
                onClick={() => {
                  Confirmation();
                  setGroupID(group.id)
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </HGForm>
        </Modal>
      )}
    </>
  );
};

export default CreateUrlButton;
