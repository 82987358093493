import { gql, useQuery } from "@apollo/client";
import {
  GetControlsSurveysQuery,
} from "API";
import { getControlsSurveys } from "graphql/queries";
import { sortToID } from "./connectorUtils";

export const useGetControlsSurvey = () => {
  const response = useQuery<
  GetControlsSurveysQuery
  >(
    gql(getControlsSurveys),
    {},
  );
  const controls = (response?.data?.GetControlsSurveys?.items || []).map(
    sortToID("CONTROLS#"),
  );
  return { ...response, controls };
};


export const useGetControl = (id) => {
  const { controls }  = useGetControlsSurvey();
  const nist_800_53 = [] as any;
  const nist_csf = [] as any;
  
  const  getControlsChildren = (obj : any, itemArray: any) => {
    obj?.forEach((subObj: any) => {
      if (subObj?.controlsList?.items?.length > 0) {
        getControlsChildren(subObj.controlsList?.items,itemArray);
      }
      else{
        itemArray.push(subObj)
      }  
    })
  }
  controls.forEach((item) => {
    if(item.uuid === "control-base-NIST-800-53"){
      return getControlsChildren(item?.controlsList?.items,nist_800_53)
    }
    return getControlsChildren(item?.controlsList?.items,nist_csf)
  })

  return nist_csf.find((item)=> item.uuid === id) || nist_800_53.find((item)=> item.uuid === id) ;
}