import { gql, useMutation, useQuery } from "@apollo/client";
import {
  GetLinksByItemQuery,
  GetLinksByItemQueryVariables,
  LinkItemsInput,
  UnlinkItemsInput,
} from "API";
import { getLinksByItem } from "graphql/queries";
import React from "react";
import { useParams } from "react-router";
import { linkItems, unlinkItems } from "graphql/mutations";
import { useGetArtifactsInGroup } from "utils/connectors/artifactsConnectors";
import { useAsync } from "react-use";
import { relatedItemTypes } from "./relatedItemTypes";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./RelatedArtifacts.scss";
import { useLocation } from "react-router-dom";

const alphabeticalSort = (a = { title: "" }, b = { title: "" }) =>
  a.title?.toLowerCase().localeCompare(b.title?.toLowerCase());

function sleep(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, milliseconds);
  });
}

export const RelatedArtifacts: React.FC<{
  onChange?: Function;
  isExpanded?: boolean;
  setIsExpanded?: Function;
  data?: any;
  refetchItem?: any;
}> = ({
  isExpanded, setIsExpanded, refetchItem, ...props 
}) => {
  const { id } = useParams<{ id: string }>();
  const handleToggler = () => {
    if (isExpanded) {
      setIsExpanded?.(false);
      localStorage.setItem("rightsidebar-collapsed", "true");
    } else {
      setIsExpanded?.(true);
      localStorage.removeItem("rightsidebar-collapsed");
    }
  };
  const { data, refetch } = useQuery<
  GetLinksByItemQuery,
  GetLinksByItemQueryVariables
  >(gql(getLinksByItem), {
    variables: { id },
    onCompleted: (data) => {
      props?.onChange?.();
    },
  });

  const [_attach, { loading: attachLoading, called: attachCalled }] =    useMutation<LinkItemsInput>(gql(linkItems));
  const attach = (id2: string) => {
    return _attach({
      variables: {
        input: {
          id,
          id2,
        },
      },
    }).then(async()=>{
      await sleep(1000);
      await refetchArtifacts();
      await refetch();
    });
  };
  const [_detach, { loading: detachLoading, called: detachCalled }] =    useMutation<UnlinkItemsInput>(gql(unlinkItems));
  const detach = (id2: string) => {
    _detach({
      variables: {
        input: {
          id,
          id2,
        },
      },
    });
  };

  const { artifacts: files, refetch: refetchArtifacts } =    useGetArtifactsInGroup();

  useAsync(async () => {
    if (attachCalled && !attachLoading) {
      await sleep(1000);
      await refetchArtifacts();
      await refetch();
      await refetchItem();

    }
  }, [attachLoading, attachCalled]);

  useAsync(async () => {
    if (detachCalled && !detachLoading) {
      await sleep(1000);
      await refetchArtifacts();

      await refetch();
    }
  }, [detachLoading, detachCalled]);

  const attachments = data?.getLinksByItem?.items ?? [];

  const types = relatedItemTypes(attach);
  let removeIndex;
  const location = useLocation();

  const _typeList = location.pathname.startsWith(
    "/assessments/assetManagement/asset",
  )
    ? {}
    : (removeIndex = types.map((item) => item?.name).indexOf("Controls"));
  removeIndex && types.splice(removeIndex, 1);

  return (
    <div className={isExpanded ? "rightside" : "rightsideshow"}>
      <div className="rightsidecard">
        <div className="spin-icon">
          <FontAwesomeIcon icon={faPaperclip} onClick={handleToggler} />
        </div>

        {types.map((type) => (
          <type.RelatedItemType
            {...{
              id,
              attachments,
              type,
              attach,
              detach,
              props,
              refetch,
            }}
          />
        ))}
      </div>
    </div>
  );
};
