import React from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

export const HGTooltip = ({ children, tooltip }) => {
  return (
    (tooltip && (
      <Tooltip
        overlayInnerStyle={{ whiteSpace: "pre" }}
        placement="top"
        trigger={["hover"]}
        overlay={<span>{tooltip}</span>}
      >
        {children}
      </Tooltip>
    )) ||
    children
  );
};
