import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { useTabbedTable } from "common/TabbedTable";
import React from "react";
import { Card, Row } from "react-bootstrap";
import { getCompositeId } from "utils/dataFormatters";
import { useDashboardSchema, createDateEntry, schemaStatus } from ".";
import { requiresApprovalReportSchema } from "./requiresApprovalReportSchema";
import GenerateReport from "utils/docx-export/GenerateReport";
const requiresApprovalSchema = () => {
  const dashboardSchema = useDashboardSchema();

  return [
    ...dashboardSchema,
    createDateEntry("nextReview", "Review Date"),
    schemaStatus,
  ];
};

const useGetDownloadRequiresApprovalObj = (item) => {
  const requiresApprovalArray = ['title','ID','reviewDate','__typename'];
  const selectArray = (arr, obj) => arr.reduce((r, e) => Object.assign(r, obj[e] ? { [e]: obj[e] } : null), {})
  item = {
    ...item,
    type: item.__typename,
    ID: getCompositeId(item),
    title: item.title,
    reviewDate: item?.nextReview || " ",
  }
  return selectArray(requiresApprovalArray,item)
}

export const RequiresApproval = ({ items }) => {
  const pastDueSchema = requiresApprovalSchema();
  const { TableUI } = useTabbedTable(
    "requiresApprovalSortOrder",
    items.filter((item) => item?.status === "Closed_Pending_Approval"),
    pastDueSchema as any,
    null,
    {
      disableColumnEdit: true,
      disableTabs: true,
      sortBy: "Review Date",
      sortOrder: "asc",
      tabFallback: {
        icon: faThumbsUp,
        header: "No items require approval",
      },
    }
  );
  const requiresApprovalItems =  items.filter((item) => item?.status === "Closed_Pending_Approval").map((item)=>{
    return useGetDownloadRequiresApprovalObj(item)
  }).reduce((acc, obj) => {
    const key = obj.__typename;
    if (!acc[key]) {
      acc[key] = [];
    }
    delete obj.__typename
    acc[key].push(obj);
    return acc;
  }, {}); 

  return (
    <Card>
      <Card.Header>
        <Row>
          <div className="col-sm-6 text-left">
        <span className="h5">
          {location.pathname === "/dashboard/department"
            ? "Department Items Needing Approval"
            : "My Items Needing Approval"}
        </span>
          </div>
        <div className="col-sm-6 text-right">
          <GenerateReport
                type="Single"
                item={requiresApprovalItems}
                schema={requiresApprovalReportSchema()}
              />
        </div>
        </Row>
      </Card.Header>
      <Card.Body style={{ minHeight: "526px", overflowY: "auto" }}>
        {TableUI}
      </Card.Body>
    </Card>
  );
};
