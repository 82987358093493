import React, { useEffect, useState } from "react";
import { usePermissions } from "common/Permissions";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useEditContext } from "./HGFormContext";
import { MultiSelect } from "react-multi-select-component";
import { HGHidden } from "./Inputs";

type OptionType = {
  label: string;
  value: string;
};
export const HGMultiSelect: React.FC<{
  label?: string;
  name: string;
  enumObj?: Object;
  defaultValue?: any;
}> = ({ name, label = "", enumObj, defaultValue }) => {
  const { organizationRole, groupRole } = usePermissions();
  const [selectedOption, setSelectedOption] = useState<any[]>([]);
  const methods = useFormContext();
  const { getValues, setValue } = methods;
  const { editing } = useEditContext();
  const changeOption = (value) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    if (defaultValue || !editing) {
      setValue(name, defaultValue);
    }
    const values = getValues();

    const defaultOptions: OptionType[] = values[name]?.split(",");
    const defaultSelectedOptions: Array<{
      label: OptionType;
      value: OptionType;
    }> = [];

    for (let i = 0; i < defaultOptions?.length; i++) {
      if (values[name]) {
        defaultSelectedOptions.push({
          label: defaultOptions[i],
          value: defaultOptions[i],
        });
      }
    }
    setSelectedOption(defaultSelectedOptions);
  }, [defaultValue]);

  useEffect(() => {
    if (selectedOption) {
      const value1: string[] = [];
      for (let i = 0; i < selectedOption.length; i++) {
        value1.push(selectedOption[i].value);
      }
      setValue(name, value1);
    }
  }, [selectedOption]);

  const enumOptions: string[] | undefined =
    enumObj &&
    Object.values(enumObj).filter(
      (val) =>
        val !== "Closed" ||
        organizationRole === "admin" ||
        groupRole === "admin"
    );
  const newenumOptions: any[] = [];
  enumOptions?.forEach(function (element) {
    newenumOptions.push({ label: element, value: element });
  });

  return (
    <Form.Group>
      {label && <Form.Label style={{ fontWeight: "bold" }}>{label}</Form.Label>}
      <MultiSelect
        options={newenumOptions}
        value={selectedOption as any}
        labelledBy="Select"
        hasSelectAll={false}
        onChange={changeOption}
      />
      <HGHidden name={name} />
    </Form.Group>
  );
};
