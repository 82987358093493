import { gql } from "@apollo/client";
import {
  GetRiskComparisonsInGroupQueryVariables,
  GetRiskComparisonsInGroupQuery,
  GetRiskComparisonQuery,
  GetRiskComparisonQueryVariables,
  GetRiskPortfoliosInGroupQuery,
  GetRiskPortfoliosInGroupQueryVariables,
  GetRiskPortfolioQuery,
  GetRiskPortfolioQueryVariables,
  GetRiskPortfolioScenariosQuery,
  GetRiskPortfolioScenariosQueryVariables,
} from "API";
import { usePermissions } from "common/Permissions";
import {
  getRiskComparison,
  getRiskComparisonsInGroup,
  getRiskPortfolio,
  getRiskPortfolioScenarios,
  getRiskPortfoliosInGroup,
} from "graphql/queries";
import { useGroupCheck } from "utils/useGroupCheck";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";

export const useGetScenarioComparisonsInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetRiskComparisonsInGroupQuery,
    GetRiskComparisonsInGroupQueryVariables
  >(
    gql(getRiskComparisonsInGroup),
    {
      variables: {
        groupID: group.id,
      },
    },
    hasGroup
  );

  const comparisons = (
    response.data?.getRiskComparisonsInGroup?.items.map((item) => item) || []
  )
    .map(sortToID("RISKCOMPARISON#"))
    .map(({ ...comparison }) => ({
      ...comparison,
      relationType: "comparison",
    }));

  return { ...response, comparisons };
};

export const useGetScenarioComparison = (id) => {
  const { hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetRiskComparisonQuery,
    GetRiskComparisonQueryVariables
  >(
    gql(getRiskComparison),
    {
      variables: {
        id: id,
      },
    },
    hasGroup
  );

  const comparison = sortToID("RISKCOMPARISON#")(
    response?.data?.getRiskComparison
  );

  const canAccess = useGroupCheck(comparison?.groupID, "/risk/comparison");

  return { ...response, comparison: canAccess && comparison };
};

export const useGetScenarioPortfoliosInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetRiskPortfoliosInGroupQuery,
    GetRiskPortfoliosInGroupQueryVariables
  >(
    gql(getRiskPortfoliosInGroup),
    {
      variables: {
        groupID: group.id,
      },
    },
    hasGroup
  );

  const portfolios: Array<
    GetRiskPortfoliosInGroupQuery["getRiskPortfoliosInGroup"]
  > = (response.data?.getRiskPortfoliosInGroup?.items.map((item) => item) || [])
    .map(sortToID("RISKPORTFOLIO#"))
    .map(({ ...comparison }) => ({
      ...comparison,
      relationType: "portfolio",
    }));

  return { ...response, portfolios };
};

export const useGetScenarioPortfolio = (id) => {
  const { hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetRiskPortfolioQuery,
    GetRiskPortfolioQueryVariables
  >(
    gql(getRiskPortfolio),
    {
      variables: {
        id: id,
      },
    },
    hasGroup
  );

  const portfolio = sortToID("RISKPORTFOLIO#")(
    response?.data?.getRiskPortfolio
  );

  const canAccess = useGroupCheck(portfolio?.groupID, "/risk/portfolio");

  return { ...response, portfolio: canAccess && portfolio };
};

export const useGetRiskPortfolioScenarios = (id) => {
  const { hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetRiskPortfolioScenariosQuery,
    GetRiskPortfolioScenariosQueryVariables
  >(
    gql(getRiskPortfolioScenarios),
    {
      variables: {
        id: id,
      },
    },
    hasGroup
  );

  const scenarios = response?.data?.getRiskPortfolioScenarios?.items.map(
    sortToID("SCENARIO#")
  );

  return { ...response, scenarios };
};
