import React, { useCallback } from "react";
import {
  Col, Card, Row, 
} from "react-bootstrap";
import { CreateRiskDomainButton } from "./CreateRiskDomain";
import { ErrorBoundary } from "App";
import { useTabbedTable } from "common/TabbedTable";
import {
  useGetDownloadRiskDomainObj,
  useGetRiskDomainInGroup,
} from "utils/connectors/riskDomainConnectors";
import { gql, useMutation } from "@apollo/client";
import { modifyRiskDomain } from "graphql/mutations";
import {
  ModifyRiskDomainMutation,
  ModifyRiskDomainMutationVariables,
} from "API";
import { useriskDomainSchema } from "./riskDomainSchema";
import useSetTitle from "utils/useSetTitle";
import { sleep } from "utils/useSleep";
import Loader from "common/Loader";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { usePermissions } from "common/Permissions";
import { riskDomainReportSchema } from "./riskDomainReportSchema";

export const RiskDomain = () => {
  useSetTitle("RiskDomain");

  const riskDomain = useGetRiskDomainInGroup();
  const { refetch, loading } = useGetRiskDomainInGroup();
  const { groupRole } = usePermissions();
  const [triggerUpdateRiskDomain] = useMutation<
  ModifyRiskDomainMutation,
  ModifyRiskDomainMutationVariables
  >(gql(modifyRiskDomain), {
    onCompleted: (response) => {
      const isArchived = response?.ModifyRiskDomain?.[0]?.archived;
      if (isArchived === true) {
        ToastifyQueue(`Risk Domain Archived Successfully`, "success");
      } else if (isArchived === false) {
        ToastifyQueue(`Risk Domain Unarchived Successfully`, "success");
      }
      sleep(500).then(refetch);
    },
  });

  const archiveRiskDomain = useCallback(
    (id: string, archived = true) => {
      triggerUpdateRiskDomain({
        variables: {
          input: [
            {
              id,
              archived,
            },
          ],
        },
      });
    },
    [triggerUpdateRiskDomain],
  );

  const tableSchema = useriskDomainSchema({ archiveRiskDomain, userRole: groupRole });

  const { TableUI, ColumnSortFilterUI } = useTabbedTable(
    "riskDomainColumnSortOrder",
    riskDomain?.riskDomains,
    tableSchema,
    riskDomainReportSchema,
    {
      tabChoices: ["Active", "Archived"],
    },
  );

  return (
    (loading && !(riskDomain?.riskDomains?.length > 0) && <Loader />) || (
      <>
          {
            groupRole !== 'read'
            && <Row className="dashboard-header">
              <Col xs="12" className="pr-0">
              <span className="float-right">
                  <CreateRiskDomainButton
                    onComplete={() => {
                      refetch();
                      ToastifyQueue(`Risk Domain Created Successfully.`, "success");
                    }}
                  />
              </span>
              </Col>
               </Row>
          }
        <Row>
          <Col>
            <Card>
              <Card.Header className="auditgrid">
                {ColumnSortFilterUI}
                <span className="h5">All Risk Domains</span>
              </Card.Header>
              <ErrorBoundary>
                <Card.Body>{TableUI}</Card.Body>
              </ErrorBoundary>
            </Card>
          </Col>
        </Row>
      </>
    )
  );
};
