import React from "react";
import { useGetOwnedNotifications } from "utils/connectors/notificationConnectors";
import { useGetCurrentUser } from "utils/connectors/userConnectors";
import { usePermissions } from "common/Permissions";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { formatStatus } from "utils/formatStatus";

const moduleMap = {
  Issue: (item) => `/issues/${item.id}`,
  "Action Plan": (item) => `/actionplans/${item.id}`,
  Task: (item) => `/actionplans/${item.parentID}/tasks/${item.id}`,
  Scenario: (item) => `/risk/${item.id}`,
};

const NotificationItem = ({ notification }) => {
  const timeStamp = DateTime.fromISO(notification.createdAt).toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS,
  );
  const notificationText = {
    "Assigned Ownership":
      ({ item, ...notificationDetails }) =>
        () =>
          (
          <Link
            to={moduleMap.Issue(item)}
            key={notificationDetails.id}
            style={{ padding: "0px 0px" }}
          >
            <div className="notifcard" style={{ margin: "0px 0px" }}>
              <p
                className={
                  notificationDetails.isRead === false ? "font-weight-bold" : ""
                }
              >
                <div className="notif-title">{item.title}</div>

                <div className="notif-status"> was assigned to you </div>
              </p>
              <div className="text-muted small float-right">{timeStamp}</div>
            </div>
          </Link>
          ),
    "Pending Approval":
      ({ item, ...notificationDetails }) =>
        () =>
          (
          <Link
            to={moduleMap.Issue(item)}
            key={notificationDetails.id}
            style={{ padding: "0px 0px" }}
          >
            <div className="notifcard" style={{ margin: "0px 0px" }}>
              <p
                className={
                  notificationDetails.isRead === false ? "font-weight-bold" : ""
                }
              >
                <div className="notif-title">{item.title}</div>
                was set to
                <div className="notif-status">
                  &nbsp;
                  {formatStatus(item.status)}
                </div>
              </p>
              <div className="text-muted small float-right">{timeStamp}</div>
            </div>
          </Link>
          ),
  };

  const Notification =    notificationText[notification.notificationType]?.(notification);
  return Notification ? <Notification /> : null;
};

export const Notifications = () => {
  const { notifications } = useGetOwnedNotifications(10);
  const permissions = usePermissions();
  const { user } = useGetCurrentUser(permissions?.userId);

  const _notificationPreference = permissions.userMeta?.notificationPreferences;
  const notificationPreference =    (_notificationPreference && parseInt(_notificationPreference)) || 30;
  return (
    <div className="notifications">
      <div className="notif-dropdown">
        {notifications
          .sort((a: any, b: any) => {
            const bDate = DateTime.fromISO(b.createdAt);
            const aDate = DateTime.fromISO(a.createdAt);
            return ((bDate as any) - (aDate as any)) as any;
          })
          .slice(0, notificationPreference)
          .map((notification) => (
            <NotificationItem notification={notification} />
          ))}
      </div>
      <hr />
      <Link to="/AllNotifications">See all...</Link>
    </div>
  );
};
