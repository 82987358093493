import React from "react";
import { Badge } from "react-bootstrap";
import { formatSeverity } from "utils/formatStatus";

export const SeverityBadge: React.FC<{
  severity?: string | null;
}> = ({ severity = "Not Set" }) => {
  const formattedSeverity = formatSeverity(severity);
  return (
    <Badge pill className={severity || ""} title={formattedSeverity}>
      {formattedSeverity}
    </Badge>
  );
};
