import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { usePermissions } from "common/Permissions";

export const GroupSelector = ({ onChange, groupID = null, isAttachment }) => {
  const { groups } = usePermissions();
  const currentGroup = groups.find((_group) => _group.id == groupID);
  const activeGroups = localStorage.getItem('activeGroups')
  
  useEffect(() => {
    if (activeGroups === '{}' ) {
      onChange(currentGroup?.id)
    }
  }, [currentGroup])
  
  return (
    <div>
      <Dropdown className="group-element" id="group-dropdown">
        <Dropdown.Toggle id="group-dropdown">
          <div style={{ float: "right" }}>
            <b className="caret" />
          </div>
          {currentGroup?.title}
        </Dropdown.Toggle>
        <Dropdown.Menu align="right" style={{ zIndex: 100000 }}>
          {(isAttachment === true ?  groups.filter((item) => item.userRole !== 'read') : groups)?.map((gr) => (
            <Dropdown.Item
              active={groupID === gr.id}
              onClick={() => onChange(gr.id)}
              style={{
                minHeight: "auto",
                padding: "0.25rem 1.5rem",
              }}
            >
              {gr.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
