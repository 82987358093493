import { gql } from "@apollo/client";
import {
  GetLocationQuery,
  GetLocationQueryVariables,
  GetLocationsInGroupQueryVariables,
  GetLocationsInGroupQuery,
} from "API";
import { usePermissions } from "common/Permissions";
import { getLocation, getLocationsInGroup } from "graphql/queries";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";

export const useGetLocationsInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetLocationsInGroupQuery,
    GetLocationsInGroupQueryVariables
  >(
    gql(getLocationsInGroup),
    {
      variables: {
        groupID: group.id,
      },
    },
    hasGroup
  );

  const locations = (response.data?.getLocationsInGroup.items || []).map(
    sortToID("LOCATION#")
  );

  const _locationsData = [
    { id: null, title: "" },
    ...locations.map((item) => {
      if (item.archived) {
        return {
          id: item.id,
          title: `${item?.title} (Archived)`,
        };
      }

      return {
        id: item.id,
        title: item?.title,
      };
    }),
  ].sort((a, b): any =>
    a.title.toLowerCase() > b.title.toLowerCase()
      ? 1
      : b.title.toLowerCase() > a.title.toLowerCase()
      ? -1
      : 0
  );

  return { ...response, locations, _locationsData };
};

export const useGetLocation = (id) => {
  const { hasGroup } = usePermissions();
  const response = useProtectedQuery<
    GetLocationQuery,
    GetLocationQueryVariables
  >(
    gql(getLocation),
    {
      variables: {
        id,
      },
    },
    hasGroup
  );

  return {
    ...response?.data?.getLocation,
    locationTitle: response?.data?.getLocation?.title,
  };
};
