import React from "react";
import "./App.scss";

import "./theme/Inspinia/style.scss";

import DataProvider from "utils/DataProvider";
import LeftNavBar from "common/layout/LeftNavBar";
import AppRoutes from "utils/AppRoutes";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import * as Sentry from "@sentry/react";
import AuthState from "utils/AuthState";

import { Permissions } from "common/Permissions";

import "react-datepicker/dist/react-datepicker.css";
import { Integrations } from "@sentry/tracing";
import Header from "common/layout/Header/Header";
import {
  CollapsibleContextProvider,
  useCollapsibleContext,
} from "common/layout/LeftNavBar/CollapsibleContext";
import Toastv2 from "common/Overlays/Toastv2";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { ProductFruitsConfig } from "ProductFruits";
import { DateTime } from "luxon"
import LeftNavBarV2 from "common/layout/LeftNavBar/LeftNavBarV2";

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT_NAME || process.env.NODE_ENV,
  dsn: "https://cf54ab9578494461a316a4bb915c72cb@o412537.ingest.sentry.io/5289440",
  ignoreErrors: ["Non-Error promise rejection captured with value"],
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1,
  beforeBreadcrumb: (crumb) => {
    return crumb?.category !== "console" ? crumb : null;
  },
  // beforeSend(event, hint) {
  //   if (event.exception) {
  //     Sentry.showReportDialog({
  //       eventId: event.event_id,
  //       name: event.tags.userName != "NA" ? event.tags.userName : "",
  //       email: event.tags.email != "NA" ? event.tags.email : "",
  //     });
  //   }
  //   return event;
  // },
});

export const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      onError={(error) => {
        ToastifyQueue(error.message, "warning");
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

const CollapsibleBody = ({ children }) => {
  const { collapsed } = useCollapsibleContext();
  return (
    <body
      className={
        !collapsed
          ? "pace-done fixed-sidebar"
          : "pace-done mini-navbar fixed-sidebar"
      }
    >
      {children}
    </body>
  );
};

function App() {
  const dt = DateTime.now();
  return (
    <CollapsibleContextProvider>
      <Toastv2 />
      <CollapsibleBody>
        <Router>
          <Switch>
            <ErrorBoundary>
              <AuthState>
                <DataProvider>
                  <Permissions>
                    <ProductFruitsConfig />
                    <Router>
                      <div id="wrapper">
                        {/* <LeftNavBar /> */}
                        <LeftNavBarV2/>
                        <div id="page-wrapper" className="gray-bg">
                          <Header />
                          <ErrorBoundary>
                            <div
                              className="col-md-12 px-0 mt-6"
                              id="content-wrapper"
                            >
                              <AppRoutes />
                            </div>
                          </ErrorBoundary>
                          <div style={{ height: "50px" }} />
                          <div className="footer fixed">
                            Copyright 
{' '}
{dt.year}
{' '}
HealthGuard Security /
<a
                              href="https://healthguardsecurity.com/tos/"
                              target="_blank"
                              rel="noreferrer"
>
                              Terms of Service
</a>
                          </div>
                        </div>
                      </div>
                    </Router>
                  </Permissions>
                </DataProvider>
              </AuthState>
            </ErrorBoundary>
          </Switch>
        </Router>
      </CollapsibleBody>
    </CollapsibleContextProvider>
  );
}

export default App;
