import { GetUserGroupMembershipQuery, UserRoles } from "API";

export type GetUsersInOrganizationQueryLimited = {
  getUsersInOrganization?: {
    __typename: "PaginatedUsersInOrganization";
    items: Array<{
      __typename: "UsersInOrganization";
      id?: string | null;
      sort?: string | null;
      userID?: string | null;
      organizationID?: string | null;
      user?: {
        __typename: "User";
        res?: string | null;
        error?: string | null;
        id?: string | null;
        sort?: string | null;
        email?: string | null;
        username?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        inactive?: boolean | null;
        lastSeen?: string | null;
        lastLoggedIn?: string | null;
      } | null;
      userRole?: UserRoles | null;
    }>;
    nextToken?: string | null;
  } | null;
};

export const getUsersInOrganizationLimited = /* GraphQL */ `
  query GetUsersInOrganization($nextToken: String, $limit: Int, $id: ID!) {
    getUsersInOrganization(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        userID
        organizationID
        user {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        userRole
      }
      nextToken
    }
  }
`;

export type GetUsersInGroupQueryLimited = {
  getUsersInGroup?: {
    __typename: "PaginatedUsersInGroup";
    items: Array<{
      __typename: "UsersInGroup";
      id?: string | null;
      sort?: string | null;
      userID?: string | null;
      groupID?: string | null;
      user?: {
        __typename: "User";
        res?: string | null;
        error?: string | null;
        id?: string | null;
        sort?: string | null;
        email?: string | null;
        username?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        inactive?: boolean | null;
        lastSeen?: string | null;
        lastLoggedIn?: string | null;
      } | null;
      userRole?: UserRoles | null;
    }>;
    nextToken?: string | null;
  } | null;
};

export const getUsersInGroupLimited = /* GraphQL */ `
  query GetUsersInGroup($nextToken: String, $limit: Int, $id: ID!) {
    getUsersInGroup(nextToken: $nextToken, limit: $limit, id: $id) {
      items {
        id
        sort
        userID
        groupID
        user {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        userRole
      }
      nextToken
    }
  }
`;
