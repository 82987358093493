import { usePermissions } from "common/Permissions";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import { useAuditPlanContext } from "./AuditPlanningPage";
import { UpdateAuditPlanningInput, ViewType } from "API";
import * as yup from "yup";
import { AuditStatus, formatStatus } from "utils/formatStatus";
import { HGForm, HGToggleSubmit } from "common/HGForm";
import { Card, Col, Row } from "react-bootstrap";
import { HGSelect } from "common/HGForm/Inputs";
import { useUserEnum } from "utils/dataFormatters";
import { useGetRiskDomainInGroup } from "utils/connectors/riskDomainConnectors";
const AuditPlanningPageAttributes = () => {
  const { id } = useParams<{ id: string }>();
  const { group, getOrganizationUser } = usePermissions();
  const users = useUserEnum(group.id);
  const { auditPlan, updateAuditPlan, viewType } = useAuditPlanContext();
  const _riskDomain = useGetRiskDomainInGroup();
  const riskDomains = [{ id: null, title: "" }];
  _riskDomain.riskDomains.forEach(function (element) {
    return element.archived === true
      ? riskDomains.push({
          id: element?.id,
          title: `${element?.title} (Archived)`,
        })
      : riskDomains.push({ id: element?.id, title: element?.title });
  });
  const auditPlanAttributesSchema = useMemo(
    () =>
      auditPlan &&
      yup.object<Partial<UpdateAuditPlanningInput>>({
        owner: yup
          .string()
          .default(auditPlan?.owner ?? undefined)
          .required()
          .label("Owner"),
        riskDomain: yup
          .string()
          .default(auditPlan?.riskDomain ?? undefined)
          .label("Risk Domain"),
        status: yup
          .mixed()
          .oneOf(Object.values(AuditStatus))
          .required()
          .default(
            auditPlan?.status === "Complete"
              ? AuditStatus.Closed
              : auditPlan?.status ?? AuditStatus.Planning
          )
          .label("Status"),
      }),
    [auditPlan]
  );
  return (
    <HGForm
      key="form_editAuditPlanningAttributes"
      onSubmit={async (input) => {
        return await updateAuditPlan({
          variables: {
            input: { id, groupID: group.id, ...input },
          },
        });
      }}
      resolver={auditPlanAttributesSchema}
      editToggle
    >
      <Card>
        <Card.Header>
          <HGToggleSubmit className="float-right" />
          Attributes
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <HGSelect
                name="owner"
                label="Owner"
                options={users}
                defaultValue={auditPlan?.owner}
                overrideFormat={
                  getOrganizationUser(auditPlan?.owner)?.displayName || ""
                }
              />
            </Col>
            <Col sm={6}>
              <HGSelect
                label="Status"
                name="status"
                defaultValue={auditPlan?.status}
                enumObj={AuditStatus}
                overrideFormat={formatStatus(auditPlan?.status)}
              />
            </Col>
            {viewType !== ViewType.Standard && (
              <Col sm={6}>
                <HGSelect
                  name="riskDomain"
                  label="Risk Domain"
                  options={riskDomains || []}
                  defaultValue={auditPlan?.riskDomain}
                  overrideFormat={auditPlan?.riskDomainDetails?.title || ""}
                />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </HGForm>
  );
};

export default AuditPlanningPageAttributes;
