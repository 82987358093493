import React, { useState, useMemo } from "react";

import { BoxPlot, ExceedenceGraph } from "common/fair-graphs";
import { Row, Card, Button } from "react-bootstrap";

import { zipSum } from "utils/stats";
import { useQuery, gql } from "@apollo/client";
import { GetAnalysisQuery, GetAnalysisQueryVariables } from "API";
import { getAnalysis } from "graphql/queries";
import { usePermissions } from "common/Permissions";
import { ScenarioFairSummaryTable } from "./ScenarioFairSummaryTable";
import { Link, useParams } from "react-router-dom";

export const useGetFAIRSummaryData = (fairId) => {
  const { group } = usePermissions();
  const { data: getAnalysisDetailsData } = useQuery<
    GetAnalysisQuery,
    GetAnalysisQueryVariables | any
  >(gql(getAnalysis), {
    variables: {
      id: fairId,
      groupID: group.id,
    },
  });
  const details = getAnalysisDetailsData?.getAnalysis?.lastRun;
  const runResults = JSON.parse(details?.results || "{}");
  const magnitudes = Object.entries<{ expectedLoss?: number[] }>(
    runResults ?? {}
  ).map(([title, result]) => {
    return {
      title,
      data: result?.expectedLoss || [],
    };
  });

  const frequency2 = useMemo(() => {
    return (
      (details?.frequencyResults &&
        JSON.parse(details?.frequencyResults).sips) ||
      null
    );
  }, [runResults]);

  const frequency = !!frequency2 && [{ title: "Frequency", data: frequency2 }];

  return { magnitudes, frequency };
};

export const getFairData = (scenario) => {
  if (scenario && scenario.FAIRSummary !== null) {
    const { lastRun } = JSON.parse(scenario.FAIRSummary);
    const runResults = lastRun?.results || {};
    const magnitudes = Object.entries<{ expectedLoss?: number[] }>(
      runResults ?? {}
    ).map(([title, result]) => {
      return {
        title,
        data: result?.expectedLoss || [],
      };
    });

    const frequency2 =
      (lastRun?.frequencyResults && lastRun?.frequencyResults.sips) || null;

    const frequency = !!frequency2 && [
      { title: "Frequency", data: frequency2 },
    ];

    return { magnitudes, frequency };
  }
};

export const FAIRSummary = ({ fairId }) => {
  const { id } = useParams<{ id: string }>();
  const { groupRole } = usePermissions();
  const [showGraphs, _setShowGraphs] = useState([1, 2, 3]);
  const { magnitudes, frequency } = useGetFAIRSummaryData(fairId);
  const dataSet = "magnitudes";

  const baseData = magnitudes || null;

  const totalAggregate =
    (baseData?.length > 0 && [
      {
        title: "Total",
        data:
          baseData.length === 0 ? [[]] : zipSum(...baseData.map((r) => r.data)),
      },
    ]) ||
    [];

  const visualizationData = [...baseData, ...totalAggregate];

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Card>
        <Card.Header>
          {groupRole !== "read" && (
            <div style={{ float: "right" }}>
              <Link to={`/risk/${id}/financial`}>
                <Button>Modify Analysis</Button>
              </Link>
            </div>
          )}
          Analysis Results
        </Card.Header>
        <Card.Body>
          {(visualizationData?.length > 0 && (
            <Row>
              {/* View Exceedance with Histogram */}
              {showGraphs.some((e) => e === 2) && (
                <ExceedenceGraph
                  style={{
                    backgroundColor: "white",
                    border: "5px solid #f3f3f4",
                    borderRadius: "15px",
                  }}
                  data={[...totalAggregate, ...baseData]}
                  sm={6}
                  label="Probability of Exceedance"
                  xFormat={(t) => (dataSet === "magnitudes" && `$${t}`) || t}
                  yFormat={(t) => `${t}%`}
                  multiple
                />
              )}
              {showGraphs.some((e) => e === 3) && (
                <BoxPlot
                  style={{
                    backgroundColor: "white",
                    border: "5px solid #f3f3f4",
                    borderRadius: "15px",
                  }}
                  data={baseData}
                  sm={6}
                  label="Whisker Box Plot"
                  yFormat={(t) => (dataSet === "magnitudes" && `$${t}`) || t}
                />
              )}
            </Row>
          )) || (
            <p>
              No Scenario data, please click Modify Analysis to update the
              Scenario.
            </p>
          )}
        </Card.Body>
      </Card>
      <ScenarioFairSummaryTable data={{ frequency, magnitudes }} />
    </>
  );
};
