import React, { useState } from "react";
import "@aws-amplify/ui/dist/style.css";
import "./AuthUI.scss";

import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifyConfirmSignIn,
} from "@aws-amplify/ui-react";
import { useMount } from "react-use";

export const AuthUI = ({ children }) => {
  const [_authState, setAuthState] = useState<any>();
  const [userVal, setUserVal] = useState<any>();
  useMount(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUserVal(authData);
    });
  });

  return (
    <div>
      <AmplifyAuthenticator>
        <AmplifySignIn
          slot="sign-in"
          hideSignUp={true}
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              label: "Email* (Must be lowercase)",
            },
            {
              type: "password",
            },
          ]}
        />
        <AmplifyConfirmSignIn
          user={userVal}
          slot="confirm-sign-in"
          headerText="Confirm Two Factor code"
        />
        {children}
      </AmplifyAuthenticator>
    </div>
  );
};
