import { Status } from "API";
import React from "react";
import { Badge } from "react-bootstrap";

export interface IActiveItems {
  archived: boolean | null;
  status: Status | null;
}

export type IActiveItemsArray = Array<IActiveItems | null>;

export const useActiveItemCount = (items?: IActiveItemsArray): number => {
  const activeItems = (items ?? []).filter(
    (item) => item?.archived !== true && item?.status !== Status.Closed
  );
  return activeItems.length;
};

export const ActiveItemCount: React.FC<{ items?: IActiveItemsArray }> = ({
  items,
  ...props
}) => {
  const itemCount = useActiveItemCount(items);

  return (
    <Badge pill {...{ variant: "primary", ...props }}>
      {itemCount}
    </Badge>
  );
};
