import { useCallback, useEffect, useState } from "react";
import {
  LazyQueryHookOptions,
  useLazyQuery,
  TypedDocumentNode,
  QueryResult,
  LazyQueryResult,
  OperationVariables,
} from "@apollo/client";
import { usePreviousDistinct } from "react-use";

export const useProtectedQuery = <T, U extends OperationVariables>(
  query: TypedDocumentNode,
  options?: Partial<LazyQueryHookOptions<T>>,
  condition = false
): LazyQueryResult<T, U> & {
  refetch: (props: any) => void;
  isPolling: boolean;
} => {
  const [callProtectedQuery, { data, refetch, ...queryResponse }] =
    useLazyQuery<T>(query, options as any);

  const { called } = queryResponse;

  const [pollInterval, setPollInterval] = useState(0);
  const startPolling = (_pollInterval) => {
    setPollInterval(_pollInterval);
    queryResponse?.startPolling?.(_pollInterval);
  };

  const stopPolling = () => {
    setPollInterval(0);
    queryResponse?.stopPolling?.();
  };

  const updatePolling = () => {
    queryResponse?.startPolling?.(pollInterval);
  };

  const collapsedProps = JSON.stringify(options);
  const prevCollapsedProps = usePreviousDistinct(collapsedProps);

  const [queryRebuilt, setQueryRebuilt] = useState(false);

  useEffect(() => {
    if (queryRebuilt) {
      updatePolling();
      setQueryRebuilt(false);
    }
  }, [queryRebuilt]);

  const _refetch = useCallback<any>(
    async (data?: any) => {
      if (!called || collapsedProps !== prevCollapsedProps) {
        const rebuildRefetch = await Promise.resolve(
          callProtectedQuery(options)
        );
        setQueryRebuilt(true);
        return rebuildRefetch;
      }
      return refetch?.();
    },
    // eslint-disable-next-line
    [collapsedProps, called, refetch]
  );

  useEffect(() => {
    if (condition) {
      _refetch();
    }
  }, [_refetch, condition]);

  return {
    data,
    ...queryResponse,
    refetch: _refetch,
    isPolling: !!pollInterval,
    startPolling,
    stopPolling,
  } as QueryResult<T, U> & { isPolling: boolean };
};

export const useProtectedPolling = (
  data: any,
  response: LazyQueryResult<any, any>
) => {
  useEffect(() => {
    if (!response.called) return;
    if (data) {
      response.stopPolling();
    }
    if (!data) {
      response.startPolling(100);
    }
  }, [data, response.called]);
};
