import React from 'react'
import { Switch, Route, Redirect } from "react-router";
import SystemLists from './SystemLists/SystemLists';

const Reporting = React.lazy(() => import("Modules/Customers/Reporting"));

const SystemManagementRoutes = () => {
  return (
    <Switch>
        <Route path="/systemManagement/performanceMetrics">
            <Reporting/>
        </Route>
        <Route path="/systemManagement/systemLists">
            <SystemLists/>
        </Route>
        <Redirect to="/systemManagement/performanceMetrics"/>
    </Switch>
  )
}

export default SystemManagementRoutes