import React, { useEffect } from "react";
import {
  HGInputProps,
  HGLabel,
  HGLabelProps,
  useHGFormError,
} from "./utils/HGInput.utils";
import { FormatHGCheckBoxProperties } from "./utils/HGCheckBox.utils";
import { useEditContext } from "../HGFormContext";
import { Form, FormLabel, InputGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { HGHidden } from "./HGHidden";

export const HGCheckBox: React.FC<
  HGLabelProps &
    FormatHGCheckBoxProperties & {
      overrideFormat?: ((input: any) => string | string) | string;
    } & {
      checked?: boolean;
      inputGroup?: any;
      onChange?: (e) => void;
      id: string;
      checkGroup?: string;
    }
> = ({ checked, onChange, label, id, checkGroup, name, ...props }) => {
  return (
    <InputGroup>
      {props?.inputGroup && props?.inputGroup()}
      <div className="custom-control custom-checkbox">
        <input
          style={{ margin: "3px" }}
          type="checkbox"
          className="custom-control-input"
          id={id}
          checked={checked}
          onChange={onChange}
          name={checkGroup ?? name}
        />
        <FormLabel className="custom-control-label" htmlFor={id}>
          {label}
        </FormLabel>
      </div>
    </InputGroup>
  );
};

export const HGCheckBoxGroup: React.FC<
  HGLabelProps &
    FormatHGCheckBoxProperties & {
      overrideFormat?: ((input: any) => string | string) | string;
    } & {
      inputGroup?: any;
      idPrefix?: string;
    }
> = ({
  name,
  enumObj,
  resolveIdToStringFn,
  setEnumObj,
  idPrefix,
  ...props
}) => {
  const methods = useFormContext();
  const { register, setValue, getValues } = methods;
  const { editing } = useEditContext();

  const { ErrorDisplay, isInvalid } = useHGFormError({
    name,
    overrideError: props.error,
    editing,
  });
  useEffect(() => {
    setValue(name, JSON.stringify(enumObj));
  }, [enumObj]);

  return (
    <>
      <Form.Group>
        <HGLabel {...props} />
        {Object.entries(enumObj).map((entry, index) => {
          const [key, value] = entry;
          return (
            <HGCheckBox
              key={key}
              id={`${idPrefix}${key}`}
              checkGroup={name}
              name={`${name}.${key}`}
              checked={enumObj[key] === "true"}
              label={resolveIdToStringFn?.(key) ?? key}
              onChange={() => {
                setEnumObj(({ ...prior }) => ({
                  ...prior,
                  [key]: prior[key] === "false" ? "true" : "false",
                }));
              }}
            />
          );
        })}
        <HGHidden name={name} />
      </Form.Group>
      <ErrorDisplay />
    </>
  );
};
