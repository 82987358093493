import { Paragraph, Table, TableLayoutType, TableRow, TextRun, WidthType } from "docx";
import { DateTime } from "luxon";
import { ReportConfig } from "utils/docx-export/reportSchemaParse.types";
import { useGenerateReportFilename } from "utils/docx-export/useCreateFilename";
import { getTableDataRows, getTableHeader } from "utils/useGetGroupByRelatedItems";

const requiresApprovalParser = (items) => {
    return Object.entries(items)
    .sort()
    .map(([key, value]: any) => {
      return [
        new Paragraph({
            children: [
              new TextRun({
                text: `${
                  key === "actionplans"
                    ? "Action Plans"
                    : key === "auditplanning"
                    ? "Audits"
                    : (key.charAt(0).toUpperCase() + key.slice(1))
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                }:`,
                bold: true,
                break: 1,
              }),
            ],
          }),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            layout: TableLayoutType.FIXED,
            rows: [
              new TableRow({
                children: getTableHeader(value),
              }),
              ...getTableDataRows(value),
            ],
          }),
      ]
    })
    .flat(1)
}

export const requiresApprovalReportSchema = (): ReportConfig<any> => {
    const getFilename = useGenerateReportFilename();
    return {
        getFilename: (item) =>
        getFilename({
            type: "filename",
            fileName: `Decipher Risk-My Items Needing Approval`,
            timestamp: DateTime.now(),
        }),
        fields: [
            { type: "Header", content: () => "My Items Needing Approval" },
            {
                type: "Custom",
                label: "",
                formatting: (item) => [
                 ...requiresApprovalParser(item)
                ],
              },
        ]
    }
}