/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createActionPlan = /* GraphQL */ `
  mutation CreateActionPlan($input: [CreateActionPlanInput!]!) {
    CreateActionPlan(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      actionPlanID
      title
      description
      resources
      notes
      owner
      priority
      status
      approver
      dateClosed
      startDate
      dueDate
      reviewDate
      compositeIDYear
      compositeID
      archived
      isTemplate
      groupID
      actionPlanRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      taskChildren {
        items {
          id
          sort
          itemID
          task {
            res
            error
            id
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            parentID
            organizationID
            groupID
            title
            description
            resources
            notes
            owner
            priority
            status
            dateClosed
            dueDate
            archived
            taskOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            taskRASCIRoles {
              items {
                id
                sort
                userID
                userInfo {
                  id
                  sort
                  email
                  firstName
                  lastName
                  inactive
                }
                user {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                userRASCIRole
              }
              nextToken
            }
          }
          createdAt
          userRASCIRole
        }
        nextToken
      }
      effortAmount
      effortType
      actionPlanOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
      actionplanApprovers {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        nextToken
      }
      type
    }
  }
`;
export const modifyActionPlan = /* GraphQL */ `
  mutation ModifyActionPlan($input: [UpdateActionPlanInput!]!) {
    ModifyActionPlan(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      actionPlanID
      title
      description
      resources
      notes
      owner
      priority
      status
      approver
      dateClosed
      startDate
      dueDate
      reviewDate
      compositeIDYear
      compositeID
      archived
      isTemplate
      groupID
      actionPlanRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      taskChildren {
        items {
          id
          sort
          itemID
          task {
            res
            error
            id
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            parentID
            organizationID
            groupID
            title
            description
            resources
            notes
            owner
            priority
            status
            dateClosed
            dueDate
            archived
            taskOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            taskRASCIRoles {
              items {
                id
                sort
                userID
                userInfo {
                  id
                  sort
                  email
                  firstName
                  lastName
                  inactive
                }
                user {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                userRASCIRole
              }
              nextToken
            }
          }
          createdAt
          userRASCIRole
        }
        nextToken
      }
      effortAmount
      effortType
      actionPlanOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
      actionplanApprovers {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        nextToken
      }
      type
    }
  }
`;
export const deleteActionPlan = /* GraphQL */ `
  mutation DeleteActionPlan($input: [DeleteActionPlanInput!]!) {
    DeleteActionPlan(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      actionPlanID
      title
      description
      resources
      notes
      owner
      priority
      status
      approver
      dateClosed
      startDate
      dueDate
      reviewDate
      compositeIDYear
      compositeID
      archived
      isTemplate
      groupID
      actionPlanRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      taskChildren {
        items {
          id
          sort
          itemID
          task {
            res
            error
            id
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            parentID
            organizationID
            groupID
            title
            description
            resources
            notes
            owner
            priority
            status
            dateClosed
            dueDate
            archived
            taskOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            taskRASCIRoles {
              items {
                id
                sort
                userID
                userInfo {
                  id
                  sort
                  email
                  firstName
                  lastName
                  inactive
                }
                user {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                userRASCIRole
              }
              nextToken
            }
          }
          createdAt
          userRASCIRole
        }
        nextToken
      }
      effortAmount
      effortType
      actionPlanOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      actionPlanRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
      actionplanApprovers {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        nextToken
      }
      type
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask($input: [CreateTaskInput!]!) {
    CreateTask(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      parentID
      organizationID
      groupID
      title
      description
      resources
      notes
      owner
      priority
      status
      dateClosed
      dueDate
      archived
      taskOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      taskRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
    }
  }
`;
export const modifyTask = /* GraphQL */ `
  mutation ModifyTask($input: [UpdateTaskInput!]!) {
    ModifyTask(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      parentID
      organizationID
      groupID
      title
      description
      resources
      notes
      owner
      priority
      status
      dateClosed
      dueDate
      archived
      taskOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      taskRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask($input: [DeleteTaskInput!]!) {
    DeleteTask(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      parentID
      organizationID
      groupID
      title
      description
      resources
      notes
      owner
      priority
      status
      dateClosed
      dueDate
      archived
      taskOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      taskRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
    }
  }
`;
export const createIssue = /* GraphQL */ `
  mutation CreateIssue($input: [CreateIssueInput!]!) {
    CreateIssue(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      verifiedBy
      lastModified
      issueID
      title
      description
      assumptions
      notes
      reportedBy
      reportedOn
      nextReview
      owner
      priority
      status
      approver
      dateClosed
      severity
      compositeIDYear
      compositeID
      archived
      isTemplate
      issueSourceId
      groupID
      issueRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      acceptanceCriteria
      requiresScenario
      requiresActionplan
      issueOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueVerifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueSource {
        res
        error
        id
        sort
        organizationID
        groupID
        description
        year
        title
        archived
      }
      issueRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
      issueApprovers {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        nextToken
      }
    }
  }
`;
export const modifyIssue = /* GraphQL */ `
  mutation ModifyIssue($input: [UpdateIssueInput!]!) {
    ModifyIssue(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      verifiedBy
      lastModified
      issueID
      title
      description
      assumptions
      notes
      reportedBy
      reportedOn
      nextReview
      owner
      priority
      status
      approver
      dateClosed
      severity
      compositeIDYear
      compositeID
      archived
      isTemplate
      issueSourceId
      groupID
      issueRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      acceptanceCriteria
      requiresScenario
      requiresActionplan
      issueOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueVerifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueSource {
        res
        error
        id
        sort
        organizationID
        groupID
        description
        year
        title
        archived
      }
      issueRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
      issueApprovers {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteIssue = /* GraphQL */ `
  mutation DeleteIssue($input: [DeleteIssueInput!]!) {
    DeleteIssue(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      verifiedBy
      lastModified
      issueID
      title
      description
      assumptions
      notes
      reportedBy
      reportedOn
      nextReview
      owner
      priority
      status
      approver
      dateClosed
      severity
      compositeIDYear
      compositeID
      archived
      isTemplate
      issueSourceId
      groupID
      issueRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      acceptanceCriteria
      requiresScenario
      requiresActionplan
      issueOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueVerifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      issueSource {
        res
        error
        id
        sort
        organizationID
        groupID
        description
        year
        title
        archived
      }
      issueRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
      issueApprovers {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        nextToken
      }
    }
  }
`;
export const createSource = /* GraphQL */ `
  mutation CreateSource($input: [CreateSourceInput!]!) {
    CreateSource(input: $input) {
      res
      error
      id
      sort
      organizationID
      groupID
      description
      year
      title
      archived
    }
  }
`;
export const modifySource = /* GraphQL */ `
  mutation ModifySource($input: [UpdateSourceInput!]!) {
    ModifySource(input: $input) {
      res
      error
      id
      sort
      organizationID
      groupID
      description
      year
      title
      archived
    }
  }
`;
export const deleteSource = /* GraphQL */ `
  mutation DeleteSource($input: [DeleteSourceInput!]!) {
    DeleteSource(input: $input) {
      res
      error
      id
      sort
      organizationID
      groupID
      description
      year
      title
      archived
    }
  }
`;
export const createScenario = /* GraphQL */ `
  mutation CreateScenario($input: [CreateScenarioInput!]!) {
    CreateScenario(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      FAIRSummary
      scenarioID
      title
      description
      notes
      assumptions
      creationDate
      lastRevision
      nextRevision
      categories
      owner
      status
      dateClosed
      indicator
      compositeIDYear
      compositeID
      archived
      isTemplate
      groupID
      scenarioRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      scenarioOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
    }
  }
`;
export const modifyScenario = /* GraphQL */ `
  mutation ModifyScenario($input: [UpdateScenarioInput!]!) {
    ModifyScenario(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      FAIRSummary
      scenarioID
      title
      description
      notes
      assumptions
      creationDate
      lastRevision
      nextRevision
      categories
      owner
      status
      dateClosed
      indicator
      compositeIDYear
      compositeID
      archived
      isTemplate
      groupID
      scenarioRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      scenarioOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
    }
  }
`;
export const deleteScenario = /* GraphQL */ `
  mutation DeleteScenario($input: [DeleteScenarioInput!]!) {
    DeleteScenario(input: $input) {
      res
      error
      id
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      FAIRSummary
      scenarioID
      title
      description
      notes
      assumptions
      creationDate
      lastRevision
      nextRevision
      categories
      owner
      status
      dateClosed
      indicator
      compositeIDYear
      compositeID
      archived
      isTemplate
      groupID
      scenarioRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      scenarioOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      scenarioRASCIRoles {
        items {
          id
          sort
          userID
          userInfo {
            id
            sort
            email
            firstName
            lastName
            inactive
          }
          user {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          userRASCIRole
        }
        nextToken
      }
    }
  }
`;
export const createBreachAnalysis = /* GraphQL */ `
  mutation CreateBreachAnalysis($input: [CreateBreachAnalysisInput!]!) {
    CreateBreachAnalysis(input: $input) {
      res
      error
      id
      sort
      mostLikelyLEF
      mostLikelyPLM
      worstCaseLEF
      worstCasePLM
      results
      groupID
    }
  }
`;
export const modifyBreachAnalysis = /* GraphQL */ `
  mutation ModifyBreachAnalysis($input: [UpdateBreachAnalysisInput!]!) {
    ModifyBreachAnalysis(input: $input) {
      res
      error
      id
      sort
      mostLikelyLEF
      mostLikelyPLM
      worstCaseLEF
      worstCasePLM
      results
      groupID
    }
  }
`;
export const deleteBreachAnalysis = /* GraphQL */ `
  mutation DeleteBreachAnalysis($input: [DeleteBreachAnalysisInput!]!) {
    DeleteBreachAnalysis(input: $input) {
      res
      error
      id
      sort
      mostLikelyLEF
      mostLikelyPLM
      worstCaseLEF
      worstCasePLM
      results
      groupID
    }
  }
`;
export const createSafetyAnalysis = /* GraphQL */ `
  mutation CreateSafetyAnalysis($input: [CreateSafetyAnalysisInput!]!) {
    CreateSafetyAnalysis(input: $input) {
      res
      error
      id
      sort
      minTEF
      mostLikelyTEF
      maxTEF
      impactProbability
      minPEF
      mostLikelyPEF
      maxPEF
      harmProbability
      results
      groupID
    }
  }
`;
export const modifySafetyAnalysis = /* GraphQL */ `
  mutation ModifySafetyAnalysis($input: [UpdateSafetyAnalysisInput!]!) {
    ModifySafetyAnalysis(input: $input) {
      res
      error
      id
      sort
      minTEF
      mostLikelyTEF
      maxTEF
      impactProbability
      minPEF
      mostLikelyPEF
      maxPEF
      harmProbability
      results
      groupID
    }
  }
`;
export const deleteSafetyAnalysis = /* GraphQL */ `
  mutation DeleteSafetyAnalysis($input: [DeleteSafetyAnalysisInput!]!) {
    DeleteSafetyAnalysis(input: $input) {
      res
      error
      id
      sort
      minTEF
      mostLikelyTEF
      maxTEF
      impactProbability
      minPEF
      mostLikelyPEF
      maxPEF
      harmProbability
      results
      groupID
    }
  }
`;
export const createUrl = /* GraphQL */ `
  mutation CreateUrl($input: [CreateUrlInput!]!) {
    CreateUrl(input: $input) {
      res
      error
      id
      sort
      address
      title
      groupID
    }
  }
`;
export const modifyUrl = /* GraphQL */ `
  mutation ModifyUrl($input: [UpdateUrlInput!]!) {
    ModifyUrl(input: $input) {
      res
      error
      id
      sort
      address
      title
      groupID
    }
  }
`;
export const deleteUrl = /* GraphQL */ `
  mutation DeleteUrl($input: [DeleteUrlInput!]!) {
    DeleteUrl(input: $input) {
      res
      error
      id
      sort
      address
      title
      groupID
    }
  }
`;
export const createRiskPortfolio = /* GraphQL */ `
  mutation CreateRiskPortfolio($input: [CreateRiskPortfolioInput!]!) {
    CreateRiskPortfolio(input: $input) {
      res
      error
      id
      sort
      groupID
      title
      description
      notes
      owner
      status
      assumptions
      archived
      modifiedBy
    }
  }
`;
export const modifyRiskPortfolio = /* GraphQL */ `
  mutation ModifyRiskPortfolio($input: [UpdateRiskPortfolioInput!]!) {
    ModifyRiskPortfolio(input: $input) {
      res
      error
      id
      sort
      groupID
      title
      description
      notes
      owner
      status
      assumptions
      archived
      modifiedBy
    }
  }
`;
export const deleteRiskPortfolio = /* GraphQL */ `
  mutation DeleteRiskPortfolio($input: [DeleteRiskPortfolioInput!]!) {
    DeleteRiskPortfolio(input: $input) {
      res
      error
      id
      sort
      groupID
      title
      description
      notes
      owner
      status
      assumptions
      archived
      modifiedBy
    }
  }
`;
export const createRiskComparison = /* GraphQL */ `
  mutation CreateRiskComparison($input: [CreateRiskComparisonInput!]!) {
    CreateRiskComparison(input: $input) {
      res
      error
      id
      sort
      groupID
      title
      description
      scenarioOneID
      scenarioTwoID
      scenarioThreeID
      scenarioOne {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioTwo {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioThree {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioOneDescription
      scenarioTwoDescription
      scenarioThreeDescription
      lossThreshold
      archived
      modifiedBy
    }
  }
`;
export const modifyRiskComparison = /* GraphQL */ `
  mutation ModifyRiskComparison($input: [UpdateRiskComparisonInput!]!) {
    ModifyRiskComparison(input: $input) {
      res
      error
      id
      sort
      groupID
      title
      description
      scenarioOneID
      scenarioTwoID
      scenarioThreeID
      scenarioOne {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioTwo {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioThree {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioOneDescription
      scenarioTwoDescription
      scenarioThreeDescription
      lossThreshold
      archived
      modifiedBy
    }
  }
`;
export const deleteRiskComparison = /* GraphQL */ `
  mutation DeleteRiskComparison($input: [DeleteRiskComparisonInput!]!) {
    DeleteRiskComparison(input: $input) {
      res
      error
      id
      sort
      groupID
      title
      description
      scenarioOneID
      scenarioTwoID
      scenarioThreeID
      scenarioOne {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioTwo {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioThree {
        res
        error
        id
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        FAIRSummary
        scenarioID
        title
        description
        notes
        assumptions
        creationDate
        lastRevision
        nextRevision
        categories
        owner
        status
        dateClosed
        indicator
        compositeIDYear
        compositeID
        archived
        isTemplate
        groupID
        scenarioRelatedItems {
          items {
            id
            sort
            target
            targetType
            item {
              id
              sort
              groupID
              owner
              uuid
              title
              dateClosed
              dueDate
              status
              priority
              severity
              responseStatus
              archived
              createdAt
              lastModified
              compositeIDYear
              compositeID
              displayID
              relatedItemsOwner {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              relatedItemsGroup {
                res
                error
                id
                sort
                organizationID
                title
                shortTitle
                riskThreshold {
                  financial {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                  privacy {
                    appetitePercent
                    appetiteAbsolute
                    capacityPercent
                    capacityAbsolute
                  }
                }
                organization {
                  res
                  error
                  id
                  sort
                  name
                  organizationRelationships
                  demographics
                }
                groupEmailFooter
              }
              fileName
              address
            }
          }
          nextToken
        }
        scenarioOwner {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        scenarioRASCIRoles {
          items {
            id
            sort
            userID
            userInfo {
              id
              sort
              email
              firstName
              lastName
              inactive
            }
            user {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            userRASCIRole
          }
          nextToken
        }
      }
      scenarioOneDescription
      scenarioTwoDescription
      scenarioThreeDescription
      lossThreshold
      archived
      modifiedBy
    }
  }
`;
export const createRiskDomain = /* GraphQL */ `
  mutation CreateRiskDomain($input: [CreateRiskDomainInput!]!) {
    CreateRiskDomain(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      description
      displayID
      parentID
      parent {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        description
        displayID
        parentID
        parent {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          description
          displayID
          parentID
          parent {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            description
            displayID
            parentID
            parent {
              res
              error
              id
              groupID
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              title
              description
              displayID
              parentID
              parent {
                res
                error
                id
                groupID
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                title
                description
                displayID
                parentID
                parent {
                  res
                  error
                  id
                  groupID
                  sort
                  createdAt
                  createdBy
                  modifiedBy
                  lastModified
                  title
                  description
                  displayID
                  parentID
                  parent {
                    res
                    error
                    id
                    groupID
                    sort
                    createdAt
                    createdBy
                    modifiedBy
                    lastModified
                    title
                    description
                    displayID
                    parentID
                    archived
                  }
                  archived
                  riskDomainCreatedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  riskDomainModifiedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                archived
                riskDomainCreatedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                riskDomainModifiedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              archived
              riskDomainCreatedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              riskDomainModifiedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            archived
            riskDomainCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            riskDomainModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          archived
          riskDomainCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          riskDomainModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        archived
        riskDomainCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskDomainModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      archived
      riskDomainCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskDomainModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const modifyRiskDomain = /* GraphQL */ `
  mutation ModifyRiskDomain($input: [UpdateRiskDomainInput!]!) {
    ModifyRiskDomain(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      description
      displayID
      parentID
      parent {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        description
        displayID
        parentID
        parent {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          description
          displayID
          parentID
          parent {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            description
            displayID
            parentID
            parent {
              res
              error
              id
              groupID
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              title
              description
              displayID
              parentID
              parent {
                res
                error
                id
                groupID
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                title
                description
                displayID
                parentID
                parent {
                  res
                  error
                  id
                  groupID
                  sort
                  createdAt
                  createdBy
                  modifiedBy
                  lastModified
                  title
                  description
                  displayID
                  parentID
                  parent {
                    res
                    error
                    id
                    groupID
                    sort
                    createdAt
                    createdBy
                    modifiedBy
                    lastModified
                    title
                    description
                    displayID
                    parentID
                    archived
                  }
                  archived
                  riskDomainCreatedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  riskDomainModifiedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                archived
                riskDomainCreatedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                riskDomainModifiedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              archived
              riskDomainCreatedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              riskDomainModifiedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            archived
            riskDomainCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            riskDomainModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          archived
          riskDomainCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          riskDomainModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        archived
        riskDomainCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskDomainModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      archived
      riskDomainCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskDomainModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const deleteRiskDomain = /* GraphQL */ `
  mutation DeleteRiskDomain($input: [DeleteRiskDomainInput!]!) {
    DeleteRiskDomain(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      description
      displayID
      parentID
      parent {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        description
        displayID
        parentID
        parent {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          description
          displayID
          parentID
          parent {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            description
            displayID
            parentID
            parent {
              res
              error
              id
              groupID
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              title
              description
              displayID
              parentID
              parent {
                res
                error
                id
                groupID
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                title
                description
                displayID
                parentID
                parent {
                  res
                  error
                  id
                  groupID
                  sort
                  createdAt
                  createdBy
                  modifiedBy
                  lastModified
                  title
                  description
                  displayID
                  parentID
                  parent {
                    res
                    error
                    id
                    groupID
                    sort
                    createdAt
                    createdBy
                    modifiedBy
                    lastModified
                    title
                    description
                    displayID
                    parentID
                    archived
                  }
                  archived
                  riskDomainCreatedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  riskDomainModifiedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                archived
                riskDomainCreatedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                riskDomainModifiedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              archived
              riskDomainCreatedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              riskDomainModifiedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            archived
            riskDomainCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            riskDomainModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          archived
          riskDomainCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          riskDomainModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        archived
        riskDomainCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskDomainModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      archived
      riskDomainCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskDomainModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const createAuditPlanning = /* GraphQL */ `
  mutation CreateAuditPlanning($input: [CreateAuditPlanningInput!]!) {
    CreateAuditPlanning(input: $input) {
      res
      error
      id
      title
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      auditPlanID
      riskDomain
      year
      hours
      owner
      days
      status
      background
      objective
      scope
      methodology
      preliminaryAuditWork
      points
      summary
      auditFindings
      recommendations
      auditStartDate
      auditEndDate
      auditor
      totalHours
      archived
      auditPlanningRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      isTemplate
      auditPlanningOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      auditPlanningCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      auditPlanningModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskDomainDetails {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        description
        displayID
        parentID
        parent {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          description
          displayID
          parentID
          parent {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            description
            displayID
            parentID
            parent {
              res
              error
              id
              groupID
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              title
              description
              displayID
              parentID
              parent {
                res
                error
                id
                groupID
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                title
                description
                displayID
                parentID
                parent {
                  res
                  error
                  id
                  groupID
                  sort
                  createdAt
                  createdBy
                  modifiedBy
                  lastModified
                  title
                  description
                  displayID
                  parentID
                  parent {
                    res
                    error
                    id
                    groupID
                    sort
                    createdAt
                    createdBy
                    modifiedBy
                    lastModified
                    title
                    description
                    displayID
                    parentID
                    archived
                  }
                  archived
                  riskDomainCreatedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  riskDomainModifiedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                archived
                riskDomainCreatedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                riskDomainModifiedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              archived
              riskDomainCreatedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              riskDomainModifiedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            archived
            riskDomainCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            riskDomainModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          archived
          riskDomainCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          riskDomainModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        archived
        riskDomainCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskDomainModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      managementResponse
      viewType
    }
  }
`;
export const modifyAuditPlanning = /* GraphQL */ `
  mutation ModifyAuditPlanning($input: [UpdateAuditPlanningInput!]!) {
    ModifyAuditPlanning(input: $input) {
      res
      error
      id
      title
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      auditPlanID
      riskDomain
      year
      hours
      owner
      days
      status
      background
      objective
      scope
      methodology
      preliminaryAuditWork
      points
      summary
      auditFindings
      recommendations
      auditStartDate
      auditEndDate
      auditor
      totalHours
      archived
      auditPlanningRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      isTemplate
      auditPlanningOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      auditPlanningCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      auditPlanningModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskDomainDetails {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        description
        displayID
        parentID
        parent {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          description
          displayID
          parentID
          parent {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            description
            displayID
            parentID
            parent {
              res
              error
              id
              groupID
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              title
              description
              displayID
              parentID
              parent {
                res
                error
                id
                groupID
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                title
                description
                displayID
                parentID
                parent {
                  res
                  error
                  id
                  groupID
                  sort
                  createdAt
                  createdBy
                  modifiedBy
                  lastModified
                  title
                  description
                  displayID
                  parentID
                  parent {
                    res
                    error
                    id
                    groupID
                    sort
                    createdAt
                    createdBy
                    modifiedBy
                    lastModified
                    title
                    description
                    displayID
                    parentID
                    archived
                  }
                  archived
                  riskDomainCreatedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  riskDomainModifiedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                archived
                riskDomainCreatedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                riskDomainModifiedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              archived
              riskDomainCreatedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              riskDomainModifiedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            archived
            riskDomainCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            riskDomainModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          archived
          riskDomainCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          riskDomainModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        archived
        riskDomainCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskDomainModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      managementResponse
      viewType
    }
  }
`;
export const deleteAuditPlanning = /* GraphQL */ `
  mutation DeleteAuditPlanning($input: [DeleteAuditPlanningInput!]!) {
    DeleteAuditPlanning(input: $input) {
      res
      error
      id
      title
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      auditPlanID
      riskDomain
      year
      hours
      owner
      days
      status
      background
      objective
      scope
      methodology
      preliminaryAuditWork
      points
      summary
      auditFindings
      recommendations
      auditStartDate
      auditEndDate
      auditor
      totalHours
      archived
      auditPlanningRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      isTemplate
      auditPlanningOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      auditPlanningCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      auditPlanningModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskDomainDetails {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        description
        displayID
        parentID
        parent {
          res
          error
          id
          groupID
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          title
          description
          displayID
          parentID
          parent {
            res
            error
            id
            groupID
            sort
            createdAt
            createdBy
            modifiedBy
            lastModified
            title
            description
            displayID
            parentID
            parent {
              res
              error
              id
              groupID
              sort
              createdAt
              createdBy
              modifiedBy
              lastModified
              title
              description
              displayID
              parentID
              parent {
                res
                error
                id
                groupID
                sort
                createdAt
                createdBy
                modifiedBy
                lastModified
                title
                description
                displayID
                parentID
                parent {
                  res
                  error
                  id
                  groupID
                  sort
                  createdAt
                  createdBy
                  modifiedBy
                  lastModified
                  title
                  description
                  displayID
                  parentID
                  parent {
                    res
                    error
                    id
                    groupID
                    sort
                    createdAt
                    createdBy
                    modifiedBy
                    lastModified
                    title
                    description
                    displayID
                    parentID
                    archived
                  }
                  archived
                  riskDomainCreatedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                  riskDomainModifiedBy {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                archived
                riskDomainCreatedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
                riskDomainModifiedBy {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              archived
              riskDomainCreatedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
              riskDomainModifiedBy {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            archived
            riskDomainCreatedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            riskDomainModifiedBy {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          archived
          riskDomainCreatedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
          riskDomainModifiedBy {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        archived
        riskDomainCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        riskDomainModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      managementResponse
      viewType
    }
  }
`;
export const createAssets = /* GraphQL */ `
  mutation CreateAssets($input: [CreateAssetsInput!]!) {
    CreateAssets(input: $input) {
      res
      error
      id
      itemID
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      qty
      assetsGroupID
      notesAboutControls
      notesAboutThreats
      category
      assetCategory {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        categoryCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        categoryModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      location
      assetLocation {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        locationCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        locationModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      support
      assetSupport {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        pointOfContact
        pocEmail
        pocPhoneNumber
        notes
        archived
        supportCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        supportModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      owner
      assetsAnalysisInput
      assetsAnalysisOutput
      assetOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      notes
      displayID
      assetRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      archived
      assetAndThreatsRelatedItems {
        items {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          threatID
          assetID
          min
          mostLikely
          max
          vulnerability
          index
          archived
        }
        nextToken
      }
      PHIRecords
      assetsGroupDetails {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        assetsGroupCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        assetsGroupModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      assetID
      assessmentID
    }
  }
`;
export const modifyAssets = /* GraphQL */ `
  mutation ModifyAssets($input: [UpdateAssetsInput!]!) {
    ModifyAssets(input: $input) {
      res
      error
      id
      itemID
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      qty
      assetsGroupID
      notesAboutControls
      notesAboutThreats
      category
      assetCategory {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        categoryCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        categoryModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      location
      assetLocation {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        locationCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        locationModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      support
      assetSupport {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        pointOfContact
        pocEmail
        pocPhoneNumber
        notes
        archived
        supportCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        supportModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      owner
      assetsAnalysisInput
      assetsAnalysisOutput
      assetOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      notes
      displayID
      assetRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      archived
      assetAndThreatsRelatedItems {
        items {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          threatID
          assetID
          min
          mostLikely
          max
          vulnerability
          index
          archived
        }
        nextToken
      }
      PHIRecords
      assetsGroupDetails {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        assetsGroupCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        assetsGroupModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      assetID
      assessmentID
    }
  }
`;
export const deleteAssets = /* GraphQL */ `
  mutation DeleteAssets($input: [DeleteAssetsInput!]!) {
    DeleteAssets(input: $input) {
      res
      error
      id
      itemID
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      qty
      assetsGroupID
      notesAboutControls
      notesAboutThreats
      category
      assetCategory {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        categoryCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        categoryModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      location
      assetLocation {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        locationCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        locationModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      support
      assetSupport {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        pointOfContact
        pocEmail
        pocPhoneNumber
        notes
        archived
        supportCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        supportModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      owner
      assetsAnalysisInput
      assetsAnalysisOutput
      assetOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      notes
      displayID
      assetRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
      archived
      assetAndThreatsRelatedItems {
        items {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          threatID
          assetID
          min
          mostLikely
          max
          vulnerability
          index
          archived
        }
        nextToken
      }
      PHIRecords
      assetsGroupDetails {
        res
        error
        id
        groupID
        sort
        createdAt
        createdBy
        modifiedBy
        lastModified
        title
        archived
        assetsGroupCreatedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
        assetsGroupModifiedBy {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      assetID
      assessmentID
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: [CreateCategoryInput!]!) {
    CreateCategory(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      categoryCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      categoryModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const modifyCategory = /* GraphQL */ `
  mutation ModifyCategory($input: [UpdateCategoryInput!]!) {
    ModifyCategory(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      categoryCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      categoryModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($input: [DeleteCategoryInput!]!) {
    DeleteCategory(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      categoryCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      categoryModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const createSupport = /* GraphQL */ `
  mutation CreateSupport($input: [CreateSupportInput!]!) {
    CreateSupport(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      pointOfContact
      pocEmail
      pocPhoneNumber
      notes
      archived
      supportCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      supportModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const modifySupport = /* GraphQL */ `
  mutation ModifySupport($input: [UpdateSupportInput!]!) {
    ModifySupport(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      pointOfContact
      pocEmail
      pocPhoneNumber
      notes
      archived
      supportCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      supportModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const deleteSupport = /* GraphQL */ `
  mutation DeleteSupport($input: [DeleteSupportInput!]!) {
    DeleteSupport(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      pointOfContact
      pocEmail
      pocPhoneNumber
      notes
      archived
      supportCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      supportModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation($input: [CreateLocationInput!]!) {
    CreateLocation(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      locationCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      locationModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const modifyLocation = /* GraphQL */ `
  mutation ModifyLocation($input: [UpdateLocationInput!]!) {
    ModifyLocation(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      locationCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      locationModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($input: [DeleteLocationInput!]!) {
    DeleteLocation(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      locationCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      locationModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const createThreats = /* GraphQL */ `
  mutation CreateThreats($input: [CreateThreatsInput!]!) {
    CreateThreats(input: $input) {
      res
      error
      id
      itemID
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      description
      impact
      archived
      threatsCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      threatsModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      threatsRelatedItems {
        items {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          threatID
          assetID
          min
          mostLikely
          max
          vulnerability
          index
          archived
        }
        nextToken
      }
    }
  }
`;
export const modifyThreats = /* GraphQL */ `
  mutation ModifyThreats($input: [UpdateThreatsInput!]!) {
    ModifyThreats(input: $input) {
      res
      error
      id
      itemID
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      description
      impact
      archived
      threatsCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      threatsModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      threatsRelatedItems {
        items {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          threatID
          assetID
          min
          mostLikely
          max
          vulnerability
          index
          archived
        }
        nextToken
      }
    }
  }
`;
export const deleteThreats = /* GraphQL */ `
  mutation DeleteThreats($input: [DeleteThreatsInput!]!) {
    DeleteThreats(input: $input) {
      res
      error
      id
      itemID
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      description
      impact
      archived
      threatsCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      threatsModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      threatsRelatedItems {
        items {
          res
          error
          id
          sort
          createdAt
          createdBy
          modifiedBy
          lastModified
          threatID
          assetID
          min
          mostLikely
          max
          vulnerability
          index
          archived
        }
        nextToken
      }
    }
  }
`;
export const createRiskAssessment = /* GraphQL */ `
  mutation CreateRiskAssessment($input: [CreateRiskAssessmentInput!]!) {
    CreateRiskAssessment(input: $input) {
      res
      error
      id
      title
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      owner
      startDate
      endDate
      overview
      assessor
      scope
      executiveSummary
      methodology
      archived
      riskAssessmentOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskAssessmentCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskAssessmentModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const modifyRiskAssessment = /* GraphQL */ `
  mutation ModifyRiskAssessment($input: [UpdateRiskAssessmentInput!]!) {
    ModifyRiskAssessment(input: $input) {
      res
      error
      id
      title
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      owner
      startDate
      endDate
      overview
      assessor
      scope
      executiveSummary
      methodology
      archived
      riskAssessmentOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskAssessmentCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskAssessmentModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const deleteRiskAssessment = /* GraphQL */ `
  mutation DeleteRiskAssessment($input: [DeleteRiskAssessmentInput!]!) {
    DeleteRiskAssessment(input: $input) {
      res
      error
      id
      title
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      owner
      startDate
      endDate
      overview
      assessor
      scope
      executiveSummary
      methodology
      archived
      riskAssessmentOwner {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskAssessmentCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      riskAssessmentModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const createAssetsGroup = /* GraphQL */ `
  mutation CreateAssetsGroup($input: [CreateAssetsGroupInput!]!) {
    CreateAssetsGroup(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      assetsGroupCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetsGroupModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const modifyAssetsGroup = /* GraphQL */ `
  mutation ModifyAssetsGroup($input: [UpdateAssetsGroupInput!]!) {
    ModifyAssetsGroup(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      assetsGroupCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetsGroupModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const deleteAssetsGroup = /* GraphQL */ `
  mutation DeleteAssetsGroup($input: [DeleteAssetsGroupInput!]!) {
    DeleteAssetsGroup(input: $input) {
      res
      error
      id
      groupID
      sort
      createdAt
      createdBy
      modifiedBy
      lastModified
      title
      archived
      assetsGroupCreatedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
      assetsGroupModifiedBy {
        res
        error
        id
        sort
        email
        username
        firstName
        lastName
        inactive
        lastSeen
        lastLoggedIn
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: [CreateUserInput!]!) {
    CreateUser(input: $input)
  }
`;
export const modifyUserMeta = /* GraphQL */ `
  mutation ModifyUserMeta($input: [ModifyUserMetaInput!]!) {
    ModifyUserMeta(input: $input)
  }
`;
export const disableUser = /* GraphQL */ `
  mutation DisableUser($input: [DisableUserInput!]!) {
    DisableUser(input: $input)
  }
`;
export const enableUser = /* GraphQL */ `
  mutation EnableUser($input: [EnableUserInput!]!) {
    EnableUser(input: $input)
  }
`;
export const modifyUserAttributes = /* GraphQL */ `
  mutation ModifyUserAttributes($input: [ModifyUserAttributesInput!]!) {
    ModifyUserAttributes(input: $input)
  }
`;
export const modifyEmail = /* GraphQL */ `
  mutation ModifyEmail($input: [ModifyEmailInput!]!) {
    ModifyEmail(input: $input)
  }
`;
export const cloneActionPlan = /* GraphQL */ `
  mutation CloneActionPlan($input: [CloneActionPlanInput!]!) {
    CloneActionPlan(input: $input)
  }
`;
export const actionPlanTemplate = /* GraphQL */ `
  mutation ActionPlanTemplate($input: [ActionPlanTemplateInput!]!) {
    ActionPlanTemplate(input: $input)
  }
`;
export const modifyNotification = /* GraphQL */ `
  mutation ModifyNotification($input: [ModifyNotificationInput!]!) {
    ModifyNotification(input: $input)
  }
`;
export const cloneIssue = /* GraphQL */ `
  mutation CloneIssue($input: [CloneIssueInput!]!) {
    CloneIssue(input: $input)
  }
`;
export const issueTemplate = /* GraphQL */ `
  mutation IssueTemplate($input: [IssueTemplateInput!]!) {
    IssueTemplate(input: $input)
  }
`;
export const cloneScenario = /* GraphQL */ `
  mutation CloneScenario($input: [CloneScenarioInput!]!) {
    CloneScenario(input: $input)
  }
`;
export const riskTemplate = /* GraphQL */ `
  mutation RiskTemplate($input: [RiskTemplateInput!]!) {
    RiskTemplate(input: $input)
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($input: [CreateOrganizationInput!]!) {
    CreateOrganization(input: $input)
  }
`;
export const addUserToOrganization = /* GraphQL */ `
  mutation AddUserToOrganization($input: [AddUserToOrganizationInput!]!) {
    AddUserToOrganization(input: $input)
  }
`;
export const setUserRoleInOrganization = /* GraphQL */ `
  mutation SetUserRoleInOrganization(
    $input: [setUserRoleInOrganizationInput!]!
  ) {
    setUserRoleInOrganization(input: $input)
  }
`;
export const removeUserFromOrganization = /* GraphQL */ `
  mutation RemoveUserFromOrganization(
    $input: [RemoveUserFromOrganizationInput!]!
  ) {
    RemoveUserFromOrganization(input: $input)
  }
`;
export const modifyOrganization = /* GraphQL */ `
  mutation ModifyOrganization($input: [modifyOrganizationInput!]!) {
    modifyOrganization(input: $input)
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: [CreateGroupInput!]!) {
    CreateGroup(input: $input)
  }
`;
export const modifyGroup = /* GraphQL */ `
  mutation ModifyGroup($input: [ModifyGroupInput!]!) {
    ModifyGroup(input: $input)
  }
`;
export const setGroupRiskThreshold = /* GraphQL */ `
  mutation SetGroupRiskThreshold($input: [SetGroupRiskThresholdInput!]!) {
    SetGroupRiskThreshold(input: $input)
  }
`;
export const addUserToGroup = /* GraphQL */ `
  mutation AddUserToGroup($input: [AddUserToGroupInput!]!) {
    AddUserToGroup(input: $input)
  }
`;
export const setUserRoleInGroup = /* GraphQL */ `
  mutation SetUserRoleInGroup($input: [setUserRoleInGroupInput!]!) {
    setUserRoleInGroup(input: $input)
  }
`;
export const removeUserFromGroup = /* GraphQL */ `
  mutation RemoveUserFromGroup($input: [RemoveUserFromGroupInput!]!) {
    RemoveUserFromGroup(input: $input)
  }
`;
export const uploadArtifact = /* GraphQL */ `
  mutation UploadArtifact($input: [UploadArtifactInput!]!) {
    UploadArtifact(input: $input)
  }
`;
export const requestArtifact = /* GraphQL */ `
  mutation RequestArtifact($input: [RequestArtifactInput!]!) {
    RequestArtifact(input: $input)
  }
`;
export const modifyArtifact = /* GraphQL */ `
  mutation ModifyArtifact($input: [ModifyArtifactInput!]!) {
    ModifyArtifact(input: $input)
  }
`;
export const createSurveyTemplate = /* GraphQL */ `
  mutation CreateSurveyTemplate($input: [CreateSurveyTemplateInput!]!) {
    CreateSurveyTemplate(input: $input)
  }
`;
export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment($input: [CreateAssessmentInput!]!) {
    CreateAssessment(input: $input)
  }
`;
export const modifyAssessment = /* GraphQL */ `
  mutation ModifyAssessment($input: [ModifyAssessmentInput!]!) {
    ModifyAssessment(input: $input)
  }
`;
export const cloneAssessment = /* GraphQL */ `
  mutation CloneAssessment($input: [CloneAssessmentInput!]!) {
    CloneAssessment(input: $input)
  }
`;
export const modifyResponse = /* GraphQL */ `
  mutation ModifyResponse($input: [ModifyResponseInput!]!) {
    ModifyResponse(input: $input) {
      res
      error
      id
      sort
      parentID
      title
      instructions
      notes
      responseType
      questionText
      response
      responseComments
      responseStatus
      artifactRequired
      artifactRequired_yes
      artifactRequired_no
      owner
      parent {
        res
        error
        id
        sort
        parentID
        templateRoot
        title
        assessmentStartDate
        assessmentEndDate
        assessor
        scope
        hours
        templateTitle
        name
        hsrID
        description
        questionText
        surveyState {
          unanswered
          noGapIdentified
          deferPendingReassignment
          deferPendingResponse
          clarify
          yesPendingRequestedArtifacts
          yesPendingArtifacts
          yesCompliant
          notApplicable
        }
        size
        parent {
          res
          error
          id
          sort
          parentID
          templateRoot
          title
          assessmentStartDate
          assessmentEndDate
          assessor
          scope
          hours
          templateTitle
          name
          hsrID
          description
          questionText
          surveyState {
            unanswered
            noGapIdentified
            deferPendingReassignment
            deferPendingResponse
            clarify
            yesPendingRequestedArtifacts
            yesPendingArtifacts
            yesCompliant
            notApplicable
          }
          size
          parent {
            res
            error
            id
            sort
            parentID
            templateRoot
            title
            assessmentStartDate
            assessmentEndDate
            assessor
            scope
            hours
            templateTitle
            name
            hsrID
            description
            questionText
            surveyState {
              unanswered
              noGapIdentified
              deferPendingReassignment
              deferPendingResponse
              clarify
              yesPendingRequestedArtifacts
              yesPendingArtifacts
              yesCompliant
              notApplicable
            }
            size
            parent {
              res
              error
              id
              sort
              parentID
              templateRoot
              title
              assessmentStartDate
              assessmentEndDate
              assessor
              scope
              hours
              templateTitle
              name
              hsrID
              description
              questionText
              surveyState {
                unanswered
                noGapIdentified
                deferPendingReassignment
                deferPendingResponse
                clarify
                yesPendingRequestedArtifacts
                yesPendingArtifacts
                yesCompliant
                notApplicable
              }
              size
              parent {
                res
                error
                id
                sort
                parentID
                templateRoot
                title
                assessmentStartDate
                assessmentEndDate
                assessor
                scope
                hours
                templateTitle
                name
                hsrID
                description
                questionText
                surveyState {
                  unanswered
                  noGapIdentified
                  deferPendingReassignment
                  deferPendingResponse
                  clarify
                  yesPendingRequestedArtifacts
                  yesPendingArtifacts
                  yesCompliant
                  notApplicable
                }
                size
                parent {
                  res
                  error
                  id
                  sort
                  parentID
                  templateRoot
                  title
                  assessmentStartDate
                  assessmentEndDate
                  assessor
                  scope
                  hours
                  templateTitle
                  name
                  hsrID
                  description
                  questionText
                  surveyState {
                    unanswered
                    noGapIdentified
                    deferPendingReassignment
                    deferPendingResponse
                    clarify
                    yesPendingRequestedArtifacts
                    yesPendingArtifacts
                    yesCompliant
                    notApplicable
                  }
                  size
                  parent {
                    res
                    error
                    id
                    sort
                    parentID
                    templateRoot
                    title
                    assessmentStartDate
                    assessmentEndDate
                    assessor
                    scope
                    hours
                    templateTitle
                    name
                    hsrID
                    description
                    questionText
                    size
                    groupID
                    archived
                  }
                  groupID
                  archived
                  assessmentAssessor {
                    res
                    error
                    id
                    sort
                    email
                    username
                    firstName
                    lastName
                    inactive
                    lastSeen
                    lastLoggedIn
                  }
                }
                groupID
                archived
                assessmentAssessor {
                  res
                  error
                  id
                  sort
                  email
                  username
                  firstName
                  lastName
                  inactive
                  lastSeen
                  lastLoggedIn
                }
              }
              groupID
              archived
              assessmentAssessor {
                res
                error
                id
                sort
                email
                username
                firstName
                lastName
                inactive
                lastSeen
                lastLoggedIn
              }
            }
            groupID
            archived
            assessmentAssessor {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
          }
          groupID
          archived
          assessmentAssessor {
            res
            error
            id
            sort
            email
            username
            firstName
            lastName
            inactive
            lastSeen
            lastLoggedIn
          }
        }
        groupID
        archived
        assessmentAssessor {
          res
          error
          id
          sort
          email
          username
          firstName
          lastName
          inactive
          lastSeen
          lastLoggedIn
        }
      }
      groupID
      responseRelatedItems {
        items {
          id
          sort
          target
          targetType
          item {
            id
            sort
            groupID
            owner
            uuid
            title
            dateClosed
            dueDate
            status
            priority
            severity
            responseStatus
            archived
            createdAt
            lastModified
            compositeIDYear
            compositeID
            displayID
            relatedItemsOwner {
              res
              error
              id
              sort
              email
              username
              firstName
              lastName
              inactive
              lastSeen
              lastLoggedIn
            }
            relatedItemsGroup {
              res
              error
              id
              sort
              organizationID
              title
              shortTitle
              riskThreshold {
                financial {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
                privacy {
                  appetitePercent
                  appetiteAbsolute
                  capacityPercent
                  capacityAbsolute
                }
              }
              organization {
                res
                error
                id
                sort
                name
                organizationRelationships
                demographics
              }
              groupEmailFooter
            }
            fileName
            address
          }
        }
        nextToken
      }
    }
  }
`;
export const createTemplateQuestion = /* GraphQL */ `
  mutation CreateTemplateQuestion($input: [CreateTemplateQuestionInput!]!) {
    CreateTemplateQuestion(input: $input)
  }
`;
export const createControls = /* GraphQL */ `
  mutation CreateControls($input: [CreateControlsInput!]!) {
    CreateControls(input: $input)
  }
`;
export const addScenarioToPortfolio = /* GraphQL */ `
  mutation AddScenarioToPortfolio($input: [AddScenarioToPortfolioInput!]!) {
    AddScenarioToPortfolio(input: $input)
  }
`;
export const removeScenarioFromPortfolio = /* GraphQL */ `
  mutation RemoveScenarioFromPortfolio(
    $input: [RemoveScenarioFromPortfolioInput!]!
  ) {
    RemoveScenarioFromPortfolio(input: $input)
  }
`;
export const cloneAuditPlan = /* GraphQL */ `
  mutation CloneAuditPlan($input: [CloneAuditPlanInput!]!) {
    CloneAuditPlan(input: $input)
  }
`;
export const auditPlanTemplate = /* GraphQL */ `
  mutation AuditPlanTemplate($input: [AuditPlanTemplateInput!]!) {
    AuditPlanTemplate(input: $input)
  }
`;
export const createAssessmentAssets = /* GraphQL */ `
  mutation CreateAssessmentAssets($input: [CreateAssessmentAssetsInput!]!) {
    CreateAssessmentAssets(input: $input)
  }
`;
export const modifyAssessmentAssets = /* GraphQL */ `
  mutation ModifyAssessmentAssets($input: [ModifyAssessmentAssetsInput!]!) {
    ModifyAssessmentAssets(input: $input)
  }
`;
export const createThreatAssessment = /* GraphQL */ `
  mutation CreateThreatAssessment($input: [CreateThreatAssessmentInput!]!) {
    CreateThreatAssessment(input: $input)
  }
`;
export const removeThreatAssessment = /* GraphQL */ `
  mutation RemoveThreatAssessment($input: [RemoveThreatAssessmentInput!]!) {
    RemoveThreatAssessment(input: $input)
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment($input: CreateCommentInput!) {
    CreateComment(input: $input)
  }
`;
export const linkItems = /* GraphQL */ `
  mutation LinkItems($input: [LinkItemsInput!]!) {
    LinkItems(input: $input)
  }
`;
export const unlinkItems = /* GraphQL */ `
  mutation UnlinkItems($input: [UnlinkItemsInput!]!) {
    UnlinkItems(input: $input)
  }
`;
export const setUserRASCIRoleOnItem = /* GraphQL */ `
  mutation SetUserRASCIRoleOnItem($input: [SetUserRASCIRoleOnItemInput!]!) {
    SetUserRASCIRoleOnItem(input: $input)
  }
`;
export const removeUserRASCIRoleFromItem = /* GraphQL */ `
  mutation RemoveUserRASCIRoleFromItem(
    $input: [RemoveUserRASCIRoleFromItemInput!]!
  ) {
    RemoveUserRASCIRoleFromItem(input: $input)
  }
`;
export const setUserApproverItem = /* GraphQL */ `
  mutation SetUserApproverItem($input: [SetUserApproverItemInput!]!) {
    SetUserApproverItem(input: $input)
  }
`;
export const removeUserApproverFromItem = /* GraphQL */ `
  mutation RemoveUserApproverFromItem(
    $input: [RemoveUserApproverFromItemInput!]!
  ) {
    RemoveUserApproverFromItem(input: $input)
  }
`;
export const setItemStatus = /* GraphQL */ `
  mutation SetItemStatus($input: [SetItemStatusInput!]!) {
    SetItemStatus(input: $input)
  }
`;
export const createAnalysis = /* GraphQL */ `
  mutation CreateAnalysis($id: ID, $groupID: ID!, $name: String!) {
    CreateAnalysis(id: $id, groupID: $groupID, name: $name)
  }
`;
export const setAnalysis = /* GraphQL */ `
  mutation SetAnalysis($input: SetAnalysisInput!) {
    SetAnalysis(input: $input)
  }
`;
export const setFrequency = /* GraphQL */ `
  mutation SetFrequency(
    $id: ID!
    $vulnerability: Float
    $vulnerabilityAssumptions: String
    $LEF: PertConfig
    $LEFAssumptions: String
    $TEF: PertConfig
    $TEFAssumptions: String
    $CEF: PertConfig
    $CEFAssumptions: String
    $POA: Float
    $POAAssumptions: String
    $sips: [Float]
    $currentLayer: FrequencyLayer!
    $seed: Int
  ) {
    SetFrequency(
      id: $id
      vulnerability: $vulnerability
      vulnerabilityAssumptions: $vulnerabilityAssumptions
      LEF: $LEF
      LEFAssumptions: $LEFAssumptions
      TEF: $TEF
      TEFAssumptions: $TEFAssumptions
      CEF: $CEF
      CEFAssumptions: $CEFAssumptions
      POA: $POA
      POAAssumptions: $POAAssumptions
      sips: $sips
      currentLayer: $currentLayer
      seed: $seed
    )
  }
`;
export const setMagnitude = /* GraphQL */ `
  mutation SetMagnitude(
    $id: ID!
    $magnitudeType: String!
    $primaryMagnitude: PertConfig!
    $secondaryFrequency: Float
    $secondaryMagnitude: PertConfig
    $assumptions: String
    $secondaryAssumptions: String
  ) {
    SetMagnitude(
      id: $id
      magnitudeType: $magnitudeType
      primaryMagnitude: $primaryMagnitude
      secondaryFrequency: $secondaryFrequency
      secondaryMagnitude: $secondaryMagnitude
      assumptions: $assumptions
      secondaryAssumptions: $secondaryAssumptions
    )
  }
`;
export const runAnalysis = /* GraphQL */ `
  mutation RunAnalysis($id: ID!, $seed: Int) {
    RunAnalysis(id: $id, seed: $seed)
  }
`;
export const modifyAnalysis = /* GraphQL */ `
  mutation ModifyAnalysis($id: ID, $name: String) {
    ModifyAnalysis(id: $id, name: $name)
  }
`;
