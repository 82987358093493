import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

const RiskAssessmentList = React.lazy(
  () =>
    import(
      "Modules/Customers/HIPAARiskAssessment/RiskAssessment/RiskAssessmentList"
    ) as any
);
const RiskAssessmentPage = React.lazy(
  () =>
    import(
      "Modules/Customers/HIPAARiskAssessment/RiskAssessment/RiskAssessmentPage"
    ) as any
);

const HIPAARiskAssessmentRoutes = () => {
  return (
    <Switch>
      {/* Old Routes Start */}
      <Route exact path="/HIPAARiskAssessment/assets">
        <Redirect to="/assessments/assetManagement/asset" />
      </Route>
      <Route exact path="/HIPAARiskAssessment/assets/createAsset">
        <Redirect to="/assessments/assetManagement/asset/createAsset" />
      </Route>
      <Route exact path="/HIPAARiskAssessment/category">
        <Redirect to="/assessments/assetManagement/category" />
      </Route>
      <Route exact path="/HIPAARiskAssessment/support">
        <Redirect to="/assessments/assetManagement/support" />
      </Route>
      <Route exact path="/HIPAARiskAssessment/location">
        <Redirect to="/assessments/assetManagement/location" />
      </Route>
      <Route exact path="/HIPAARiskAssessment/threats">
        <Redirect to="/assessments/assetManagement/threat" />
      </Route>
      <Route exact path="/HIPAARiskAssessment/controls">
        <Redirect to="/systemManagement/systemLists/controls" />
      </Route>
      <Route
        exact
        path="/HIPAARiskAssessment/assets/:id"
        render={(props) => (
          <Redirect
            to={`/assessments/assetManagement/asset/${props.match.params.id}`}
          />
        )}
      />
      {/* Old Routes End */}
      <Route
        exact
        path="/assessments/HIPAARiskAssessment/:id/:tab/:relatedItems?"
      >
        <RiskAssessmentPage />
      </Route>
      <Redirect
        from="/assessments/HIPAARiskAssessment/:id"
        to="/assessments/HIPAARiskAssessment/:id/details"
      />
      <Route path="/assessments/HIPAARiskAssessment">
        <RiskAssessmentList />
      </Route>
    </Switch>
  );
};

export default HIPAARiskAssessmentRoutes;
