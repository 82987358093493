//Configure frequency input object
import axios from "axios";

const baseURL = "https://stats.decipherrisk.com";

export type SummaryTypeRaw = {
  min: Array<number>;
  max: Array<number>;
  mean: Array<number>;
  mode: Array<number>;
  median: Array<number>;
};

export type SummaryType = {
  min: number;
  max: number;
  mean: number;
  mode: number;
  median: number;
};

export const unwrapSummary = (raw: SummaryTypeRaw): SummaryType => ({
  min: raw.min[0],
  max: raw.max[0],
  mean: raw.mean[0],
  mode: raw.mode[0],
  median: raw.median[0],
});

export type FairOutputType = {
  primaryFrequencyStats: SummaryType;
  primaryMagnitudeStats: SummaryType;
  secondaryFrequencyStats: SummaryType;
  secondaryMagnitudeStats: SummaryType;
  totalStats: SummaryType;
  expectedLoss: Array<number>;
};

export const unwrapAllSummaries = ({ expectedLoss, ...stats }) => ({
  expectedLoss,
  ...stats.map(unwrapSummary),
});

export type BinomialConfigType = {
  probability: number;
};

export type PertConfigType = {
  min: number;
  max: number;
  mode: number;
  shape?: number;
};

export type DistributionOrOther<T> =
  | {
    pertConfig: PertConfigType;
  }
  | {
    sips: Array<number>;
  }
  | T;

export type FrequencyInput = DistributionOrOther<{
  threatEvents: DistributionOrOther<{
    contactFrequency: DistributionOrOther<PertConfigType>;
    probabilityOfAction: BinomialConfigType;
  }>;
  vulnerability: BinomialConfigType;
}>;

export type MagnitudeInput = DistributionOrOther<{
  primaryMagnitude: { pertConfig: PertConfigType };
  secondaryLoss: {
    secondaryFrequency: BinomialConfigType;
    secondaryMagnitude: { pertConfig: PertConfigType };
  };
}>;

export type FairFrequencyInputs = {
  config: FrequencyInput;
  seed?: number;
};

export type FairFullInputs = {
  frequency: FrequencyInput;
  magnitude: MagnitudeInput;
  seed?: number;
  ThreatsDetails?: any
};

export const createPertConfig = (values: PertConfigType) => {
  return { ...values };
};

//Call API /Frequency endpoint

export function test() {
  return 1;
}

export function runFairTaxonomy(config: FairFullInputs) {
  const requestParams = config;
  return axios
    .post(`${baseURL}/fairTaxonomy`, requestParams)
    .then(
      (response): FairOutputType => {
        let data = {
          ...response.data,
          threatID: requestParams?.ThreatsDetails?.threatID
        }
        return response.status === 200 ? data : response.statusText;
      }
    );
}

export function runFairFrequency(frequencyConfig: FairFrequencyInputs) {
  const requestParams = frequencyConfig;
  return axios
    .post(`${baseURL}/fairFrequency`, requestParams)
    .then((response) => {
      return response.status === 200 ? response.data : response.statusText;
    });
}

interface PatientHarmInputs {
  threatEventFrequency_min: number;
  threatEventFrequency_mode: number;
  threatEventFrequency_max: number;

  impactProbability: number;

  patientEncounterFrequency_min: number;
  patientEncounterFrequency_mode: number;
  patientEncounterFrequency_max: number;

  probabilityOfHarm: number;
}

export function runPatientHarm(harmConfig: PatientHarmInputs) {
  const requestParams = harmConfig;
  return axios
    .post(`${baseURL}/patientHarm`, requestParams)
    .then((response) => {
      return response.status === 200 ? response.data : response.statusText;
    });
}

export const breachFrequencies = [
  { id: "veryrare", name: "Once every 10-50 years" },
  { id: "rare", name: "Once every 5-20 years" },
  { id: "common", name: "Once every 1-5 years" },
  { id: "often", name: "More than 1 time per year" },
  { id: "veryoften", name: "More than 10 times per year" },
];

export const breachMagnitudes = [
  { id: "none", name: "No loss" },
  { id: "verylow", name: "Between 1-10 records" },
  { id: "low", name: "Between 10-100 records" },
  { id: "medium", name: "Between 100-1k records" },
  { id: "high", name: "More than 1k records" },
  { id: "veryhigh", name: "More than 100k records" },
];

interface BreachInputs {
  frequency: "veryrare" | "rare" | "common" | "often" | "veryoften";
  magnitude: "none" | "verylow" | "low" | "medium" | "high" | "veryhigh";
}

export function runBreach(breachConfig: BreachInputs) {
  const requestParams = breachConfig;
  return axios
    .post(`${baseURL}/privacyLoss`, requestParams)
    .then((response) => {
      return response.status === 200 ? response.data : response.statusText;
    });
}