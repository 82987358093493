import React from "react";
import { Switch, Route, Redirect } from "react-router";
import AssetManagementRoutes from "../AssetManagement/AssetManagement";
import { CreateAssets } from "../HIPAARiskAssessment/Assets";
import { EditAssets } from "../HIPAARiskAssessment/Assets/EditAssets";
import HIPAARiskAssessmentRoutes from "../HIPAARiskAssessment/HIPAARiskAssessmentRoutes";
import HIPAAGapAssessmentRoutes from "./HIPAAGapAssessmentRoutes";

const AssessmentRoutes = () => {
  return (
    <Switch>
      {/* Old Assessment Routes Start */}
      <Route
        exact
        path="/assessments/response/:id"
        render={(props) => (
          <Redirect
            to={`/assessments/HIPAAGapAssessment/response/${props.match.params.id}`}
          />
        )}
      />
      {/* Old Assessment Routes End */}
      <Route path="/assessments/HIPAAGapAssessment">
        <HIPAAGapAssessmentRoutes />
      </Route>
      <Route path="/assessments/HIPAARiskAssessment">
        <HIPAARiskAssessmentRoutes />
      </Route>
      <Route path="/assessments/assetManagement/asset/createAsset">
        <CreateAssets />
      </Route>
      <Route exact path="/assessments/assetManagement/asset/:id">
        <EditAssets />
      </Route>
      <Route path="/assessments/assetManagement">
        <AssetManagementRoutes />
      </Route>
      <Route
        exact
        path="/assessments/:id"
        render={(props) => (
          <Redirect
            to={`/assessments/HIPAAGapAssessment/${props.match.params.id}`}
          />
        )}
      />
      <Redirect to="/assessments/HIPAAGapAssessment" />
    </Switch>
  );
};

export default AssessmentRoutes;
