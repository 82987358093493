// @ts-nocheck
import React, { useCallback } from "react";
import { Col, Card, Row } from "react-bootstrap";

import { ErrorBoundary } from "App";
import { useTabbedTable } from "common/TabbedTable";
import { useGetSourcesInGroup } from "Modules/Customers/Issues/IssuePage/Sources";
import { gql, useMutation } from "@apollo/client";
import { modifySource } from "graphql/mutations";
import { ModifySourceMutation, ModifySourceMutationVariables } from "API";
import { useSourceSchema } from "../sourceSchema";
import useSetTitle from "utils/useSetTitle";
import { CreateSourceButton } from "../CreateSourceButton";
import { sleep } from "utils/useSleep";
import Loader from "common/Loader";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { usePermissions } from "common/Permissions";

export const SourceList = () => {
  useSetTitle("Sources");

  const { sources, refetch, loading } = useGetSourcesInGroup();
  const { groupRole } = usePermissions();
  const [triggerUpdateSource] = useMutation<
    ModifySourceMutation,
    ModifySourceMutationVariables
  >(gql(modifySource), {
    onCompleted: (response) => {
      const isArchived = response?.ModifySource?.[0]?.archived;
      if (isArchived === true) {
        ToastifyQueue(`Source Archived Successfully`, "success");
      } else if (isArchived === false) {
        ToastifyQueue(`Source Unarchived Successfully`, "success");
      }
      sleep(500).then(refetch);
    },
  });

  const archiveSource = useCallback(
    (id: string, archived = true) => {
      triggerUpdateSource({
        variables: {
          input: {
            id,
            archived,
          },
        },
      });
    },
    [triggerUpdateSource]
  );

  const tableSchema = useSourceSchema({ archiveSource, refetch, userRole: groupRole });

  const { TableUI, ColumnSortFilterUI } = useTabbedTable(
    "sourceColumnSortOrder",
    sources,
    tableSchema,
    null,
    {
      tabChoices: ["Active", "Archived"],
    }
  );

  return (
    (loading && !(sources.length > 0) && <Loader />) || (
      <>
      {
        groupRole !== 'read'
        && <Row className="dashboard-header">
          <Col xs="12">
            <span className="float-right">
              <CreateSourceButton
                onCreate={(source) => {
                  refetch();
                }}
              />
            </span>
          </Col>
           </Row>
      }
        
        <Row>
          <Col>
            <Card style={{ maxHeight: "100%" }}>
              <Card.Header>
                {ColumnSortFilterUI}
                <span className="h5">All Sources</span>
              </Card.Header>
              <ErrorBoundary>
                <Card.Body>{TableUI}</Card.Body>
              </ErrorBoundary>
            </Card>
          </Col>
        </Row>
      </>
    )
  );
};
