import { useMutation, gql } from "@apollo/client";
import {
  CreateGroupInput,
  CreateGroupMutation,
  CreateGroupMutationVariables,
  GetGroupsInOrganizationQuery,
  GetGroupsInOrganizationQueryVariables,
} from "API";
import { HGForm, HGFormControl, HGFormSubmit } from "common/HGForm";
import { usePermissions } from "common/Permissions";
import { createGroup } from "graphql/mutations";
import { getGroupsInOrganization } from "graphql/queries";
import React, { useState, useMemo, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { cancelPopupMessage } from "utils/globalVariables";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import * as yup from "yup";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { HGInputBox } from "common/HGForm/Inputs";

const CreateGroup: React.FC<{ refetch }> = ({ refetch }) => {
  const { organization, groups } = usePermissions();
  const [show, setShow] = useState(false);

  const createGroupSchema = useMemo(() => {
    return yup.object<Partial<CreateGroupInput>>({
      title: yup
        .string()
        .default("")
        .required()
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test(
          "check-duplication",
          "Department title already exists!",
          (value) => {
            const index = groups
              .map((item) => item.title.trim())
              .findIndex((element) => {
                return element.toLowerCase() === value?.toLowerCase().trim();
              });
            return index === -1;
          }
        ),
      shortTitle: yup
        .string()
        .max(3, "Department Name Abbreviation can be at most 3 characters")
        .label("Department Name Abbreviation"),
    });
  }, [groups]);

  const [runCreateGroup] = useMutation<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >(gql(createGroup));
  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShow(false);
    }
  }
  return (
    <>
      <Button
        variant="outline-primary"
        onClick={() => {
          setShow(true);
        }}
      >
        Create Department
      </Button>
      <Modal
        show={show}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          onSubmit={async ({ title, shortTitle }) => {
            return runCreateGroup({
              variables: {
                input: [
                  {
                    organizationID: organization.id,
                    title,
                    shortTitle,
                  },
                ],
              },
            }).then(async (response) => {
              await refetch();
              return setShow(false);
            });
          }}
          resolver={createGroupSchema}
        >
          <Modal.Header>Create Department</Modal.Header>
          <Modal.Body>
            <HGInputBox name="title" label="Department Name" />
            <HGInputBox
              name="shortTitle"
              label="Department Name Abbreviation"
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn cancel-btn"
              style={{ marginRight: "5px" }}
              data-dismiss="modal"
              onClick={() => {
                Confirmation();
              }}
            >
              Cancel
            </button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};

const OrganizationPermissionsViewGroups: React.FC = () => {
  const { organization, hasOrganization, refetchGroups } = usePermissions();

  const { data: groupList, refetch } = useProtectedQuery<
    GetGroupsInOrganizationQuery,
    GetGroupsInOrganizationQueryVariables
  >(
    gql(getGroupsInOrganization),
    {
      variables: {
        id: organization.id,
      },
    },
    hasOrganization
  );
  const groupListingData =
    groupList?.getGroupsInOrganization?.items
      ?.map((item) => item?.group)
      .sort((a, b) => a?.title?.localeCompare(b?.title ?? "") ?? 0)
      .map((item) => {
        return {
          Department: item?.title ? item?.title : "Admin",
          ID: item?.id,
        };
      }) || [];

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      sortable: true,
    };
  }, []);
  const customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };
  const [groupListColumnDefs, _setGroupListColumnDefs] = useState([
    {
      field: "Department",
      unSortIcon: true,
      comparator: customComparator,
      width: 1000,
    },
  ]);

  return (
    <Card>
      <Card.Header>
        <span className="float-right">
          <CreateGroup refetch={() => (refetch(), refetchGroups())} />
        </span>
        Departments
      </Card.Header>
      <Card.Body>
        <div
          className="ag-theme-alpine"
          style={{
            height: "500px",
            width: "100%",
          }}
        >
          <AgGridReact
            overlayNoRowsTemplate="No data found"
            modules={AllCommunityModules}
            rowData={groupListingData}
            columnDefs={groupListColumnDefs}
            defaultColDef={defaultColDef}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default OrganizationPermissionsViewGroups;
