// @flow
import {
  faArrowRight,
  faCheck,
  faCircle,
  faEnvelope,
  faFile,
  faMinus,
  faQuestion,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./StatusDisplay.scss";

interface StatusProps {
  status?: string | null;
}

export const getClass = (status: string) => {
  return status.replace(/,/g, "").replace(/ /g, "-").toLowerCase();
};

export const getStatusProps = (className?: string | null) => {
  switch (className) {
    case "yesCompliant":
      return { text: "Yes, Compliant", icon: faCheck };
    case "yesPendingArtifacts":
      return { text: "Yes, Pending Artifacts", icon: faFile };
    case "yesPendingRequestedArtifacts":
      return { text: "Yes, Pending Requested Artifacts", icon: faEnvelope };
    case "clarify":
      return { text: "Clarify", icon: faQuestion };
    case "deferPendingResponse":
      return { text: "Defer, Pending Response", icon: faArrowRight, className };
    case "deferPendingReassignment":
      return { text: "Defer Pending Reassignment", icon: faQuestion };
    case "noGapIdentified":
      return { text: "No, Gap Identified", icon: faTimes };
    case "notApplicable":
      return { text: "Not Applicable", icon: faTimes };
    default:
      return { text: "Unanswered", icon: faMinus };
  }
};

export const getStatusClean = (d) => {
  let name = d.name
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, function (str: string): string {
      return str.toUpperCase();
    });
  if (
    name.indexOf("Not ") < 0 &&
    name.indexOf("Unanswer") < 0 &&
    name !== "Clarify"
  ) {
    name = `${name.slice(0, name.indexOf(" "))}, ${name.slice(
      name.indexOf(" ")
    )}`;
  }
  return name;
};

export default (props: StatusProps) => {
  const { text, icon } = getStatusProps(props.status);

  return (
    <div className="statusDisplay">
      <span className="fa-layers fa-fw fa-2x" style={{ marginRight: "5px" }}>
        <FontAwesomeIcon
          icon={faCircle}
          className={`icon-back ${props.status || "unanswered"}`}
        />

        <FontAwesomeIcon
          icon={icon}
          className="iconFront"
          transform="shrink-4"
        />
      </span>
      <span className="statusText">{text}</span>
    </div>
  );
};
