import React from "react";

import { Link } from "react-router-dom";

import { gql, useMutation, ApolloError } from "@apollo/client";
import { modifyUserMeta } from "graphql/mutations";
import { ModifyUserMetaMutation, ModifyUserMetaMutationVariables } from "API";
import { usePermissions } from "common/Permissions";
import { getCompositeId } from "utils/dataFormatters";
import { sleep } from "utils/useSleep";
import ToastifyQueue from "common/Overlays/ToastifyQueue";

export const getEntityType = ({
  name,
  value,
  baseUrl,
  attachMethod,
  targetType,
  fallback,
  CreateItemButton,
  RelatedItemType,
  getItemsInSelectedGroup,
}: {
  name: string;
  value: string;
  baseUrl: string;
  attachMethod?;
  targetType?: string;
  fallback?: string;
  CreateItemButton?: React.FC<any>;
  RelatedItemType: React.FC<any>;
  getItemsInSelectedGroup?: (id: any) => Promise<any[]>;
}) => {
  const defaultLeftsidePreferences = {
    Audits: "true",
    Assets: "true",
    Assessments: "true",
    Issues: "true",
    Risk: "true",
    ActionPlans: "true",
    Artifacts: "true",
  };

  const { user, userMeta, refetchUserMeta, groups } = usePermissions();
  const preLeftSide = userMeta?.leftsidePreferences
    ? JSON.parse(userMeta?.leftsidePreferences)
    : defaultLeftsidePreferences;
  const [runModifyUserPrefs] = useMutation<
    ModifyUserMetaMutation,
    ModifyUserMetaMutationVariables
  >(gql(modifyUserMeta), {
    onCompleted: () => {
      ToastifyQueue("Left Side Preferences has been changed.", "success");
      sleep(500).then(refetchUserMeta);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });
  return {
    name,
    value,

    attachedFilter: (d) => {
      return d.targetType === (fallback || baseUrl);
    },
    filter: (d) => {
      return d.__typename === targetType ?? value;
    },

    field: (d) => {
      if (d?.__typename === "Controls") {
        return d?.uuid || null;
      }

      return d.id || null;
    },
    onAttach: attachMethod,
    format: (d) => {
      const compositeID = getCompositeId(d?.item);
      if (d?.type === "Controls") {
        return (
          <span style={{ cursor: "pointer" }} className="form-check-label">
            {d.title} {compositeID && `(${compositeID})`}
          </span>
        );
      }
      if (groups.filter((i) => i?.groupID === d?.item?.groupID).length > 0) {
        return (
          <span>
            <Link
              to={`/${baseUrl}/${d.id}`}
              onClick={async () => {
                Object.keys(preLeftSide).forEach((key) => {
                  if (
                    key.toLowerCase() === baseUrl.split("/")[0].toLowerCase()
                  ) {
                    preLeftSide[key] = "true";
                  }
                });
                return runModifyUserPrefs({
                  variables: {
                    input: [
                      {
                        id: user.id,
                        leftsidePreferences: JSON.stringify(preLeftSide),
                      },
                    ],
                  },
                });
              }}
            >
              <span style={{ cursor: "pointer" }} className="form-check-label">
                {d.title} {compositeID && `(${compositeID})`}
              </span>
            </Link>
          </span>
        );
      }
      return (
        <span>
          <span className="form-check-label">
            {d.title} {compositeID && `(${compositeID})`}
          </span>
        </span>
      );
    },
    CreateItemButton,
    RelatedItemType,
    getItemsInSelectedGroup,
  };
};
