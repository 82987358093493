import React, { ReactElement } from "react";
import ReactDOMServer from "react-dom/server";
import { svgAsPngUri, saveSvgAsPng } from "save-svg-as-png";

export const graphToHTML = (element: ReactElement): HTMLElement => {
  const svgString = ReactDOMServer.renderToStaticMarkup(element);
  const doc = new DOMParser().parseFromString(svgString, "text/html");
  return doc.body.firstChild as unknown as HTMLElement;
};

export const graphToPngUri = async (item: ReactElement) => {
  const htmlOut = graphToHTML(item);
  return svgAsPngUri(htmlOut)
    .then((data) => {
      return data;
    })
    .catch((...props) => {
      console.error(...props);
    });
};

export const graphToPng = async (item: ReactElement, fileName: string) => {
  const htmlOut = graphToHTML(item);
  return saveSvgAsPng(htmlOut, fileName);
};
