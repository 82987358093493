import React from "react";

export interface RowInterface {
  children: any;
  onClick?: () => void;
  footChild?: any;
  expanded?: boolean;
  index?: number;
  rowFormat?: Object;
}
export default (props: RowInterface) => {
  let showChild = props.footChild !== undefined && props.expanded;
  let className =
    props.index != null && props.index % 2 === 0
      ? "footable-even"
      : "footable-odd";
  return (
    <React.Fragment>
      <tr
        className={className}
        onClick={props.onClick}
        style={Object.assign(
          {},
          showChild ? { backgroundColor: "#eeeeee" } : undefined,
          props.onClick ? { cursor: "pointer" } : undefined,
          props.rowFormat ?? {}
        )}
      >
        {props.children}
      </tr>
      {showChild ? (
        <tr>
          <td
            colSpan={props.children.length}
            style={{ backgroundColor: "#eeeeee" }}
          >
            {/* $FlowFixMe */}
            {/* <Common.Containers.IBox.IBox> */}
            {props.footChild}
            {/* </Common.Containers.IBox.IBox> */}
          </td>
        </tr>
      ) : null}
    </React.Fragment>
  );
};
