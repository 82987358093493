import * as R from "ramda";

export const arrayToExceedance = (data: any[]) => {
  const reduceIndexed = R.addIndex<unknown, Array<any>>(R.reduce);
  const getEmptyBins = R.times(() => 0);
  const resolution = 100;

  return data.map(({ data }) => {
    const max = Math.max(...data);

    const binData = R.reduce(
      /* eslint-disable-next-line */
      (a, b: number) => ((a[Math.floor((b / max) * resolution)] += 1), a),
      getEmptyBins(resolution)
    );
    const binsToLineData = reduceIndexed(
      (a, b, index) => [...a, { x: index, y: b }],
      []
    );

    const rescaleLineData = (a) => ({
      x: (a.x * max) / resolution,
      y: (a.y * 100 * resolution) / (data.length * data.length),
    });
    const sumCumulativeProbability = (input) =>
      R.reduce<{ x: number; y: number }, { data; sum }>(
        (a, b) => ({
          data: [...a.data, { x: b.x, y: 100 - a.sum }],
          sum: a.sum + (b.y * data.length) / resolution,
        }),
        { data: [], sum: 0 }
      )(input).data;

    const binnedData = binData(data);
    const unscaledlineData = binsToLineData(binnedData).filter(
      (a) => !isNaN(a.y)
    );

    const scaledLineData = unscaledlineData.map(rescaleLineData);

    const cumulativeProbability = sumCumulativeProbability(scaledLineData);

    return [
      ...cumulativeProbability.filter((a) => !isNaN(a.y)),
      { x: max, y: 0 },
    ];
  });
};
