import React, { useMemo } from "react";
import { Breadcrumb, Button, Row, Col, Card } from "react-bootstrap";
import { usePermissions } from "common/Permissions";
import { HGForm, HGFormSubmit } from "common/HGForm";
import { useMutation, ApolloError } from "@apollo/client";
import { createAssets } from "graphql/mutations";
import gql from "graphql-tag";
import * as yup from "yup";
import {
  CreateAssetsInput,
  CreateAssetsMutation,
  CreateAssetsMutationVariables,
} from "API";
import { useHistory } from "react-router-dom";
import AssetsInputs from "./AssetsInputs";
import { cancelPopupMessage } from "utils/globalVariables";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { useGetAssetsInGroup } from "utils/connectors/assetsConnectors";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";

export const CreateAssets: React.FC<{
  onComplete?: Function;
}> = ({ onComplete }) => {
  const history = useHistory();
  const { group, userId } = usePermissions();
  const { assets } = useGetAssetsInGroup();

  const [makeAssets] = useMutation<
    CreateAssetsMutation,
    CreateAssetsMutationVariables
  >(gql(createAssets), {
    onCompleted: (response) => {
      ToastifyQueue("Asset Created Successfully.", "success");
      history.push("/assessments/assetManagement/asset");
      onComplete?.(response?.CreateAssets);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });

  const createAssetSchema = useMemo(() => {
    return yup.object<Partial<CreateAssetsInput>>({
      title: yup
        .string()
        .default("")
        .required()
        .label("Name")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test("check-duplication", "Asset title already exists!", (value) => {
          if (!value) return true;
          return !isUniqueTitle(assets, value);
        }),
      qty: yup
        .number()
        .label("Qty")
        .default(0)
        .typeError("Please enter a number.")
        .min(0, "Qty must be a positive number"),
      owner: yup.string().label("Owner").default(userId).required(),
    });
  }, [userId, assets]);

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      history.push("/assessments/assetManagement/asset");
    }
  }

  return (
    <HGForm
      onSubmit={async (input) => {
        return makeAssets({
          variables: { input: { ...input, groupID: group.id } },
        });
      }}
      resolver={createAssetSchema}
    >
      <Row className="dashboard-header">
        <Col xs="12" className="pr-0">
          <Breadcrumb>
            <Breadcrumb.Item>Assets</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs="6" className="pr-0">
          <h3>Create Assets</h3>
        </Col>
      </Row>
      <Row className="px-2">
        <Col xs="12">
          <Card>
            <Card.Body>
              <Row>
                <AssetsInputs />
              </Row>
            </Card.Body>
            <Card.Footer className="text-right w-100">
              <Button
                variant="light"
                className="mr-2 cancel-btn"
                onClick={() => {
                  Confirmation();
                }}
              >
                Cancel
              </Button>
              <HGFormSubmit />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </HGForm>
  );
};
