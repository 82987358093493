import { usePermissions } from "common/Permissions";
import React, { useEffect, useMemo, useState } from "react";
import { Button, InputGroup, Modal } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { createAssetsGroup } from "graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { HGForm, HGFormControl, HGFormSubmit } from "common/HGForm";
import {
  CreateAssetsGroupInput,
  CreateAssetsGroupMutationVariables,
} from "API";
import * as yup from "yup";
import { useGetAssetsGroupsInGroup } from "utils/connectors/assetsGroupConnectors";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { HGSelect, HGInputBox } from "common/HGForm/Inputs";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";

export const CreateAssetsGroup = (props) => {
  const { group } = usePermissions();
  const { assetsGroups } = useGetAssetsGroupsInGroup();
  const [showCreateAssetsGroup, setShowCreateAssetsGroup] = useState(false);
  const [mutationCreateAssetsGroup] = useMutation(gql(createAssetsGroup));

  const createAssetsGroupSchema = useMemo(() => {
    return yup.object<Partial<CreateAssetsGroupInput>>({
      title: yup
        .string()
        .default("")
        .required()
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test(
          "check-duplication",
          "Asset group title already exists!",
          (value) => {
            if (!value) return true;
            return !isUniqueTitle(assetsGroups, value);
          }
        ),
    });
  }, [assetsGroups]);

  return (
    <>
      {props.iCreate ? (
        <Button
          onClick={() => {
            setShowCreateAssetsGroup(true);
          }}
        >
          Create Asset Group
        </Button>
      ) : (
        <Button
          variant="light"
          onClick={() => {
            setShowCreateAssetsGroup(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} size="sm" />
        </Button>
      )}

      <Modal
        show={showCreateAssetsGroup}
        onHide={() => {
          setShowCreateAssetsGroup(false);
        }}
      >
        <HGForm
          key="form_createSource"
          onSubmit={async (input) => {
            const variables: CreateAssetsGroupMutationVariables = {
              input: [
                {
                  groupID: group?.id,
                  title: input.title,
                },
              ],
            };
            await mutationCreateAssetsGroup({ variables }).then(props.onCreate);
            setShowCreateAssetsGroup(false);
          }}
          resolver={createAssetsGroupSchema}
        >
          <Modal.Header>Create Assets Group</Modal.Header>
          <Modal.Body>
            <HGInputBox label="Title" name="title" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              className="mr-2 cancel-btn"
              onClick={() => {
                setShowCreateAssetsGroup(false);
              }}
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};

export const AssetsGroups = (props) => {
  const [syncVal, setSyncVal] = useState<string | null>(null);
  const { assetsGroups, refetch } = useGetAssetsGroupsInGroup();
  const { setValue } = useFormContext();
  const onCreate = ({ data }) => {
    const response = data.CreateAssetsGroup[0];
    ToastifyQueue("Assets group created successfully", "success");
    refetch().then(() => {
      setSyncVal(response.id);
    });
  };

  useEffect(() => {
    if (syncVal) {
      setTimeout(() => {
        setValue(props.name, syncVal);
        setSyncVal(null);
      }, 10);
    }
  }, [syncVal]);
  return (
    <HGSelect
      key="assetsGroupControl"
      name="assetsGroupID"
      label="Assets Group"
      defaultValue={props.selectedId}
      overrideFormat={props?.assets?.assetsGroupDetails?.title || ""}
      inputGroup={(element) => (
        <InputGroup key="sourceGroup">
          {element}
          <InputGroup.Append>
            <CreateAssetsGroup onCreate={onCreate} />
          </InputGroup.Append>
        </InputGroup>
      )}
      options={
        (assetsGroups && [
          { id: null, title: null },
          ...assetsGroups
            .filter(
              (source) => source.id === props.selectedId || !source.archived
            )
            .map((source) => ({
              ...source,
              title: `${source.title}${source.archived ? " (archived)" : ""}`,
            })),
        ]) ||
        []
      }
      {...props}
    />
  );
};
