import { usePermissions } from "common/Permissions";
import React, { useState, useMemo, useEffect } from "react";
import { Card } from "react-bootstrap";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import {
  groupNonMemberRender,
  groupMemberRender,
} from "./GroupActions";

const GroupPermissionsViewUsers: React.FC = () => {
  const { organizationUsers, groupUsers, user } = usePermissions();

  const users = organizationUsers.map((user) => ({
    ...user,
    organizationRole: user.userRole,
    groupRole:
      groupUsers.find((u) => u.userID === user.userID)?.userRole || "none",
  }));

  const groupMembers = users.filter(
    (user) => user.groupRole === "admin" || user.groupRole === "write" || user.groupRole === "moderate" || user.groupRole === "read",
  );
  const groupNonMembers = users.filter((user) => user.groupRole === "none");

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      sortable: true,
      filter: true,
    };
  }, []);
  const [groupMember, setGroupMemberData] = useState();
  const [groupNonMember, setGroupNonMemberData] = useState();
  const customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };
  const [groupMemberColumnDefs, _setgroupMemberColumnDefs] = useState([
    {
      field: "User",
      width: 300,
      comparator: customComparator,
      unSortIcon: true,
    },
    {
      field: "Role",
      unSortIcon: true,
      width: 250,
    },
    {
      field: "Action",
      minWidth: 473,
      cellRenderer: groupMemberRender,
      sortable: false,
    },
  ]);
 
  const [groupNonMemberColumnDefs, _setGroupNonMemberColumnDefs] = useState([
    {
      field: "User",
      unSortIcon: true,
      comparator: customComparator,
      width: 500,
    },
    {
      field: "Action",
      minWidth: 473,
      cellRenderer: groupNonMemberRender,
      sortable: false,
    },
  ]);

  const groupNonMemberAdminData = groupNonMembers.map((item) => {
    return {
      User: item?.displayName,
      Action: "Add",
      ID: item?.id,
    };
  });

  const groupMemberData = groupMembers.map((item) => {
    return {
      User: item?.displayName,
      "Role": item.groupRole === 'admin' ? 'Administrator' : item.groupRole.charAt(0).toUpperCase() + item.groupRole.slice(1),
      Action: user.id === item.id ? "" : item.groupRole,
      ID: item?.id,
    };
  });
  

  useEffect(() => {
    setGroupMemberData(groupMemberData);
    setGroupNonMemberData(groupNonMemberAdminData);
  }, [groupUsers]);
  return (
    <>
        <Card>
          <Card.Header>Department Members</Card.Header>
          <Card.Body>
            <div
              className="ag-theme-alpine"
              style={{ height: 230, width: "100%" }}
            >
              <AgGridReact
                overlayNoRowsTemplate="No data found"
                modules={AllCommunityModules}
                rowData={groupMember}
                columnDefs={groupMemberColumnDefs}
                defaultColDef={defaultColDef}
              />
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Non-Members</Card.Header>
          <Card.Body>
            <div
              className="ag-theme-alpine"
              style={{ height: 230, width: "100%" }}
            >
              <AgGridReact
                overlayNoRowsTemplate="No data found"
                modules={AllCommunityModules}
                rowData={groupNonMember}
                columnDefs={groupNonMemberColumnDefs}
                defaultColDef={defaultColDef}
              />
            </div>
          </Card.Body>
        </Card>
    </>
  );
};

export default GroupPermissionsViewUsers;
