import React, { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import { Row, Button, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { sleep } from "utils/useSleep";
import ToastifyQueue from "./Overlays/ToastifyQueue";
import { HGForm, HGFormControl, HGFormSubmit } from "./HGForm";
import * as yup from "yup";
import { isUniqueTitle } from "./formFieldsValidationHelpers";
import { HGInputBox } from "./HGForm/Inputs";

const TitleEdit: React.FC<
  { item; items; mutation; refetch; yupSchema; userRole; type } | any
> = ({
  item,
  items,
  mutation,
  refetch,
  children,
  yupSchema,
  userRole,
  type,
}) => {
  const [title, setTitle] = useState(item?.title);
  const [isEditing, setIsEditing] = useState(false);
  const schema = yup.object().shape({
    title: yup
      .string()
      .required()
      .label("Title")
      .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
      .test(
        "check-duplication",
        `${type} ${
          item?.isTemplate === true ? "template" : ""
        } title already exists!`,
        (value) => {
          return !isUniqueTitle(
            items.filter((i) => i.id !== item.id),
            value
          );
        }
      ),
  });

  const [_updateTitle] = useMutation(gql(mutation), {
    onCompleted: () => {
      ToastifyQueue(
        `${
          item.__typename === "AssessmentResponse"
            ? "Assessment"
            : item.__typename === "AuditPlanning"
            ? "Audit Plan"
            : item.__typename
        } Updated Successfully`,
        "success"
      );
      setIsEditing(false);
      sleep(500).then(refetch);
    },
  });

  useEffect(() => {
    setTitle(item?.title);
  }, [item]);
  return (
    <div style={{ display: "flex" }}>
      {isEditing ? (
        <HGForm
          onSubmit={async (input, e) => {
            return _updateTitle({
              variables: {
                input: {
                  id: item.id,
                  title: input?.title,
                },
              },
            }).then(refetch);
          }}
          resolver={schema ?? null}
        >
          <Row>
            <Col sm={8}>
              <HGInputBox
                name="title"
                label="Title"
                defaultValue={title}
                onChange={(e) => {
                  setTitle(e.currentTarget.value);
                }}
              />
            </Col>
            <Col sm={2} style={{ marginTop: "30px" }}>
              <HGFormSubmit title="Save" />
            </Col>
            <Col sm={2} style={{ marginTop: "30px" }}>
              <Button
                variant="light"
                className="cancel-btn"
                onClick={() => {
                  setTitle(item?.title);
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </HGForm>
      ) : (
        <div
          className="w-100"
          style={{ display: "inline-block" }}
          title={title}
        >
          <h4 className="title-left" style={{ display: "inline" }}>
            {title}{" "}
            {userRole !== "read" && (
              <Button
                variant="link"
                className="p-0"
                style={{ padding: "0px", verticalAlign: "top", height: "24px" }}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <FontAwesomeIcon
                  size="xs"
                  icon={faEdit}
                  style={{ verticalAlign: "top", marginTop: "6px" }}
                />
              </Button>
            )}
          </h4>
          {children && <span> {children}</span>}
        </div>
      )}
    </div>
  );
};

export default TitleEdit;
