export const useGetQuery = (ids : Array<string>) => {
 return `query GetRelatedControls{
    ${ids.map((id,index)=>`items${index}: getLinksByItem(id: "${id.toString()}"){
        items {
            item {
              title
              uuid
            }
          }
    }`)}
  }`
};
