import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CreateUrlButton from "./CreateUrlButton";
import { SearchBar } from "./SearchBar";
import { AttachmentModalBase } from "./AttachmentModal";

export const RelatedItemSearchButton = ({
  types,
  attachedData,
  attach,
  detach,
  refetch,
  id,
  className,
  ...props
}: any) => {
  const [showModal, setShowModal] = useState(false);
  const type = types[0];

  const passthrough = {
    types,
    attachedData,
    attach,
    detach,
    refetch,
    id,
    className,
    ...props,
  };
  return type.name !== "Assessements" && type.name !== "Assets" ? (
    <>
      <Button
        className={className}
        {...props}
        variant="primary"
        size="sm"
        onClick={() => setShowModal(true)}
      >
        Attach
      </Button>
      <AttachmentModalBase
        {...passthrough}
        {...{ type, showModal, setShowModal }}
      />
    </>
  ) : (
    <></>
  );
};
