// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { DateTime } from "luxon";

import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";

import "./HGRichText.scss";
import {
  faBold,
  faHeading,
  faItalic,
  faListOl,
  faListUl,
  faParagraph,
  faQuoteRight,
  faRedo,
  faRemoveFormat,
  faStrikethrough,
  faUndo,
  IconDefinition,
  faTasks,
  faClock,
  faPaste,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import { useEditContext } from "./HGFormContext";
import { Form, Dropdown, DropdownButton } from "react-bootstrap";
import { usePreviousDistinct } from "react-use";
import { SvgIcon } from "@material-ui/core";
import { usePermissions } from "common/Permissions";
import { HGHidden } from "./Inputs";

const MenuItem = ({ id, action, isActive, icon, type }) => (
  <button
    onClick={action}
    className={
      isActive ? "rich-text-menu-item is-active" : "rich-text-menu-item"
    }
  >
    {type && <SvgIcon component={icon} viewBox="0 0 1024 1024" />}
    <FontAwesomeIcon icon={icon} title={id} />
  </button>
);

const MenuBar = ({ editor, name }) => {
  const { userMeta, refetchUserMeta } = usePermissions();
  const [snippets, setSnippets] = useState<any>([]);
  useEffect(() => {
    if (userMeta === undefined) {
      refetchUserMeta();
    } else if (userMeta?.snippets !== null) {
      setSnippets(JSON.parse(userMeta.snippets));
    }
  }, [userMeta]);

  if (!editor) {
    return null;
  }

  const TableItems = [
    "Insert Table",
    "Delete Table",
    "Insert Column",
    "Delete Column",
    "Insert Row",
    "Delete Row",
  ];

  const handleTableItemSelect = (item) => {
    switch (item) {
      case "Insert Table":
        editor
          .chain()
          .focus()
          .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
          .run();
        break;
      case "Delete Table":
        editor.chain().focus().deleteTable().run();
        break;
      case "Insert Row":
        editor.chain().focus().addRowBefore().run();
        break;
      case "Delete Row":
        editor.chain().focus().deleteRow().run();
        break;
      case "Insert Column":
        editor.chain().focus().addColumnBefore().run();
        break;
      case "Delete Column":
        editor.chain().focus().deleteColumn().run();
        break;
      default:
        break;
    }
  };

  const menuItems: Array<
    | {
        id: string;
        action: Function;
        isActive?: boolean;
        icon: IconDefinition;
      }
    | { type: string }
  > = [
    {
      icon: faBold,
      id: "Bold",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().toggleBold().run();
      },
      isActive: editor.isActive("bold"),
    },
    {
      icon: faItalic,
      id: "Italic",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().toggleItalic().run();
      },
      isActive: editor.isActive("italic"),
    },
    {
      icon: faStrikethrough,
      id: "Strike",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().toggleStrike().run();
      },
      isActive: editor.isActive("strike"),
    },
    {
      type: "divider",
    },
    {
      icon: faHeading,
      id: "Heading 1",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().toggleHeading({ level: 1 }).run();
      },
      isActive: editor.isActive("heading", { level: 1 }),
    },
    {
      icon: faParagraph,
      id: "Paragraph",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().setParagraph().run();
      },
      isActive: editor.isActive("paragraph"),
    },
    {
      icon: faListUl,
      id: "Bullet List",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().toggleBulletList().run();
      },
      isActive: editor.isActive("bulletList"),
    },
    {
      icon: faListOl,
      id: "Ordered List",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().toggleOrderedList().run();
      },
      isActive: editor.isActive("orderedList"),
    },
    {
      type: "divider",
    },
    {
      icon: faQuoteRight,
      id: "Blockquote",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().toggleBlockquote().run();
      },
      isActive: editor.isActive("blockquote"),
    },
    {
      type: "divider",
    },
    {
      icon: faRemoveFormat,
      id: "Clear Format",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().clearNodes().unsetAllMarks().run();
      },
    },
    {
      type: "divider",
    },
    {
      icon: faUndo,
      id: "Undo",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().undo().run();
      },
    },
    {
      icon: faRedo,
      id: "Redo",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().redo().run();
      },
    },
    {
      type: "divider",
    },
    {
      icon: faTasks,
      id: "Tasks",
      action: (e) => {
        e.preventDefault();
        editor.chain().focus().toggleTaskList().run();
      },
    },
    {
      type: "divider",
    },
    {
      icon: faClock,
      id: "Current Time",
      action: (e) => {
        e.preventDefault();
        editor
          .chain()
          .focus()
          .insertContent(`${DateTime.now().toFormat("MM/dd/yyyy hh:mm:ss a")}`)
          .run();
      },
    },
    {
      type: "new_line",
    },
    {
      icon: faPaste,
      type: "custom",
      id: "Table",
      content: () => (
        <DropdownButton
          as="button"
          variant="link"
          onClick={(e) => e.preventDefault()}
          title="Table"
          id="Table-menu"
          className="rich-text-menu-item"
        >
          {TableItems.map((item) => {
            return (
              <Dropdown.Item
                as="button"
                onSelect={() => handleTableItemSelect(item)}
              >
                {item}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      ),
    },
    {
      type: "divider",
    },
    {
      icon: faPaste,
      type: "custom",
      id: "Snippets",
      content: () => (
        <DropdownButton
          as="button"
          variant="link"
          onClick={(e) => e.preventDefault()}
          title="Snippets"
          id="snippets-menu"
          className="rich-text-menu-item"
        >
          {snippets.length > 0 &&
            snippets
              .filter((item) => item?.archived !== true)
              .map((item) => {
                return (
                  <Dropdown.Item
                    as="button"
                    onClick={(e) => {
                      e.preventDefault();
                      editor?.commands.insertContent(item.snippet).run();
                    }}
                  >
                    {item.name}
                  </Dropdown.Item>
                );
              })}
        </DropdownButton>
      ),
    },
  ];

  return (
    <div className="editor__header">
      {menuItems.map((menuItem) => {
        switch (menuItem?.type) {
          case "divider":
            return <div className="divider" />;
          case "new_line":
            return <div className="new_line" />;
          case "table_text":
            return <button className="table_text" />;
          case "custom":
            return menuItem.content();
          default:
            return <MenuItem {...menuItem} />;
        }
      })}
    </div>
  );
};
export const insertSVG = () => {
  return (
    <svg
      className="svg-icon"
      style={{
        width: "1.2em",
        height: "1.2em",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden",
      }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M725.333333 725.333333v213.333334h85.333334a128 128 0 0 0 128-128v-85.333334h-213.333334z m-85.333333 0H384v213.333334h256v-213.333334z m85.333333-85.333333h213.333334V384h-213.333334v256z m-85.333333 0V384H384v256h256z m85.333333-341.333333h213.333334V213.333333a128 128 0 0 0-128-128h-85.333334v213.333334z m-85.333333 0V85.333333H384v213.333334h256z m384 391.552V810.666667a213.333333 213.333333 0 0 1-213.333333 213.333333H213.333333a213.333333 213.333333 0 0 1-213.333333-213.333333V213.333333a213.333333 213.333333 0 0 1 213.333333-213.333333h597.333334a213.333333 213.333333 0 0 1 213.333333 213.333333v120.448a35.968 35.968 0 0 1 0 15.104v326.229334a35.968 35.968 0 0 1 0 15.104zM298.666667 85.333333H213.333333a128 128 0 0 0-128 128v85.333334h213.333334V85.333333zM85.333333 384v256h213.333334V384H85.333333z m0 341.333333v85.333334a128 128 0 0 0 128 128h85.333334v-213.333334H85.333333z">
        <title>Insert Table</title>
      </path>
    </svg>
  );
};
export const InsertColumnSVG = () => {
  return (
    <svg
      className="svg-icon"
      style={{
        width: "1.2em",
        height: "1.2em",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden",
      }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M810.666667 597.333333a213.333333 213.333333 0 1 1 0 426.666667 213.333333 213.333333 0 0 1 0-426.666667z m106.666666 253.354667a39.978667 39.978667 0 1 0 0-80h-53.333333a13.312 13.312 0 0 1-13.354667-13.354667V704a39.978667 39.978667 0 1 0-80 0v53.333333c0 7.381333-5.973333 13.354667-13.312 13.354667H704a39.978667 39.978667 0 1 0 0 80h53.333333c7.381333 0 13.354667 5.973333 13.354667 13.312v53.333333a39.978667 39.978667 0 1 0 80 0v-53.333333c0-7.381333 5.973333-13.354667 13.312-13.354667h53.333333zM85.333333 810.666667a128 128 0 0 0 256 0V213.333333a128 128 0 1 0-256 0v597.333334z m-85.333333 0V213.333333a213.333333 213.333333 0 1 1 426.666667 0v597.333334a213.333333 213.333333 0 0 1-426.666667 0z">
        <title>Insert Column</title>
      </path>{" "}
    </svg>
  );
};
export const DeleteColumnSVG = () => {
  return (
    <svg
      className="svg-icon"
      style={{
        width: "1.2em",
        height: "1.2em",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden",
      }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M539.343266 935.71214a299.089969 299.089969 0 0 0 48.89545 74.239165A213.330933 213.330933 0 0 1 298.663307 810.657547V213.330933a213.330933 213.330933 0 1 1 426.661866 0v311.036501a297.468653 297.468653 0 0 0-85.332373 41.17287V213.330933a127.99856 127.99856 0 0 0-255.99712 0v597.326614a127.99856 127.99856 0 0 0 155.347586 125.054593zM810.657547 597.326613a213.330933 213.330933 0 1 1 0 426.661867 213.330933 213.330933 0 0 1 0-426.661867z m-106.665467 253.351817h213.330933a39.978217 39.978217 0 1 0 0-79.9991h-213.330933a39.978217 39.978217 0 1 0 0 79.9991z">
        <title>Delete Column</title>
      </path>
    </svg>
  );
};
export const InsertRowSVG = () => {
  return (
    <svg
      className="svg-icon"
      style={{
        width: "1.2em",
        height: "1.2em",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden",
      }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M810.666667 0a213.333333 213.333333 0 1 1 0 426.666667 213.333333 213.333333 0 0 1 0-426.666667z m106.666666 253.354667a39.978667 39.978667 0 1 0 0-80h-53.333333a13.312 13.312 0 0 1-13.354667-13.354667V106.666667a39.978667 39.978667 0 1 0-80 0v53.333333c0 7.381333-5.973333 13.354667-13.312 13.354667H704a39.978667 39.978667 0 1 0 0 80h53.333333c7.381333 0 13.354667 5.973333 13.354667 13.312V320a39.978667 39.978667 0 1 0 80 0V266.666667c0-7.381333 5.973333-13.354667 13.312-13.354667h53.333333zM213.333333 682.666667a128 128 0 0 0 0 256h597.333334a128 128 0 0 0 0-256H213.333333z m0-85.333334h597.333334a213.333333 213.333333 0 0 1 0 426.666667H213.333333a213.333333 213.333333 0 0 1 0-426.666667z">
        <title>Insert Row</title>
      </path>
    </svg>
  );
};
export const deleteSVG = () => {
  return (
    <svg
      className="svg-icon"
      style={{
        width: "1.2em",
        height: "1.2em",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden",
      }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M810.666667 597.333333a213.333333 213.333333 0 1 1 0 426.666667 213.333333 213.333333 0 0 1 0-426.666667z m-106.666667 253.354667h213.333333a39.978667 39.978667 0 1 0 0-80h-213.333333a39.978667 39.978667 0 1 0 0 80zM524.373333 725.333333H384v213.333334h156.757333c15.189333 32 35.882667 60.842667 60.885334 85.333333H213.333333a213.333333 213.333333 0 0 1-213.333333-213.333333V213.333333a213.333333 213.333333 0 0 1 213.333333-213.333333h597.333334a213.333333 213.333333 0 0 1 213.333333 213.333333v120.448a35.968 35.968 0 0 1 0 15.104V601.6a299.434667 299.434667 0 0 0-85.333333-60.885333V384h-213.333334v140.373333a297.472 297.472 0 0 0-85.333333 41.173334V384H384v256h181.546667a297.472 297.472 0 0 0-41.173334 85.333333zM725.333333 298.666667h213.333334V213.333333a128 128 0 0 0-128-128h-85.333334v213.333334z m-85.333333 0V85.333333H384v213.333334h256zM298.666667 85.333333H213.333333a128 128 0 0 0-128 128v85.333334h213.333334V85.333333zM85.333333 384v256h213.333334V384H85.333333z m0 341.333333v85.333334a128 128 0 0 0 128 128h85.333334v-213.333334H85.333333z">
        <title>Delete Table</title>
      </path>
    </svg>
  );
};
export const deleteRowSVG = () => {
  return (
    <svg
      className="svg-icon"
      style={{
        width: "1.2em",
        height: "1.2em",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden",
      }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M565.546667 640a297.472 297.472 0 0 0-41.173334 85.333333H213.333333A213.333333 213.333333 0 0 1 213.333333 298.666667h597.333334a213.333333 213.333333 0 0 1 199.296 289.578666 299.093333 299.093333 0 0 0-74.24-48.896A128 128 0 0 0 810.666667 384H213.333333a128 128 0 0 0 0 256h352.213334zM810.666667 597.333333a213.333333 213.333333 0 1 1 0 426.666667 213.333333 213.333333 0 0 1 0-426.666667z m-106.666667 253.354667h213.333333a39.978667 39.978667 0 1 0 0-80h-213.333333a39.978667 39.978667 0 1 0 0 80z">
        <title>Delete Row</title>
      </path>
    </svg>
  );
};
export const HGRichTextRead = ({ content, disableMargin = false }) => {
  const cleanHTML = DOMPurify.sanitize(content, {
    USE_PROFILES: { html: true },
  });

  const parsedHTML = parse(cleanHTML);

  return disableMargin ? (
    <span>{parsedHTML}</span>
  ) : (
    <div className="ProseMirror">
      <div className="text-scroll">{parsedHTML}</div>
    </div>
  );
};

export const HGRichTextStandalone = ({ value, onChange }) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: value?.replaceAll?.("\n", "<br/>") || "",
    onUpdate: ({ editor }) => {
      const innerHTML = editor.getHTML();
      onChange(innerHTML !== "<p></p>" ? innerHTML : "");
    },
  });

  return (
    <Form.Group>
      <div className="editor">
        <MenuBar editor={editor} />
        <EditorContent editor={editor} className="editor__content" />
      </div>
    </Form.Group>
  );
};

export const HGRichText: React.FC<{
  name: string;
  label?: string;
  defaultValue?: string | null;
  appendValue?: any;
  required?: boolean;
  error?: any;
}> = ({ name, label = "", defaultValue, appendValue, required, error }) => {
  const methods = useFormContext();
  const { errors, register, setValue, getValues } = methods;

  const { editing } = useEditContext();

  const editor = useEditor({
    extensions: [
      StarterKit,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
    ],
    content: defaultValue?.replaceAll?.("\n", "<br/>") || "",
  });
  const _error = error || errors?.[name] || _.get(errors, name);
  const setDefaultVal = useCallback(() => {
    editor?.commands.setContent(
      defaultValue?.replaceAll?.("\n", "<br/>") || ""
    );
  }, [defaultValue]);

  // value reset to null for resetting after Activity Feed submission
  const _val = getValues(name);
  const _valPrev = usePreviousDistinct(_val);
  usePreviousDistinct(() => {});
  const innerHTML = editor?.getHTML();

  useEffect(() => {
    if (appendValue) {
      let newInnerHTML = editor?.getHTML();
      newInnerHTML += appendValue;
      editor?.commands?.setContent(newInnerHTML);
    }
  }, [appendValue]);

  useEffect(() => {
    if (!defaultValue && _val && _valPrev === undefined) {
      editor?.commands.setContent("");
    }
  }, [_val, _valPrev]);

  useEffect(() => {
    if (!editing) {
      setDefaultVal();
    }
  }, [editing, setDefaultVal]);

  useEffect(() => {
    if (editing) {
      setValue(name, innerHTML, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [editing, innerHTML]);

  useEffect(() => {
    setDefaultVal();
  }, [defaultValue]);
  return (
    <Form.Group>
      {label && (
        <Form.Label style={{ fontWeight: "bold" }} id={name}>
          {label}
        </Form.Label>
      )}
      {(!editing && <HGRichTextRead content={defaultValue} />) || (
        // className={`editor ${_error ? "is-invalid" : ""}`}
        <div className="editor">
          <MenuBar editor={editor} name={name} />
          <EditorContent
            editor={editor}
            className="editor__content"
            key={name}
            name={name}
            required={required}
          />
        </div>
      )}
      {editing && _error && (
        <Form.Control.Feedback type="invalid">
          {_error.message}
        </Form.Control.Feedback>
      )}
      <HGHidden name={name} required={required} />
    </Form.Group>
  );
};
