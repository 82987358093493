/* eslint-disable prefer-spread */
import { AlignmentType, convertInchesToTwip, LevelFormat } from "docx";

export const olReference = (identifier: string) => `ol-numbering-${identifier}`;

export const indentLevels = [
  {
    start: convertInchesToTwip(0.25),
    hanging: convertInchesToTwip(0.18),
  },
  {
    start: convertInchesToTwip(0.5),
    hanging: convertInchesToTwip(0.18),
  },
  {
    start: convertInchesToTwip(0.75),
    hanging: convertInchesToTwip(0.18),
  },
  {
    start: convertInchesToTwip(1),
    hanging: convertInchesToTwip(0.18),
  },
];

export const generateOLNumbering = (olCount: number) => {
  // eslint-disable-next-line prefer-spread
  return [
    {
      reference: 'sample-text',
      levels: [
        {
          level: 0,
          text: "%1.",
          alignment: AlignmentType.LEFT,
          style: {
            paragraph: {
              indent: indentLevels[0],
            },
          },
        },
      ]
    },
    ...Array.apply(null, Array(olCount + 1)).map((id, index) => ({
      reference: olReference(index.toString()),
      levels: [
        {
          level: 0,
          text: "%1.",
          alignment: AlignmentType.START,
          style: {
            paragraph: {
              indent: indentLevels[0],
            },
          },
        },
        {
          level: 1,
          text: "%2.",
          alignment: AlignmentType.START,
          style: {
            paragraph: {
              indent: indentLevels[1],
            },
          },
        },
        {
          level: 2,
          text: "%3.",
          alignment: AlignmentType.LEFT,
          style: {
            paragraph: {
              indent: indentLevels[2],
            },
          },
        },
        {
          level: 3,
          format: LevelFormat.UPPER_LETTER,
          text: "%4.",
          alignment: AlignmentType.START,
          style: {
            paragraph: {
              indent: indentLevels[3],
            },
          },
        },
      ],
    })),
  ];
};
