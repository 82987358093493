import { DateTime } from "luxon";
import { Status } from "../API";
import { color } from "d3";

export const mapStatus = (status) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (status) {
    case "Pending":
      return "Created";
    default:
      return status;
  }
};
export const formatStatus = (text) => {
  const status = mapStatus(text);
  switch (status) {
    case "Pending":
      return "Pending (deprecated)";
    case "In_Triage":
      return "In-Triage";
    case "In_Progress":
      return "In-Progress";
    case "Closed_Pending_Approval":
      return "Closed (Pending Approval)";
    default:
      return status?.replace("_", " ") || "";
  }
};

export const formatObj = (item, formattingFunction) => {
  const enumOptions: string[] = item && Object.values(item);
  const formattedArray: any[] = [];
  enumOptions.forEach((element) => {
    formattedArray.push(formattingFunction(element));
  });
  return formattedArray;
};

export const formatSeverity = (text) => {
  switch (text) {
    case "Null":
      return "Not Set";
    case "Minor":
      return "Medium";
    case "Major":
      return "High";
    default:
      return text;
  }
};

export const {
  Pending,
  Planning,
  Complete,
  Closed_Pending_Approval,
  Closed,
  ...restStatus
} = Status;
const { In_Triage, Deferred, ...rest } = restStatus;
export const IssueStatus = {
  ...restStatus,
  Closed_Pending_Approval,
  Closed,
};

const { In_Triage: Renamed_TriageInIssueStatus, ...actionPlanStatus } =
  IssueStatus;

export const ActionPlanStatus = { ...actionPlanStatus };
const { In_Progress } = rest;

export const AuditStatus = {
  Pending,
  Planning,
  In_Progress,
  Closed_Pending_Approval,
  Closed,
};

export const ModuleStatus = {
  ...rest,
  Closed_Pending_Approval,
  Closed,
};
export const formatPriority = (text) => {
  if (text === "Null") {
    return "Not Set";
  } else {
    return text;
  }
};

export const getValidDateOrEmpty = (fieldValue) => {
  const retVal =
    fieldValue &&
    fieldValue !== "Invalid DateTime" &&
    DateTime.fromFormat(fieldValue, "MM/dd/yyyy");
  return retVal ?? "";
};

export const getStyleByStatus = (
  status: string | undefined | null,
  fieldValue: any
) => {
  if (status === "Closed") {
    return {
      color: "gray",
      fontStyle: "italic",
    };
  } else if (
    status !== "Closed" &&
    getValidDateOrEmpty(fieldValue) < DateTime.now()
  ) {
    return {
      color: "red",
      fontStyle: "normal",
    };
  }
};
