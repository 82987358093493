import React from "react";
import { Col, Breadcrumb } from "react-bootstrap";
import { useGetOwnedNotifications } from "utils/connectors/notificationConnectors";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { formatStatus } from "utils/formatStatus";
import useSetTitle from "utils/useSetTitle";

const moduleMap = {
  Issue: (item) => `/issues/${item.id}`,
  "Action Plan": (item) => `/actionplans/${item.id}`,
  Task: (item) => `/actionplans/${item.parentID}/tasks/${item.id}`,
  Scenario: (item) => `/risk/${item.id}`,
};

const NotificationItem = ({ notification }) => {
  const timeStamp = DateTime.fromISO(notification.createdAt).toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS,
  );
  // show text
  const notificationText = {
    "Assigned Ownership":
      ({ item, ...notificationDetails }) =>
        () =>
          (
          <React.Fragment key={notificationDetails.id}>
            <p
              className={
                notificationDetails.isRead === false ? "font-weight-bold" : ""
              }
            >
              <Link to={moduleMap.Issue(item)} className="notif-title">
                {item.title}
              </Link>
              was assigned to you
            </p>
            <div className="text-muted small float-right">{timeStamp}</div>
          </React.Fragment>
          ),
    "Pending Approval":
      ({ item, ...notificationDetails }) =>
        () =>
          (
          <React.Fragment key={notificationDetails.id}>
            <p
              className={
                notificationDetails.isRead === false ? "font-weight-bold" : ""
              }
            >
              <Link to={moduleMap.Issue(item)} className="notif-title">
                {item.title}
              </Link>
              was set to
              {formatStatus(item.status)}
{" "}
            </p>
            <div className="text-muted small float-right">{timeStamp}</div>
          </React.Fragment>
          ),
  };

  const Notification =    notificationText[notification.notificationType]?.(notification);
  return Notification ? (
    <div className="notifcard notif-all">
      <Notification />
    </div>
  ) : null;
};

export const AllNotifications = () => {
  useSetTitle("Notifications");
  const { notifications } = useGetOwnedNotifications();
  return (
    <>
      <div className="dashboard-header">
        <Col xs="12" className="px-8">
          <Breadcrumb>
            <Breadcrumb.Item>Notifications</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs="12" className="px-8">
          <h3>All Notifications</h3>
        </Col>
      </div>
      <div>
        {notifications
          .sort((a: any, b: any) => {
            const bDate = DateTime.fromISO(b.createdAt);
            const aDate = DateTime.fromISO(a.createdAt);
            return ((bDate as any) - (aDate as any)) as any;
          })
          .map((notification) => (
            <NotificationItem notification={notification} />
          ))}
      </div>
    </>
  );
};
