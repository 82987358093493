import React, { useEffect, useMemo, useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { HGForm, HGFormControl, HGFormSubmit } from "common/HGForm";
import { getSourcesInGroup } from "graphql/queries";
import { Button, InputGroup, Modal } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createSource } from "graphql/mutations";
import {
  CreateSourceInput,
  CreateSourceMutationVariables,
  GetSourcesInGroupQuery,
  GetSourcesInGroupQueryVariables,
} from "API";
import { usePermissions } from "common/Permissions/Permissions";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { sleep } from "utils/useSleep";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { HGSelect } from "common/HGForm/Inputs";

export const sourceInGroupTransform = (response) => {
  return (response.data?.getSourcesInGroup?.items || [])
    .map(({ id, ...props }) => ({
      id: props?.sort?.replace("SOURCE#", ""),
      ...props,
    }))
    .sort((a: any, b: any) => (a?.title > b?.title ? 1 : -1));
};

export const useGetSourcesInSelectedGroup = (id) => {
  const { group, hasGroup } = usePermissions();

  const [_getSourceInSelectedGroup, response] = useLazyQuery<
    GetSourcesInGroupQuery,
    GetSourcesInGroupQueryVariables
  >(gql(getSourcesInGroup), { variables: { groupID: id } });
  const getSourceInSelectedGroup = (id) =>
    _getSourceInSelectedGroup(id).then(sourceInGroupTransform);

  return {
    getSourceInSelectedGroup,
    refetch: () => response.refetch().then(sourceInGroupTransform),
  };
};

export const CreateSource = ({ onCreate, groupID }) => {
  const { group, organization } = usePermissions();
  const [showCreateSource, setShowCreateSource] = useState(false);
  const [sources, setSources] = useState<any>([]);
  const [mutationCreateSource] = useMutation(gql(createSource));
  const { getSourceInSelectedGroup } = useGetSourcesInSelectedGroup(groupID);
  useEffect(() => {
    getSourceInSelectedGroup({
      variables: { id: groupID },
    }).then((sources) => {
      setSources(sources);
    });
  }, [groupID]);

  const sourcesData = [] as any;
  sources?.forEach((d) => {
    if (d?.title !== "") {
      sourcesData.push(d?.title?.trim());
    }
  });

  const createSourceSchema = useMemo(() => {
    return yup.object<Partial<CreateSourceInput>>({
      title: yup
        .string()
        .default("")
        .required()
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test("check-duplication", "Source title already exists!", (value) => {
          const index = sourcesData.findIndex((element) => {
            return element.toLowerCase() === value?.toLowerCase().trim();
          });
          return index === -1;
        }),
    });
  }, [sources]);

  return (
    <>
      <Button
        variant="light"
        onClick={() => {
          setShowCreateSource(true);
        }}
      >
        <FontAwesomeIcon icon={faPlus} size="sm" />
      </Button>
      <Modal
        show={showCreateSource}
        onHide={() => {
          setShowCreateSource(false);
        }}
      >
        <HGForm
          key="form_createSource"
          onSubmit={async (input) => {
            const variables: CreateSourceMutationVariables = {
              input: [
                {
                  groupID: groupID || group.id,
                  organizationID: organization?.id,
                  title: input.title,
                },
              ],
            };
            const response = await mutationCreateSource({ variables });
            await onCreate(response);
            setShowCreateSource(false);
          }}
          resolver={createSourceSchema}
        >
          <Modal.Header>Create Source</Modal.Header>
          <Modal.Body>
            <HGFormControl label="Title" name="title" required />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              className="mr-2 cancel-btn"
              onClick={() => {
                setShowCreateSource(false);
              }}
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};

export const useGetSourcesInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetSourcesInGroupQuery,
    GetSourcesInGroupQueryVariables
  >(gql(getSourcesInGroup), { variables: { groupID: group.id } }, hasGroup);

  const sources = (response.data?.getSourcesInGroup?.items || [])
    .map(({ id, ...props }) => ({
      id: props?.sort?.replace("SOURCE#", ""),
      ...props,
    }))
    // eslint-disable-next-line
    .sort((a: any, b: any) => (a?.title > b?.title ? 1 : -1));

  return { ...response, sources };
};

const Sources = (props) => {
  const [syncVal, setSyncVal] = useState<string | null>(null);
  const [sources, setSources] = useState<any>([]);
  const { setValue } = useFormContext();
  const { getSourceInSelectedGroup, refetch } = useGetSourcesInSelectedGroup(
    props.groupID
  );
  const onCreate = async ({ data }) => {
    const response = data.CreateSource[0];
    ToastifyQueue("Source created successfully", "success");
    await sleep(500);
    refetch().then((sources) => {
      setSources(sources);
      setSyncVal(response.id);
    });
  };
  useEffect(() => {
    getSourceInSelectedGroup({
      variables: { id: props.groupID },
    }).then((sources) => {
      setSources(sources);
    });
  }, [props.groupID]);

  useEffect(() => {
    if (syncVal) {
      setTimeout(() => {
        setValue(props.name, syncVal);
        setSyncVal(null);
      }, 10);
    }
  }, [syncVal]);
  return (
    <HGSelect
      label="Source"
      name="source"
      key="sourceControl"
      overrideFormat={sources.find(item => item.id === props?.defaultValue)?.title || 'Not Set'}
      defaultValue={props.defaultValue}
      options={
        (sources && [
          { id: "Null", title: "Not Set" },
          ...sources
            .filter(
              (source) => source.id === props.defaultValue || !source.archived
            )
            .map((source) => ({
              ...source,
              title: `${source.title}${source.archived ? " (archived)" : ""}`,
            })),
        ]) ||
        []
      }
      inputGroup={(element) => (
        <InputGroup key="sourceGroup">
          {element}
          <InputGroup.Append>
            <CreateSource onCreate={onCreate} groupID={props?.groupID} />
          </InputGroup.Append>
        </InputGroup>
      )}
      {...props}
    />
  );
};

export default Sources;
