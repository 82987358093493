import { gql, useMutation } from "@apollo/client";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CreateRiskComparisonInput,
  CreateRiskComparisonMutation,
  CreateRiskComparisonMutationVariables,
} from "API";
import { HGForm, HGFormControl, HGFormSubmit } from "common/HGForm";
import { HGRichText } from "common/HGForm/HGRichText";
import { usePermissions } from "common/Permissions";
import { createRiskComparison } from "graphql/mutations";
import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { useScenarioComparisonContext } from "./TableComparison";
import { cancelPopupMessage } from "utils/globalVariables";
import * as yup from "yup";
import { useGetScenarioComparisonsInGroup } from "utils/connectors/scenarioAggregateConnectors";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";
import { HGHidden, HGInputBox } from "common/HGForm/Inputs";

export const CreateComparison = ({ scenarios }) => {
  const history = useHistory();
  const { group } = usePermissions();
  const scenarioComparison = useScenarioComparisonContext();
  const { comparisons } = useGetScenarioComparisonsInGroup();
  const [showCreate, setShowCreate] = useState(false);

  const [makeRiskComparison] = useMutation<
    CreateRiskComparisonMutation,
    CreateRiskComparisonMutationVariables
  >(gql(createRiskComparison), {
    onCompleted: (response) => {
      setShowCreate(false);
      const responseID = response?.CreateRiskComparison?.[0]?.id;
      if (!responseID) throw Error();
      history.push(`/risk/comparison/${responseID}`);
    },
  });

  const createComparisonSchema = useMemo(() => {
    return yup.object<Partial<CreateRiskComparisonInput>>({
      title: yup
        .string()
        .default("")
        .required()
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test(
          "check-duplication",
          "Risk Comparison title already exists!",
          (value) => {
            if (!value) return true;
            return !isUniqueTitle(comparisons, value);
          }
        ),
    });
  }, [scenarios, comparisons]);

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowCreate(false);
    }
  }
  return (
    <>
      <Button
        className="mr-2"
        variant="light"
        disabled={scenarioComparison.checked.length > 3}
        onClick={() => {
          setShowCreate(true);
        }}
      >
        <FontAwesomeIcon className="mr-1" icon={faChartBar} />
        Compare
      </Button>
      <Modal
        show={showCreate}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          onSubmit={async (input) => {
            return makeRiskComparison({
              variables: { input: { ...input, groupID: group.id } },
            });
          }}
          resolver={createComparisonSchema}
        >
          <Modal.Header>Create Comparison</Modal.Header>
          <Modal.Body>
            <HGInputBox name="title" label="Title" />
            <HGRichText name="description" label="Description for Comparison" />
            <HGFormControl
              label="Acceptable Loss Threshold"
              name="lossThreshold"
              type="number"
            />
            {scenarioComparison.checked.map((scenarioID, index) => {
              const scenarioIndex =
                index === 0 ? "One" : index === 1 ? "Two" : "Three";
              const scenario = scenarios.find(
                (_scenario) => _scenario.id === scenarioID
              );
              return (
                <>
                  <HGHidden
                    name={`scenario${scenarioIndex}ID`}
                    value={scenarioID}
                  />
                  <HGRichText
                    label={`Description for Scenario - ${scenario?.title}`}
                    name={`scenario${scenarioIndex}Description`}
                  />
                </>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn cancel-btn"
              variant="light"
              onClick={() => {
                Confirmation();
              }}
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
