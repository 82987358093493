import { gql } from "@apollo/client";
import {
  GetRiskDomainQuery,
  GetRiskDomainQueryVariables,
  GetRiskDomainsInGroupQueryVariables,
  GetRiskDomainsInGroupQuery,
} from "API";
import { usePermissions } from "common/Permissions";
import { getRiskDomain, getRiskDomainsInGroup } from "graphql/queries";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";

export const useGetRiskDomainInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetRiskDomainsInGroupQuery,
    GetRiskDomainsInGroupQueryVariables
  >(
    gql(getRiskDomainsInGroup),
    {
      variables: {
        groupID: group.id,
      },
    },
    hasGroup
  );

  const riskDomains = (response.data?.getRiskDomainsInGroup.items || []).map(
    sortToID("RISKDOMAIN#")
  );

  return { ...response, riskDomains };
};

export const useGetRiskDomain = (id) => {
  const { hasGroup } = usePermissions();
  const response = useProtectedQuery<
    GetRiskDomainQuery,
    GetRiskDomainQueryVariables
  >(
    gql(getRiskDomain),
    {
      variables: {
        id,
      },
    },
    hasGroup
  );

  return { ...response?.data?.getRiskDomain };
};

export const useGetDownloadRiskDomainObj = (item) => {
  const riskDomainArray = ["title", "ID", "parent", "description"];
  const selectArray = (arr, obj) =>
    arr.reduce((r, e) => Object.assign(r, { [e]: obj[e] }), {});
  if (item) {
    item = {
      ...item,
      ID: item?.displayID,
      parent: item?.parent?.title,
    };
    return selectArray(riskDomainArray, item);
  }
};
