import React, { useState } from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap";
import QRCode from "qrcode.react";

export const MfaModal = ({ code, onHide, verify }) => {
  const [confirmationCode, setConfirmationCode] = useState("");
  return (
    <Modal show onHide={onHide}>
      <Modal.Header>MFA Code</Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <ol>
              <li>
                Download an Authenticator app
                <ul>
                  <li>
                    <a
                      href="https://www.microsoft.com/en-us/account/authenticator"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Microsoft Authenticator
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Google Authenticator
                    </a>
                  </li>
                </ul>
              </li>
              <li>Use the QR Code below to add a new entry</li>
              <li>Insert the confirmation code in the form below</li>
            </ol>
          </Col>
          <Col
            sm={12}
            className="col-centered"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <QRCode value={code} />
          </Col>
          <Col sm={12}>
            <FormGroup>
              <FormLabel>Confirmation Code</FormLabel>
              <FormControl
                value={confirmationCode}
                onChange={(e) => {
                  setConfirmationCode(e.currentTarget.value);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            verify(confirmationCode);
          }}
        >
          Submit
        </Button>
        <Button onClick={onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};
