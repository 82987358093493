import React from "react";
import { useEditContext } from "../HGFormContext";
import Form from "react-bootstrap/Form";
import {
  formatHGSelect,
  FormatHGSelectProperties,
  useFilterHGSelectForApprovers,
} from "./utils/HGSelect.utils";
import {
  HGInputProps,
  HGLabel,
  HGLabelProps,
  useHGFormError,
} from "./utils/HGInput.utils";
import { HGFormComponent } from "./utils/HGFormComponent";

export const HGSelect: React.FC<
  FormatHGSelectProperties &
    HGLabelProps &
    HGInputProps & {
      overrideFormat?: ((input: any) => string | string) | string;
    } & {
      inputGroup?: any;
      isApprover?: boolean;
    }
> = ({
  name,
  enumObj,
  options,
  defaultValue,
  overrideFormat,
  inputGroup,
  isApprover,
  ...props
}) => {
  const { editing } = useEditContext();

  const { ErrorDisplay, isInvalid } = useHGFormError({
    name,
    overrideError: props.error,
    editing,
  });
  const filterSelectOptions = useFilterHGSelectForApprovers({ isApprover });
  const optionsArray = formatHGSelect({ name, enumObj, options })?.filter(
    filterSelectOptions
  );
  //is the approver field required because in create we have it as required
  return (
    <Form.Group>
      <HGLabel {...props} />
      {(!editing && <p>{overrideFormat}</p>) || (
        <>
          <HGFormComponent
            as="select"
            defaultValue={defaultValue}
            name={name}
            isInvalid={isInvalid}
            onChange={props?.onChange}
            inputGroup={inputGroup}
            defaultName={props?.defaultName}
          >
            {optionsArray?.map(({ label, value }) => (
              <option
                key={label}
                value={value || ""}
                selected={value === defaultValue}
              >
                {label}
              </option>
            ))}
          </HGFormComponent>
          <ErrorDisplay />
        </>
      )}
    </Form.Group>
  );
};
