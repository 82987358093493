import React from "react";
import { useVisibility } from "utils/useVisibility";
import { useCollapsibleContext } from "./layout/LeftNavBar/CollapsibleContext";

export const StickyHeader = ({ children }) => {
  const [isVisible, headerRef] = useVisibility<any>();
  const { collapsed } = useCollapsibleContext();
  return (
    <div
      ref={headerRef}
      style={{
        width: "auto",
        marginLeft: "-15px",
        marginRight: "-15px",
      }}
    >
      <div className="dashboard-header sticky">
        {isVisible ? (
          children
        ) : (
          <div className="header-fix">
            <div style={{ marginRight: `${collapsed ? "70" : "220"}px` }}>
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
