import { usePermissions } from "common/Permissions";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import { useAuditPlanContext } from "./AuditPlanningPage";
import * as yup from "yup";
import { UpdateAuditPlanningInput, ViewType } from "API";
import { DateTime } from "luxon";
import { HGForm, HGToggleSubmit } from "common/HGForm";
import { Card, Col, Row } from "react-bootstrap";
import { HGNumber } from "common/HGForm/Inputs";
import { HGDatePicker } from "common/HGForm/HGDatePicker";
const AuditPlanningPageDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { group } = usePermissions();
  const { auditPlan, updateAuditPlan, viewType } = useAuditPlanContext();
  const auditPlanDetailsSchema = useMemo(
    () =>
      auditPlan &&
      yup.object<Partial<UpdateAuditPlanningInput>>({
        year: yup
          .string()
          .default("")
          .label("Plan Year")
          .test(
            "is-date",
            // eslint-disable-next-line
            "${path} should be in YYYY format",
            (value) => {
              if (value) {
                const date = DateTime.fromFormat(value, "yyyy");
                return date?.isValid;
              }
              return true;
            }
          ),
        hours: yup
          .string()
          .nullable()
          .notRequired()
          .default(auditPlan?.hours)
          .label("Estimated Hours")
          .matches(/^\d*\.?\d*$/, "Hours must be a positive number or zero")
          .test(
            "is-positive-or-zero",
            "Estimated Hours must be a positive number or zero",
            (value) =>
              value === null ||
              value === "" ||
              (value !== undefined && parseFloat(value) >= 0)
          ),
        auditStartDate: yup
          .string()
          .default("")
          .label("Start Date")
          .test(
            "is-date",
            // eslint-disable-next-line
            "${path} should be in MM/DD/YYYY format",
            (value) => {
              if (value) {
                const date = DateTime.fromFormat(value, "MM/dd/yyyy");
                return date?.isValid;
              }
              return true;
            }
          ),
        auditEndDate: yup
          .string()
          .default("")
          .label("End Date")
          .test(
            "is-date",
            // eslint-disable-next-line
            "${path} should be in MM/DD/YYYY format",
            (value) => {
              if (value) {
                const date = DateTime.fromFormat(value, "MM/dd/yyyy");
                return date?.isValid;
              }
              return true;
            }
          ),
        days: yup
          .string()
          .nullable()
          .notRequired()
          .default(auditPlan?.days)
          .label("Estimated Duration")
          .matches(
            /^\d*\.?\d*$/,
            "Estimated Duration must be a positive number or zero"
          )
          .test(
            "is-positive-or-zero",
            "Estimated Duration must be a positive number or zero",
            (value) =>
              value === null ||
              value === "" ||
              (value !== undefined && parseFloat(value) >= 0)
          ),
      }),
    [auditPlan]
  );
  return (
    <HGForm
      key="form_editAuditPlanningDetails"
      onSubmit={async (input) => {
        return await updateAuditPlan({
          variables: {
            input: { id, groupID: group.id, ...input },
          },
        });
      }}
      resolver={auditPlanDetailsSchema}
      editToggle
    >
      <Card>
        <Card.Header>
          <HGToggleSubmit className="float-right" />
          Details
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <HGNumber
                label="Plan Year"
                name="year"
                defaultValue={auditPlan?.year}
              />
            </Col>
            <Col sm={6}>
              <HGNumber
                label="Estimated Hours"
                name="hours"
                defaultValue={auditPlan?.hours}
              />
            </Col>
            <Col sm={6}>
              <HGDatePicker
                name="auditStartDate"
                label="Start Date"
                defaultValue={auditPlan?.auditStartDate || ""}
                maxFieldValue="auditEndDate"
              />
            </Col>
            <Col sm={6}>
              <HGDatePicker
                name="auditEndDate"
                label="End Date"
                defaultValue={auditPlan?.auditEndDate || ""}
                minFieldValue="auditStartDate"
              />
            </Col>
            {viewType !== ViewType.Standard && (
              <Col sm={6}>
                <HGNumber
                  label="Estimated Duration"
                  name="days"
                  defaultValue={auditPlan?.days}
                />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </HGForm>
  );
};

export default AuditPlanningPageDetails;
