import { usePermissions } from "common/Permissions";
import { LineChart } from "common/fair-graphs/LineChart";
import { DateTime } from "luxon";
import React from "react";
import { useGetDailyActiveUser } from "utils/connectors/userConnectors";

export const dailyActiveUserFormatData = (activeUserData) => {
  const dayCount = 14;

  const allDates = Array.from(Array(dayCount + 1))
    .map((val, index) => DateTime.now().minus({ day: index }).startOf("day"))
    .reverse();

  const data = allDates.map((date) => {
    return {
      x: date,
      y:
        activeUserData?.find((userDate) =>
          DateTime.fromISO(userDate.date).hasSame(date, "day"),
        )?.totalActiveUsers ?? 0,
    };
  });
  const yAxisData =    activeUserData && (Math.max(...data.map((o) => o?.y)) as any);
  return {
    xTicks: allDates,
    xFormat: (t) => {
      return `${t.toFormat("MM/dd")}`;
    },
    graphData: data,
    yAxis: [0, yAxisData],
    xAxis: [
      DateTime.now().minus({ day: dayCount }).startOf("day"),
      DateTime.now().startOf("day"),
    ],
    xAxisLabel: "Days",
    yAxisLabel: "User Count",
  };
};

export const UsageStats = () => {
  const permissions = usePermissions();
  const {
    organization: { organizationID },
  } = permissions;
  const dailyusers = useGetDailyActiveUser(organizationID);
  const formattedData = dailyActiveUserFormatData(dailyusers);
  return <LineChart title="Daily Active Users" data={formattedData} />;
};
