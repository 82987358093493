import React from "react";
import { Badge } from "react-bootstrap";
import { formatPriority } from "utils/formatStatus";

export const PriorityBadge: React.FC<{
  priority?: string | null;
}> = ({ priority = "Not Set" }) => {
  const formattedPriority = formatPriority(priority)
  return (
    <Badge pill className={priority || ""} title={formattedPriority as any}>
      {formattedPriority}
    </Badge>
  );
};
