import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Route, Switch, Redirect } from "react-router-dom";

const AssetsList = React.lazy(
  () => import("Modules/Customers/HIPAARiskAssessment/Assets/AssetsList") as any
);
const CategoryList = React.lazy(
  () =>
    import("Modules/Customers/HIPAARiskAssessment/Category/CategoryList") as any
);
const SupportList = React.lazy(
  () =>
    import("Modules/Customers/HIPAARiskAssessment/Support/SupportList") as any
);
const LocationList = React.lazy(
  () =>
    import("Modules/Customers/HIPAARiskAssessment/Location/LocationList") as any
);
const ThreatsList = React.lazy(
  () =>
    import("Modules/Customers/HIPAARiskAssessment/Threats/ThreatsList") as any
);

const AssetsGroupList = React.lazy(
  () =>
    import(
      "Modules/Customers/HIPAARiskAssessment/AssetsGroup/AssetsGroupList"
    ) as any
);

const AssetManagementRoutes = () => {
  const tabItems = [
    {
      id: "assets",
      link: "asset",
    },
    {
      id: "groups",
      link: "assetGroup",
    },
    {
      id: "categories",
      link: "category",
    },
    {
      id: "support",
      link: "support",
    },
    {
      id: "locations",
      link: "location",
    },
    {
      id: "threats",
      link: "threat",
    },
  ];
  return (
    <>
      <br />
      <Row className="dashboard-header">
        <Col xs="12">
          <Nav variant="tabs" style={{ display: "inline-flex" }}>
            {tabItems.map((item) => {
              return [
                <LinkContainer
                  exact
                  to={`/assessments/assetManagement/${item.link}`}
                >
                  <Nav.Link>
                    {item.id.charAt(0).toUpperCase() + item.id.slice(1)}
                  </Nav.Link>
                </LinkContainer>,
              ];
            })}
          </Nav>
        </Col>
      </Row>
      <Switch>
        <Route
          path="/assessments/assetManagement/asset"
          component={AssetsList}
        />
        <Route
          path="/assessments/assetManagement/assetGroup"
          component={AssetsGroupList}
        />

        <Route
          path="/assessments/assetManagement/category"
          component={CategoryList}
        />

        <Route
          path="/assessments/assetManagement/support"
          component={SupportList}
        />

        <Route
          path="/assessments/assetManagement/location"
          component={LocationList}
        />

        <Route
          path="/assessments/assetManagement/threat"
          component={ThreatsList}
        />
        <Redirect to="/assessments/assetManagement/asset" />
      </Switch>
    </>
  );
};

export default AssetManagementRoutes;
