import React, { createContext, useContext, useEffect } from "react";
import useQueueManager from "./useQueueManager";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AlertType {
  id?: string;
  contents: string;
  style:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "dark"
    | "light"
    | undefined;
}

export const AlertContext = createContext({
  removeAlert: (alertid: string): void => {},
  addAlert: (alert: AlertType): string => "",
});

const AlertQueue: React.FC = ({ children }) => {
  const { queue, addToQueue, removeFromQueue } = useQueueManager<AlertType>();
  const notify = () => {
    queue.forEach((alert) => {
      switch (alert.style) {
        case "success": {
          return toast.success(alert.contents, {
            onClose: () => {
              removeFromQueue(alert.key);
            },
            closeButton: (
              <FontAwesomeIcon icon={faTimes} style={{ color: "#155724" }} />
            ),
            style: {
              color: "#155724",
              background: "#d4edda",
            },
          });
        }
        case "danger": {
          return toast.error(alert.contents, {
            onClose: () => {
              removeFromQueue(alert.key);
            },
          });
        }
        case "warning": {
          return toast.warn(alert.contents, {
            onClose: () => {
              removeFromQueue(alert.key);
            },
          });
        }
        case "primary": {
          return toast.info(alert.contents, {
            onClose: () => {
              removeFromQueue(alert.key);
            },
          });
        }
        default:
          break;
      }
    });
  };
  useEffect(() => {
    if (queue.length > 0) {
      notify();
    }
  });
  return (
    <AlertContext.Provider
      value={{ addAlert: addToQueue, removeAlert: removeFromQueue }}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      {children}
    </AlertContext.Provider>
  );
};

export const useAlertQueue = () => useContext(AlertContext);

export default AlertQueue;
