import { usePermissions } from "common/Permissions";
import React, { useEffect, useMemo } from "react";
import {
  Col, Form, FormCheck, Row, 
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useEditContext } from "./HGFormContext";
import { formatPriority, formatSeverity, formatStatus } from "utils/formatStatus";
import _ from "lodash";
import Slider from "react-input-slider";

export const HGFormCheckbox: React.FC<any> = (props) => {
  const methods = useFormContext();
  return <FormCheck type="checkbox" ref={methods.register} {...props} />;
};

export const HGFormControl: React.FC<
| {
  label?: string;
  name?: string;
  enumObj?: Object;
  inputGroup?: any;
  value: string;
  registerProps?: Object;
  noRegister?: boolean;
  defaultValue?: string;
  hideLabel?: boolean;
  checkboxLabel?: boolean;
  unwrapped?: boolean;
  options?: { id: string; title: string }[];
  overrideFormat?: Function;
  error: any;
  help: any;
  isApprover?: any;
}
| any
> = ({
  label,
  name,
  children,
  enumObj,
  inputGroup,
  value,
  registerProps,
  noRegister,
  defaultValue,
  hideLabel,
  checkboxLabel,
  unwrapped,
  options,
  overrideFormat,
  error,
  help,
  isApprover,
  ...props
}: any) => {
  const { organizationRole, groupRole } = usePermissions();
  const methods = useFormContext();
  const {
    errors, register, getValues, setValue, 
  } = methods;
  const { editing, resolverFields } = useEditContext();
  const _options = JSON.stringify(options);

  const resolverField =    resolverFields
    && (resolverFields?.[name]
      || _.get(resolverFields, name.replaceAll(".", ".fields.")));

  useEffect(() => {
    if (defaultValue || defaultValue >= 0 || !editing) {
      setValue(name, defaultValue);
    }
  }, [name, defaultValue, editing, _options]);
  const values = getValues();
  const _value = value ?? values[name];
  

  const enumOptions: any[] =    enumObj
    && Object.values(enumObj).filter(
      (val) =>
        val !== "Closed"
        || (isApprover !== undefined ? isApprover === true : (organizationRole === "admin"
        || groupRole === "admin")),
    );
  const _label = label || resolverField?._label || undefined;

  const registerElement =    (!noRegister
      && ((!registerProps && register) || register(registerProps)))
    || undefined;

  const Control: React.FC =    props.type === "checkbox" ? FormCheck : Form.Control;

  const _error = error || errors?.[name] || _.get(errors, name);

  const FormElement = useMemo(
    () => (
      <Control
        defaultValue={
          defaultValue
          || resolverFields?.[name]?._default
          || (props.type === "number" && "0")
          || ""
        }
        value={value ?? undefined}
        hidden={props.hidden || !editing}
        key={name}
        name={name}
        ref={registerElement}
        isInvalid={!!_error}
        label={label}
        checked={
          (props.type === "checkbox"
            && getValues(name)?.some?.((_id) => _id === value))
          || undefined
        }
        {...props}
      >
        {enumOptions?.map((o) => (
          <option key={o} value={o}>
            {(name === "status" && formatStatus(o))
              || (name === "severity" && formatSeverity(o))
              || (name === "priority" && formatPriority(o))
              || o}
          </option>
        ))
          || options?.map((o) => (
            <option key={o.id} value={o?.id ? o?.id : ""}>
              {o?.id ? o?.title : "Select"}
            </option>
          ))
          || children}
      </Control>
    ),
    // eslint-disable-next-line
    [props, defaultValue, _options]
  );

  const WrappedElement = (
    <Form.Group key={name}>
      {!hideLabel && _label && props.type !== "checkbox" && (
        <Form.Label
          style={{ fontWeight: "bold" }}
          className={props.labelClass ? props.labelClass : null}
          id={name}
        >
          {_label}
          {help}
        </Form.Label>
      )}
      {(editing && inputGroup?.(FormElement)) || FormElement}
      {!editing && (
        <p
          style={{
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {overrideFormat?.(_value)
            || options?.find(({ id }) => id === _value)?.title
            || (name === "status" && formatStatus(_value))
            || (name === "severity" && formatSeverity(_value))
            || (name === "priority" && formatPriority(_value))
            || _value}
        </p>
      )}
      {editing && _error && (
        <Form.Control.Feedback type="invalid">
          {_error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );

  return ((unwrapped || props.type === "hidden") && FormElement) || WrappedElement;
};

export const HGFormSlider = ({ inputID, defaultValue }) => {
  const { getValues, setValue } = useFormContext();

  const val = getValues(inputID);
  return (
    <>
      <Row>
        <Col xs={12} sm={8} md={6} lg={4} xl={3}>
          <HGFormControl
            name={inputID}
            defaultValue={defaultValue}
            type="number"
            className="inline"
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: "5px" }}>
          <Slider axis="x" x={val} onChange={({ x }) => setValue(inputID, x)} />
        </Col>
      </Row>
    </>
  );
};
