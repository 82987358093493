import { categories, subcategories, items } from "./categories.data";
import {
  CategoryRaw,
  RiskCategory,
  RiskCategoryOptions,
} from "./categories.types";

export const mapRiskCategoryOptions = (
  parent: CategoryRaw
): RiskCategoryOptions => ({
  label: parent.title,
  value: "header",
  disabled: true,
  children: items
    .filter((item) => item.parentId === parent.id)
    .map((item) => ({
      label: item.title,
      value: item.id.toString(),
    })),
});

export const getRiskCategories = (): RiskCategory => {
  const riskCategories = categories.map((category) => {
    return {
      ...category,
      options: subcategories
        .filter((sub) => sub.parentId === category.id)
        .map(mapRiskCategoryOptions)
        .reduce(
          (a, { children = [], ...b }) => [...a, b, ...(children || [])],
          [] as Array<RiskCategoryOptions>
        ),
    };
  });
  return { riskCategories };
};
