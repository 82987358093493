import { gql } from "@apollo/client";
import {
  GetThreatsQuery,
  GetThreatsQueryVariables,
  GetThreatssInGroupQuery,
  GetThreatssInGroupQueryVariables,
} from "API";
import { usePermissions } from "common/Permissions";
import { getThreats, getThreatssInGroup } from "graphql/queries";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";

export const useGetThreatsInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetThreatssInGroupQuery,
    GetThreatssInGroupQueryVariables
  >(
    gql(getThreatssInGroup),
    {
      variables: {
        groupID: group.id,
      },
    },
    hasGroup
  );

  const threats = (response.data?.getThreatssInGroup.items || [])
    .map(sortToID("THREATS#"))
    .sort((a, b) =>
      a.title.toLowerCase() < b.title.toLowerCase()
        ? -1
        : b.title.toLowerCase() > a.title.toLowerCase()
        ? 1
        : 0
    );

  return { ...response, threats };
};

export const useGetThreats = (id) => {
  const { hasGroup } = usePermissions();
  const response = useProtectedQuery<GetThreatsQuery, GetThreatsQueryVariables>(
    gql(getThreats),
    {
      variables: {
        id,
      },
    },
    hasGroup
  );

  return {
    ...response?.data?.getThreats,
    threatsTitle: response?.data?.getThreats?.title,
  };
};

export const useGetThreatsTableHtml = (items) => {
  if (items?.length > 0) {
    const keys = Object.keys(items[0]);
    let threatHtmlStrng = `<table><tbody><tr>`;
    keys.forEach((key) => {
      threatHtmlStrng += `<th colspan="1" rowspan="1">${(
        key.charAt(0).toUpperCase() + key.slice(1)
      )
        .replace(/([A-Z])/g, " $1")
        .trim()}</th>`;
    });
    threatHtmlStrng += `</tr>`;
    items &&
      items.forEach((item) => {
        const value = Object.values(item);
        threatHtmlStrng += `<tr>`;
        value.forEach((i) => {
          threatHtmlStrng += `<td colspan="1" rowspan="1">${i}</td>`;
        });
        threatHtmlStrng += `</tr>`;
      });
    threatHtmlStrng += `</tbody></table>`;
    return threatHtmlStrng;
  }
};
