import { gql, useLazyQuery } from "@apollo/client";
import {
  GetArtifactsInGroupQuery,
  GetArtifactsInGroupQueryVariables,
} from "API";
import { usePermissions } from "common/Permissions";
import { getArtifactsInGroup } from "graphql/queries";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";

const artifactsInGroupTransform = (response) => {
  return (response.data?.getArtifactsInGroup?.items || [])
    .map(sortToID("ARTIFACT#"))
    .map(({ ...artifact }) => ({ ...artifact, title: artifact?.fileName }));
};

export const useGetArtifactsInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
  GetArtifactsInGroupQuery,
  GetArtifactsInGroupQueryVariables
  >(
    gql(getArtifactsInGroup),
    {
      variables: {
        groupID: group.id,
      },
    },
    hasGroup,
  );

  const artifacts = (response.data?.getArtifactsInGroup?.items || [])
    .map(sortToID("ARTIFACT#"))
    .map(({ ...artifact }) => ({ ...artifact, title: artifact?.fileName }));

  return { ...response, artifacts };
};

export const useGetArtifactsInSelectedGroup = () => {
  const [_getArtifactsInSelectedGroup, response] = useLazyQuery<
  GetArtifactsInGroupQuery,
  GetArtifactsInGroupQueryVariables
  >(gql(getArtifactsInGroup));

  return (groupID) => _getArtifactsInSelectedGroup(groupID).then(artifactsInGroupTransform);
};
