import React from "react";
import { DateTime } from "luxon";
import { Col, FormLabel, Row } from "react-bootstrap";

export type HasDisplayDates = {
  createdAt?: string | null;
  lastModified?: string | null;
};

export const AttributeDateDisplay: React.FC<{
  item: HasDisplayDates | null;
  col?: Object;
}> = ({ item, col }) => {
  return item ? (
    <Row>
      {item?.lastModified && (
        <Col xs="6">
          <FormLabel>
            <b>Last Modified</b>
          </FormLabel>
          <p style={{ whiteSpace: "normal" }}>
            {DateTime.fromISO(item?.lastModified).toLocaleString(
              DateTime.DATETIME_MED
            )}
          </p>
        </Col>
      )}
      {item?.createdAt && (
        <Col xs="6">
          <FormLabel>
            <b>Created At</b>
          </FormLabel>
          <p style={{ whiteSpace: "normal" }}>
            {DateTime.fromISO(item?.createdAt).toLocaleString(
              DateTime.DATETIME_MED
            )}
          </p>
        </Col>
      )}
    </Row>
  ) : null;
};
