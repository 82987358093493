import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { usePermissions } from "common/Permissions";
import { cancelPopupMessage } from "utils/globalVariables";
import { HGForm, HGFormSubmit } from "common/HGForm";
import { createRiskDomain } from "graphql/mutations";
import {
  CreateRiskDomainInput,
  CreateRiskDomainMutation,
  CreateRiskDomainMutationVariables,
} from "API";
import gql from "graphql-tag";
import { useMutation, ApolloError } from "@apollo/client";
import RiskDomainInputs from "./RiskDomainInputs";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import * as yup from "yup";
import { useGetRiskDomainInGroup } from "utils/connectors/riskDomainConnectors";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";

export const CreateRiskDomainButton: React.FC<{
  onComplete?: Function;
  onOpen?: Function;
  onClose?: Function;
  defaultValues?: Object;
}> = ({ onComplete, onOpen, onClose, defaultValues, ...props }) => {
  const { riskDomains } = useGetRiskDomainInGroup();
  const { group } = usePermissions();
  const [showCreateRiskDomain, setShowCreateRiskDomain] = useState<boolean>(false);

  const [makeRiskDomain] = useMutation<
    CreateRiskDomainMutation,
    CreateRiskDomainMutationVariables
  >(gql(createRiskDomain), {
    onCompleted: (response) => {
      setShowCreateRiskDomain(false);
      onComplete?.(response?.CreateRiskDomain);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });
  
  const RiskDomainSchema = yup.object<Partial<CreateRiskDomainInput>>({
    displayID: yup.string().label("ID"),
    title: yup
      .string()
      .required()
      .label("Title")
      .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
      .test(
        "check-duplication",
        "Risk Domain title already exists!",
        (value) => {
          if (!value) return true
           return  !isUniqueTitle(riskDomains, value)
        }
      ),
    description: yup.string().label("Description"),
    parentID: yup.string().label("Parent"),
  });

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowCreateRiskDomain(false);
    }
  }

  useEffect(() => {
    if (showCreateRiskDomain) {
      return onOpen?.();
    }
    return onClose?.();
  }, [showCreateRiskDomain]);
  return (
    <>
      <Button
        className="float-right"
        {...props}
        onClick={() => {
          setShowCreateRiskDomain(true);
        }}
      >
        Create Risk Domain
      </Button>
      <Modal
        className="auditmodal"
        show={!!showCreateRiskDomain}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          onSubmit={async (input) => {
            return makeRiskDomain({
              variables: {
                input: {
                  ...input,
                  groupID: group.id,
                },
              },
            });
          }}
          resolver={RiskDomainSchema}
        >
          <Modal.Header>Create Risk Domain</Modal.Header>
          <Modal.Body>
            <RiskDomainInputs />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                Confirmation();
              }}
              variant="light"
              className="cancel-btn"
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
