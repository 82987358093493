import { gql } from "@apollo/client";
import {
  GetDailyActiveUsersInOrganizationQuery,
  GetDailyActiveUsersInOrganizationQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  GetUsersInGroupQuery,
  GetUsersInGroupQueryVariables,
  GetUsersInOrganizationQueryVariables,
} from "API";

import {
  getDailyActiveUsersInOrganization,
  getUser,
  getUsersInGroup,
} from "graphql/queries";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";
import { DateTime } from "luxon";
import {
  getUsersInGroupLimited,
  GetUsersInGroupQueryLimited,
  getUsersInOrganizationLimited,
  GetUsersInOrganizationQueryLimited,
} from "./userConnectors.queries";

export type UserType = {
  id: string;
  displayName: string;
  userRole: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    inactive: boolean;
  };
  userInfo: {
    firstName: string;
    lastName: string;
    email: string | null;
    inactive: boolean;
  };
  meta: {
    forceResetPassword?: boolean;
  };
};

const addDisplayName = (user: UserType): UserType => {
  const _displayName =
    (user.user?.firstName &&
      user.user?.lastName &&
      `${user.user.firstName} ${user.user.lastName}`) ||
    user.user?.firstName ||
    user.user?.email ||
    user.id.toString();

  const displayName = `${_displayName}${
    (user.user?.inactive && " (inactive)") || ""
  }`;
  return {
    ...user,
    displayName,
  };
};

const sortByDisplayName = (a: UserType, b: UserType) =>
  a?.displayName > b?.displayName ? 1 : -1;

export const useGetUsersInGroup = (id) => {
  const response = useProtectedQuery<
    GetUsersInGroupQueryLimited,
    GetUsersInGroupQueryVariables
  >(
    gql(getUsersInGroupLimited),
    {
      variables: {
        id,
      },
    },
    !!id
  );

  const users: Array<UserType> = (response.data?.getUsersInGroup?.items || [])
    .map(sortToID("USER#"))
    .map(addDisplayName)
    .sort(sortByDisplayName);

  return { ...response, users };
};

export const useGetUsersInOrganization = (id) => {
  const response = useProtectedQuery<
    GetUsersInOrganizationQueryLimited,
    GetUsersInOrganizationQueryVariables
  >(
    gql(getUsersInOrganizationLimited),
    {
      variables: {
        id,
      },
    },
    !!id
  );

  const users: Array<UserType> = (
    response.data?.getUsersInOrganization?.items || []
  )
    .map(sortToID("USER#"))
    .map(addDisplayName)
    .sort(sortByDisplayName);

  return { ...response, users };
};

export const useGetCurrentUser = (id) => {
  const response = useProtectedQuery<GetUserQuery, GetUserQueryVariables>(
    gql(getUser),
    {
      variables: {
        id,
      },
    },
    !!id
  );

  const user = response.data?.getUser;

  return { ...response, user };
};

export const useGetDailyActiveUser = (id) => {
  const dt = DateTime.now();
  const response = useProtectedQuery<
    GetDailyActiveUsersInOrganizationQuery,
    GetDailyActiveUsersInOrganizationQueryVariables
  >(
    gql(getDailyActiveUsersInOrganization),
    {
      variables: {
        id,
      },
    },
    !!id
  );
  return response?.data?.getDailyActiveUsersInOrganization?.items;
};
