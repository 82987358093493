import { faTimes, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePermissions } from "common/Permissions";
import React from "react";
import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useEditContext } from "./HGFormContext";

export const HGFormSubmit: React.FC<any> = ({
  children,
  title = null,
  ...props
}) => {
  const { formState, handleSubmit } = useFormContext();
  const { editing, submit } = useEditContext();
  return (
    (editing && (
      <Button
        {...props}
        disabled={
          !formState.isValid ||
          formState.isSubmitting ||
          props?.disable ||
          (props?.applyDirty ? !formState.isDirty : null)
        }
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          handleSubmit(submit)();
          props.setIsEdit && props?.setIsEdit(false)
        }}
      >
        {(formState.isSubmitting && "Saving...") ||
          children ||
          (title && title) ||
          "Submit"}
      </Button>
    )) ||
    null
  );
};

export const HGEditToggle: React.FC<any> = ({ onEdit, onCancel, ...props }) => {
  const { editing, toggleEditing } = useEditContext();
  return (
    <Button
      data-testid="toggleButton"
      {...props}
      variant="link"
      style={{ margin: "2px 15px 2px 4px" }}
      onClick={() => {
        if (editing) {
          onCancel?.();
          props.setIsEdit && props?.setIsEdit(false)
        } else {
          onEdit?.();
          props.setIsEdit && props?.setIsEdit(true)
        }
        toggleEditing();
      }}
    >
      {(editing && <FontAwesomeIcon size="lg" icon={faTimes} />) || (
        <FontAwesomeIcon
          data-testid="toggleIcon"
          size="lg"
          icon={faPencilAlt}
        />
      )}
    </Button>
  );
};

export const HGToggleSubmit: React.FC<any> = ({
  onEdit,
  onCancel,
  ...props
}) => {
  const { groupRole } = usePermissions();
  return (
    <span {...props}>
      {
        groupRole !== 'read' 
        && <>
          <HGFormSubmit style={{ padding: "2px 4px" }} {...props}/>
          <HGEditToggle onCancel={onCancel} onEdit={onEdit} {...props}/>
           </>
      }
      
    </span>
  );
};

export const HGShowHide: React.FC<any> = ({
  isEditing,
  isNotEditing,
  children,
}) => {
  const { editing } = useEditContext();
  return (
    (isEditing && editing && children) ||
    (isNotEditing && !editing && children) ||
    null
  );
};
