import { CategoryRaw, CategoryChildRaw } from "./categories.types";

export const categories: Array<CategoryRaw> = [
  { id: 1, title: "Threats" },
  { id: 2, title: "Assets" },
  { id: 3, title: "Events" },
  { id: 4, title: "Impact Categories" },
  { id: 5, title: "Financial Loss" },
];

export const subcategories: Array<CategoryChildRaw> = [
  { id: 1, parentId: 1, title: "Malicious/Targeted" },
  { id: 2, parentId: 1, title: "Malicious/Non-Targeted" },
  { id: 3, parentId: 1, title: "Non-Malicious/Human" },
  { id: 4, parentId: 1, title: "Non-Malicious/Non-Human" },

  { id: 5, parentId: 2, title: "Information Assets" },
  { id: 6, parentId: 2, title: "Network Services" },
  { id: 7, parentId: 2, title: "Physical Assets" },
  { id: 8, parentId: 2, title: "Cloud Assets" },

  { id: 9, parentId: 3, title: "Threat Event" },
  { id: 10, parentId: 3, title: "Information/System Related Outcomes" },
  { id: 11, parentId: 3, title: "Legal Action" },
  { id: 12, parentId: 3, title: "Business Risk Categories" },
  { id: 13, parentId: 3, title: "Investigation/Audit" },

  { id: 14, parentId: 4, title: "Loss Event Type" },

  { id: 15, parentId: 5, title: "Productivity Loss" },
  { id: 16, parentId: 5, title: "Response Costs" },
  { id: 17, parentId: 5, title: "Replacement Costs" },
  { id: 18, parentId: 5, title: "Competitive Advantage Loss" },
  { id: 19, parentId: 5, title: "Fines and Judgment" },
  { id: 20, parentId: 5, title: "Reputation Damage" },
];

export const items: Array<CategoryChildRaw> = [
  { id: 1, parentId: 1, title: "Cybercriminal" },
  { id: 2, parentId: 1, title: "Terrorist" },
  { id: 3, parentId: 1, title: "Political Activist" },
  { id: 4, parentId: 1, title: "Competitor" },
  { id: 5, parentId: 1, title: "Legal Adversary" },
  { id: 6, parentId: 1, title: "Disgruntled Employee / Contractor" },
  { id: 7, parentId: 1, title: "Vendor" },

  { id: 8, parentId: 2, title: "Malware" },
  { id: 9, parentId: 2, title: "Thief (opportunistic)" },

  { id: 10, parentId: 3, title: "Untrained Employee / Contractor" },
  { id: 11, parentId: 3, title: "Reckless Employee / Contractor" },
  { id: 12, parentId: 3, title: "Business Partner / Vendor" },

  { id: 13, parentId: 4, title: "Acts of God / Nature" },
  { id: 14, parentId: 4, title: "Failure" },

  { id: 15, parentId: 5, title: "Personal Health Information (PHI)" },
  { id: 16, parentId: 5, title: "Personal Identifiable Information (PII)" },
  { id: 17, parentId: 5, title: "Business Sensitive Information (BSI)" },
  { id: 18, parentId: 5, title: "Intellectual Property (IP)" },
  { id: 19, parentId: 5, title: "Bank account credentials" },
  { id: 20, parentId: 5, title: "Network credentials / account information" },
  {
    id: 21,
    parentId: 5,
    title: "Local system credentials / account information",
  },
  {
    id: 22,
    parentId: 5,
    title: "Application credentials / Account information",
  },
  { id: 23, parentId: 5, title: "Service account credentials / information" },
  { id: 24, parentId: 5, title: "External credentials / account information" },
  { id: 25, parentId: 5, title: "Two-factor auth secret" },
  { id: 26, parentId: 5, title: "Digital certificate" },
  { id: 27, parentId: 5, title: "Credit Card Information" },

  { id: 28, parentId: 6, title: "Network infrastructure services" },
  { id: 29, parentId: 6, title: "Virtualization infrastructure services" },
  { id: 30, parentId: 6, title: "Public Internet services" },
  { id: 31, parentId: 6, title: "Network / system management systems" },

  { id: 32, parentId: 7, title: "Desktop / Workstation" },
  { id: 33, parentId: 7, title: "Laptops" },
  { id: 34, parentId: 7, title: "Mobile Devices" },
  { id: 35, parentId: 7, title: "Mobile Media" },
  { id: 36, parentId: 7, title: "Servers" },
  { id: 37, parentId: 7, title: "Medical Devices" },
  { id: 38, parentId: 7, title: "Hard Drives / Internal Storage" },
  { id: 39, parentId: 7, title: "Network Storage Devices" },
  { id: 40, parentId: 7, title: "Backup Media" },
  { id: 41, parentId: 7, title: "Virtual Machines" },

  { id: 42, parentId: 8, title: "Cloud Storage" },
  { id: 43, parentId: 8, title: "Hosted Systems" },
  { id: 44, parentId: 8, title: "Cloud Applications" },
  { id: 45, parentId: 8, title: "Mobile APplications" },

  { id: 46, parentId: 9, title: "Lost / Stolen Device" },
  { id: 47, parentId: 9, title: "Social Engineering" },
  { id: 48, parentId: 9, title: "System Intrusion / Compromise" },
  { id: 49, parentId: 9, title: "Network Intrusion / Compromise" },

  { id: 50, parentId: 10, title: "Information Destruction" },
  { id: 51, parentId: 10, title: "Information Access (Unauthorized)" },
  {
    id: 52,
    parentId: 10,
    title: "Resource Abuse (e.g. bandwidth, computing or storage capacity",
  },
  { id: 53, parentId: 10, title: "Data Exposure (Breach)" },
  { id: 54, parentId: 10, title: "Data Modification" },
  { id: 55, parentId: 10, title: "System Modification" },
  { id: 56, parentId: 10, title: "Communication Interruption" },
  { id: 57, parentId: 10, title: "Performance Degradation" },

  { id: 58, parentId: 11, title: "Class Action Suit" },
  { id: 59, parentId: 11, title: "Civil Action Suit" },
  { id: 60, parentId: 11, title: "Criminal Action Suit" },

  { id: 61, parentId: 12, title: "Financial" },
  { id: 62, parentId: 12, title: "Legal" },
  { id: 63, parentId: 12, title: "Compliance" },
  { id: 64, parentId: 12, title: "Privacy" },
  { id: 65, parentId: 12, title: "Health and Safety" },

  { id: 66, parentId: 13, title: "Complaint" },
  { id: 67, parentId: 13, title: "Random Selection" },
  { id: 68, parentId: 13, title: "Notification (Required / Voluntary)" },
  { id: 69, parentId: 13, title: "Public Discourse Through Media" },

  { id: 70, parentId: 14, title: "Confidentiality" },
  { id: 71, parentId: 14, title: "Integrity" },
  { id: 72, parentId: 14, title: "Availability" },

  { id: 73, parentId: 15, title: "Degraded Performance" },
  { id: 74, parentId: 15, title: "Unable to Perform Duty / Function" },

  { id: 75, parentId: 16, title: "IT Incident Response" },
  { id: 76, parentId: 16, title: "Forensics / Investigation" },
  { id: 77, parentId: 16, title: "Breach Notification" },
  { id: 78, parentId: 16, title: "Identity Theft Protection" },
  { id: 79, parentId: 16, title: "Legal Counsel" },

  { id: 80, parentId: 17, title: "Equipment" },
  { id: 81, parentId: 17, title: "System Rebuild" },
  { id: 82, parentId: 17, title: "Information Recreate / Restore" },

  { id: 83, parentId: 18, title: "Intellectual Property" },
  { id: 84, parentId: 18, title: "Strategy / Plans" },

  { id: 85, parentId: 19, title: "Civil / Class Action" },
  { id: 86, parentId: 19, title: "Regulators" },

  { id: 87, parentId: 20, title: "Patient / Client Revenue (Market Share)" },
];
