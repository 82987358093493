import React, { useMemo, useState } from "react";
import { ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { Button, Col, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import { cancelPopupMessage } from "utils/globalVariables";
import { useMutationWithManualUpdate } from "utils/useMutationWithManualUpdate";
import {
  AuditPlanTemplateInput,
  AuditPlanTemplateMutation,
  AuditPlanTemplateMutationVariables,
  CloneAuditPlanMutation,
  CloneAuditPlanMutationVariables,
} from "API";
import { auditPlanTemplate, cloneAuditPlan } from "graphql/mutations";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { HGForm, HGFormSubmit } from "common/HGForm";
import { useGetAuditPlansInGroup } from "utils/connectors/auditPlanningConnectors";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";
import { HGInputBox } from "common/HGForm/Inputs";

export const AuditPlanningTemplatebutton = ({ onComplete, auditPlan }) => {
  const { auditPlans, auditPlansTemplates } = useGetAuditPlansInGroup();
  const [showAuditPlanTemplate, setShowAuditPlanTemplate] =
    useState<boolean>(false);

  const [CreateAuditPlanTemplate] = useMutationWithManualUpdate<
    AuditPlanTemplateMutation,
    AuditPlanTemplateMutationVariables
  >(
    gql(auditPlanTemplate),
    {
      mutationKey: "AuditPlanTemplate",
      currentData: auditPlan,
      onCompleted: (response) => {
        setShowAuditPlanTemplate(false);
        onComplete(response);
      },
      onError: ({ message }: ApolloError) => {
        ToastifyQueue(message, "danger");
      },
    },
    { message: "Audit Plan Template Created Successfully" }
  );

  const [CloneAuditPlan] = useMutationWithManualUpdate<
    CloneAuditPlanMutation,
    CloneAuditPlanMutationVariables
  >(
    gql(cloneAuditPlan),
    {
      mutationKey: "CloneAuditPlan",
      currentData: auditPlan,
      onCompleted: (response) => {
        setShowAuditPlanTemplate(false);
        onComplete(response);
      },
      onError: ({ message }: ApolloError) => {
        ToastifyQueue(message, "danger");
      },
    },
    { message: "Audit Plan Cloned Successfully" }
  );

  const AuditPlanTemplateSchema = useMemo(() => {
    return yup.object<Partial<AuditPlanTemplateInput>>({
      auditPlanID: yup.string().default(auditPlan?.id).required().label("id"),
      title: yup
        .string()
        .default(
          `${auditPlan?.title} ${
            auditPlan?.isTemplate === true ? "" : "Template"
          }`
        )
        .required()
        .label("Title")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test(
          "check-duplication",
          `${
            auditPlan?.isTemplate === true
              ? "Audit Plan"
              : "Audit Plan Template"
          } title already exists!`,
          (value) => {
            if (!value) return true;
            return !isUniqueTitle(
              auditPlan?.isTemplate === true ? auditPlans : auditPlansTemplates,
              value
            );
          }
        ),
    });
  }, [auditPlan, auditPlans]);

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowAuditPlanTemplate(false);
    }
  }

  return (
    <>
      <Button
        className="mb-2 ml-2"
        onClick={() => {
          setShowAuditPlanTemplate(true);
        }}
      >
        {auditPlan?.isTemplate === true
          ? "Clone as Audit Plan"
          : "Clone as Template"}
      </Button>
      <Modal
        key="AuditPlanTemplateModal"
        show={!!showAuditPlanTemplate}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          key="templateForm"
          onSubmit={async (input) => {
            if (auditPlan?.isTemplate === true) {
              return CloneAuditPlan({
                variables: { input: { ...input, isTemplate: false } },
              });
            }
            return CreateAuditPlanTemplate({
              variables: { input: { ...input } },
            });
          }}
          resolver={AuditPlanTemplateSchema}
        >
          <Modal.Header>
            {auditPlan?.isTemplate === true
              ? "Clone Audit Plan"
              : "Create Audit Plan Template"}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <HGInputBox name="title" label="Title" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn cancel-btn"
              onClick={() => {
                Confirmation();
              }}
              variant="light"
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
