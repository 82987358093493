import { UpdateAuditPlanningInput, ViewType } from "API";
import React, { useEffect, useMemo, useState } from "react";
import { useAuditPlanContext } from "./AuditPlanningPage";
import { usePermissions } from "common/Permissions";
import { useParams } from "react-router";
import * as yup from "yup";
import { HGForm, HGToggleSubmit } from "common/HGForm";
import { Card, Col, Row } from "react-bootstrap";
import { HGRichText } from "common/HGForm/HGRichText";
import { HGCheckBoxGroup, HGInputBox, HGNumber } from "common/HGForm/Inputs";
import { defaultSummary, resolveIdToStringFn } from "../AuditPlanningInputs";

const AuditPlanningPageResults = () => {
  const { id } = useParams<{ id: string }>();
  const { group } = usePermissions();
  const { auditPlan, updateAuditPlan, viewType } = useAuditPlanContext();
  const [_summary, _setSummary] = useState(defaultSummary);
  const loadedSummary = auditPlan?.summary;
  const auditPlanResultSchema = useMemo(
    () =>
      auditPlan &&
      yup.object<Partial<UpdateAuditPlanningInput>>({
        summary: yup.string().default("").label("Summary"),
        auditFindings: yup.string().default("").label("Findings (Itemized)"),
        auditor: yup.string().default("").label("Conducted By"),
        recommendations: yup.string().default("").label("Recommendations"),
        managementResponse: yup
          .string()
          .default("")
          .label("Management Response"),
        totalHours: yup
          .string()
          .nullable()
          .notRequired()
          .default(auditPlan?.totalHours)
          .label("Actual Hours")
          .matches(
            /^\d*\.?\d*$/,
            "Actual Hours  must be a positive number or zero"
          )
          .test(
            "is-positive-or-zero",
            "Actual Hours  must be a positive number or zero",
            (value) =>
              value === null ||
              value === "" ||
              (value !== undefined && parseFloat(value) >= 0)
          ),
      }),
    [auditPlan]
  );
  useEffect(() => {
    if (!loadedSummary) return;
    _setSummary({
      ...defaultSummary,
      ...JSON.parse(loadedSummary),
    });
  }, [loadedSummary]);
  return (
    <HGForm
      key="form_editAuditPlanningResult"
      onSubmit={async (input) => {
        return await updateAuditPlan({
          variables: {
            input: {
              id,
              groupID: group.id,
              ...input,
              summary: JSON.stringify(_summary),
            },
          },
        });
      }}
      resolver={auditPlanResultSchema}
      editToggle
    >
      <Card>
        <Card.Header>
          <HGToggleSubmit className="float-right" />
          Results
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <HGInputBox
                name="auditor"
                label="Conducted By"
                defaultValue={auditPlan?.auditor}
              />
            </Col>
            {viewType !== ViewType.Standard && (
              <>
                <Col sm={6}>
                  <HGNumber
                    name="totalHours"
                    label="Actual Hours "
                    defaultValue={auditPlan?.totalHours}
                  />
                </Col>
                <Col sm={12}>
                  <HGCheckBoxGroup
                    name="summary"
                    label="Summary"
                    enumObj={_summary}
                    resolveIdToStringFn={resolveIdToStringFn}
                    setEnumObj={_setSummary}
                    idPrefix={"customSummaryCheckbox"}
                  />
                </Col>
              </>
            )}
            <Col sm={12}>
              <HGRichText
                label="Findings (Itemized)"
                name="auditFindings"
                defaultValue={auditPlan?.auditFindings}
              />
            </Col>
            <Col sm={12}>
              <HGRichText
                label="Recommendations"
                name="recommendations"
                defaultValue={auditPlan?.recommendations}
              />
            </Col>
            <Col sm={12}>
              <HGRichText
                label="Management Response"
                name="managementResponse"
                defaultValue={auditPlan?.managementResponse}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </HGForm>
  );
};

export default AuditPlanningPageResults;
