import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const SeverityWizard = ({
  setSeverity,
  serverityData,
  setServerityResponse,
}): any => {
  const [isOpen, setIsOpen] = useState(false);
  const options = ["Yes", "No", "Unsure"];
  const { handleSubmit, register } = useForm();

  const OptionsUi = (questionNo) => {
    return (
      <>
        {options.map((item, index) => {
          return (
            <div>
              <input
                type="radio"
                value={item}
                name={questionNo.questionNo}
                ref={register}
              />
              <label htmlFor={`${index}`}>{item}</label>
            </div>
          );
        })}
      </>
    );
  };

  const onFormSubmit = (data) => {
    if (
      data.Impact1 === "No" &&
      data.Impact2 === "No" &&
      data.Impact3 === "No"
    ) {
      if (data.Urgency === "No") {
        setSeverity("Low");
      } else if (data.Urgency === "Yes" || data.Urgency === "Unsure") {
        setSeverity("Minor");
      }
    } else if (
      data.Impact1 === "Yes" ||
      data.Impact2 === "Yes" ||
      data.Impact3 === "Yes"
    ) {
      if (data.Urgency === "Yes" || data.Urgency === "Unsure") {
        setSeverity("Critical");
      } else if (data.Urgency === "No") {
        setSeverity("Major");
      }
    }
    setServerityResponse(Object.values(data));
    setIsOpen(false);
  };
  return (
    <>
      <Button
        variant="light"
        onClick={() => setIsOpen((preState) => !preState)}
      >
        Wizard
      </Button>
      <form>
        <Modal
          show={isOpen}
          onHide={() => setIsOpen((preState) => !preState)}
          backdrop="static"
        >
          <Modal.Header>Severity Rubric Wizard</Modal.Header>
          <Modal.Body>
            {serverityData?.map((item, index) => {
              const Qno =
                item.QNo !== "Urgency" ? `${item.QNo}${index + 1}` : "Urgency";
              return (
                <Form.Group>
                  <Form.Label>
                    {item.QNo}: {item.Question}?{" "}
                  </Form.Label>
                  <OptionsUi questionNo={Qno} key={Qno} />
                </Form.Group>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              className="mr-2 cancel-btn"
              onClick={() => setIsOpen((preState) => !preState)}
            >
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmit(onFormSubmit)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </>
  );
};

export default SeverityWizard;
