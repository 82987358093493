import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { Modal, Button, InputGroup, Dropdown } from "react-bootstrap";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { HGForm, HGFormControl } from "common/HGForm";
import "./CSVExport.scss";
import GenerateReport from "utils/docx-export/GenerateReport";
import { HGCheckBox, HGInputBox } from "common/HGForm/Inputs";

export const CSVExportModal = ({
  isOpen,
  tabData,
  closeModal,
  type,
  tabs,
  currentTab,
  getFiltered,
  tableShape,
  filteredData,
  downloadFn,
}) => {
  const dateTime = DateTime.now().toFormat("MMddyy-HHmm");
  const [filename, setFilename] = useState(
    `DecipherRisk-${
      type === "RelatableItem" ? "Related-Items" : type
    }-${dateTime}`
  );
  const [filterValue, setFilterValue] = useState("1");
  const [includeValues, setIncludeValues] = useState(
    (currentTab && [currentTab]) || []
  );
  const [fileType, setFileType] = useState<any>(".csv");
  const includedData = includeValues
    .map((d) => tabData[d])
    .reduce((a, b) => [...a, ...b], [])
    .sort((a, b) => a.id - b.id);
  const filteredDatas =
    filterValue === "1"
      ? filteredData.length === 0
        ? []
        : getFiltered(includedData)
      : includedData;
  const finalData = filteredDatas.map((record) =>
    Object.assign(
      {},
      ...tableShape
        .filter((d) => !d.last)
        .map((d) => ({
          [d.name]: (
            d.textFormat?.(record) ||
            d.displayFormat?.(record) ||
            d.format(record)?.toString() ||
            ""
          )
            .replace(/,/g, `\,`)
            .replace(/"/g, `""`)
            .replace(/'/g, `\'`),
        }))
    )
  );

  const handleCheckbox = (e, target) => {
    if (includeValues.some((d) => d === target)) {
      setIncludeValues([...includeValues].filter((d) => d !== target));
    } else {
      setIncludeValues([...includeValues, target]);
    }
  };
  return (
    <Modal show onHide={closeModal}>
      <HGForm>
        <Modal.Header>
          Download {type === "RelatableItem" ? "Related Items" : type}
        </Modal.Header>
        <Modal.Body className="csvmodal">
          <HGInputBox
            name="filename"
            label="File Name"
            defaultValue={filename}
            required
            onChange={(e) => {
              setFilename(e.target.value?.trim());
            }}
            inputGroup={(element) => (
              <InputGroup>
                {element}
                <InputGroup.Append>
                  {downloadFn === null ? (
                    <InputGroup.Text>.csv</InputGroup.Text>
                  ) : (
                    <InputGroup.Text className="pl-0 pr-0">
                      <Dropdown onSelect={(e) => setFileType(e)}>
                        <Dropdown.Toggle>
                          <div style={{ float: "right" }}>
                            <b className="caret" />
                          </div>
                          {fileType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey=".csv">.csv</Dropdown.Item>
                          <Dropdown.Item eventKey=".docx">.docx</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup.Text>
                  )}
                </InputGroup.Append>
              </InputGroup>
            )}
          />
          {!filename && (
            <>
              <p className="text-danger">File Name is a required field</p>
            </>
          )}
          <div className="form-group">
            <label>
              Filter
              <span className="text-info">*</span>
            </label>
            {[
              { id: "1", name: "Use Applied Filters" },
              { id: "2", name: "Ignore Applied Filters" },
            ].map(({ id, name }) => (
              <div className="custom-control custom-radio">
                <input
                  className="custom-control-input"
                  aria-labelledby="checkbox"
                  id={`${id}customRadio1`}
                  type="radio"
                  onChange={(e: any) => {
                    setFilterValue(e.target.value);
                  }}
                  value={id}
                  checked={filterValue === id}
                />

                <label
                  className="custom-control-label"
                  htmlFor={`${id}customRadio1`}
                >
                  {name}
                </label>
              </div>
            ))}
          </div>
          <div className="form-group">
            <label>
              Include
              <span className="text-info">*</span>
            </label>

            {tabs.map((tab, i) => (
              <HGCheckBox
                id={`${i}customCheckbox`}
                onChange={(e) => handleCheckbox(e, tab)}
                checked={includeValues.some((d) => d === tab)}
                label={tab}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              className="btn cancel-btn mb-2"
              style={{ marginRight: "5px" }}
              data-dismiss="modal"
              onClick={closeModal}
            >
              Cancel
            </button>
            {filteredDatas.length === 0 ? (
              <button
                className="btn btn-primary mb-2"
                style={{ cursor: "not-allowed" }}
                disabled
              >
                No matching records
              </button>
            ) : fileType === ".csv" ? (
              <CSVLink
                className={`mb-2 btn btn-primary ${
                  !filename ? "disabled" : ""
                }`}
                data={finalData}
                onClick={(event) => {
                  return finalData.length > 0;
                }}
                filename={`${filename}.csv`}
              >
                Download
              </CSVLink>
            ) : (
              <GenerateReport
                type="Multi"
                items={includedData}
                schema={downloadFn()}
                fileName={filename}
                tableOfContents
              />
            )}
          </div>
        </Modal.Footer>
      </HGForm>
    </Modal>
  );
};

export const CSVExport = ({
  type,
  tabData,
  tabs,
  tableShape,
  filteredData,
  getFiltered,
  currentTab,
  downloadFn,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        {...props}
        variant="light"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <FontAwesomeIcon icon={faDownload} />
      </Button>
      {isOpen && (
        <CSVExportModal
          isOpen
          tabData={tabData}
          tabs={tabs}
          type={type}
          currentTab={currentTab}
          tableShape={tableShape}
          getFiltered={getFiltered}
          filteredData={filteredData}
          downloadFn={downloadFn}
          closeModal={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};
