import { gql, useLazyQuery } from "@apollo/client";
import {
  GetAuditPlanningQuery,
  GetAuditPlanningQueryVariables,
  GetAuditPlanningsInGroupv2QueryVariables,
  GetAuditPlanningsInGroupv2Query,
} from "API";
import { usePermissions } from "common/Permissions";
import { getAuditPlanning, getAuditPlanningsInGroupv2 } from "graphql/queries";
import { formatStatus } from "utils/formatStatus";
import { useGetGroupByRelatedItem } from "utils/useGetGroupByRelatedItems";
import { useGroupCheck } from "utils/useGroupCheck";
import {
  useProtectedPolling,
  useProtectedQuery,
} from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";

const auditPlansInGroupTransform = (response) => {
  return (
    response.data?.getAuditPlanningsInGroupv2?.items.map(
      (item) => item.auditPlanning
    ) || []
  )
    .filter((item) => item?.isTemplate !== true)
    .map((item) => {
      if (item?.status === "Complete") {
        return {
          ...item,
          status: "Closed",
        };
      }
      return item;
    });
};

export const useGetAuditPlansInSelectedGroup = () => {
  const [_getAuditPlansInSelectedGroup, response] = useLazyQuery<
    GetAuditPlanningsInGroupv2Query,
    GetAuditPlanningsInGroupv2QueryVariables
  >(gql(getAuditPlanningsInGroupv2));

  return (id) =>
    _getAuditPlansInSelectedGroup(id).then(auditPlansInGroupTransform);
};

export const useGetAuditPlansInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetAuditPlanningsInGroupv2Query,
    GetAuditPlanningsInGroupv2QueryVariables
  >(
    gql(getAuditPlanningsInGroupv2),
    {
      variables: {
        id: group.id,
      },
    },
    hasGroup
  );

  const auditPlans = (
    response.data?.getAuditPlanningsInGroupv2?.items.map(
      (item) => item.auditPlanning
    ) || []
  )
    .filter((item) => item?.isTemplate !== true)
    .map((item) => {
      if (item?.status === "Complete") {
        return {
          ...item,
          status: "Closed",
        };
      }
      return item;
    });

  const auditPlansTemplates = (
    response.data?.getAuditPlanningsInGroupv2?.items.map(
      (item) => item.auditPlanning
    ) || []
  )
    .filter((item) => item?.isTemplate)
    .map(sortToID("AUDITPLANNING#"))
    .map((item) => {
      if (item?.status === "Complete") {
        return {
          ...item,
          status: "Closed",
        };
      }
      return item;
    });

  return {
    ...response,
    auditPlans,
    auditPlansTemplates,
  };
};

export const useGetAuditPlan = (id) => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetAuditPlanningQuery,
    GetAuditPlanningQueryVariables
  >(
    gql(getAuditPlanning),
    {
      variables: {
        groupID: group.id,
        id,
      },
    },
    hasGroup
  );
  const auditPlan = sortToID("AUDITPLANNING#")(
    response?.data?.getAuditPlanning
  );

  useProtectedPolling(auditPlan, response);

  const canAccess = useGroupCheck(auditPlan?.groupID, "/audits");

  return { ...response, auditPlan: canAccess && auditPlan };
};

export const useGetDownloadAuditPlanObj = (item, files) => {
  const { groups } = usePermissions();
  const groupedRelatedItems = useGetGroupByRelatedItem(
    item?.auditPlanningRelatedItems?.items,
    groups
  );
  const auditPlanArray = [
    "title",
    "status",
    "auditPeriod",
    "background",
    "objectiveOfAudit",
    "scope",
    "methodology",
    "auditFindings",
    "recommendations",
    "managementResponse",
    "auditStartDate",
    "auditEndDate",
    "auditWasPerformedBy",
    "totalHours",
    "relatedItems",
    "followUpActions",
  ];
  const selectArray = (arr, obj) =>
    arr.reduce((r, e) => Object.assign(r, { [e]: obj[e] }), {});
  if (item) {
    item = {
      ...item,
      status: formatStatus(item?.status),
      auditPeriod: item.days ? `${item.days} (days)` : "",
      objectiveOfAudit: item.objective,
      auditWasPerformedBy: item.auditor,
      totalHours: item.hours,
      relatedItems: groupedRelatedItems || " ",
      followUpActions: " ",
    };
    return selectArray(auditPlanArray, item);
  }
};
