import { gql, useMutation } from "@apollo/client";
import {
  RequestArtifactMutation,
  RequestArtifactMutationVariables,
  UploadArtifactMutation,
  UploadArtifactMutationVariables,
} from "API";
import axios, { AxiosRequestConfig } from "axios";
import { requestArtifact, uploadArtifact } from "graphql/mutations";
import { useState } from "react";

const useFileManager = () => {
  const [runGetUploadPermission] = useMutation<
  UploadArtifactMutation,
  UploadArtifactMutationVariables
  >(gql(uploadArtifact));

  const [runGetDownloadPermission] = useMutation<
  RequestArtifactMutation,
  RequestArtifactMutationVariables
  >(gql(requestArtifact));

  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const upload = async ({ fileName, groupID, file }): Promise<string> => {
    setIsUploading(true);
    const retID = await runGetUploadPermission({
      variables: {
        input: [
          {
            fileName,
            groupID,
            displayFileName: fileName.split('.')[0],
          },
        ],
      },
    }).then(async ({ data }) => {
      const artifact: string = data?.UploadArtifact?.toString() || "";
      const artifactObj = JSON.parse(artifact);

      const {
        id,
        response: { uploadUrl },
      } = artifactObj;

      const options: AxiosRequestConfig = {
        headers: {
          "Content-Type": file.type,
        },
      };
      await axios
        .put(uploadUrl, file, options)
        .then((result) => console.log("FILE UPLOAD", result, data))
        .catch((error) => console.error(error));

      return id;
    });
    setIsUploading(false);
    return retID;
  };

  const download = (id: string, fileName: string) => {
    setIsDownloading(true);
    runGetDownloadPermission({
      variables: {
        input: [
          {
            id,
          },
        ],
      },
    }).then(({ data }) => {
      const artifact: string = data?.RequestArtifact?.toString() || "";
      const artifactObj = JSON.parse(artifact);
      const { getUrl } = artifactObj.response;
      axios
        .get(getUrl, {
          responseType: "blob", // important
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); // or any other extension
          document.body.appendChild(link);
          link.click();
          setIsDownloading(false);
        });
    });
    return id;
  };

  return {
    upload,
    download,
    isUploading,
    isDownloading,
  };
};

export default useFileManager;
