import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ChangeLog } from "Modules/Customers/Reporting/Reports/Changelog";
import { OrganizationSettings } from "Modules/Customers/OrganizationSettings";
import { PermissionsView } from "Modules/Customers/PermissionsView";
import { SystemSettings } from "../SystemSettings";
import { UsageStats } from "../UsageStats/UsageStats";

const AdministrationRoutes = () => {
  return (
    <Switch>
      <Route path="/administration/permissions">
        <PermissionsView />
      </Route>
      <Route path="/administration/usageStats">
        <UsageStats />
      </Route>
      <Route path="/administration/organizationSetting">
        <OrganizationSettings />
      </Route>
      <Route path="/administration/changeLog">
        <ChangeLog />
      </Route>
      {/* <Route path="/administration/systemSetting">
        <SystemSettings />
      </Route> */}
      <Redirect to="/administration/permissions" />
    </Switch>
  );
};

export default AdministrationRoutes;
