import React from "react";
import {
  VictoryChart,
  VictoryLabel,
  VictoryAxis,
  VictoryTheme,
  VictoryLegend,
  VictoryLine,
  VictoryContainer,
  VictoryHistogram,
} from "victory";
import { colors } from "./common";
import { Col, ColProps } from "react-bootstrap";
import useDimensions from "react-use-dimensions";
import { humanReadable } from "./formatting";
import { arrayToExceedance } from "./exceedanceTransform";

interface BoxPlotAnalysisInput {
  title: string;
  data: number[];
}

export const base64Data = "";

export type IExceedanceGraphInputs = {
  data: Array<{ title: string; data: any }>;
  label?: string;
  height?: number;
  width?: number;
  displayRawValues?: boolean;
  xFormat?: (x: number) => string | number;
  yFormat?: (y: number) => string;
  withHistogram?: boolean;
  style?: object;
  multiple?: boolean;
} & ColProps;

export const ExceedanceGraphSVG: React.FC<IExceedanceGraphInputs> = ({
  height = 350,
  width = 500,
  data,
  multiple = false,
  yFormat = (x) => x.toString(),
  xFormat = (y) => y.toString(),
  displayRawValues = false,
  withHistogram = false,
}) => {
  const [internalHeight, internalWidth] = [height * 1, width * 1];

  const _data = data.filter(({ data }) => !!data);

  const maxVal = multiple
    ? Math.max(..._data.map((d) => d.data).reduce((a, b) => [...a, ...b], []))
    : Math.max(..._data[0].data);

  const histogramScale = maxVal < 30 ? 4 : 1;
  const linesData = multiple
    ? arrayToExceedance(_data).map((d) =>
      d.map(({ x, y }) => ({
        x,
        y: y * histogramScale,
      })),
    )
    : [
      arrayToExceedance(_data)[0].map(({ x, y }) => ({
        x,
        y: y * histogramScale,
      })),
    ];
  return (
    <svg
      height={height}
      preserveAspectRatio="none"
      width="100%"
      viewBox={`0 0  ${internalWidth} ${internalHeight}`}
      pointerEvents=""
    >
      <VictoryChart
        containerComponent={<VictoryContainer style={{ margin: "auto" }} />}
        padding={{
          left: 50,
          bottom: 50,
          top: 20,
          right: 50,
        }}
        width={internalWidth}
        height={internalHeight}
        standalone={false}
        theme={VictoryTheme.material}
        domain={{ y: [0, 100 * histogramScale] }}
      >
        <VictoryLegend
          x={internalWidth - 180}
          y={25}
          centerTitle
          gutter={10}
          style={{
            parent: { fill: "white" },
            border: { stroke: "black" },
            title: { fontSize: 10 },
            labels: { fontSize: 10 },
          }}
          data={_data.map((line, index) => ({
            name: line.title,
            symbol: { fill: colors[index] },
          }))}
        />
        <VictoryAxis
          style={{
            tickLabels: {
              fontSize: 10,
            },
            grid: {
              strokeWidth: "0.5px",
              stroke: "gray",
              zIndex: "-100",
            },
          }}
          axisLabelComponent={<VictoryLabel dy={-30} />}
          label="Exceedance"
          fixLabelOverlap
          tickFormat={(d: number) => yFormat?.(d / histogramScale) || ""}
          dependentAxis
        />

        <VictoryAxis
          gridComponent={<></>}
          style={{
            tickLabels: {
              fontSize: 10,
            },
          }}
          tickFormat={(val) =>
            (!displayRawValues && xFormat?.(humanReadable(val)))
            || xFormat?.(val)
            || ""
          }
          padding={{ right: 300 }}
        />

        {withHistogram
          && _data.map((_data, index) => (
            <VictoryHistogram
              style={{
                data: {
                  fill: colors[index],
                  strokeWidth: 0.2,
                  strokeOpacity: 0.3,
                  fillOpacity: 0.3,
                },
              }}
              data={_data.data.map((d) => ({
                x: d,
              }))}
              bins={Math.min(70, maxVal + 1)}
              padding={{ left: 300 }}
              standalone
            />
          ))}
        {linesData.map((line, index) => (
          <VictoryLine
            style={{
              data: {
                stroke: colors[index],
                strokeWidth: 1,
                strokeOpacity: 0.8,
              },
            }}
            padding={{ left: 300 }}
            data={line}
          />
        ))}
      </VictoryChart>
      {/* You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods */}
    </svg>
  );
};

export const ExceedenceGraph: React.FC<IExceedanceGraphInputs> = ({
  data,
  label,
  height = 350,
  displayRawValues = false,
  xFormat = (x) => x.toString(),
  yFormat = (y) => y.toString(),
  withHistogram = false,
  multiple = false,
  ...props
}) => {


  const [measureRef, { width }] = useDimensions();

  const graphProps = {
    height,
    width,
    data,
    multiple,
    displayRawValues,
    withHistogram,
    xFormat,
    yFormat,
  };

  return (
    <Col {...props} ref={measureRef}>
      <div
        style={{
          width: "100%",
          fontWeight: "bolder",
          fontSize: "1.2em",
          textAlign: "center",
          paddingTop: "10px",
        }}
      >
        {label}
      </div>
        <div style={{ background: "white" }}>
          <ExceedanceGraphSVG {...graphProps} />
        </div>
    </Col>
  );
};
