import { gql } from "@apollo/client";
import { usePermissions } from "common/Permissions";
import {
  useProtectedPolling,
  useProtectedQuery,
} from "utils/useProtectedApollo";
import {
  GetAssetsGroupQuery,
  GetAssetsGroupQueryVariables,
  GetAssetsGroupsInGroupQuery,
  GetAssetsGroupsInGroupQueryVariables,
} from "API";
import { getAssetsGroup, getAssetsGroupsInGroup } from "graphql/queries";
import { sortToID } from "./connectorUtils";

export const useGetAssetsGroupsInGroup = () => {
  const { group, hasGroup } = usePermissions();
  const response = useProtectedQuery<
    GetAssetsGroupsInGroupQuery,
    GetAssetsGroupsInGroupQueryVariables
  >(
    gql(getAssetsGroupsInGroup),
    {
      variables: {
        groupID: group.id,
      },
    },
    hasGroup
  );

  const assetsGroups = (response.data?.getAssetsGroupsInGroup?.items || [])
    .map(sortToID("ASSETSGROUP#"))
    .sort((a, b) =>
      a.title.toLowerCase() < b.title.toLowerCase()
        ? -1
        : b.title.toLowerCase() > a.title.toLowerCase()
        ? 1
        : 0
    );

  return { ...response, assetsGroups };
};

export const useGetAssetsGroup = (id) => {
  const { group, hasGroup } = usePermissions();
  const response = useProtectedQuery<
    GetAssetsGroupQuery,
    GetAssetsGroupQueryVariables
  >(
    gql(getAssetsGroup),
    {
      variables: {
        groupID: group.id,
        id,
      },
    },
    hasGroup
  );

  const assetsGroup = response.data?.getAssetsGroup;

  return { ...response, assetsGroup };
};
