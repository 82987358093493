import React from "react";
import { useParams } from "react-router";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row } from "react-bootstrap";
import Loader from "common/Loader";
import { useTabbedTable } from "common/TabbedTable";
import * as StatTypes from "common/Stats";
import { useRelatedItemsTableSchema } from "./RelatedItemsTableSchema";
import HGAccordion from "common/BootStrapComponents/HGAccordion";
import { ActionPlanStatus, IssueStatus } from "utils/formatStatus";

export const getRelatedItems = (items) => {
  return items
    .filter(
      (item) =>
        item.targetType === "scenarios" ||
        item.targetType === "issues" ||
        item.targetType === "actionplans"
    )
    .map((item) => {
      if (item.targetType === "issues") {
        return { ...item.item, module: "Issue" };
      }
      if (item.targetType === "actionplans") {
        return { ...item.item, module: "Action Plan" };
      }
      if (item.targetType === "scenarios") {
        return { ...item.item, module: "Scenario" };
      }
    });
};
const RelatedItemsTable = ({ module, relatedItems, moduleSortOrder }) => {
  const { id } = useParams<{ id: string; tab: string }>();
  const tableSchema = useRelatedItemsTableSchema();
  const {
    TableUI,
    ColumnSortFilterUI,
    filteredFormatting,
    setSearchEntry,
    setTab: setHeaderTab,
  } = useTabbedTable(moduleSortOrder, relatedItems, tableSchema as any, null, {
    tabChoices: [
      "Active",
      "Deferred",
      "Closed (Pending Approval)",
      "Closed",
      "Archived",
    ],
    disableColumnEdit: true,
    disableTabs: false,
    sortBy: "Last Modified",
    sortOrder: "asc",
    tabFallback: {
      icon: faThumbsUp,
      header: "No Related items",
    },
  });
  return (
    (relatedItems === undefined && <Loader />) || (
      <>
        <HGAccordion module={`${module}-relatedItems`}>
          <Row className="mt-205">
            <Col xl={4} sm={12}>
              <Card>
                <Card.Header>Issue Status</Card.Header>
                <Card.Body>
                  <StatTypes.StatusGraph
                    type={IssueStatus}
                    data={relatedItems?.filter(
                      (item) =>
                        item.module === "Issue" && item.archived !== true
                    )}
                    onClick={
                      filteredFormatting.some((d) => d.name === "Status") &&
                      ((name) => {
                        setSearchEntry("Module", "Issue");
                        if (name === "Closed") {
                          setHeaderTab("Closed");
                          setSearchEntry("Status", "");
                        } else if (name === "Closed (Pending Approval)") {
                          setHeaderTab("Closed (Pending Approval)");
                          setSearchEntry("Status", "");
                        } else if (name === "Deferred") {
                          setHeaderTab("Deferred");
                          setSearchEntry("Status", "");
                        } else {
                          setHeaderTab("Active");
                          setSearchEntry("Status", name);
                        }
                      })
                    }
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} sm={12}>
              <Card>
                <Card.Header>Action Plan Status</Card.Header>
                <Card.Body>
                  <StatTypes.StatusGraph
                    type={ActionPlanStatus}
                    data={relatedItems?.filter(
                      (item) =>
                        item.module === "Action Plan" && item.archived !== true
                    )}
                    onClick={
                      filteredFormatting.some((d) => d.name === "Status") &&
                      ((name) => {
                        setSearchEntry("Module", "Action Plan");
                        if (name === "Closed") {
                          setHeaderTab("Closed");
                          setSearchEntry("Status", "");
                        } else if (name === "Closed (Pending Approval)") {
                          setHeaderTab("Closed (Pending Approval)");
                          setSearchEntry("Status", "");
                        } else if (name === "Deferred") {
                          setHeaderTab("Deferred");
                          setSearchEntry("Status", "");
                        } else {
                          setHeaderTab("Active");
                          setSearchEntry("Status", name);
                        }
                      })
                    }
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} sm={12}>
              <Card>
                <Card.Header>Scenario Status</Card.Header>
                <Card.Body>
                  <StatTypes.StatusGraph
                    type={IssueStatus}
                    data={relatedItems?.filter(
                      (item) =>
                        item.module === "Scenario" && item.archived !== true
                    )}
                    onClick={
                      filteredFormatting.some((d) => d.name === "Status") &&
                      ((name) => {
                        setSearchEntry("Module", "Scenario");
                        if (name === "Closed") {
                          setHeaderTab("Closed");
                          setSearchEntry("Status", "");
                        } else if (name === "Closed (Pending Approval)") {
                          setHeaderTab("Closed (Pending Approval)");
                          setSearchEntry("Status", "");
                        } else if (name === "Deferred") {
                          setHeaderTab("Deferred");
                          setSearchEntry("Status", "");
                        } else {
                          setHeaderTab("Active");
                          setSearchEntry("Status", name);
                        }
                      })
                    }
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </HGAccordion>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Header>
                <span className="h5">Related Items</span>
                {ColumnSortFilterUI}
              </Card.Header>
              <Card.Body>{TableUI}</Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    )
  );
};

export default RelatedItemsTable;
