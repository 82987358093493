import { faTimes, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button, Col, Row, Spinner, 
} from "react-bootstrap";
import { FileIcon, defaultStyles } from "react-file-icon";

export const InfoBubble = ({
  title,
  itemId,
  color,
  thresholdColor,
  url,
  date = null,
  extension,
  onClick,
  onCancel,
  downloadButton,
  modalFn,
  loading,
  currentId,
  archived,
  userRole,
  groupTitle,
}: {
  title: any;
  itemId?: any; 
  color?: any;
  thresholdColor?: any;
  url?: string;
  date?: any;
  extension?: string;
  onClick?: (e: any) => void;
  onCancel?: (e: any) => void;
  downloadButton?: any;
  modalFn?: any;
  loading?: any;
  currentId?: any;
  archived?: any;
  userRole?: any;
  groupTitle?: any;
}) => {  
  return (
    <div
      style={{
        backgroundColor: color?.background || "#F8F8FA",
        border: "1px solid #E6E7E9",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {extension && (
        <div style={{ maxWidth: "30px", margin: "10px" }}>
          <FileIcon extension={extension} {...defaultStyles[extension]} />
        </div>
      )}
      <div style={{ padding: "10px", width: `${extension ? "70%" : "100%"}` }}>
        {color && (
          <div
            style={{
              borderRadius: "10px",
              height: "20px",
              width: "20px",
              border: `2px solid ${thresholdColor || "rgb(180, 180, 180)"}`,
            }}
          />
        )}
        {
          modalFn ? <Button variant="link" style={{ fontWeight: 'bold', textAlign: 'left' }} onClick={() => {modalFn(true)}}>{title}</Button> : <p style={{ fontWeight: "bold", marginBottom: "0" }}>
{groupTitle && <b>{groupTitle}</b>}
<br/>
{title}
{archived && "(archived)"}
                                                                                                                                                </p>
        }
        {date && <p style={{ marginTop: "0.5rem" }}>{date}</p>}
      </div>
      <div style={{ marginLeft: "auto", marginRight: "10px" }}>
        {downloadButton && (
          <Button
            variant="link"
            onClick={onClick}
            style={{ verticalAlign: "middle" }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        )}
        {
          userRole !== 'read'
          && <Button
            variant="link"
            onClick={onCancel}
            style={{ verticalAlign: "middle" }}
          >
          {loading === false ? <FontAwesomeIcon icon={faTimes} /> : currentId.includes(itemId) ? <Spinner size="sm" animation="border" variant="primary" /> : <FontAwesomeIcon icon={faTimes} />}
             </Button>
        }
        
      </div>
    </div>
  );
};
