import React from "react";
import { usePermissions } from "common/Permissions";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import {
  faFirstAid,
  faBug,
  faRadiationAlt,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import {
  getCompositeId,
  getDateFormat,
  getDateDisplayFormat,
  dateFromCustomFormat,
} from "utils/dataFormatters";
import { formatStatus } from "utils/formatStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const useDashboardSchema = () => {
  const { getOrganizationGroup } = usePermissions();
  return [
    {
      id: "module",
      name: "",
      format: (item) => `${item.icon}`,
      tableDisplayFormat: (item) => (
        <span title={item.icon}>
          <FontAwesomeIcon aria-hidden="true" icon={displayIcon(item.icon)} />
        </span>
      ),
    },
    {
      id: "department",
      name: "Department",
      format: (item) => getCompositeId(item),
      tableDisplayFormat: (item) => (
        <span>
          {getOrganizationGroup(item.groupID)?.title}
        </span>
      ),
    },
    {
      id: "compositeID",
      name: "ID",
      format: (item) => getCompositeId(item),
      tableDisplayFormat: (item) => (
        <span>
          {getCompositeId(item)}
        </span>
      ),
    },
    {
      id: "title",
      name: "Title",
      format: (item) => item.title,
      tableDisplayFormat: (item) => {
        const moduleMap = {
          Issue: (data) => `/issues/${data.id}`,
          "Action Plan": (data) => `/actionplans/${data.id}`,
          Task: (data) => `/actionplans/${data.parentID}/tasks/${data.id}`,
          Scenario: (data) => `/risk/${data.id}`,
        };
        return <Link aria-hidden="true" to={moduleMap[item.module](item)}>{item.title}</Link>;
      },
    },
  ];
};

export const createDateEntry = (id, name) => {
  return {
    id,
    name,
    type: "Date",
    format: getDateFormat(id),
    displayFormat: (item) => {
      let style: any;
      const date = dateFromCustomFormat(item?.[id], "MM/dd/yyyy");
      if (!date) return null;
      if (date < DateTime.local().plus({ days: 7 })) {
        style = { color: "orange" };
      }
      if (date < DateTime.local()) {
        style = { color: "#CC0000" };
      }
      return <div style={style}>{getDateDisplayFormat(id)(item)}</div>;
    },
  };
};

export const schemaStatus = {
  id: "status",
  name: "Status",
  format: ({ status }) => formatStatus(status),
};

export const displayIcon = (icon) => {
  switch (icon) {
    case "Issue": {
      return faBug;
    }
    case "Scenario": {
      return faRadiationAlt;
    }
    case "Action Plan": {
      return faFirstAid;
    }
    case "Task": {
      return faTasks;
    }
    default: {
      return faBug;
    }
  }
};
