import React, { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "react-use";

interface iCollapsibleContext {
  collapsed: boolean | undefined;
  setCollapsed: (val: boolean) => void;
  toggleCollapsed: () => void;
}

const CollapsibleContext = createContext<iCollapsibleContext>({
  collapsed: false,
  setCollapsed: (val: boolean) => {},
  toggleCollapsed: () => {},
});

export const CollapsibleContextProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useLocalStorage<boolean>(
    "sidebar-collapsed",
    true
  );

  const contextVals = useMemo<iCollapsibleContext>(() => {
    return {
      collapsed,
      setCollapsed,
      toggleCollapsed: () => setCollapsed(() => !collapsed),
    };
  }, [collapsed]);
  return (
    <CollapsibleContext.Provider value={contextVals}>
      {children}
    </CollapsibleContext.Provider>
  );
};

export const useCollapsibleContext = () => useContext(CollapsibleContext);
