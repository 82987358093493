import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loader: React.FC = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Spinner animation="border" variant="primary" /> <br />
    </div>
  );
};

export default Loader;
