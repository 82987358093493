import { usePermissions } from "common/Permissions";
import {
  formatPriority,
  formatSeverity,
  formatStatus,
} from "utils/formatStatus";

export type HGSelectOptionType = {
  id?: string | number | null;
  title?: string | null;
};
export type FormatHGSelectProperties = {
  name?: string;
  enumObj?: Object;
  options?: Array<HGSelectOptionType>;
};

export type HGSelectOptionFormattedType = {
  label?: string;
  value?: string | number | null;
};

export const formatHGSelect = (
  props: FormatHGSelectProperties
): Array<HGSelectOptionFormattedType> => {
  if (props.enumObj) {
    const { name, enumObj } = props;
    return Object.keys(props.enumObj)?.map((key) => ({
      label:
        (name === "status" && formatStatus(enumObj[key])) ||
        (name === "severity" && formatSeverity(enumObj[key])) ||
        (name === "priority" && formatPriority(enumObj[key])) ||
        enumObj[key], // If none of the conditions match, use the original label
      value: key,
    }));
  }
  if (props.options) {
    const { options } = props;
    return options.map((obj) => ({
      label: obj.title === "" || obj.title === null ? "Select" : obj.title,
      value: obj.id,
    }));
  }
  return [];
};

export const useFilterHGSelectForApprovers = ({ isApprover }) => {
  const { organizationRole, groupRole } = usePermissions();
  return (item: HGSelectOptionFormattedType) => {
    return (
      item.value !== "Closed" ||
      (isApprover !== undefined
        ? isApprover === true
        : organizationRole === "admin" || groupRole === "admin")
    );
  };
};
