import React, { useEffect, useMemo, useState, useCallback } from "react";

import { useLocalStorage } from "react-use";
import * as R from "ramda";

import searchDictionary from "./searchDictionary";
import SearchUI from "./SearchUI";
import { ITableRecordFormatting } from "common/TabbedTable";

interface IItem {
  name: string;
  visible: boolean;
}

export interface ISelectiveSearchInputs<T> {
  itemList: Array<ITableRecordFormatting<T>>;
  data: Array<T>;
  localStorageKey: string;
  formatting: Array<ITableRecordFormatting<T>>;
}

export const useSelectiveSearch = <T extends {}>({
  itemList,
  data,
  localStorageKey,
  formatting,
}: ISelectiveSearchInputs<T>) => {
  const [searchBarValue, setSearchBarValue] = useState("");
  const collapsedItemList = JSON.stringify(itemList);

  const filterableColumns = useMemo(
    () =>
      itemList
        .filter((column) => column.name !== "" && column.visible)
        .map((item) => ({
          type: "Text",
          ...item,
          format: formatting.find((f) => f.name === item.name)?.format,
        })),
    [collapsedItemList]
  );

  const [searchEntries, setSearchEntries] = useLocalStorage(
    localStorageKey,
    Object.assign(
      {},
      ...filterableColumns.map((item) =>
        searchDictionary[item.type].defaultValue(item)
      )
    )
  );

  useEffect(() => {
    setSearchEntries(
      Object.assign(
        {},
        ...filterableColumns.map(
          (item) =>
            (searchEntries[item.name] && {
              [item.name]: searchEntries[item.name],
            }) ||
            searchDictionary[item.type].defaultValue(item)
        )
      )
    );
  }, [filterableColumns]);

  const setSearchEntry = (key, value) => {
    setSearchEntries(Object.assign(searchEntries, { [key]: value }));
  };

  const searchFilterFn = (testItem) =>
    filterableColumns.every((searchItem) =>
      searchDictionary[searchItem.type].filterFn(
        testItem,
        searchItem,
        searchEntries,
        formatting
      )
    );

  const searchFilter = (item) => {
    return filterableColumns
      .map((a) => {
        try {
          const format = formatting.find((f) => f.name === a.name);
          return (
            format?.displayFormat?.(item)?.toString() ||
            format?.format?.(item)?.toString() ||
            ""
          )
            ?.toLocaleLowerCase()
            .includes(searchBarValue.toLocaleLowerCase());
        } catch (e) {
          console.log(a, e, item);
        }
      })
      .some((a) => a);
  };

  const filterCallback = useCallback(
    (data) => data.filter(searchFilter).filter(searchFilterFn),
    [searchFilter, searchFilterFn]
  );

  const _data = JSON.stringify(data);
  const filteredData = useMemo(() => {
    return filterCallback(data);
  }, [_data, filterCallback]);

  const changes = Object.entries(searchEntries)
    .map(([name, value]) => ({
      name,
      value,
    }))
    .filter((d) => {
      if (Array.isArray(d.value)) {
        return d.value.filter((e) => e !== "").length > 0;
      }
      return d.value !== "";
    });

  const filteredFormatting = R.innerJoin(
    (formatItem: any, orderingItem: any) =>
      (orderingItem.name === formatItem.name && orderingItem.visible) ||
      formatItem.required,
    itemList,
    filterableColumns
  );
  return {
    searchFilterFn,
    setSearchEntry,
    searchEntries,
    filterCallback,
    filteredData,
    filterableColumns,
    changes,
    filteredFormatting,
    SearchUI: (
      <SearchUI
        searchableItems={filterableColumns}
        setSearchEntries={setSearchEntries}
        setSearchBarValue={setSearchBarValue}
        searchBarValue={searchBarValue}
        searchEntries={searchEntries}
        module={data.length > 0 ? data[0]["__typename"] : undefined}
      />
    ),
  };
};
