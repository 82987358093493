import { Header, Paragraph, AlignmentType, TextRun } from "docx";
import { DateTime } from "luxon";

export const docxHeader = ({ organization, group }) =>
  new Header({
    children: [
      new Paragraph({
        alignment: AlignmentType.LEFT,
        children: [
          new TextRun({
            children: ["Organization: "],
            bold: true,
            size: 18,
          }),
          new TextRun({ children: [organization], size: 18 }),
          new TextRun({
            children: ["Department: "],
            bold: true,
            size: 18,
            break: 1,
          }),
          new TextRun({ children: [group], size: 18 }),
        ],
      }),
      // new Paragraph({
      //   alignment: AlignmentType.RIGHT,
      //   contextualSpacing: true,
      //   children: [
      //     new TextRun(DateTime.now().toFormat("MMM dd, yyyy, hh:mm a")),
      //   ],
      // }),
    ],
  });
