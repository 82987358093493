import React from "react";
import { Status } from "API";
import {
  formatObj,
  formatPriority,
  formatSeverity,
  formatStatus,
} from "utils/formatStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { displayIcon } from "Modules/Customers/Dashboard/Panels";
import { Link } from "react-router-dom";
import { PriorityBadge, SeverityBadge, StatusBadge } from "common/Badges";
import {
  getActivityFeedUserFormat,
  getCompositeId,
  getDateTimeDisplayFormat,
  getDateTimeFormat,
  getUserFormatById,
} from "utils/dataFormatters";
import { usePermissions } from "common/Permissions";

export const useRelatedItemsTableSchema = () => {
  const { getOrganizationGroup } = usePermissions();
  const itemStatus = formatObj(Status, formatStatus);
  return [
    {
      id: "module",
      name: "Module",
      format: (item) => `${item.module}`,
      tableDisplayFormat: (item) => (
        <span title={item.icon}>
          <FontAwesomeIcon aria-hidden="true" icon={displayIcon(item.icon)} />
        </span>
      ),
    },
    {
      id: "department",
      name: "Department",
      format: (actionPlan) =>
        `${getOrganizationGroup(actionPlan.groupID)?.title}`,
      required: true,
      first: true,
    },
    {
      id: "title",
      name: "Title",
      format: (item) => item.title,
      tableDisplayFormat: (item) => {
        const moduleMap = {
          Issue: (data) => `/issues/${data.id}`,
          "Action Plan": (data) => `/actionplans/${data.id}`,
          Scenario: (data) => `/risk/${data.id}`,
        };
        return (
          <Link aria-hidden="true" to={moduleMap[item.module](item)}>
            {item.title}
          </Link>
        );
      },
    },
    {
      id: "status",
      name: "Status",
      format: ({ status }) => formatStatus(status),
      tableDisplayFormat: ({ status }) => <StatusBadge status={status} />,
      defaultVisible: true,
      type: "Options",
      optionValues: itemStatus,
    },
    {
      id: "severity",
      name: "Severity",
      format: (item) => formatSeverity(item.severity),
      tableDisplayFormat: ({ severity }) => (
        <SeverityBadge severity={severity} />
      ),
      defaultVisible: true,
      type: "Options",
    },
    {
      id: "priority",
      name: "Priority",
      format: (item) => formatPriority(item?.priority),
      tableDisplayFormat: ({ priority }) => (
        <PriorityBadge priority={priority} />
      ),
      defaultVisible: true,
      type: "Options",
    },
    {
      id: "owner",
      name: "Owner",
      format: getUserFormatById("owner"),
      activityFeed: getActivityFeedUserFormat("owner"),
      defaultVisible: false,
    },
    {
      id: "createdAt",
      name: "Created At",
      type: "Date",
      format: getDateTimeFormat("createdAt"),
      displayFormat: getDateTimeDisplayFormat("createdAt"),
      defaultVisible: false,
    },
    {
      id: "lastModified",
      name: "Last Modified",
      type: "Date",
      format: getDateTimeFormat("lastModified"),
      displayFormat: getDateTimeDisplayFormat("lastModified"),
      defaultVisible: false,
    },
  ];
};
