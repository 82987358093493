import { gql } from "@apollo/client";
import {
  GetAssetsQuery,
  GetAssetsQueryVariables,
  GetAssetsInGroupv2Query,
  GetAssetsInGroupv2QueryVariables,
  GetAssessmentAssetsQuery,
  GetAssessmentAssetsQueryVariables,
} from "API";
import { usePermissions } from "common/Permissions";
import {
  getAssessmentAssets,
  getAssets,
  getAssetsInGroupv2,
} from "graphql/queries";
import { useGetGroupByRelatedItem } from "utils/useGetGroupByRelatedItems";
import { useGroupCheck } from "utils/useGroupCheck";
import {
  useProtectedPolling,
  useProtectedQuery,
} from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";
import { useGetThreatsTableHtml } from "./threatsConnectors";
import { AssetType } from "Modules/Customers/HIPAARiskAssessment/Assets/AssetType";

export const useGetAssetsInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetAssetsInGroupv2Query,
    GetAssetsInGroupv2QueryVariables
  >(
    gql(getAssetsInGroupv2),
    {
      variables: {
        id: group.id,
      },
    },
    hasGroup
  );

  const assets = (
    response.data?.getAssetsInGroupv2?.items.map((item) => item.assets) || []
  ).map(sortToID("ASSETS#"));

  return { ...response, assets };
};

export const useGetAssets = (id) => {
  const { hasGroup } = usePermissions();
  const response = useProtectedQuery<GetAssetsQuery, GetAssetsQueryVariables>(
    gql(getAssets),
    {
      variables: {
        id,
      },
    },
    hasGroup
  );
  const canAccess = useGroupCheck(
    response?.data?.getAssets?.groupID,
    "/HIPAARiskAssessment/assets"
  );

  const assets: AssetType | undefined =
    (canAccess && sortToID("ASSETS#")(response?.data?.getAssets)) || undefined;

  useProtectedPolling(assets, response);

  return {
    ...response,
    assets,
  };
};

export const useGetAssessmentAssets = (id) => {
  const { hasGroup } = usePermissions();
  const response = useProtectedQuery<
    GetAssessmentAssetsQuery,
    GetAssessmentAssetsQueryVariables
  >(
    gql(getAssessmentAssets),
    {
      variables: {
        id,
      },
    },
    hasGroup
  );
  const assessmentAssets = response?.data?.getAssessmentAssets?.items;
  return { assessmentAssets, ...response };
};

export const useGetDownloadAssetsObj = (item, files) => {
  const { getOrganizationUser, groups } = usePermissions();
  const groupedRelatedItems = useGetGroupByRelatedItem(
    item?.assetRelatedItems?.items,
    groups
  );
  const getPHIRecordHTMLTable = (PHIRecords) => {
    const parsedPHIRecords = JSON.parse(PHIRecords);
    return `<table><tbody><tr><th>Min</th><th>Most Likely</th><th>Max</th><th>Confidence</th></tr><tr><td>${parsedPHIRecords.min}</td><td>${parsedPHIRecords.mode}</td><td>${parsedPHIRecords.max}</td><td>${parsedPHIRecords.confidence}</td></tr></tbody></table>`;
  };
  return (item) => {
    if (item) {
      const owner = getOrganizationUser(item?.owner)?.displayName;
      const category = item.assetCategory?.title;
      const location = item.assetLocation?.title;
      const support = item.assetSupport?.title;
      const PHIRecords =
        item?.PHIRecords && getPHIRecordHTMLTable(item?.PHIRecords);
      const newThreatsArray = item?.assetAndThreatsRelatedItems?.items?.map(
        (currentItem) => {
          const newItem = currentItem.threatID;
          const threatTitle = item.Threats.find(
            (i) => i.itemID === newItem
          )?.title;
          return {
            ...currentItem,
            title: threatTitle,
          };
        }
      );
      const reducedThreatsArray = newThreatsArray?.map((item) => {
        return {
          title: item.title,
          min: item.min,
          mostLikely: item.mostLikely,
          max: item.max,
          vulnerability: item.vulnerability,
        };
      });
      const threatsHTML = useGetThreatsTableHtml(reducedThreatsArray);
      item = {
        ...item,
        ID: item?.displayID,
        category,
        location,
        support,
        owner,
        assetsGroup: item.assetGroup?.title || "",
        PHIRecords: PHIRecords || "",
        threats: threatsHTML || "",
        relatedItems: groupedRelatedItems || " ",
      };

      const assetsArray = [
        "title",
        "ID",
        "qty",
        "notes",
        "notesAboutControls",
        "notesAboutThreats",
        "support",
        "location",
        "category",
        "owner",
        "assetsGroup",
        "PHIRecords",
        "threats",
        "relatedItems",
      ];

      const selectArray = (arr, obj) =>
        arr.reduce((r, e) => Object.assign(r, { [e]: obj[e] }), {});
      return item && selectArray(assetsArray, item);
    }
  };
};
