import React from "react";
import { Badge } from "react-bootstrap";
import { formatStatus } from "utils/formatStatus";

export const StatusBadge: React.FC<{ status?: string | null }> = ({
  status = "Not Set",
}) => {
  const formattedStatus = formatStatus(status);
  return (
    <Badge pill className={status || ""} title={formattedStatus}>
      {formattedStatus}
    </Badge>
  );
};
