import { UpdateRiskDomainInput } from "API";
import { HGFormControl } from "common/HGForm";
import { HGRichText } from "common/HGForm/HGRichText";
import { HGSelect, HGInputBox } from "common/HGForm/Inputs";
import React from "react";
import { useGetRiskDomainInGroup } from "utils/connectors/riskDomainConnectors";

const RiskDomainInputs: React.FC<{ riskDomain?: any }> = ({ riskDomain }) => {
  const _riskDomains = useGetRiskDomainInGroup();
  const parentRiskDomain = [{ id: "", title: "" }];

  _riskDomains?.riskDomains.forEach(function (element) {
    if (riskDomain?.id !== element?.id) {
      return element?.archived === true
        ? parentRiskDomain.push({
            id: element?.id,
            title: `${element?.title}   (Archived)`,
          })
        : parentRiskDomain.push({ id: element?.id, title: element?.title });
    }
  });
  return (
    <>
      <HGInputBox
        name="displayID"
        label="ID"
        defaultValue={riskDomain?.displayID}
      />
      <HGInputBox name="title" label="Title" defaultValue={riskDomain?.title} />
      <HGSelect
        label="Parent"
        name="parentID"
        options={parentRiskDomain || []}
        defaultValue={riskDomain?.parentID}
        overrideFormat={riskDomain?.parent?.title}
      />
      <HGRichText
        label="Description"
        name="description"
        defaultValue={riskDomain?.description}
      />
    </>
  );
};

export default RiskDomainInputs;
