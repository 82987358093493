import { gql, useMutation, ApolloError } from "@apollo/client";
import { modifyRiskDomain } from "graphql/mutations";
import {
  ModifyRiskDomainMutation,
  ModifyRiskDomainMutationVariables,
  UpdateRiskDomainInput,
} from "API";
import { HGForm, HGFormSubmit, HGToggleSubmit } from "common/HGForm";
import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { cancelPopupMessage } from "utils/globalVariables";
import {
  useGetRiskDomain,
  useGetRiskDomainInGroup,
} from "utils/connectors/riskDomainConnectors";
import RiskDomainInputs from "./RiskDomainInputs";
import Loader from "common/Loader";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import * as yup from "yup";
import { usePermissions } from "common/Permissions";

export const EditRiskDomain = ({ refetch = () => {} }) => {
  const { id } = useParams<{ id: string; task: string }>();
  const { groupRole } = usePermissions();
  const history = useHistory();
  const riskDomain = useGetRiskDomain(id);
  const [runModifyRiskDomain] = useMutation<
    ModifyRiskDomainMutation,
    ModifyRiskDomainMutationVariables
  >(gql(modifyRiskDomain), {
    onCompleted: () => {
      ToastifyQueue("Risk Domain Updated Successfully.", "success");
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });

  const goToRiskDomain = () => {
    history.push(`/systemManagement/systemLists/riskDomain`);
  };
  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      goToRiskDomain();
    }
  }

  const { riskDomains } = useGetRiskDomainInGroup();
  const riskDomainsData = [] as any;
  riskDomains?.forEach((d) => {
    if (d?.title !== "" && d?.id !== id) {
      riskDomainsData.push(d?.title.trim());
    }
  });

  const RiskDomainSchema = yup.object<Partial<UpdateRiskDomainInput>>({
    displayID: yup.string().label("ID"),
    title: yup
      .string()
      .required()
      .label("Title")
      .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
      .test(
        "check-duplication",
        "Risk Domain title already exists!",
        (value) => {
          const index = riskDomainsData.findIndex((element) => {
            return element.toLowerCase() === value?.toLowerCase().trim();
          });
          return index === -1;
        }
      ),
    description: yup.string().label("Description"),
    parentID: yup.string().label("Parent"),
  });

  return (
    <Modal show onHide={Confirmation}>
      <HGForm
        onSubmit={async (input, e) => {
          return runModifyRiskDomain({
            variables: {
              input: {
                ...input,
                id,
              },
            },
          })
            .then(() => e.target?.reset())
            .then(refetch)
            .then(goToRiskDomain);
        }}
        resolver={RiskDomainSchema}
        editToggle
      >
        <Modal.Header>
          {groupRole !== "read" ? "Edit Risk Domain" : "Risk Domain"}

          <HGToggleSubmit className="float-right" />
        </Modal.Header>
        <Modal.Body>
          <RiskDomainInputs riskDomain={riskDomain} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn cancel-btn"
            style={{ marginRight: "5px" }}
            data-dismiss="modal"
            onClick={() => {
              Confirmation();
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </HGForm>
    </Modal>
  );
};
