import { useMutation, gql, ApolloError } from "@apollo/client";
import { CreateSourceInput } from "API";
import { HGForm, HGFormControl, HGFormSubmit } from "common/HGForm";
import { HGRichText } from "common/HGForm/HGRichText";
import { usePermissions } from "common/Permissions";
import { createSource } from "graphql/mutations";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as yup from "yup";
import { cancelPopupMessage } from "utils/globalVariables";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { useGetSourcesInGroup } from "../Issues/IssuePage/Sources";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";
import { HGNumber, HGInputBox } from "common/HGForm/Inputs";

export const CreateSourceButton: React.FC<{
  onComplete?: Function;
  onOpen?: Function;
  onClose?: Function;
  onCreate: (any) => any;
}> = ({ onComplete, onOpen, onClose, onCreate, ...props }) => {
  const { group, organization } = usePermissions();
  const { sources } = useGetSourcesInGroup();
  const [showCreateSource, setShowCreateSource] = useState<boolean>(false);

  const [mutationCreateSource] = useMutation(gql(createSource), {
    onCompleted: (response) => {
      ToastifyQueue("Source Created Successfully.", "success");
      setShowCreateSource(false);
      onComplete?.(response?.CreateSource);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });

  const createSourceSchema = useMemo(
    () =>
      yup.object<Partial<CreateSourceInput>>({
        title: yup
          .string()
          .default("")
          .required()
          .label("Title")
          .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
          .test(
            "check-duplication",
            "Source title already exists!",
            (value) => {
              if (!value) return true;
              return !isUniqueTitle(sources, value);
            }
          ),
        organizationID: yup
          .string()
          .default(organization?.id)
          .required()
          .label("OrganizationID"),
        groupID: yup.string().default(group?.id).required().label("GroupID"),
        description: yup.string().label("Description"),
        year: yup
          .string()
          .default("")
          .label("Year")
          .test(
            "is-date",
            // eslint-disable-next-line
            "${path} should be in YYYY format",
            (value) => {
              if (value) {
                const date = DateTime.fromFormat(value, "yyyy");
                return date?.isValid;
              }
              return true;
            }
          ),
        archived: yup.boolean().required().default(false),
      }),
    [group, sources]
  );

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowCreateSource(false);
    }
  }

  useEffect(() => {
    if (showCreateSource) {
      return onOpen?.();
    }
    return onClose?.();
  }, [showCreateSource]);
  return (
    <>
      <Button
        {...props}
        onClick={() => {
          setShowCreateSource(true);
        }}
      >
        Create Source
      </Button>
      <Modal
        show={!!showCreateSource}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          // key={"form_createSource"}
          onSubmit={async (input) => {
            return mutationCreateSource({ variables: { input } }).then(
              onCreate
            );
          }}
          resolver={createSourceSchema}
        >
          <Modal.Header>Create Source</Modal.Header>
          <Modal.Body>
            <HGInputBox label="Title" name="title" />
            <HGNumber label="Year" name="year" />
            <HGRichText
              label="Description"
              name="description"
              defaultValue=""
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-white"
              style={{ marginRight: "5px" }}
              data-dismiss="modal"
              onClick={() => {
                Confirmation();
              }}
            >
              Cancel
            </button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
