// @ts-nocheck
import { gql } from "@apollo/client";
import {
  GetOwnedNotificationsQuery,
  GetOwnedNotificationsQueryVariables,
  FilterSortDirection,
} from "API";
import { usePermissions } from "common/Permissions";
import { getOwnedNotifications } from "graphql/queries";
import { useProtectedQuery } from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";
import { useLocation } from "react-router";

export const useGetOwnedNotifications = (limit?: any) => {
  const location = useLocation();
  const { userId, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetOwnedNotificationsQuery,
    GetOwnedNotificationsQueryVariables
  >(
    gql(getOwnedNotifications),
    {
      variables: {
        id: userId,
        sortDirection: FilterSortDirection.DESC,
        ...(limit &&
          !location.pathname.startsWith("/AllNotifications") && { limit }),
      },
    },
    hasGroup
  );

  const notifications = (response.data?.getOwnedNotifications?.items || [])
    .map(({ notification }) => notification)
    .map(sortToID("NOTIFICATION#"));

  return { ...response, notifications };
};
