import React from 'react'
import { Switch, Route } from "react-router";

const AssessmentList = React.lazy(
  () => import("Modules/Customers/Assessment/AssessmentList"),
);
const AssessmentDisplay = React.lazy(
  () => import("Modules/Customers/Assessment/AssessmentDisplay"),
);
const AssessmentResponse = React.lazy(
  () => import("Modules/Customers/Assessment/AssessmentResponse") as any,
);
const HIPAAGapAssessmentRoutes = () => {
  return (
    <Switch>
      <Route exact path="/assessments/HIPAAGapAssessment/response/:id">
        <AssessmentResponse />
      </Route>
      <Route exact path="/assessments/HIPAAGapAssessment/:id">
        <AssessmentDisplay />
      </Route>
      <Route exact path="/assessments/HIPAAGapAssessment">
        <AssessmentList />
      </Route>
    </Switch>
  )
}

export default HIPAAGapAssessmentRoutes