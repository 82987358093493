import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useEditContext } from "../HGFormContext";
import { Form } from "react-bootstrap";
import { HGLabel, useHGFormError } from "./utils/HGInput.utils";
export const HGRadioButton: React.FC<{
  name: string;
  label?: string;
  defaultValue?: string | null;
  required?: boolean;
  defaultLabel?: string;
  options: any[];
  error?: string | boolean | undefined;
}> = ({ name, defaultValue, defaultLabel, options, ...props }) => {
  const methods = useFormContext();
  const { register, setValue } = methods;
  const { editing } = useEditContext();
  const { ErrorDisplay, isInvalid } = useHGFormError({
    name,
    overrideError: props.error,
    editing,
  });
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const handleRadioChange = (value: string) => {
    setSelectedValue(value); // Update the selected value
    setValue(name, value); // Update the form context with the selected value
  };
  return (
    <>
      <HGLabel {...props} />
      {(!editing && <p>{defaultLabel || "Not Set"}</p>) || (
        <>
          {options.map((option, index) => {
            return (
              <Form.Group className="mb-3">
                <Form.Check
                  ref={register}
                  label={option.label}
                  type="radio"
                  name={name}
                  value={option.value}
                  inline
                  onChange={() => handleRadioChange(option.value)}
                  defaultChecked={defaultValue === option.value}
                  checked={selectedValue === option.value}
                  isInvalid={isInvalid}
                />
              </Form.Group>
            );
          })}
        </>
      )}
      <ErrorDisplay />
    </>
  );
};
