import React from "react";

import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

import "./NavItem.scss";

import { useCollapsibleContext } from "./CollapsibleContext";

interface NavItemProps {
  name: string;
  link: string;
  icon?: IconDefinition;
  activeExclude?: string | string[];
  id: string;
}
const NavItem: React.FC<NavItemProps> = ({
  name,
  link,
  icon,
  children,
  activeExclude,
  id,
}) => {
  const location = useLocation();
  const { collapsed } = useCollapsibleContext();
  const checkActive = () => {
    if (location.pathname.indexOf(link) !== 0) return false;
    if (!activeExclude) return true;
    if (Array.isArray(activeExclude)) {
      const retVal = activeExclude.map(
        (exclude) => location.pathname.indexOf(exclude) === 0,
      );

      return !retVal.includes(true);
    }
    if (typeof activeExclude === "string") {
      return location.pathname.indexOf(activeExclude || "") !== 0;
    }
  };
  const active = checkActive();
  return (
    <li
      data-testid="navitem"
      className={`nav-item ${(active && "active") || "inactive"}`}
      style={{ width: "100%" }}
      title={collapsed === true && icon ? name : ""}
      id={id}
    >
      <Link to={link}>
        {(icon && <FontAwesomeIcon className="nav-icon" icon={icon} />) || (
          <div />
        )}
        <span className="nav-label">{name}</span>
      </Link>
      {children && <ul className="nav nav-second-level">{children}</ul>}
    </li>
  );
};

export default NavItem;
