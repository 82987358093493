import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { BulkEdit } from "common/bulkEdit";
import { ScenarioSelectorCheckbox } from "../../Modules/Customers/Risk/Comparison/TableComparison";
import { AssetSelectorCheckbox } from "../../Modules/Customers/HIPAARiskAssessment/Assets/BulkEditAssets";
import { usePermissions } from "common/Permissions";

export default (props: any) => {
  const {
    prefSaveSortOrder,
    calculatedSortBy,
    calculatedSortOrder,
    setSortBy,
    sortBy,
    setSortOrder,
    sortOrder,
    formatting,
    headerAppend,
    hideSort,
    type,
  } = props;
  const { groupRole } = usePermissions();
  return (
    <thead>
      <tr>
        {formatting.map((f, i) => {
          const header =
            headerAppend && headerAppend.find((d) => d.name === f.name);
          return (
            <th
              style={{
                whiteSpace: "nowrap",
                ...(f.tableDisplayStyle || {}),
              }}
              className={f.className}
              key={i}
              onClick={() => {
                if (f.sortable !== false) {
                  if (prefSaveSortOrder) {
                    const _sortBy = f.name;
                    const _sortOrder =
                      calculatedSortBy === f.name &&
                      calculatedSortOrder === "asc"
                        ? "desc"
                        : "asc";
                    prefSaveSortOrder({
                      sortBy: _sortBy,
                      sortOrder: _sortOrder,
                    });
                  } else {
                    setSortBy(f.name);
                    setSortOrder(
                      sortBy === f.name && sortOrder === "asc" ? "desc" : "asc"
                    );
                  }
                }
              }}
            >
              {(!f.hideColumnName && f.name) ||
                (f.id === "checkbox" &&
                  (() => {
                    if (type === "Assets") {
                      return (
                        <AssetSelectorCheckbox
                          asset="selectAll"
                          userRole={groupRole}
                        />
                      );
                    }
                    if (type === "Scenario") {
                      return (
                        <ScenarioSelectorCheckbox
                          scenario="selectAll"
                          userRole={groupRole}
                        />
                      );
                    }
                    return <BulkEdit item="selectAll" />;
                  })()) ||
                ""}
              {header && header.value}
              {!f.hideColumnName && !hideSort && f.name && (
                <span
                  className="fa-layers fa-fw"
                  style={{ display: "inline-block" }}
                >
                  <FontAwesomeIcon
                    className="nav-icon"
                    icon={faSortUp}
                    style={{
                      color:
                        (calculatedSortBy === f.name &&
                          calculatedSortOrder === "asc" &&
                          "#999999") ||
                        "#cccccc",
                    }}
                  />
                  <FontAwesomeIcon
                    className="nav-icon"
                    icon={faSortDown}
                    style={{
                      color:
                        (calculatedSortBy === f.name &&
                          calculatedSortOrder === "desc" &&
                          "#999999") ||
                        "#cccccc",
                    }}
                  />
                </span>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
