import {
  AlignmentType,
  BorderStyle,
  Document,
  Header,
  ImageRun,
  Packer,
  Paragraph,
  ShadingType,
  TableOfContents,
  TextRun,
} from "docx";
import { docxHeader } from "./docx-header";
import { docxFooter } from "./docx-footer";
import { generateOLNumbering } from "./docConfig";
import healthGuardLogo from "common/layout/Header/HealthGuard DecipherRisk.jpg";
import Axios from "axios";

export const exportDocX = async ({
  contents,
  ols,
  organization,
  group,
  titlePage,
  tableOfContents,
}) => {
  const olConfig = generateOLNumbering(ols);
  const targetImageHeight = 60;
  const logoImage = new Image();
  logoImage.src = healthGuardLogo;
  await logoImage.decode();
  const { height, width } = logoImage;

  const scaledWidth = (width / height) * targetImageHeight;

  const logo = await Axios(healthGuardLogo, {
    responseType: "blob",
  }).then((response) => response.data);
  const doc = new Document({
    numbering: {
      config: olConfig,
    },
    features: {
      updateFields: false,
    },
    styles: {
      characterStyles: [
        {
          id: "codeBlock",
          name: "Code Block",
          basedOn: "Normal",
          run: {
            font: "Courier New",
            border: {
              color: "000000",
              size: 2,
              space: 0,
              style: BorderStyle.SINGLE,
            },
            shading: {
              fill: "EEEEEE",
              color: "EEEEEE",
              type: ShadingType.SOLID,
            },
          },
        },
      ],
      default: {
        heading1: {
          run: {
            font: "Arial",
            size: 48,
            bold: true,
            color: "000000",
          },
          paragraph: {
            spacing: {
              after: 120,
            },
            alignment: AlignmentType.CENTER,
          },
        },
        heading2: {
          run: {
            font: "Arial",
            size: 44,
            bold: true,
            color: "000000",
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
          },
        },
        heading3: {
          run: {
            font: "Arial",
            size: 40,
            bold: true,
            color: "000000",
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
          },
        },
        heading4: {
          run: {
            font: "Arial",
            size: 36,
            bold: true,
            color: "000000",
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
          },
        },
        heading5: {
          run: {
            font: "Arial",
            size: 32,
            bold: true,
            color: "000000",
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
          },
        },
        heading6: {
          run: {
            font: "Arial",
            size: 28,
            bold: true,
            color: "000000",
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
          },
        },
        document: {
          run: {
            font: "Arial",
            size: 24,
          },
        },
      },
      paragraphStyles: [
        {
          id: "richText-h1",
          name: "Rich Text H1",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Arial",
            size: 36,
            bold: true,
            color: "000000",
          },
          paragraph: {
            spacing: {
              after: 60,
            },
            alignment: AlignmentType.LEFT,
          },
        },
        {
          id: "richText-h2",
          name: "Rich Text H2",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Arial",
            size: 32,
            bold: true,
            color: "000000",
          },
          paragraph: {
            spacing: {
              after: 60,
            },
            alignment: AlignmentType.LEFT,
          },
        },
        {
          id: "richText-h3",
          name: "Rich Text H3",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Arial",
            size: 28,
            bold: true,
            color: "000000",
          },
          paragraph: {
            spacing: {
              after: 60,
            },
            alignment: AlignmentType.LEFT,
          },
        },
        {
          id: "richText-h4",
          name: "Rich Text H4",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Arial",
            size: 24,
            bold: true,
            color: "000000",
          },
          paragraph: {
            spacing: {
              after: 60,
            },
            alignment: AlignmentType.LEFT,
          },
        },
        {
          id: "richText-h5",
          name: "Rich Text H5",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Arial",
            size: 20,
            bold: true,
            color: "000000",
          },
          paragraph: {
            spacing: {
              after: 60,
            },
            alignment: AlignmentType.LEFT,
          },
        },
        {
          id: "richText-h6",
          name: "Rich Text H6",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Arial",
            size: 16,
            bold: true,
            color: "000000",
          },
          paragraph: {
            spacing: {
              after: 60,
            },
            alignment: AlignmentType.LEFT,
          },
        },
        {
          id: "MainStyle",
          name: "Main Style",
          basedOn: "Heading1",
          next: "Heading1",
          quickFormat: true,
          run: {
            font: "Arial",
            italics: true,
            color: "990000",
          },
        },
      ],
    },
    sections: [
      {
        properties: {
          verticalAlign: "center", // Set vertical alignment to center
        },
        children: [
          ...titlePage,
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: "Powered by",
              }),
              new ImageRun({
                data: logo,
                transformation: {
                  height: targetImageHeight,
                  width: scaledWidth,
                },
              }),
            ],
            spacing: { before: 8000 },
          }),
        ],
      },
      ...(tableOfContents
        ? [
            {
              headers: {
                default: new Header({
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      text: organization,
                    }),
                  ],
                }),
              },
              children: [
                new TableOfContents("Table of content", {
                  hyperlink: true,
                  headingStyleRange: "1-5",
                }),
              ],
              footers: {
                default: await docxFooter(),
              },
            },
          ]
        : []),
      {
        properties: {
          titlePage: false,
        },
        headers: {
          default: docxHeader({ organization, group }),
        },
        footers: {
          default: await docxFooter(),
        },
        children: contents,
      },
    ],
  });

  return Packer.toBlob(doc);
};
