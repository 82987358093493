import React from 'react'
import {
  Col, Nav, Row, 
} from 'react-bootstrap'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RiskDomain } from "Modules/Customers/Audit/RiskDomain";
import { EditRiskDomain } from "Modules/Customers/Audit/RiskDomain/EditRiskDomain";
import { SourceList } from "Modules/Customers/Sources/SourceList/SourceList";
import { LinkContainer } from 'react-router-bootstrap';

const ControlsList  = React.lazy(
  () => import("Modules/Customers/HIPAARiskAssessment/Controls/ControlsList") as any,
);
const ArtifactList = React.lazy(
  () => import("Modules/Customers/Artifacts/ArtifactsList"),
);

const SystemLists = () => {
  const tabItems = [
    {
      id: 'Risk Domains',link: 'riskDomain',
    },
    {
      id: 'Sources', link: 'sources',
    },
    {
      id: 'Artifacts', link: 'artifacts',
    },
    {
      id: 'Controls', link: 'controls',
    },
  ]
  return (
    <>
      <br/>
      <Row className="dashboard-header">
      <Col xs="12">
          <Nav variant="tabs" style={{ display: "inline-flex" }}>
            {tabItems.map((item)=>{
              return([
                <LinkContainer exact to={`/systemManagement/systemLists/${item.link}`}>
                  <Nav.Link>{item.id.charAt(0).toUpperCase() + item.id.slice(1)}</Nav.Link>
                </LinkContainer>,
              ])
            })}
          </Nav>
      </Col>
      </Row>
    <Switch>
      <Route exact path="/systemManagement/systemLists/riskDomain/:id" component={EditRiskDomain}/>
      <Route path="/systemManagement/systemLists/riskDomain" component={RiskDomain}/>
      <Route path="/systemManagement/systemLists/sources" component={SourceList}/>
      <Route path="/systemManagement/systemLists/artifacts" component={ArtifactList}/>
      <Route path="/systemManagement/systemLists/controls" component={ControlsList}/>
      <Redirect to="/systemManagement/systemLists/riskDomain"/>
    </Switch>
    </>
  )
}

export default SystemLists