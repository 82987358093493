import React, { useState, useRef } from "react";

import ResultHolder from "./ResultHolder";
import { InputGroup, FormControl, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useGetControlsSurvey } from "utils/connectors/controlsConnectors";
import useOnLoseTreeFocus from "utils/useOnLoseTreeFocus";
import "./SearchBar.scss";


interface SearchBarProps {
  data: any;
  onUserCreate: Function;
}
interface SearchBarState {
  filter: string;
  type: Object;
  open: boolean;
  addNew: boolean;
}

export const SearchBar = ({
  types,
  attachedData,
  entities,
  attach,
  detach,
  refetch,
  id,
}) => {
  const [filter, setFilter] = useState("");
  const [type, setType] = useState(types[0]);
  const [modalOpen, setModalOpen] = useState(false);
  const [treeActive, setTreeActive] = useState(false);
  /* eslint-disable-next-line */
  const [addNew, setAddNew] = useState(false);
  const [fileType, setFileType] = useState<any>('NIST-CSF');

  const wrapperRef = useRef(null);
  useOnLoseTreeFocus(wrapperRef, () => {
    setTreeActive(false);
  })
  let currentEntities;
  const controlsList: any[] = [];
  function getControlsChildren(obj) {
    obj?.forEach((subObj: any) => {
      if (subObj?.controlsList?.items?.length > 0) {
        getControlsChildren(subObj.controlsList?.items);
      }
      else {
        controlsList.push(subObj)
      }
    })
    return currentEntities = controlsList;
  }
  const { controls } = useGetControlsSurvey();
  if (type?.name === "Controls") {
    if (fileType === "NIST-800-53") {
      controls && controls.map((item) => {
        if (item?.uuid === "control-base-NIST-800-53") {
          return currentEntities = getControlsChildren(item?.controlsList?.items)
        }
      })
    }
    else if (fileType === "NIST-CSF") {
      controls && controls?.map((item) => {
        if (item?.uuid === "control-base-NIST-CSF") {
          return currentEntities = getControlsChildren(item?.controlsList?.items)
        }
      })
    }
  }
  else {
    currentEntities = entities?.filter((d) => {
      return type?.filter(d);
    });
  }
  return (
    <div style={{ display: "relative" }} ref={wrapperRef}>
      <InputGroup>
        <FormControl
          className="searchinput"
          type="text"
          placeholder={`Search for ${type.name}...`}
          value={filter}
          onFocus={(e) => {
            setTreeActive(true);
            setAddNew(false);
          }}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />

        <InputGroup.Append>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          
          {
            type?.name === "Controls" ? <Dropdown
                onSelect={(e) => setFileType(e)}
            >
                <Dropdown.Toggle>
                  <div style={{ float: "right" }}>
                    <b className="caret" />
                  </div>
                  {fileType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="NIST-CSF">NIST-CSF</Dropdown.Item>
                  <Dropdown.Item eventKey="NIST-800-53">NIST-800-53</Dropdown.Item>
                </Dropdown.Menu>
                                        </Dropdown> : <></>
          }
        </InputGroup.Append>
      </InputGroup>

      <ResultHolder
        attachedData={attachedData}
        data={currentEntities}
        filter={filter}
        type={type}
        {...{
          attach,
          detach,
          setOpen: setTreeActive,
          setModalOpen,
          refetch,
          id,
        }}
      />
    </div>
  );
};
