import React from 'react'
import { toast } from "react-toastify";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToastifyQueue = ( message, type) => {
  switch (type) {
    case "success":
      return toast.success(message, {
        closeButton: (
            <FontAwesomeIcon icon={faTimes} style={{ color: "#155724" }} />
        ),
        style: {
          color: "#155724",
          background: "#d4edda",
        },
      });
    case "danger":
      return toast.error(message);
    case "warning":
      return toast.warning(message);
    case "primary":
      return toast.info(message);
    default:
      break;
  }
}

export default ToastifyQueue