export const tableSorter = (dataFormatter) => {
  return (a, b) => {
    const format = dataFormatter.sortFormat || dataFormatter.format;

    const _aVal = format(a);
    const _bVal = format(b);

    const aVal =
      _aVal?.isLuxonDateTime || typeof _aVal === "number"
        ? _aVal
        : _aVal?.trim()?.toLowerCase();

    const bVal =
      _bVal?.isLuxonDateTime || typeof _bVal === "number"
        ? _bVal
        : _bVal?.trim()?.toLowerCase();

    if (bVal === undefined || bVal === null) {
      return -1;
    }
    if (aVal < bVal) {
      return -1;
    }
    if (aVal > bVal) {
      return 1;
    }
    return 0;
  };
};
